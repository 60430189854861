import { get, call } from 'vuex-pathify'
import UsesFeatureFlags from './UsesFeatureFlags'

export default {
  mixins: [ UsesFeatureFlags ],
  computed: {
    ...get({
      userIsAuthenticated: 'auth/isAuthenticated',
      authenticatedUser: 'auth/user',
    }),
    authenticatedUserId () {
      return this.authenticatedUser?.id
    },
    authenticatedUserIsLearner () {
      return this.authenticatedUser.type === 'Learner'
    },
    authenticatedUserIsAdvisor () {
      return this.authenticatedUser.type === 'Advisor'
    },
  },
  methods: {
    ...call({
      incrementAuthenticatedUserStat: 'auth/incrementUserStat'
    })
  }
}
