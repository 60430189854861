import {
  pages,
  errors,
  fields,
  confirm,
  tour,
  booking,
  login,
  signUp,
  kintellCard,
  myKintell,
  uploadImage,
  notInterested,
  modal,
  codeOfConduct,
  faq,
  resources,
  articles,
  becomeAnAdvisor,
  roundup,
  giftCards,
  feature,
  groupAdmin,
  library,
} from './en/'

export default {
  /**
   * Application translations
   */
  app: {
    /**
     * Pages - Used for navigation and links
     */
    pages: {
      ...pages,
    }
  },
  /**
   * Generic error messages
   */
  errors: {
    ...errors,
  },

  fields: {
    ...fields,
  },

  confirm: {
    ...confirm,
  },
  tour: {
    ...tour,
  },
  /* ---
   * Page translations
   * ---
   */
  // Login page
  login: {
    ...login,
  },
  // sign up page
  signUp: {
    ...signUp,
  },
  // booking stuff
  booking: {
    ...booking,
  },
  // kintell card edit page
  kintellCard: {
    ...kintellCard,
  },
  myKintell: {
    ...myKintell
  },
  uploadImage: {
    ...uploadImage
  },
  codeOfConduct: {
    ...codeOfConduct
  },
  faq: {
    ...faq
  },
  articles,
  resources,
  feature: {
    ...feature
  },
  giftCards,

  becomeAnAdvisor,

  notInterested: {
    ...notInterested
  },
  modal: {
    ...modal
  },
  roundup: {
    ...roundup
  },
  groupAdmin: {
    ...groupAdmin
  },

  library,

  /**
   * Note: Below is the legacy way of adding page / feature translations. Use seperate.js files
   */
  // Home page
  home: {
    signIn: {
      message: '{link} to search for an Advisor',
      link: 'Log in'
    }
  },
  // forgot password page
  forgotPassword: {
    title: 'Forgot Password',
    description: 'Enter your email below and we’ll send you the reset link.',
    form: {
      guide: 'Please enter your email address so we can find your account.',
      submit: 'Confirm',
      labels: {
        email: 'Email'
      }
    },
    errors: {
      noUser: {
        label: 'Sorry, we can’t find that email address. Perhaps try an alternative, or {getInTouch}.',
        getInTouch: 'Get in touch'
      },
    },
    alerts: {
      success: 'Thanks! We’ve sent a link to that email so you can reset your password. You can close this window now.'
    }
  },
  // reset password page
  resetPassword: {
    title: 'Reset Password',
    guide: 'Please enter a new password for your account.',
    form: {
      newPassword: 'New Password',
      retypePassword: 'Retype Your New Password',
      submit: 'Submit',
      hint: 'Please use at least 12 characters containing numbers, uppercase and lowercase letters.'
    },
    errors: {
      noUser: 'No user exists with this email address.',
    },
    alerts: {
      success: 'Your password has been reset.',
      passwordUpdated: 'Your password has been updated.',
      invalid: 'Your reset token is invalid.'
    },
    button: {
      continue: 'Continue to Kintell',
    }
  },
  //  invitation request
  joinNowModalAdvisorProfile: {
    title: 'Ready to book {name}?',
    subTitle: 'Joining Kintell will give you access to book {name} and all our advisors - it\'s free and takes less than a minute.',
    submit: 'Join now'
  },
  joinNowModalKintellCard: {
    title: 'Ready to book {name}?',
    subTitle: 'Joining Kintell will give you access to book {name} and all our advisors - it\'s free and takes less than a minute.',
    submit: 'Join now'
  },
  // verify mobile component
  verifyMobile: {
    title: 'Mobile verification - your number',
    resendCode: 'Try again',
    alternative: 'or alternatively, Kintell can',
    requestCall: 'call you now',
    needCode: 'Didn’t receive your code?',
    subtitle: {
      phoneNumber: 'As a final step, we need to verify your mobile number so we can send you SMS booking reminders :)',
      code: 'Thanks, we’ve sent a verification code to {phone}. Please enter it below.'
    },
    noMarketing: 'Kintell will never use your mobile number for marketing purposes.',
    submit: {
      phoneNumber: 'SMS me the code',
      code: 'Confirm'
    },
    toast: {
      verified: 'Thanks for verifying your mobile!',
    },
    error: {
      // API response when Twilio says the number is invalid
      phoneNumber: 'Looks like the mobile {phone} isn\'t quite right.',
      code: 'The code {code} doesn\'t match what we were expecting. Please try again.'
    },
  },
  verifyEmail: {
    submit: {
      email: 'Email me the code',
      code: 'Confirm'
    },
    toast: {
      verified: 'Thanks for verifying your email!',
    },
    error: {
      email: 'Looks like the email isn\'t quite right.',
      code: 'The code {code} doesn\'t match what we were expecting. Please try again.'
    },
  },
  // form submission messages
  formSubmission: {
    toast: {
      error: 'You have an error in your {form}!',
      success: 'You have updated your {form}!',
      strongPassword: 'YOLO.'
    }
  },
  // get-in-touch
  contact: {
    title: 'Get In Touch',
    form: {
      submit: 'Submit', // form submit button
    },
    alert: 'Thanks for getting in touch! We will get back to you as soon as possible.'
  },

  share: {
    advisorProfile: {
      title: 'Check out my Kintell profile',
      text: 'Kintell connects people looking for advice with those qualified to give it, via paid or Pro Bono video sessions.'
    }
  },
}
