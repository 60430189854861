export default (ctx, inject) => {
  // handle iframes
  const inIframe = (() => {
    if (window.frameElement) {
      return true
    }
    try {
      if (typeof window === 'undefined') {
        return false
      }
      return window.self !== window.top
    } catch (e) {
      return true
    }
  })()
  inject('inIframe', inIframe)
}
