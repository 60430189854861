import { clone, filter, findIndex, map, upperFirst } from 'lodash-es'
import Model from './Model'
import User from './User'
import SupportingLink from './SupportingLink'
import WorkHistory from './WorkHistory'
import RelevantQualification from './RelevantQualification'
import Skill from './Skill'
import Review from './Review'
import Category from './Category'
import KintellGroup from './KintellGroup'
import {
  transformAvailabilityToCalendarFormat,
  transformAvailabilityToAPIFormat
} from '@/lib/util'
import moment from 'moment-timezone'

export default class KintellCard extends Model {
  static queryForPage () {
    return this
      .include('user,skills,workHistories,supportingLinks,relevantQualifications')
  }

  static queryForCard () {
    return this
      .include('user,skills,workHistories,relevantQualifications,kintellGroup')
  }

  resource () {
    return 'kintell-cards'
  }

  transform (data) {
    if (!data) {
      return data
    }

    if (data.user) {
      data.user = new User(data.user)
    }

    if (data.kintellGroup) {
      data.kintellGroup = new KintellGroup(data.kintellGroup)
    }

    // transform the rate to 2 decimal places with trailing 0s
    // 3 -> 3.00 || 2.6 -> 2.60
    if (data.rate) {
      let value = Number(data.rate)
      const res = data.rate.toString().split('.')
      if (res.length === 1 || (res[1].length < 3)) {
        value = value.toFixed(2)
      }
      data.rate = value
    }

    if (!data.convertedRate) {
      data.convertedRate = data.rate
    }

    if (!data.slug || data.slug.length < 0) {
      data.slug = data.id
    }
    if (data.advisingIn) {
      const advisingIn = []
      for (let i = 0; i < data.advisingIn.length; i++) {
        if (!data.advisingIn[i]) {
          continue
        }
        if (typeof data.advisingIn[i] === 'string') {
          advisingIn[i] = { value: data.advisingIn[i] }
        } else if (typeof data.advisingIn[i] === 'object' && data.advisingIn[i].line) {
          advisingIn[i] = { value: data.advisingIn[i].line }
        } else if (typeof data.advisingIn[i] === 'object' && data.advisingIn[i].value) {
          advisingIn[i] = data.advisingIn[i]
        }
      }
      data.advisingIn = advisingIn
    }
    if (data.skills) {
      data.skills = map(data.skills, skill => skill.name || skill)
    }

    if (data.unavailableTimes && data.timezone) {
      data.unavailableTimes = transformAvailabilityToCalendarFormat(data.unavailableTimes, data.timezone)
    }

    if (data.approvedAt) {
      data.approvedAt = moment.utc(data.approvedAt)
    }

    return data
  }

  castsForRequest (data) {
    if (data.unavailableTimes && data.timezone) {
      data.unavailableTimes = transformAvailabilityToAPIFormat(data.unavailableTimes, data.timezone)
    }
    return data
  }

  /**
   * -------------------------
   * Kintell Card Relations
   * -------------------------
   * The entities which sit under a KintellCard in terms of the api
   */

  // relations
  reviews () {
    return this.hasMany(Review)
  }

  skillsPivot () {
    return this.hasMany(Skill)
  }

  categories () {
    return this.hasMany(Category)
  }

  workHistories () {
    return this.hasMany(WorkHistory)
  }

  relevantQualifications () {
    return this.hasMany(RelevantQualification)
  }

  supportingLinks () {
    return this.hasMany(SupportingLink)
  }

  createdBy () {
    return this.belongsTo(User)
  }

  updated () {
    this.syncToVuex(true)
  }
  /**
   * -------------------------
   * Kintell Card Actions
   * -------------------------
   *
   * Custom actions that can be performed on the kintell card
   */

  publish () {
    return this.action('publish')
  }

  unpublish () {
    return this.action('unpublish')
  }

  unlist () {
    return this.action('unlist')
  }

  requestApproval () {
    return this.action('requestApproval')
  }

  approve (options) {
    return this.action('approve', options)
  }

  reject (options) {
    return this.action('reject', options)
  }

  toggleFavourite () {
    return this.action('favourite', undefined, false)
  }

  // Review group cards by group admins.
  review (kinteellCardId, data) {
    return this.$http.$put(this.resourceUrl() + `/${kinteellCardId}/review`, data)
  }

  /**
   * -------------------------
   * Kintell Card Getters
   * -------------------------
   * Utility computed properties
   */

  get isPrivate () {
    return this.status === 'unlisted'
  }

  get isDraft () {
    return this.status === 'draft'
  }

  get isAwaitingReview () {
    return this.status === 'awaiting-review'
  }

  get isPublished () {
    return this.status === 'published'
  }

  get friendlyStatus () {
    if (this.isAwaitingReview) {
      return 'Awaiting Review'
    }
    if (this.isPrivate) {
      return 'Private'
    }
    return upperFirst(this.status)
  }

  get link () {
    if (!this.user) {
      return null
    }
    return '/advisors/' + this.user.slug + '/' + this.slug
  }

  get bookLink () {
    if (!this.user) {
      return null
    }
    return '/advisors/' + this.user.slug + '/' + this.slug + '/book'
  }

  get editLink () {
    if (!this.user) {
      return null
    }
    return '/advisors/' + this.user.slug + '/' + this.id + '/edit'
  }

  get isFree () {
    return this.rate <= 0
  }

  get categoryLink () {
    if (!this.category) {
      return '/search/'
    }
    return '/search/?category=' + this.category.id
  }

  get firstSelectableDuration () {
    // Default duration is 30
    let duration = 30
    // If kintellCard.durations is not null then set duration to the first available duration
    if (this.durations) {
      const kintellCardAvailableDurations = map(this.durations, (e, key) => e ? key : e)
      duration = parseInt(filter(kintellCardAvailableDurations, e => e !== false)[0])
    }
    return duration
  }

  syncToVuex (updateActive = false) {
    const kintellCards = this.$store.getters['myKintell/kintellCards']
    // Update my personal vuex cards
    const myCards = clone(kintellCards)
    const index = findIndex(myCards, { id: this.id })
    myCards[index] = clone(this)
    this.$store.commit('myKintell/SET_KINTELL_CARDS', myCards)
    // Update the featured advisors in case we're on it
    if (updateActive) {
      this.$store.commit('kintellCards/SET_ACTIVE_KINTELL_CARD', this)
      if (this.user) {
        this.$store.commit('kintellCards/SET_ACTIVE_USER', this.user)
      }
    }
  }

  removeFromVuex () {
    // @todo update any vuex stores which may have the kintell card
  }

  async fetchShortLink () {
    if (this.short_link) {
      return
    }
    return await this.$http.$post(`${this.baseURL()}/guest/kintell-card/${this.id}/short-link`)
  }

  fetchRelatedKintellCards () {
    return KintellCard
      .pagination({
        page: 1,
        limit: 4
      })
      // Work histories is needed to make the kintell card widgets work
      .include('workHistories', 'kintellGroup')
      .custom(this, 'related')
      .$get()
  }
}
