//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Sizeable } from '@/mixins'

export default {
  mixins: [
    Sizeable,
  ],
  props: {
    src: {
      type: [ String, Boolean ],
      default: false,
    },
    eager: {
      type: Boolean,
      default: false
    },
    alt: {
      type: String,
      default: '',
    },
    userAccountBadge: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      trueSrc: null,
      reloadAttempts: 0,
      reloadTimeout: null,
      lazySource: require('~images/user-grey.svg'),
    }
  },
  watch: {
    src: {
      handler () {
        this.trueSrc = this.src || this.lazySource
      },
      immediate: true
    }
  },
  destroyed () {
    if (this.reloadTimeout) {
      clearTimeout(this.reloadTimeout)
    }
  },
  methods: {
    handleError () {
      this.trueSrc = this.lazySource
      // if we've failed to load the image over 3 attempts (6 seconds)
      // then we give up, the image is most likely broken
      if (this.reloadAttempts >= 3) {
        return
      }
      this.reloadAttempts++
      // try again in in an exponential amount of time based on attempts
      this.reloadTimeout = setTimeout(
        () => {
          /**
           * TODO: review this code and see if it is needed anymore.
           * KImg does not use v-img anymore so the old code (this.$refs.img.loadImage())
           * chucks errors.  However, removing all of it broke avatars images.
           */
          this.trueSrc = this.src
        },
        this.reloadAttempts * 1000
      )
    }
  }
}
