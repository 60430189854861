export default {
  introduction: {
    tagline: 'Creating an inclusive community',
    title: 'The Kintell Code of Conduct',
    paragraphs: [ 'Kintell was created to empower people from all walks of life, so maintaining an inclusive, transparent, and trustworthy community is key.',
      'So we ask all Kintell users to stick to the principles we’ve outlined in our Code of Conduct below. Please take a look - it’ll only take a couple of minutes :)' ],
  },
  rules: {
    tagline: 'The code of conduct',
    info: {
      title: 'What happens if our Code of Conduct is violated?',
      paragraphs: [
        'If we feel that a Kintell user has violated our guidelines we’ll take action, either in the form of an initial warning, a temporary suspension of an account, or a full lifetime ban for an individual.',
        'We’re serious about maintaining an inclusive and trustworthy platform, so reserve the right to take any action we feel is appropriate.'
      ]
    },
    list: [
      {
        title: 'Leave prejudice at the door',
        paragraph: 'We are a diverse platform and we want all of our users to feel like they can express themselves freely. However we don’t support users that promote, condone or execute hate or violence against groups or individuals because of their background, race, nationality, political affiliations, sexual orientation, gender or gender identity, disability or other reasons based on prejudice.'
      },
      {
        title: 'Be nice to each other',
        paragraph: 'We ask users to not in any circumstances conduct or encourage behaviour that may harm others while on the platform. Whether this means sharing nude pictures, bullying, encouraging wrongful behaviour, or anything else we believe to be in violation of our values.'
      },
      {
        title: 'Respect others\' privacy',
        paragraph: 'Don’t ask others for personal details such as bank accounts, phone numbers, or information about their employers. And if asked, don’t give out your own.'
      },
      {
        title: 'Reject violence in any form',
        paragraph: 'Don’t encourage others to commit violence or promote violence in any shape or form.'
      },
      {
        title: 'Record with care',
        paragraph: 'Please don’t record Kintell sessions without the consent of the meeting participants. The same goes for sharing recordings - make sure you have permission before doing so.'
      },
      {
        title: 'Be transparent and truthful',
        paragraph: 'We ask all Kintell users to be as truthful and accurate as possible when creating Kintell Cards or having video sessions. If found to be engaging in dishonest behaviour, whether that be fabricating qualifications or masking recruitment activity under the pretence of seeking advice, we will take action including banning accounts.'
      },
      {
        title: 'Guard your community',
        paragraph: 'If you encounter any instances of behaviour or content that you feel violate our Code of Conduct, please report it {contact} so that we can look into it.',
        contact: 'here'
      },
    ]
  }
}


