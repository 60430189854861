export default {
  width: 19,
  height: 20,
  stroke: true,
  svg: `
  <svg fill="none">
    <path d="M4.37856 9.90403C2.21429 9.90403 1 8.28498 1 5.85639V2.61829H3.83334" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.1668 2.61829H18.0001V5.85639C18.0001 8.28498 16.7859 9.90403 14.6216 9.90403" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.5 16.3812V13.1431" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.50019 13.1429C7.99729 13.1429 6.55594 12.5459 5.49323 11.4832C4.43052 10.4205 3.8335 8.97912 3.8335 7.47622V1H15.1669V7.47622C15.1669 8.97912 14.5698 10.4205 13.5071 11.4832C12.4444 12.5459 11.0031 13.1429 9.50019 13.1429V13.1429Z" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.9526 18.8099H5.04785C5.04785 18.1658 5.30372 17.5481 5.75917 17.0927C6.21461 16.6372 6.83233 16.3813 7.47643 16.3813H11.5241C12.1682 16.3813 12.7859 16.6372 13.2413 17.0927C13.6968 17.5481 13.9526 18.1658 13.9526 18.8099Z" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

  `
}
