export default {
  height: 63,
  width: 63,
  stroke: true,
  svg: `
    <g fill="none">
      <path d="M26.7483 36.2524C23.9056 33.4105 21.4358 30.2187 19.3983 26.7538L25.0144 22.2611C25.6725 21.7341 26.1376 21.0041 26.337 20.1849C26.5365 19.3657 26.4591 18.5036 26.1169 17.733L20.9995 6.20533C20.6326 5.3783 19.9837 4.70853 19.1688 4.31548C18.3539 3.92243 17.4258 3.83167 16.5501 4.05939L6.80613 6.58727C5.89676 6.82485 5.10614 7.38779 4.5841 8.16939C4.06207 8.95098 3.8449 9.89693 3.97376 10.828C5.684 23.0062 11.3125 34.2966 20.0083 42.9924C28.7041 51.6882 39.9945 57.3166 52.1727 59.0269C53.1035 59.1554 54.0491 58.9381 54.8305 58.4161C55.6118 57.8941 56.1745 57.1036 56.4121 56.1945L58.9399 46.4492C59.1672 45.5737 59.0764 44.646 58.6837 43.8312C58.2909 43.0164 57.6218 42.3674 56.7953 41.9998L45.2689 36.8811C44.4988 36.5383 43.6369 36.4601 42.8177 36.6589C41.9985 36.8576 41.2682 37.322 40.7408 37.9796L36.2481 43.5958C34.6023 42.6305 33.0166 41.5663 31.4995 40.4091" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M5.25 57.75L57.75 5.25" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
`
}
