export default {
  recordNow: {
    title: 'Record a video now',
    subtitle: 'Launch your Personal Room now and record a video message',
  },
  upload: {
    title: 'Upload an existing video',
    subtitle: 'Coming soon - get view reports when people watch your videos'
  }
}
