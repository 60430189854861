export default {
  width: 46,
  height: 55,
  svg: `
    <g fill-rule="nonzero">
      <path d="M12.8187 38.8025L8.44368 52.8025C8.19658 53.5932 8.63727 54.4345 9.42799 54.6816C10.2187 54.9287 11.06 54.488 11.3071 53.6973L15.6821 39.6973C15.9292 38.9066 15.4885 38.0653 14.6978 37.8182C13.9071 37.5711 13.0658 38.0118 12.8187 38.8025Z"/>
      <path d="M37.5571 52.8025L33.1821 38.8025C32.935 38.0118 32.0937 37.5711 31.303 37.8182C30.5123 38.0653 30.0716 38.9066 30.3187 39.6973L34.6937 53.6973C34.9408 54.488 35.7821 54.9287 36.5728 54.6816C37.3635 54.4345 37.8042 53.5932 37.5571 52.8025Z"/>
      <path d="M12.0625 47.75H33.9375C34.7659 47.75 35.4375 47.0784 35.4375 46.25C35.4375 45.4216 34.7659 44.75 33.9375 44.75H12.0625C11.2341 44.75 10.5625 45.4216 10.5625 46.25C10.5625 47.0784 11.2341 47.75 12.0625 47.75Z"/>
      <path d="M24.5 6V1.625C24.5 0.796573 23.8284 0.125 23 0.125C22.1716 0.125 21.5 0.796573 21.5 1.625V6C21.5 6.82843 22.1716 7.5 23 7.5C23.8284 7.5 24.5 6.82843 24.5 6Z"/>
      <path d="M20.4783 31.7862C21.0319 32.1184 21.736 32.0591 22.2262 31.6389L33.4216 22.0429L42.9394 31.5607C43.5252 32.1465 44.4749 32.1465 45.0607 31.5607C45.6465 30.9749 45.6465 30.0251 45.0607 29.4393L34.5607 18.9393C34.0065 18.3851 33.119 18.351 32.5238 18.8611L21.0947 28.6575L13.2718 23.9638C12.7247 23.6355 12.0299 23.6892 11.5398 24.0977L1.03976 32.8477C0.403346 33.378 0.31736 34.3239 0.847706 34.9603C1.37805 35.5967 2.3239 35.6827 2.96031 35.1523L12.6419 27.0844L20.4783 31.7862Z"/>
      <path d="M44 4.5H2C1.17157 4.5 0.5 5.17157 0.5 6V39.25C0.5 40.0784 1.17157 40.75 2 40.75H44C44.8284 40.75 45.5 40.0784 45.5 39.25V6C45.5 5.17157 44.8284 4.5 44 4.5ZM42.5 7.5V37.75H3.5V7.5H42.5Z"/>
      <path d="M20.375 21.5C23.1364 21.5 25.375 19.2614 25.375 16.5C25.375 13.7386 23.1364 11.5 20.375 11.5C17.6136 11.5 15.375 13.7386 15.375 16.5C15.375 19.2614 17.6136 21.5 20.375 21.5ZM20.375 18.5C19.2704 18.5 18.375 17.6046 18.375 16.5C18.375 15.3954 19.2704 14.5 20.375 14.5C21.4796 14.5 22.375 15.3954 22.375 16.5C22.375 17.6046 21.4796 18.5 20.375 18.5Z"/>
    </g>
  `
}
