import { make } from 'vuex-pathify'

const videoTokenPromises = new Map()
const videoTokens = new Map()

const savedSettings = typeof localStorage !== 'undefined' ? JSON.parse(localStorage.getItem('meeting.settingsv3')) : {}
const STATE_IN_PREVIEW_SCREEN = 0
const STATE_IN_CALL_SCREEN = 1
const STATE_IN_END_CALL_SCREEN = 2

const state = () => ({
  // Video call room
  room: null,
  isInCall: false,
  partnerWaiting: false,
  callJoined: false,
  isCameraEnabled: true,
  isMicrophoneEnabled: true,
  isRequestingAccess: false,
  complete: '',
  activeChatWindow: 'chat',
  currentState: STATE_IN_PREVIEW_SCREEN,
  devices: {
    audioDevices: [],
    videoDevices: [],
    hasAudioDevice: false,
    hasVideoDevice: false,
  },
  status: {
    isSwitchingCamera: false,
    isLoadingToggleVideo: false,
    isLoadingToggleSound: false,
    isLoadingToggleRecording: false,
    isLoadingScreenShare: false,
    isFullscreen: false,
    isScreenSharing: false,
    isScreenShareFullScreen: false,
    isConnectingSession: false,
    isLoadingEndSession: false,
  },
  show: {
    leaveButtons: false,
    settings: false,
    inviteOthers: false,
    chat: false,
  },
  capabilities: {
    canProcessBackgroundImage: false,
    canFullscreen: false,
    canScreenShare: false,
    canShowToggleSound: true,
  },
  settings: {
    viewMode: 'gallery',
    audioInputId: '',
    videoDeviceId: '',
    flipVideo: true,
    pipOnScreenShare: true,
    preferredVideoDevice: '',
    preferredAudioInput: '',
    videoBitrate: 1500000,
    CPUThreshold: 90,
    videoProcessor: false,
    ...savedSettings
  },
  cpaasResponse: null,
  participants: [],
  vonage: {
    session: null,
    token: null,
  },
  timer: {
    meetingTime: 0,
    meetingTimer: 0,
    lastTimer: 0,
    timeExceededSent: false,
    timeExceededChargeSent: false,
  },
  events: {
    updateDevices: false,
  },
  chime: {
    meetingSession: null
  }
})

const getters = {
  booking (state, getters, rootState, rootGetters) {
    return rootGetters['myKintell/activeBooking']
  },
  user (state, getters, rootState, rootGetters) {
    return rootGetters['auth/user']
  },
  otherUser (state, getters) {
    const { booking, user } = getters
    return user.id === booking.advisor.id ? booking.learner : booking.advisor
  },
  meetingState (state, getters) {
    return {
      isInPreviewScreen: state.currentState === STATE_IN_PREVIEW_SCREEN,
      isInCallScreen: state.currentState === STATE_IN_CALL_SCREEN,
      isInEndCallScreen: state.currentState === STATE_IN_END_CALL_SCREEN,
      STATE_IN_CALL_SCREEN,
      STATE_IN_END_CALL_SCREEN,
      STATE_IN_PREVIEW_SCREEN,
    }
  },
}

const actions = {
  getVideoToken ({ getters }) {
    const { booking } = getters

    if (videoTokens.has(booking.id)) { return videoTokens.get(booking.id) }

    if (videoTokenPromises.has(booking.id)) { return videoTokenPromises.get(booking.id) }

    const tokenPromise = (async () => {
      const response = await booking.fetchVideoToken()

      videoTokens.set(booking.id, response)
      return response
    })()

    videoTokenPromises.set(booking.id, tokenPromise)

    return tokenPromise
  },
  resetVideoTokens ({ state, commit, getters }) {
    videoTokenPromises.clear()
    videoTokens.clear()
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: make.mutations(state),
}
