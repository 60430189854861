import { Context } from '@nuxt/types'
import { KintellCardMessage } from '@/lib/broadcasting/messages'

// .App\\Events\\User\\ClaimedInvite
export default (ctx: Context) => {
  return (message: KintellCardMessage) => {
    ctx.$toast.success(
      `Your Kintell Card "${message.kintellCard.title}" has been approved!`,
      { duration: 10000 }
    )
    // we need to check if the user has rejected the card we're looking at
    const kintellCard = ctx.store.getters['kintellCards/activeKintellCard']
    if (kintellCard && kintellCard.id === message.kintellCard.id) {
      kintellCard.status = message.kintellCard.status
      ctx.store.commit('kintellCards/SET_ACTIVE_KINTELL_CARD', kintellCard)
    }
    ctx.$auth.fetchUser()
    ctx.store.dispatch('myKintell/reloadKintellCards')
  }
}
