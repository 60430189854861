import { Context } from '@nuxt/types'
import { endsWith } from 'lodash-es'
import { KintellCardMessage } from '@/lib/broadcasting/messages'

export default (ctx: Context) => {
  return (message: KintellCardMessage) => {
    const kintellCard = message.kintellCard
    const isBooking = endsWith(ctx.route.path, '/book')
    const activeBooking = ctx.store.getters['myKintell/activeBooking']
    const isReviewing = ctx.route.query.review

    ctx.store.commit('kintellCards/SET_ACTIVE_USER', kintellCard.user)
    ctx.store.commit('kintellCards/SET_ACTIVE_KINTELL_CARD', kintellCard)

    if (isReviewing) {
      return
    }

    // only redirect if it's published, private and there's no active booking
    if (kintellCard.status !== 'draft' && kintellCard.status !== 'awaiting-review') {
      if (!activeBooking || isBooking) {
        if (kintellCard.link !== ctx.route.path && !isBooking) {
          ctx.redirect(kintellCard.link)
        } else if (kintellCard.bookLink !== ctx.route.path && isBooking) {
          ctx.redirect(kintellCard.bookLink)
        }
        ctx.$toast.info(kintellCard.user.fullName + ' has updated their Kintell Card.', {
          duration: 6000
        })
      }
    }
  }
}
