import Vue, { ComputedOptions } from 'vue'
import { get, sync } from 'vuex-pathify'
import OT, { Device } from '@opentok/client'
import { User, Booking } from '@/models'
import { Participant } from '~components/Video/Meeting/_types'
import { VonageParticipant } from '~components/Video/Vonage/_types'
import { DefaultMeetingSession } from 'amazon-chime-sdk-js'

interface MeetingState {
  isInPreviewScreen: boolean,
  isInCallScreen: boolean,
  isInEndCallScreen: boolean,
  STATE_IN_CALL_SCREEN: number,
  STATE_IN_END_CALL_SCREEN: number,
  STATE_IN_PREVIEW_SCREEN: number,
}

interface MeetingCapabilities {
  canProcessBackgroundImage: boolean,
  canFullscreen: boolean,
  canScreenShare: boolean,
  canShowToggleSound: boolean,
}

interface MeetingStatus {
  isSwitchingCamera: boolean,
  isLoadingToggleVideo: boolean,
  isLoadingToggleSound: boolean,
  isLoadingToggleRecording: boolean,
  isLoadingScreenShare: boolean,
  isFullscreen: boolean,
  isScreenSharing: boolean,
  isScreenShareFullScreen: boolean,
  isConnectingSession: boolean,
  isLoadingEndSession: boolean,
}

interface MeetingDevices {
  devices: {
    audioDevices: OT.Device[],
    videoDevices: OT.Device[],
    audioOutputDevices: OT.Device[],
    hasAudioDevice: boolean,
    hasVideoDevice: boolean,
  },
}

interface MeetingShow {
  show: {
    leaveButtons: boolean,
    settings: boolean,
    inviteOthers: boolean,
    chat: boolean | null,
  },
}

const GALLERY_MODE = 'gallery'
const GRID_MODE = 'grid'
const ACTIVE_CHAT_WINDOW = 'chat'
const ACTIVE_PEOPLE_WINDOW = 'people'

export default Vue.extend({
  data () {
    return {
      form: {
        videoDeviceId: this.$store.state.meeting.settings.preferredVideoDevice,
        audioInputId: this.$store.state.meeting.settings.preferredAudioInput,
      },
    }
  },
  computed: {
    user: get('auth/user') as ComputedOptions<User>,
    booking: get('myKintell/activeBooking') as ComputedOptions<Booking>,
    meetingState: get('meeting/meetingState') as ComputedOptions<MeetingState>,
    isMicrophoneEnabled: sync('meeting/isMicrophoneEnabled') as ComputedOptions<boolean>,
    isCameraEnabled: sync('meeting/isCameraEnabled') as ComputedOptions<boolean>,
    currentState: sync('meeting/currentState') as ComputedOptions<number>,
    activeChatWindow: sync('meeting/activeChatWindow') as ComputedOptions<string>,
    devices: sync('meeting/devices') as ComputedOptions<MeetingDevices>,
    viewMode: sync('meeting/settings@viewMode') as ComputedOptions<'grid' | 'gallery'>,
    capabilities: sync('meeting/capabilities') as ComputedOptions<MeetingCapabilities>,
    status: sync('meeting/status') as ComputedOptions<MeetingStatus>,
    isInCall: sync('meeting/isInCall') as ComputedOptions<boolean>,
    participants: sync('meeting/participants') as ComputedOptions<Participant[]>,
    cpaasResponse: sync('meeting/cpaasResponse') as ComputedOptions<any>,
    callJoined: sync('meeting/callJoined') as ComputedOptions<boolean>,
    isRequestingAccess: sync('meeting/isRequestingAccess') as ComputedOptions<boolean>,
    vonage: sync('meeting/vonage') as ComputedOptions<{
      session: OT.Session,
      token: string,
    }>,
    chime: sync('meeting/chime') as ComputedOptions<{
      meetingSession: DefaultMeetingSession,
    }>,
    timer: sync('meeting/timer') as ComputedOptions<{
      meetingTime: number,
      meetingTimer: number | undefined,
      lastTimer: number,
      timeExceededSent: boolean,
      timeExceededChargeSent: boolean,
    }>,
    show: sync('meeting/show') as ComputedOptions<MeetingShow>,
    flipVideo: get('meeting/settings@flipVideo') as ComputedOptions<boolean>,
    settings: sync('meeting/settings') as ComputedOptions<{
      flipVideo: boolean,
      pipOnScreenShare: boolean,
      audioInputId: string,
      videoDeviceId: string,
      preferredVideoDevice: string,
      preferredAudioInput: string,
      videoBitrate: number,
      CPUThreshold: number,
      videoProcessor: false | 'blur' | 'replace',
    }>,
    events: sync('meeting/events') as ComputedOptions<{
      updateDevices: boolean,
    }>,
    hasVideo (): boolean {
      return this.devices.hasVideoDevice || this.status.isScreenSharing
    },
    hasAudio (): boolean {
      return this.devices.hasAudioDevice
    },
    videoDevices (): OT.Device[] {
      return this.devices.videoDevices
    },
    audioDevices (): OT.Device[] {
      return this.devices.audioDevices
    },
    isInPreviewMode (): boolean {
      return this.meetingState.isInPreviewScreen
    },
    isInEndCallScreen (): boolean {
      return this.meetingState.isInEndCallScreen
    },
    isInCallScreen (): boolean {
      return this.meetingState.isInCallScreen
    },
    isGalleryMode (): boolean {
      return this.viewMode === GALLERY_MODE
    },
    isGridMode (): boolean {
      return this.viewMode === GRID_MODE
    },
    canScreenShare (): boolean {
      return this.capabilities.canScreenShare
    },
    canFullscreen (): boolean {
      return this.capabilities.canFullscreen
    },
    canProcessBackgroundImage (): boolean {
      return this.capabilities.canProcessBackgroundImage
    },
    canShowToggleSound (): boolean {
      return this.capabilities.canShowToggleSound
    },
    recordingEnabled (): boolean {
      return this.booking.permissions.canRecord
    },
    canRecord (): boolean {
      return this.recordingEnabled && [ undefined, this.user.id ].includes(this.booking.recordingOwner?.id)
    },
    activeChatWindowIsChat (): boolean {
      return this.activeChatWindow === ACTIVE_CHAT_WINDOW
    },
    activeChatWindowIsPeople (): boolean {
      return this.activeChatWindow === ACTIVE_PEOPLE_WINDOW
    },
    availableParticipants (): Participant[]|VonageParticipant[] {
      return this
        .participants
        .filter((participant: Participant) => participant.inCall || participant.isScreenSharing)
    },
    remoteParticipants (): Participant[] {
      return this
        .participants
        .filter((participant: Participant) => !participant.isLocal)
    },
    activeParticipants () {
      return this.participants
        .filter((participant: Participant) => participant.inCall)
    },
    isScreenSharing () {
      return this.activeParticipants.some((part: VonageParticipant) => part.isScreenSharing) || this.status.isScreenSharing
    },
    // the activeUserId is fixed when there is screen share, so we use this property
    // to get the current speaker based on the audio levels to manage the picture in picture functionality
    currentSpeakerId () {
      if (this.activeParticipants.length === 0) {
        return
      }
      const indexOfMaxAudioLevel = [ ...this.activeParticipants ]
        .reduce((maxIndex, current, index, array) => {
          const currentAudioLevel = current.audioLevel || 0
          const maxAudioLevel = array[maxIndex]?.audioLevel || 0
          return currentAudioLevel > maxAudioLevel ? index : maxIndex
        }, 0)
      if (indexOfMaxAudioLevel === -1) {
        return ''
      }
      return this.activeParticipants[indexOfMaxAudioLevel].userId
    }
  },
  methods: {
    setMeetingStateToInCall (): void {
      this.currentState = this.meetingState.STATE_IN_CALL_SCREEN
    },
    setMeetingStateToInEndCall (): void {
      this.currentState = this.meetingState.STATE_IN_END_CALL_SCREEN
    },
    setViewModeToGallery (): void {
      this.viewMode = GALLERY_MODE
    },
    toggleViewMode (): void {
      const viewMode = this.viewMode === 'gallery' ? 'grid' : 'gallery'
      this.report({ name: viewMode === 'gallery' ? 'exitGridView' : 'gridView' })
      this.viewMode = this.viewMode === GALLERY_MODE ? GRID_MODE : GALLERY_MODE
    },
    getAverageAudioLevel (audioLevels: number[]) {
      const audioLevel = audioLevels.reduce((a, b) => a + b, 0) / audioLevels.length
      return Number.isNaN(audioLevel) ? 0 : audioLevel
    },
    report (feature: any) {
      this.$activity.clickOn({
        type: 'booking',
        bookingId: this.booking.id,
        ...feature,
      })
    },
    addOrUpdateParticipant (participant: Participant|VonageParticipant) {
      const currentParticipants = this.participants
      const existingParticipantIndex = currentParticipants.findIndex(
        (p: Participant) => p.userId === participant.userId
      )

      if (existingParticipantIndex === -1) {
        currentParticipants.push(participant)
        return
      }

      const existingParticipant = currentParticipants[existingParticipantIndex]

      const propertiesToUpdate = [
        'provider',
        'isOwner',
        'isCoHost',
        'stream',
        'streamId',
        'subscriberId',
        'mediaStream',
        'videoEnabled',
        'audioEnabled',
        'audioLevel',
        'inCall',
        'flipped',
        'requestJoin',
        'isPlaceHolder',
        'avatarUrl',
        'firstName',
        'lastName'
      ]

      propertiesToUpdate.forEach((prop: string) => {
        if (participant[prop] !== undefined) {
          existingParticipant[prop] = participant[prop]
        }
      })
      this.participants = currentParticipants
    },
    maybeRemoveParticipant (participant: Participant) {
      if (!participant.isScreenSharing) {
        return
      }
      this.removeParticipant(participant.userId)
    },
    removeParticipant (userId: string) {
      this.participants = this
        .participants
        .filter((participant: Participant) => participant.userId !== userId)
    },
    findVideoDevice (deviceId: string): Device | undefined {
      return this.devices.videoDevices.find((device: Device) => device.deviceId === deviceId)
    },
    findAudioDevice (deviceId: string): Device | undefined {
      return this.devices.audioDevices.find((device: Device) => device.deviceId === deviceId)
    },
  }
})
