import { render, staticRenderFns } from "./demo.vue?vue&type=template&id=68e08e9a&lang=pug&"
import script from "./demo.vue?vue&type=script&lang=js&"
export * from "./demo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {KIcon: require('/opt/atlassian/pipelines/agent/build/app/components/shared/Icon/KIcon.vue').default,KIconHamburger: require('/opt/atlassian/pipelines/agent/build/app/components/shared/Icon/KIconHamburger.vue').default,KChip: require('/opt/atlassian/pipelines/agent/build/app/components/shared/Chip/KChip.vue').default,KContainer: require('/opt/atlassian/pipelines/agent/build/app/components/shared/Container/KContainer.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VApp,VList,VListGroup,VListItem,VListItemAction,VListItemContent,VListItemTitle,VMain,VNavigationDrawer,VSpacer})
