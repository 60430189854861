//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { UsesAuthenticatedUser } from '@/mixins'

export default {
  mixins: [
    UsesAuthenticatedUser
  ],
  data () {
    return {
      articles: [],
      loadingArticles: false
    }
  },
  computed: {
    currentYear () {
      return new Date().getFullYear()
    },
  },
  async mounted () {
    this.loadingArticles = true
    this.articles = await this.$content('articles')
      .sortBy('createdAt', 'desc')
      .limit(2)
      .fetch()
    this.loadingArticles = false
  },
}
