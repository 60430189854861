import { filter, defaults } from 'lodash-es'
import { get } from 'vuex-pathify'
import currencyListJsonFile from '~data/currencies.json'

export default {
  data () {
    return {
      currencyList: currencyListJsonFile,
    }
  },
  computed: {
    ...get({
      ipinfo: 'guest/ipinfo',
      loadingIpinfo: 'guest/loadingIpinfo',
    }),
    privateCardPercentage () {
      switch (this.usersCurrency) {
        case 'AUD':
        case 'GBP':
        case 'EUR':
          return 3
        case 'USD':
        case 'CAD':
        case 'NZD':
        default:
          return 4
      }
    },
    averageRate () {
      switch (this.usersCurrency) {
        case 'USD':
          return 40
        case 'GBP':
        case 'EUR':
          return 25
        case 'AUD':
        case 'CAD':
        case 'NZD':
        default:
          return 50
      }
    },
    signUpCredit () {
      switch (this.usersCurrency) {
        case 'USD':
          return 15
        case 'GBP':
        case 'EUR':
          return 10
        case 'AUD':
        case 'CAD':
        case 'NZD':
        default:
          return 20
      }
    },
    usersCurrency () {
      if (this.authenticatedUser) {
        return this.authenticatedUser.currency
      }
      if (this.ipinfo?.currency) {
        return this.ipinfo.currency
      }
      // fallback to AUD if there's problems
      return 'AUD'
    },
    currencyChoices () {
      return Object.values(this.currencyList)
    },
    getCurrencySymbol () {
      return currencyKey => {
        const currencies = Object.values(this.currencyList)
        const symbol = filter(currencies, currency => currency.key === currencyKey)
        return defaults(symbol[0], { symbol: '$' }).symbol
      }
    },
  },
  methods: {
    isSupportedCurrency (currencyKey) {
      const currencies = Object.values(this.currencyList)
      return filter(currencies, currency => currency.key === currencyKey).length > 0
    }
  }
}
