//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

export default {
  components: {
    VueSlickCarousel,
  },

  props: {
    i18nKey: {
      type: String,
      required: true
    },
  },

  data: () => ({
    carouselSettings: {
      arrows: false,
      infinite: true,
      slidesToShow: 1,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 3000,
    }
  }),

  computed: {
    I18nItems () {
      return this.$td(`tour.caseStudies.${this.i18nKey}`)
    },
  },

  methods: {
    navigateSlide () {
      const carousel = this.$refs.refSuccessStory

      carousel.next()
    }
  }
}
