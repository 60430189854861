//
//
//
//
//
//
//

import { UsesAuthenticatedUser } from '@/mixins'

export default {
  mixins: [
    UsesAuthenticatedUser
  ],
  data () {
    return {
      showConversionModal: false
    }
  },
  head () {
    const title = 'A better video experience'
    const description = 'Host instant and scheduled meetings - an in-browser experience you and your guests will love!'
    return {
      title,
      meta: [
        { hid: 'og:title', property: 'og:title', content: title },
        { hid: 'description', name: 'description', content: description, },
        { hid: 'og:description', property: 'og:description', content: description },
        { hid: 'og:image', property: 'og:image', content: 'https://assets.kintell.com/social/video-meeting-og.png' },
      ]
    }
  },
  created () {
    this.$nuxt.$on('showConversionModal', () => {
      this.showConversionModal = true
    })
  },
  beforeDestroy () {
    // removes event listener
    this.$nuxt.$off('showConversionModal')
  },
}
