import { Context } from '@nuxt/types'
import { BookingMessage } from '@/lib/broadcasting/messages'

// '.App\\Events\\Booking\\Status\\Confirmed'
export default (ctx: Context) => {
  return (e: BookingMessage) => {
    // We do not want to do toast messages for instant booking
    if (!e.booking.instantBooking) {
      const whoConfirmed = e.booking.confirmedBy === e.booking.learnerId
        ? e.booking.learner.fullName
        : e.booking.advisor.fullName

      // The learner may only be listening to check if payment went through
      // Or if an instant booking went through
      if (e.booking.confirmedBy !== e.booking.learnerId && e.booking.learnerId !== ctx.$auth.$state.user.id && !e.booking.instantBooking) {
        ctx!.$toast.success(`${whoConfirmed} has just confirmed a booking with you!`, {
          duration: 10000
        })
      }
    }

    ctx.store.dispatch('myKintell/maybeReloadActiveBooking', e)
    ctx.store.dispatch('myKintell/reloadBookings')
    ctx.store.dispatch('auth/refresh')
  }
}
