import Model from './Model'
import KintellCard from './KintellCard'

export default class Skill extends Model {
  resource () {
    return 'skills'
  }

  kintellCard () {
    return this.hasMany(KintellCard)
  }
}
