export default {
  width: 21,
  height: 25,
  svg: `
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="17" viewBox="0 0 19 17" fill="none">
      <path d="M8.56 12.52H3C1.89543 12.52 1 11.6246 1 10.52V3C1 1.89543 1.89543 1 3 1H14.84C15.9446 1 16.84 1.89543 16.84 3V7.12" stroke="#30324B"/>
      <path d="M1.35986 1.35999L7.60796 5.6294C8.28804 6.09411 9.18348 6.09435 9.8638 5.62999L16.1199 1.35999" stroke="#30324B"/>
      <circle cx="14.5" cy="12.5" r="4" stroke="#30324B"/>
      <line x1="12.7675" y1="14.3033" x2="16.3031" y2="10.7678" stroke="#30324B"/>
      <line x1="12.7675" y1="14.3033" x2="16.3031" y2="10.7678" stroke="#30324B"/>
      <line x1="16.3032" y1="14.3033" x2="12.7677" y2="10.7677" stroke="#30324B"/>
      <line x1="16.3032" y1="14.3033" x2="12.7677" y2="10.7677" stroke="#30324B"/>
     </svg> `
}
