import { render, staticRenderFns } from "./dashboard.vue?vue&type=template&id=2238a91b&scoped=true&"
import script from "./dashboard.vue?vue&type=script&lang=js&"
export * from "./dashboard.vue?vue&type=script&lang=js&"
import style0 from "./dashboard.vue?vue&type=style&index=0&id=2238a91b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2238a91b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {KDashboardTabs: require('/opt/atlassian/pipelines/agent/build/app/components/app/Dashboard/KDashboardTabs.vue').default,KContainer: require('/opt/atlassian/pipelines/agent/build/app/components/shared/Container/KContainer.vue').default,KIcon: require('/opt/atlassian/pipelines/agent/build/app/components/shared/Icon/KIcon.vue').default,KDashboardNavMenu: require('/opt/atlassian/pipelines/agent/build/app/components/app/Dashboard/KDashboardNavMenu.vue').default,KCardText: require('/opt/atlassian/pipelines/agent/build/app/components/shared/Card/KCardText.vue').default,KCard: require('/opt/atlassian/pipelines/agent/build/app/components/shared/Card/KCard.vue').default,KUserDashboardConversion: require('/opt/atlassian/pipelines/agent/build/app/components/app/User/Dashboard/KUserDashboardConversion.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBadge,VCol,VListItem,VListItemContent,VListItemIcon,VListItemTitle,VRow,VTab})
