export default {
  width: 16,
  height: 16,
  svg: `
  <g clip-path="url(#clip0)">
    <path d="M2.14795 6.33252L0.341309 5.47412L0.770509 4.5708C2.08984 1.79395 4.92773 0 8 0C11.0693 0 13.9058 1.7915 15.2261 4.56445L15.6558 5.46728L13.8501 6.32715L13.4204 5.42383C12.4302 3.34424 10.3022 2 8 2C5.69531 2 3.56689 3.34619 2.57715 5.4292L2.14795 6.33252Z"/>
    <path d="M5.12895 13.8709C4.648 12.2853 5.67757 5.34448 5.67757 5.34448C5.67757 5.34448 10.3897 10.5437 10.8706 12.1292C11.3516 13.7147 10.4561 15.3899 8.87061 15.8709C7.28509 16.3518 5.6099 15.4564 5.12895 13.8709Z"/>
  </g>
  `
}
