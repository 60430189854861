//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { UsesAuthenticatedUser } from '@/mixins'

export default {
  mixins: [ UsesAuthenticatedUser ],

  computed: {
    alertCount () {
      return this.authenticatedUser?.avatarAlertCount
    },

    items () {
      return [
        {
          icon: 'film',
          iconSize: '12',
          text: 'Host a meeting now',
          href: this.authenticatedUser.instantMeetingLink,
        },
        {
          icon: 'groupAdmin',
          text: 'Initiate a booking',
          to: '/my-kintell/bookings',
        },
        {
          icon: 'cog',
          text: 'My Account',
          to: '/my-kintell/my-details',
        },
      ]
    },
  }
}
