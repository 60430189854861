export default {
  email: 'Hmm that doesn\'t look like a valid email address..',
  minLength: 'Please provide a {field} with at least {min} characters.',
  maxLength: 'Please provide a {field} with no more than {max} characters.',
  required: 'Oops! Looks like you missed the {field}.',
  isTrue: 'Oops, this field is required.',
  mobile: 'This doesn\'t look like a valid mobile number.',
  url: 'Please provide a valid url.',
  name: 'Please use letters, hyphens, single spaces and apostrophes only. A {field} must be at least 2 characters.',
  noTitle: 'Please remove the title. We like to consider our knowledge community as merit-based and egalitarian. If you want to indicate a qualification achieved through traditional means, please feel free to select your highest education level in \'My Details\' so people can find you easier.',
  minValue: 'Oops! Please provide a {field} greater than {min}.',
  maxValue: 'Oops! Please provide a {field} less than {max}.',
  minLengthNotEmpty: 'Please provide {min} {field}s.',
  // social link validators
  linkedinUrl: 'Hmm that doesn\'t look like a valid LinkedIn URL.',
  githubUrl: 'Hmm that doesn\'t look like a valid GitHub URL.',
  instagramUrl: 'Hmm that doesn\'t look like a valid Instagram URL.',
  facebookUrl: 'Hmm that doesn\'t look like a valid Facebook URL.',
  twitterUrl: 'Hmm that doesn\'t look like a valid Twitter URL.',
  youtubeUrl: 'Hmm that doesn\'t look like a valid YouTube URL.',
  researchGateUrl: 'Hmm that doesn\'t look like a valid ResearchGate URL.',
  sameAsPassword: 'Oops, looks like these passwords don\'t match.',
  notInArray: 'Oops, looks like you have already chosen that.',
  hasOneTrueValue: 'Oops, at least one of the {field} needs to be selected.',
  onlyHasApprovedCharacters: 'Sorry we only allow letters, numbers, and the following characters !?-\'&,. in the {field} field.',
  onlyHasApprovedCharactersVideoTitle: 'Sorry we only allow letters, numbers, and the following characters !?-\'&,.| in the {field} field.',
  strongPassword: 'Sorry, password needs at least 12 characters containing numbers, uppercase and lowercase letters.'
}
