import moment from 'moment-timezone'
import { max } from 'lodash-es'
import pluralize from 'pluralize'

export default {
  data () {
    return {
      expiryDelayItems: this.$t('fields.booking.requestExpiresAtUtc.items')
    }
  },
  methods: {
    bookingTimesSuggestedChanged (val) {
      this.fields.bookingTimesSuggested = val
      let maxExpiryHours = 0
      let maxExpiryMinutes = 0
      let maxExpiryHoursAbs = 0
      for (const timeIndex in val) {
        const time = val[timeIndex]
        // Fields.duration or 0
        let fieldDuration = 0
        if (this.fields && this.fields.duration) {
          fieldDuration = this.fields.duration
        } else if (this.booking) {
          fieldDuration = this.booking.duration
        }
        const timeSlotMoment = moment.utc(time.timestampUtc).add(time.duration - fieldDuration, 'm')
        const diffHours = timeSlotMoment.diff(moment.utc(), 'hours')
        const diffHoursAbsolute = timeSlotMoment.diff(moment.utc(), 'hours', true)
        const diffMinutes = timeSlotMoment.diff(moment.utc(), 'minutes')
        maxExpiryHours = max([ maxExpiryHours, diffHours ])
        maxExpiryHoursAbs = max([ maxExpiryHoursAbs, diffHoursAbsolute ])
        maxExpiryMinutes = max([ maxExpiryMinutes, diffMinutes ])
      }
      const items = []
      let newExpiryDelay = maxExpiryHours
      if (maxExpiryHours < 48) {
        newExpiryDelay = maxExpiryHoursAbs
        if (maxExpiryHours <= 0) {
          items.push({ text: pluralize('minute', maxExpiryMinutes, true), value: maxExpiryHoursAbs })
        } else {
          maxExpiryMinutes = maxExpiryMinutes % 60
          let expiry = pluralize('hour', maxExpiryHours, true)
          if (maxExpiryHours < 4) {
            maxExpiryHours = Math.floor(maxExpiryHours)
            expiry += ' ' + pluralize('minute', maxExpiryMinutes, true)
          }
          items.push({ text: expiry, value: maxExpiryHoursAbs })
        }
      } else {
        const allItems = this.$t('fields.booking.requestExpiresAtUtc.items')
        for (const i in allItems) {
          if (allItems[i].value < maxExpiryHours) {
            items.push(allItems[i])
            if (allItems[i].value <= 48) {
              newExpiryDelay = allItems[i].value
            }
          }
        }
      }
      this.expiryDelayItems = items || this.$t('fields.booking.requestExpiresAtUtc.items')
      // don't reset the time if the user selected something 48 hours or more and is still applicable
      if (this.fields.requestExpiresAtUtc < 48 || this.fields.requestExpiresAtUtc > maxExpiryHours) {
        this.fields.requestExpiresAtUtc = newExpiryDelay
      }
    }
  }
}
