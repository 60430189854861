export default {
  width: 24,
  height: 22,
  svg: `
   <g fill-rule="nonzero">
    <path d="M13.0204082 14.7692308H9.41188701l-.23643282-.3582019c-.7401409-1.1213328-2.08029693-1.7956443-3.58361746-1.7956443H4V11h1.59183673c1.86037357 0 3.57197146.7883076 4.64756487 2.1538462h2.7810066C15.0024715 13.1538462 17 15.1809239 17 17.1923077V18H8.7755102v-1.6153846h6.4353814c-.4019838-.856695-1.3491628-1.6153846-2.1904834-1.6153846z"/>
    <path d="M21.9004152 16.4004254c-.4279363-.5020769-1.0252589-.8004612-1.8749167-.8004612h-4.9846977V14h4.9846977c1.2960735 0 2.3002005.5015978 3.0086816 1.3328244.4268908.5008502.6630622 1.0042452.7663582 1.3763894l.1994617.7186-11.4273369 4.387971-.0849414.0268334c-.9697982.242612-1.9841623.204148-2.93480581-.1113916l-.0669069-.0258887L4 19.2701924l.59219599-1.4739208 5.45169461 2.419914c.6649916.2141624 1.3717818.2417182 2.0499915.0800565l9.8868224-3.7964305c-.0256701-.0335372-.0524288-.0666989-.0802893-.0993862zM16.5 12c-1.9329966 0-3.5-1.5670034-3.5-3.5C13 6.56700338 14.5670034 5 16.5 5S20 6.56700338 20 8.5c0 1.9329966-1.5670034 3.5-3.5 3.5zm0-1.6153846c1.0408443 0 1.8846154-.84377106 1.8846154-1.8846154S17.5408443 6.61538462 16.5 6.61538462c-1.0408443 0-1.8846154.84377104-1.8846154 1.88461538S15.4591557 10.3846154 16.5 10.3846154zM9.5 7C7.56700338 7 6 5.43299662 6 3.5S7.56700338 0 9.5 0C11.4329966 0 13 1.56700338 13 3.5S11.4329966 7 9.5 7zm0-1.61538462c1.0408443 0 1.8846154-.84377104 1.8846154-1.88461538S10.5408443 1.61538462 9.5 1.61538462c-1.04084434 0-1.88461538.84377104-1.88461538 1.88461538S8.45915566 5.38461538 9.5 5.38461538z"/>
    <path d="M1.66666667 11.5789474v6.8421052h1.66666666v-6.8421052H1.66666667zM5 10v10H0V10h5z"/>
  </g>`
}
