// pathify
import { join, single } from '../build'
import { Booking } from '~/models'

const activeBooking = single('activeBooking', Booking)

// Store the invited by uuid to send up with the invited by request
const invitedBy = single('invitedBy')
const blogAlertOpen = single('blogAlertOpen', null, true)

const loadingIpinfo = single('loadingIpinfo', null, false)
const ipinfo = single('ipinfo')

const {
  state,
  getters,
  mutations,
  actions
} = join(
  activeBooking,
  invitedBy,
  loadingIpinfo,
  ipinfo,
  blogAlertOpen
)

// manually-created actions
actions.reset = ({ commit }) => {
  commit('SET_ACTIVE_BOOKING', null)
}

actions.loadIpinfo = async ({ commit, state, rootState }, context) => {
  const params = new URLSearchParams(window.location.search)
  const forcedRegion = params.get('region')

  // Don't need to call api for auth users, if it is already being loaded,
  // if the ipTimezone has been found already
  if (!forcedRegion && (rootState.auth.user || state.loadingIpinfo || state.ipinfo)) {
    return
  }
  commit('SET_LOADING_IPINFO', true)
  try {
    const params = {}
    if (forcedRegion) {
      params.region = forcedRegion
    }
    commit('SET_IPINFO', await context.$axios.$get('/ipinfo', { params }))
  } catch (e) {
    // not the end of the world if it fails
    context.$logger.error(e)
  } finally {
    commit('SET_LOADING_IPINFO', false)
  }
}

// export store
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
