export default {
  features: [
    {
      title: 'Success stories',
      description: 'Get Inspired by real life cases of Kintell customers and users.',
      image: require('~images/articles/success-stories.png'),
      to: '/articles/category/success-stories',
    },
    {
      title: 'Guides & playbooks',
      description: 'Proven methods and practical step-by-step guides for video-first engagement via insight-sharing.',
      image: require('~images/articles/guides.png'),
      to: '/articles/category/playbooks',
    },
    {
      title: 'Kintell Editorial',
      description: 'The Kintell team often share their expert view on insight sharing, video engagement, and much more.',
      image: require('~images/articles/editorial.png'),
      to: '/articles/category/kintell-editorial',
    },
    {
      title: 'Business',
      description: 'Up your business skills by articles and videos from business experts on Kintell Insight Marketplace.',
      image: require('~images/articles/business.svg'),
      width: '98',
      height: '106',
      to: '/articles/category/business',
    },
    {
      title: 'Innovation',
      description: `Articles and videos from innovation and tech experts on Kintell Insight Marketplace.`,
      image: require('~images/articles/innovation.svg'),
      width: '97',
      height: '127',
      to: '/articles/category/innovation',
    },
    {
      title: 'Lifestyle',
      description: 'Thinking of improving your lifestyle? Hear from health experts on Kintell Insight Marketplace.',
      image: require('~images/articles/lifestyle.svg'),
      width: '97',
      height: '103',
      to: '/articles/category/lifestyle',
    },
    {
      title: 'Society',
      description: 'Views and opinions about our society from Kintell experts coming from all walks of life.',
      image: require('~images/articles/society.svg'),
      width: '100',
      height: '96',
      to: '/articles/category/society',
    },
    {
      title: 'Creativity',
      description: 'Stay creative by reading ideas from designers and artists on Kintell Insight Marketplace.',
      image: require('~images/articles/creativity.svg'),
      width: '98',
      height: '119',
      to: '/articles/category/creativity',
    },
  ]
}
