//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { UsesAuthenticatedUser, UsesDashboardLinks, UsesGroupQueryString, UsesKintellGroup } from '@/mixins'
import { get, sync } from 'vuex-pathify'

export default {
  mixins: [
    UsesAuthenticatedUser,
    UsesDashboardLinks,
    UsesKintellGroup,
    UsesGroupQueryString
  ],

  data: () => ({
    discoverLinks: [
      { i18n: 'resources', link: '/resources' },
      { i18n: 'forBusiness', link: '/for-business' },
      { i18n: 'testimonials', link: '/testimonials' },
      { i18n: 'marketplace', link: '/search' },
      { i18n: 'features', link: '/features' },
      { i18n: 'pricing', link: '/pricing' },
    ]
  }),

  computed: {
    ...sync({
      showDrawer: 'layout/showDrawer',
    }),

    ...get({
      awaitingBookingSession: 'myKintell/awaitingBookingSession',
      activeDashboardTabIndex: 'myKintell/activeDashboardTabIndex',
      activeConsoleTabIndex: 'myKintell/activeConsoleTabIndex',
    }),
    activeDashboardTab () {
      if (this.activeDashboardTabIndex === -1) {
        return []
      }
      return this.dashboardLinks[this.activeDashboardTabIndex]
    },
    activeConsoleTab () {
      if (this.activeConsoleTabIndex === -1) {
        return []
      }
      return this.consoleLinks[this.activeConsoleTabIndex] || { sub: [] }
    },
    alertCount () {
      return this.authenticatedUser ?? this.authenticatedUser.stats.alerts + (this.authenticatedUser.stats.bookingActive ? 1 : 0)
    },
    styles () {
      return {
        width: '100%',
        transform: `translateX(${this.showDrawer ? 0 : 100}%)`
      }
    },
    kintellGroupLinks () {
      const groups = this.authenticatedUser?.kintellGroupsNonContent

      if (groups?.length > 0) {
        return groups.map(group => ({
          name: group.name,
          link: `/g/${group.slug}`
        }))
      }

      return []
    },
    myDashboardLinks () {
      return this.dashboardLinks.filter(item => item && item.name)
    },
  },

  watch: {
    showDrawer (val) {
      if (val) {
        document.documentElement.classList.add('overflow-hidden')
      } else {
        document.documentElement.classList.remove('overflow-hidden')
      }
    }
  },

  methods: {
    toggleClose (item) {
      this.activeDashboardTab = item
    },
  }
}
