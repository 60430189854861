export default {
  props: {
    ghostCardPlaceholderIndex: {
      type: Number,
      default: 0
    }
  },
  computed: {
    kintellCardPlaceholderIndex () {
      return window.kintellCardPlaceholderIndex
    },
  },
  methods: {
    getGhostPlaceholderForField (fieldName, subFieldName = null) {
      let key = `kintellCard.ghostCards.${this.kintellCardPlaceholderIndex}.`
      if (subFieldName) {
        key += `${subFieldName}.${this.ghostCardPlaceholderIndex}.${fieldName}`
      } else {
        key += fieldName
      }

      if (!this.$te(key)) {
        return ''
      }
      return 'E.g. ' + this.$t(key)
    },
  }
}
