import Model from './Model'

export default class Subscription extends Model {
  public name: string
  public slug: string
  public priceCentsAud: number
  public features: any
  public convertedMonthlyPrice: number

  resource () {
    return 'subscriptions'
  }

  subscribe (subscriptionId: string, data: object) {
    return this.action(`${subscriptionId}/subscribe`, data)
  }

  unsubscribe (subscriptionId: string, data: string) {
    return this.$http.$put(this.resourceUrl() + `/${subscriptionId}/unsubscribe`, data)
  }
}
