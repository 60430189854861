//
//
//
//
//
//
//
//

import { get } from 'vuex-pathify'

export default {
  computed: {
    ...get({
      showDarkHeader: 'layout/showDarkHeader',
    }),
  },
}
