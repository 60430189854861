import UsesFeatureFlags from '@/mixins/auth/UsesFeatureFlags'

export default {
  mixins: [ UsesFeatureFlags ],
  computed: {
    bookingNotificationCount () {
      if (!this.authenticatedUser) {
        return 0
      }
      return this.authenticatedUser.stats.bookingAlerts + (this.authenticatedUser.stats.bookingActive ? 1 : 0)
    },
    bookingRequestActions () {
      if (!this.authenticatedUser) {
        return 0
      }
      return this.authenticatedUser.stats.bookingAlerts
    },

    consoleLinks () {
      if (!this.userIsAuthenticated) {
        return []
      }

      if (this.authenticatedUser.groupsUserIsAdminWithContentOnly.length === 0) {
        return []
      }

      const showMembersTab = this.authenticatedUser.groupsUserIsAdmin.length > 0

      const hasAdminResources = this.authenticatedUser.groupsUserIsAdminWithContentOnly.some(group => !!group.adminResource)

      const consoleTabs = [
        {
          name: 'Console',
          link: '/my-kintell/console',
          icon: 'groupAdmin',
          noTabs: true,
          iconSize: 20,
          sub: [
          ]
        }
      ]

      if (hasAdminResources) {
        consoleTabs[0].sub.push({
          name: 'Resources',
          link: '/my-kintell/console/resources',
        })
      }

      if (showMembersTab) {
        consoleTabs[0].sub.push({
          name: 'Members',
          link: '/my-kintell/console/members',
        })
      }

      return consoleTabs
    },

    dashboardLinks () {
      const linkItems = []

      const { authenticatedUser } = this

      // unauthenticated user don't need dashboard links
      if (!authenticatedUser) {
        return linkItems
      }

      const { stats } = authenticatedUser

      // Main dashboard link
      linkItems.push({
        name: this.$t('app.pages.dashboard.title'),
        link: '/my-kintell/dashboard',
        icon: 'overview',
        iconSize: 16,
      })

      // Booking section
      linkItems.push({
        name: 'Bookings',
        link: '/my-kintell/bookings',
        icon: 'bookings',
        iconSize: 19,
        alerts: this.bookingNotificationCount,
        sub: [
          {
            name: 'Initiate a Booking',
            link: '/my-kintell/bookings',
          },
          {
            name: 'Requests',
            link: '/my-kintell/bookings/requests',
            alerts: this.bookingRequestActions,
          },
          {
            name: 'Confirmed',
            link: '/my-kintell/bookings/confirmed',
            alerts: stats.bookingActive ? 1 : 0
          },
          {
            name: 'Archive',
            link: '/my-kintell/bookings/archive',
          },
        ]
      })

      // Video section
      linkItems.push({
        name: 'Library',
        link: '/my-kintell/videos',
        icon: 'play',
        iconSize: 19,
      })

      const kintellCardSection = {
        // if they have a pending card we use Kintell Cards so we can fit the chip
        name: this.$t('app.pages.myKintellCards.title'),
        link: '/my-kintell/kintell-cards',
        icon: 'edit',
        iconSize: 20,
        alerts: stats.kintellCardAwaitingAmendment ? 1 : 0,
      }

      if (authenticatedUser.kintellGroups.length > 0) {
        const groupLinks = authenticatedUser.kintellGroupsNonContent.map(group => ({
          name: group.name,
          link: `/my-kintell/kintell-cards/${group.id}`
        }))

        kintellCardSection.sub = [
          {
            name: 'Kintell',
            link: '/my-kintell/kintell-cards',
            alerts: stats.kintellCardAwaitingAmendment ? 1 : 0,
          },

          ...groupLinks,
        ]
      }

      // Kintell Card section
      linkItems.push(kintellCardSection)

      // Calendar section
      linkItems.push({
        name: this.$t('app.pages.calendar.title'),
        link: '/my-kintell/my-calendar',
        icon: 'time',
        iconSize: 20,
        sub: [
          {
            name: 'Calendar view',
            link: '/my-kintell/my-calendar'
          },
          {
            name: 'Working hours',
            link: '/my-kintell/my-calendar/working-hours'
          },
          {
            name: 'Sync settings',
            link: '/my-kintell/my-calendar/sync-settings'
          },
          // {
          //   name: 'Snooze',
          //   link: ''
          // },
        ]
      })

      // My Account section
      const myAccountLink = {
        name: 'My Account',
        link: '/my-kintell/my-details',
        icon: 'cog',
        iconSize: 21,
        alerts: stats.myDetailsAlerts + authenticatedUser.unverifiedGroupCount,
        sub: [
          {
            name: 'My details',
            link: '/my-kintell/my-details',
            alerts: stats.myDetailsProfileAlerts,
          },
          {
            name: 'Advisor settings',
            link: '/my-kintell/my-details/advisor-settings'
          },
          {
            name: 'Billing',
            link: '/my-kintell/my-details/billing',
            alerts: stats.myDetailsPayoutAlerts,
          },
        ]
      }

      if (authenticatedUser.kintellGroups?.length > 0) {
        myAccountLink.sub.splice(1, 0, {
          name: 'My groups',
          link: '/my-kintell/my-details/my-groups',
          alerts: authenticatedUser.unverifiedGroupCount,
        })
      }

      linkItems.push(myAccountLink)

      // More section
      linkItems.push({
        name: 'More',
        link: '/my-kintell/more',
        icon: 'ellipsis',
        iconSize: 5,
        sub: [
          {
            name: this.$t('app.pages.myRewards.title'),
            link: '/my-kintell/more',
          },
          {
            name: this.$t('app.pages.credits.title'),
            link: '/my-kintell/more/credits',
          },
          {
            name: this.$t('app.pages.favourites.title'),
            link: '/my-kintell/more/favourites',
          },
        ]
      })

      return linkItems
    },
  }
}
