import { render, staticRenderFns } from "./roundup.vue?vue&type=template&id=946c7608&lang=pug&"
import script from "./roundup.vue?vue&type=script&lang=js&"
export * from "./roundup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {KLandingInfiniteLearning: require('/opt/atlassian/pipelines/agent/build/app/components/app/Landing/KLandingInfiniteLearning.vue').default,KContainer: require('/opt/atlassian/pipelines/agent/build/app/components/shared/Container/KContainer.vue').default})
