import { validationMixin } from 'vuelidate'
import {
  forEach,
  startsWith,
  lowerCase,
  replace,
  kebabCase,
  map,
  sortBy,
  pickBy,
  filter,
  isFunction,
  isEmpty,
  flattenDeep,
  find,
  snakeCase,
  camelCase,
  keys,
} from 'lodash-es'
import { optional } from '@/lib/util'
import { push, FORM } from '@/lib/gtm'
import { sync } from 'vuex-pathify'
import ChangesScroll from '../ChangesScroll'

/** An immutable array to avoid re-rendering when nothing has changed */
const emptyArray = Object.freeze([])

/**
 * Validates Mixin is our drop-in for form validation and utility functions. It handles form submissions, error messages,
 * API errors, translating errors, etc.
 */
export default {
  mixins: [
    validationMixin,
    ChangesScroll,
  ],
  data () {
    return {
      /**
       * i18nKey is the i18n namespace for where to find the field error messages. This would typically be the component
       * or page name. Example: signUp for the Sign Up pages.
       */
      $_formI18nKey: '',
      /**
       * Within the above namespace (blank by default) where should it look for the error messages. You shouldn't need
       * to modify this
       */
      $_i18nErrorsKey: 'errors',
      /**
       * When scrolling to an error, what vertical offset from the field should the screen go to.
       */
      scrollToErrorYOffset: 150,
      /**
       * i18n data to substitute on all of this components error fields
       */
      $_i18nData: {
        currency: '$'
      },
      /**
       * A local flag for submitting the form to make mixin usage easier
       */
      isSubmittingForm: false,
      /**
       * Flag to prevent scroll when there's an error
       */
      disableScrollToErrorRef: false,
      /**
       * Flag to prevent blur when there's an error
       */
      disableBlurElement: false,
    }
  },
  methods: {
    /**
     * We provide an instance of Vue component. Our code will then iterate through the refs recursively
     * @returns {array} Array of nodes
     * @param node
     */
    $_getRecursiveNodesThroughRefs (node) {
      if (isEmpty(node.$refs)) {
        return [ node ]
      }

      const validNodes = filter(node.$refs, node => !isEmpty(node))

      return [ node, ...map(validNodes, node => this.$_getRecursiveNodesThroughRefs(node)) ]
    },
    /**
     * Iterates through all children $refs to find components which have allValid as false
     * @returns {array}
     */
    $_findInvalidNodeDeep () {
      // recursively get the refs of the vue component
      let deepNodes = this.$_getRecursiveNodesThroughRefs(this)
      deepNodes = flattenDeep(deepNodes)
      // filter the refs that contain the validation mixin
      const nodeWithValidationMixin = filter(deepNodes, node => {
        return isFunction(node.allValid)
      })
      // get the first ref that has an error
      return find(nodeWithValidationMixin, node => !node.allValid())
    },
    /**
     * Touches the vuelidate objects and checks if ALL fields are valid. This will trigger error messages. Note this
     * is for Vuelidate fields only, won't work for API errors
     * @returns {boolean}
     */
    allValid () {
      // just in-case someone is using this wrong
      if (typeof this.$v === 'undefined') {
        return true
      }
      this.$v.$touch()
      return !this.$v.$invalid
    },
    resetFields () {
      if (typeof this.$v === 'undefined') {
        return true
      }
      this.$v.$reset()
    },
    /**
     * Checks if there is a error and scrolls to it
     * @returns {boolean} True if there are no errors, false if there are
     */
    scrollToFirstError () {
      const errorRef = this.firstFieldWithError

      // error handling
      if (errorRef) {
        if (!this.disableScrollToErrorRef) {
          this.scrollToTarget(errorRef, { offset: this.scrollToErrorYOffset })
        }

        return false
      }
      return true
    },
    /**
     * A bit of a monolith function that encapsulates the use case of validating a form for frontend validation,
     * submitting an API request and capturing the API errors and treating them as the frontend errors.
     *
     * It will: validate frontend fields, send API request, capture any errors, emit a GTM event.
     *
     * @param request Closure which will execute the API request and any other form submission logic.

     * @param options
     *  error Any additional functionality needed when an error occurs, such as a toast or event.
     *  scrollToTop if the page should scroll to top after a successful api submission
     *  shouldValidate If the values should be validated
     * @returns {Promise<boolean>} True the request was completed successfully
     */
    async attemptFormApiSubmission (
      request,
      options = {},
    ) {
      options = {
        scrollToTop: true,
        showToastOnError: true,
        shouldValidate: true,
        onApiError: () => {},
        deep: false,
        ...options
      }
      const onApiError = options.onApiError
      const showToastOnError = options.showToastOnError
      const scrollToTop = options.scrollToTop
      const shouldValidate = options.shouldValidate
      const deep = options.deep

      // make sure the user isn't already submitting the form
      if (this.isSubmittingFormGlobally) {
        return false
      }
      // reset the focus
      if (document.activeElement && !this.disableBlurElement) {
        document.activeElement.blur()
      }

      // get the first ref that has an error
      if (shouldValidate) {
        let errorRef = null
        errorRef = deep
          // for deep validations we need to iterate through all nodes
          ? this.$_findInvalidNodeDeep()
          // otherwise just check this reference
          : (!this.allValid() ? this : null)
        // validate submission
        if (errorRef) {
          // handle error and prevent api submission
          errorRef.scrollToFirstError()
          // push GTM event for errors
          const invalidRef = keys(pickBy(errorRef.fields, (value, key) => {
            const hasError = errorRef.defaultErrorMessages(key).length > 0
            if (hasError && !errorRef.$refs[key]) {
              this.$logger.error('Field ' + key + ' does not have a ref, please add one. It must match the field name')
              return false
            }
            return hasError
          }))[0]
          if (invalidRef) {
            // by default the error function will just show a toast
            if (showToastOnError) {
              this.$toast.error(errorRef.defaultErrorMessages(invalidRef)[0])
            }
            push(FORM.VALIDATION_ERROR, {
              component: this.$options._componentTag,
              errorField: invalidRef,
              errorMessage: errorRef.defaultErrorMessages(invalidRef)
            })
          }
          return false
        }
      }

      // now do the API request

      // we flag that we're now doing the request to avoid subsequent requests and for spinners
      this.isSubmittingForm = this.isSubmittingFormGlobally = true
      const nodesToScan = deep
        ? flattenDeep(this.$_getRecursiveNodesThroughRefs(this))
        : [ this ]
      try {
        await request()
        // submitted successfully, reset validation objects
        this.apiErrors = {}
        if (scrollToTop) {
          this.scrollToTop()
        }
        // reset form fields to not be dirty
        const nodes = filter(nodesToScan, node => isFunction(node.allValid))
        nodes.forEach(node => {
          node.resetFields()
        })
        push(FORM.SUBMITTED, {
          component: this.$options._componentTag ?? this.$route.name,
        })
        return true
      } catch (e) {
        const response = e.response
        if (!response) {
          this.$logger.error(e)
        } else {
          // only allow handling of 400 errors
          if (response.status >= 400 && response.status < 500 && response && response.data) {
            onApiError(response.data)
          }
          // if it's not a validation error then axios will handle it anyway
          if (response.status !== 422) {
            return
          }

          if (optional(e, e => e.response.data.errors, false)) {
            this.$logger.error(e.response.data.errors)
            this.apiErrors = e.response.data.errors
            // filter out Vue nodes
            const vueNodes = filter(nodesToScan, node => node._isVue)
            let toasted = false
            // iterate each errors key
            map(e.response.data.errors, (error, key) => {
              // show a toast error for the first error
              if (!toasted) {
                this.$toast.error(error[0])
                toasted = true
              }
              key = camelCase(key)
              push(FORM.API_ERROR, {
                errorField: key,
                component: this.$options._componentTag,
                errorMessage: error[0]
              })
              // for each key, recursively find a child component with the component.$data.fields.$key
              const nodeWithField = find(vueNodes, node => {
                // check if this node actually has fields data
                if (typeof node.$data.fields === 'undefined' || typeof node.$data.fields[key] === 'undefined') {
                  return false
                }
                return node.$data.fields && node.$data.fields[key]
              })
              if (!nodeWithField) {
                return false
              }
              this.$logger.debug('API Field Error', nodeWithField, key, error)
              nodeWithField.scrollToFirstError()
              return nodeWithField
            })
          } else {
            push(FORM.API_ERROR, {
              errorMessage: optional(e, e => e.response.data.message, e),
              errorField: '',
              component: this.$options._componentTag,
            })
          }
        }
      } finally {
        // loading clean-up
        this.isSubmittingForm = this.isSubmittingFormGlobally = false
      }
      return false
    },
    /**
     * This is internal function for finding the i18n key for a component, field and validation type.
     * @param field
     * @param validation
     * @param fieldI18nKey
     * @param label
     * @returns {*}
     */
    getErrorMessageForFieldValidation (field, validation, fieldI18nKey, label) {
      const fieldLabel = label || lowerCase(replace(kebabCase(fieldI18nKey), '-', ' '))
      // if they aren't valid, we generate an error message from the i18n files
      const translateData = {
        field: fieldLabel,
        // merge the field level validation configurations
        ...field.$params[validation],
        // attach component level i18n data
        ...this.$data.$_i18nData
      }

      // namespaces error field key exists with validation name e.g. kintellCards.errors.title--regular.minLength
      const namespacedFieldKey = [
        this.$data.$_formI18nKey,
        this.$data.$_i18nErrorsKey,
        fieldI18nKey,
        validation
      ].join('.')
      // namespaces error field key exists with validation name e.g. kintellCards.errors.minLength
      const namespacedKey = [ this.$data.$_formI18nKey, this.$data.$_i18nErrorsKey, validation ].join('.')
      const globalFieldKey = [ this.$data.$_i18nErrorsKey, fieldI18nKey, validation ].join('.')

      let key = [ this.$data.$_i18nErrorsKey, validation ].join('.')
      if (this.$te(namespacedFieldKey)) {
        key = namespacedFieldKey
      } else if (this.$te(namespacedKey)) {
        key = namespacedKey
      } else if (this.$te(globalFieldKey)) {
        key = globalFieldKey
      }
      this.$logger.debug('Validation i18n Key', key, fieldI18nKey, validation)
      // use default message e.g. errors.minLength
      return this.$t(key, translateData)
    },
  },
  computed: {
    ...sync({
      isSubmittingFormGlobally: 'form/isSubmittingForm',
      apiErrors: 'form/apiErrors'
    }),
    /**
     * Given a field key, we generate the default error messages from the language files. This merges in API errors as
     * well.
     * @returns {Array} A list of errors for the field
     */
    defaultErrorMessages () {
      return (field, label) => {
        if (!this.$v.fields[field]) {
          this.$logger.warn(
            'The field "' + field + '" is specified in ' + this.$options._componentTag + ' data().fields but has no validation rules. ' +
                'Please add a validation rule if required.')
          return []
        }
        // if the input hasn't been changed, don't validate
        if (!this.$v.fields[field].$dirty) { return emptyArray }

        // iterate through all fields that we are validation for
        const errors = []
        forEach(this.$v.fields[field], (isValid, key) => {
          if (startsWith(key, '$')) { // check the property isn't private
            return
          }
          if (isValid) { // only if it's invalid
            return
          }
          const error = this.getErrorMessageForFieldValidation(this.$v.fields[field], key, field, label)
          errors.push(error)
        })
        // add server errors
        if (this.apiErrors[snakeCase(field)]) {
          errors.push(...this.apiErrors[snakeCase(field)])
        }
        return errors
      }
    },
    /**
     * Filter through all field inputs and sort them by their vertical position on the page
     * @returns {*}
     */
    firstFieldWithError () {
      // get only fields which have validation, are invalid and have a ref setup
      const $invalidFields = pickBy(this.fields, (value, key) =>
        this.$refs[key] && this.defaultErrorMessages(key).length > 0
      )
      // get the actual html fields for each validation field if there is no $el, just get the ref with that key (case: advising in < 3 errors just go to the ref of the title)
      const $fields = map($invalidFields, (value, key) => {
        /**
         * In Vue 2, using the ref attribute inside v-for will populate the corresponding $refs property with an array of refs.
         * https://v3.vuejs.org/guide/migration/array-refs.html
         * The form builder uses a v-for of fields which each has a ref.  So this.$refs[key] will return an array with one ref in it.
         */
        if (Array.isArray(this.$refs[key])) {
          return this.$refs[key][0]
        }
        return this.$refs[key].$el || this.$refs[key]
      })
      // order by them by vertical position
      const $orderedFields = sortBy($fields, field => {
        return field.offsetTop
      })
      // return the first result
      return $orderedFields[0]
    },
  }
}
