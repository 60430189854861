import { get, call } from 'vuex-pathify'
import { filter, first, flatten, map } from 'lodash-es'

export default {
  computed: {
    ...get({
      sortedCategories: 'kintell/sortedCategories',
      isCategoriesLoading: 'kintell/categoriesLoading'
    }),
    flattenedCategories () {
      return flatten(map(this.sortedCategories, category => category.children))
    }
  },
  methods: {
    ...call({
      loadCategories: 'kintell/loadCategories'
    }),
    findCategory (id) {
      return first(filter(this.flattenedCategories, category => category.id === id))
    },
    toggleCategory (category, search = true) {
      const filteredCategories = this.filters.category
      const index = filteredCategories.indexOf(category.id)
      if (index >= 0 && index !== false) {
        filteredCategories.splice(index, 1)
      } else {
        filteredCategories.push(category.id)
      }
      this.filters.category = this.modals.category.fields.category = filteredCategories
      if (search) {
        this.search()
      }
    },
  },
  mounted () {
    const options = {}
    if (this.$route.name === 'g-kintellGroup') {
      options.kintellGroupSlug = this.$route.params?.kintellGroup
    }
    this.loadCategories(options)
  }
}
