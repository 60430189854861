import { isString } from 'lodash-es'
import Sizeable from '../Sizeable'
import HandlesErrors from './HandlesErrors'

export default {
  mixins: [ HandlesErrors, Sizeable ],
  inheritAttrs: false,
  props: {
    value: {
      type: [ String, Number, Array, Object, Boolean ],
      required: false,
      default: ''
    },
    label: {
      type: [ String, Boolean ],
      default: ''
    },
    labelLarge: {
      type: Boolean,
      default: false,
    },
    secondaryLabel: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '',
    },
    prefix: {
      type: String,
      default: '',
    },
    topLabel: {
      type: Boolean,
      default: true
    },
    i18nKey: {
      type: String,
      default: ''
    },
    i18nData: {
      type: Object,
      default: () => {}
    },
    grey: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: ''
    },
    maxlength: {
      type: [ Number, String ],
      default: null
    },
    counter: {
      type: Boolean,
      default: false
    },
    // displayed using Kintells hint system
    hint: {
      type: String,
      default: '',
    },
    // displayed under the input using Vuetify's hint
    fieldHint: {
      type: String,
      default: ''
    },
    // display the hint without the icon and change the colour
    quiteHint: {
      type: Boolean,
      default: true,
    },
    /**
     * Hint positioning
     */
    // position the hint to the right of the field and label
    sideHint: {
      type: Boolean,
      default: false,
    },
    // position the hint above the field
    topHint: {
      type: Boolean,
      default: false,
    },
    // position the hint underneath the field
    bottomHint: {
      type: Boolean,
      default: false,
    },
    // position the hint next to the label
    inlineHint: {
      type: Boolean,
      default: false,
    },
    // prop that forces input to be capitalized
    capitalizeInput: {
      type: Boolean,
      default: false
    },
    secondaryLabelClass: {
      type: String,
      default: 'brand-grey--text'
    }
  },
  computed: {
    /**
     * Whether or not the counter should appear below the input or inline.
     *
     * @todo This could be rewritten to take into account the text width and the width of the text input and smartly
     * switch to a bottom counter once it hits that point. For sake of simplicity and speed this is hardcoded to 20
     * for now.
     *
     * @returns {boolean}
     */
    displayCounterBottom () {
      if (isString(this.value) && this.value) {
        return this.value.length > 20
      }
      return false
    },
    labelI18n () {
      if (this.label) {
        return this.label
      }
      if (this.label === false) {
        return ''
      }
      return this.$td('fields.' + this.i18nKey + '.label', '', this.i18nData)
    },
    placeholderI18n () {
      if (this.placeholder) {
        return this.placeholder
      }
      return this.$td('fields.' + this.i18nKey + '.placeholder', ' ', this.i18nData)
    },
    prefixI18n () {
      if (this.prefix) {
        return this.prefix
      }
      return this.$td('fields.' + this.i18nKey + '.prefix', '', this.i18nData)
    },
    labelClasses () {
      return {
        label: !this.labelLarge,
        'label--large': this.labelLarge
      }
    },
    hintI18n () {
      if (this.hint) {
        return this.hint
      }
      return this.$td('fields.' + this.i18nKey + '.hint', ' ', this.i18nData)
    },
    fieldHintI18n () {
      if (this.hint && !this.topHint && !this.sideHint && !this.bottomHint) {
        return this.hint
      }
      return this.$td('fields.' + this.i18nKey + '.fieldHint', '', this.i18nData)
    },
    secondaryLabelI18n () {
      if (this.secondaryLabel) {
        return this.secondaryLabel
      }

      return this.$td('fields.' + this.i18nKey + '.secondaryLabel', '', this.i18nData)
    },
    fieldClasses () {
      const componentPrefix = this.$options._componentTag
      return {
        [componentPrefix]: true,
        [componentPrefix + '--grey']: this.grey,
        [componentPrefix + '--counter']: this.counter,
        ...this.sizeClasses
      }
    }
  },
  methods: {
    onFocus (e) {
      this.displayErrors = false
      this.$emit('focus', e)
    },
    onBlur (e) {
      if (this.displayErrorsOnBlur) {
        this.displayErrors = true
      }
      if (!this.$refs.field) {
        this.$emit('blur', e)
        return
      }
      if (this.$refs.field.internalValue) {
        let trimmed = this.$refs.field.internalValue.toString().trim()
        // turn multiple new lines into just 2
        trimmed = trimmed.replace(/\n\s*\n/g, '\n\n')

        // capitalize the first letter of the string (works if it is one char too)
        if (this.capitalizeInput) {
          trimmed = trimmed.charAt(0).toUpperCase() + trimmed.slice(1)
        }

        // only emit input if it's been updated !
        if (trimmed !== this.$refs.field.internalValue.toString()) {
          this.$emit('input', trimmed)
        }
      }
      this.$emit('blur', e)
    },
    handleInputClick (e) {
      this.displayErrors = false
      this.$refs.field.focus()
      this.$emit('click', e)
    }
  }
}
