import { get } from 'vuex-pathify'
import { UsesAuthenticatedUser } from '@/mixins/index'

export default {
  mixins: [ UsesAuthenticatedUser ],
  computed: {
    ...get({
      ipinfo: 'guest/ipinfo'
    }),
    // if there is no authenticated user use the ip timezone
    timezone () {
      if (this.authenticatedUser) {
        return this.authenticatedUser.timezone
      }
      // if the ipTimezone still hasn't been set we still need a default timezone
      return this.ipinfo ? this.ipinfo.timezone : 'Australia/Sydney'
    },
    timezoneFriendly () {
      return this.timezone.replace('_', ' ')
    }
  },
  methods: {

    /**
     * Convert time to minutes and then add the duration to it
     * It is useful here to convert the time into minutes.  E.g so 2:30am would be 150 minutes so (60 + 60 + 30)
     *  @param time {string}
     *  @return number
     */
    convertTimeToMinutes (time) {
      const splitTime = time.split(':')
      const minutes = parseInt(splitTime[1])
      return (splitTime[0] * 60) + minutes
    },

    /**
     * gets the hour of a
     * @param minutes
     * @return number
     */
    getHour (minutes) {
      return Math.floor(minutes / 60)
    },

    /**
     * gets the minutes
     * @param minutes
     * @return number
     */
    getMinute (minutes) {
      return minutes % 60
    }
  }
}
