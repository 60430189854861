export default {
  width: 23,
  height: 5,
  fill: true,
  svg: `
    <g>
      <circle cx="20.5" cy="2.5" r="2.5"/>
      <circle cx="11.5" cy="2.5" r="2.5"/>
      <circle cx="2.5" cy="2.5" r="2.5"/>
    </g>
  `
}
