import { User } from '@/models'
import {
  inviteClaimed,
  reviewApproved,
  reviewRejected,
  reloadAuthenticatedUser,
  newBookingRequest,
  cancelledProvisionalBooking,
  expiredProvisionalBooking,
  bookingConfirmed,
  confirmedBookingCancelled,
  bookingSuggestedTimes,
  kintellCardUnpublished,
  kintellCardUpdated,
  advisorUpdated,
  kintellUpdated,
  bookingCompleted,
} from './listeners'
import { Context } from '@nuxt/types'
import {
  UserMessage,
  KintellCardMessage,
  ModelMessageConstructable,
  BookingMessage,
  UpdateMessage,
  ModelMessage, MuteMessage,
} from './messages'
import { Channel, PusherChannel, PusherPrivateChannel } from 'laravel-echo/dist/channel'
import KintellCardSpec from '@/lib/models/KintellCardSpec'
import UserSpec from '@/lib/models/UserSpec'

interface EventsMap {
  [name: string]: {
    fn: (ctx: Context) => (data: any) => void,
    resolver?: ModelMessageConstructable,
  },
}

const privateEvents: EventsMap = {
  // User
  '.App\\Events\\User\\ClaimedInvite': {
    resolver: UserMessage,
    fn: inviteClaimed
  },
  '.App\\Events\\User\\StripeIndividualVerificationStatus\\Pending': {
    fn: reloadAuthenticatedUser
  },
  '.App\\Events\\User\\StripeIndividualVerificationStatus\\Verified': {
    fn: reloadAuthenticatedUser
  },
  '.App\\Events\\User\\StripeIndividualVerificationStatus\\Unverified': {
    fn: reloadAuthenticatedUser
  },
  // Kintell Group Access
  '.App\\Events\\KintellGroup\\GroupAccessGranted': {
    fn: reloadAuthenticatedUser
  },
  // Kintell Card
  '.App\\Events\\KintellCard\\ReviewApproved': {
    resolver: KintellCardMessage,
    fn: reviewApproved,
  },
  '.App\\Events\\KintellCard\\ReviewRejected': {
    resolver: KintellCardMessage,
    fn: reviewRejected,
  },

  // Booking
  '.App\\Events\\Booking\\Status\\PendingApproval': {
    resolver: BookingMessage,
    fn: newBookingRequest,
  },
  '.App\\Events\\Booking\\Status\\CancelledProvisional': {
    resolver: BookingMessage,
    fn: cancelledProvisionalBooking,
  },
  '.App\\Events\\Booking\\Status\\ProvisionalRequestExpired': {
    resolver: BookingMessage,
    fn: expiredProvisionalBooking,
  },
  '.App\\Events\\Booking\\Status\\Confirmed': {
    resolver: BookingMessage,
    fn: bookingConfirmed,
  },
  '.App\\Events\\Booking\\Status\\Completed': {
    /**
     * When a booking is complete a user may not access to the booking anymore if they are a guest so do not
     * use BookingMessage
     */
    resolver: UpdateMessage,
    fn: bookingCompleted,
  },
  '.App\\Events\\Booking\\Status\\CancelledConfirmed': {
    resolver: BookingMessage,
    fn: confirmedBookingCancelled,
  },
  '.App\\Events\\Booking\\Status\\SuggestedTimes': {
    resolver: BookingMessage,
    fn: bookingSuggestedTimes,
  },
  '.App\\Events\\Booking\\Reminder\\StartsInTenMinutes': {
    resolver: BookingMessage,
    fn: ctx => {
      return (message: BookingMessage) => {
        ctx.store.commit('myKintell/SET_AWAITING_BOOKING_SESSION', message.booking)
      }
    },
  },
  '.App\\Events\\Booking\\Video\\UserRequestingAccess': {
    resolver: BookingMessage,
    fn: ctx => {
      return (message: BookingMessage) => {
        ctx.store.commit('meeting/SET_IS_REQUESTING_ACCESS', true)
      }
    }
  },
  '.App\\Events\\Booking\\Video\\RefreshBooking': {
    resolver: BookingMessage,
    fn: ctx => {
      return (message: BookingMessage) => {
        ctx.store.dispatch('myKintell/maybeReloadActiveBooking', message)
      }
    }
  },
  '.App\\Events\\Booking\\Video\\Mute': {
    resolver: MuteMessage,
    fn: MuteMessage.handle,
  },
  '.App\\Events\\Booking\\Video\\MuteAll': {
    resolver: MuteMessage,
    fn: MuteMessage.handle,
  },
}

const kintellCardEvents: EventsMap = {
  '.App\\Events\\KintellCard\\Status\\Draft': {
    fn: kintellCardUnpublished
  },
  '.App\\Events\\KintellCard\\Deleted': {
    fn: kintellCardUnpublished
  },
  '.App\\Events\\KintellCard\\Updated': {
    fn: kintellCardUpdated,
    resolver: KintellCardMessage
  },
}

const advisorEvents: EventsMap = {
  '.App\\Events\\User\\PublishedAdvisorUpdated': {
    fn: advisorUpdated,
    resolver: UserMessage
  },
}

export default class Broadcasting {
  public ctx: Context
  public privateChannel?: PusherPrivateChannel
  public activeKintellCardChannel: PusherChannel
  public activeUserChannel?: PusherChannel

  constructor (ctx: Context) {
    this.ctx = ctx
  }

  listen (channel: Channel, event: string, handler: (ctx: Context) => (data: any) => void, ResolverClass?: ModelMessageConstructable) {
    channel.listen(event, async (data: any) => {
      this.ctx.$logger.info('Handling private channel event [' + event + ']', data)

      if (ResolverClass) {
        // set the data from the message to our resolve class
        const resolver = new ResolverClass(data)
        if (resolver instanceof ModelMessage) {
          await resolver.resolve()
        }
        const fn = handler(this.ctx)
        fn(resolver)
      } else {
        const fn = handler(this.ctx)
        fn(data)
      }
    })
  }

  attachUpdateListener () {
    // ci is a public channel for all ci updates
    this.ctx.$logger.trace('Attaching Update listener.')
    this.listen(this.ctx.$echo.channel('ci'), '.update', kintellUpdated, UpdateMessage)
  }

  attachActiveKintellCardListeners () {
    const user: UserSpec = this.ctx.store.getters['kintellCards/activeUser']
    if (this.ctx.$auth.loggedIn && user.id === this.ctx.$auth.user.id) {
      return
    }
    if (this.activeKintellCardChannel || this.activeUserChannel) {
      this.detachActiveKintellCardListeners()
    }
    const card: KintellCardSpec = this.ctx.store.getters['kintellCards/activeKintellCard']
    this.ctx.$logger.info('Attaching Kintell Card broadcasting listeners')

    this.activeKintellCardChannel = card.publicChannel
    this.activeUserChannel = user.publicChannel

    for (const key in kintellCardEvents) {
      this.listen(this.activeKintellCardChannel, key, kintellCardEvents[key].fn, kintellCardEvents[key].resolver)
    }
    for (const key in advisorEvents) {
      this.listen(this.activeUserChannel, key, advisorEvents[key].fn, advisorEvents[key].resolver)
    }
  }

  detachActiveKintellCardListeners () {
    this.ctx.$logger.info('Detaching Kintell Card broadcasting listeners')

    if (this.activeUserChannel) {
      this.activeUserChannel.unsubscribe()
      this.activeUserChannel = undefined
    }
  }

  detachPrivateChannelEvents () {
    if (this.privateChannel) {
      this.privateChannel.unsubscribe()
      this.privateChannel = undefined
    }
  }

  attachPrivateChannelEvents () {
    // only authenticated users get broadcasting
    if (!this.ctx.$auth.$state.user) {
      return
    }
    this.ctx.$logger.info('Attaching User broadcasting listeners')

    // the private channel shouldn't exist here but we clean up in case
    if (this.privateChannel) {
      this.privateChannel.unsubscribe()
      this.privateChannel = undefined
    }
    this.privateChannel = new User(this.ctx.$auth.$state.user).privateChannel

    for (const key in privateEvents) {
      this.listen(this.privateChannel!, key, privateEvents[key].fn, privateEvents[key].resolver)
    }
  }
}
