export default {
  width: 13,
  height: 17,
  svg: `
   <defs>
    <path id="a" d="M0 0h19v19H0z"/>
  </defs>
  <g fill-rule="evenodd" opacity=".90000004" transform="translate(-3 -1)">
    <mask id="b" fill="#fff">
      <use xlink:href="#a"/>
    </mask>
    <path fill-rule="nonzero" d="M9.5 13.952381c.89375 0 1.625-.7285715 1.625-1.6190477s-.73125-1.6190476-1.625-1.6190476-1.625.7285714-1.625 1.6190476.73125 1.6190477 1.625 1.6190477zm4.875-7.28571433h-.8125V5.04761905C13.5625 2.81333333 11.7425 1 9.5 1 7.2575 1 5.4375 2.81333333 5.4375 5.04761905v1.61904762H4.625c-.89375 0-1.625.72857143-1.625 1.61904762v8.09523811C3 17.2714286 3.73125 18 4.625 18h9.75c.89375 0 1.625-.7285714 1.625-1.6190476V8.28571429c0-.89047619-.73125-1.61904762-1.625-1.61904762zM6.98125 5.04761905c0-1.38428572 1.129375-2.50952381 2.51875-2.50952381 1.389375 0 2.51875 1.12523809 2.51875 2.50952381v1.61904762h-5.0375V5.04761905zM14.375 16.3809524h-9.75V8.28571429h9.75v8.09523811z" mask="url(#b)"/>
  </g>
  `
}
