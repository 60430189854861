//
//
//
//
//
//
//
//
//

import { resolveImageSrc } from '@/composables'

export default {
  props: {
    contain: {
      type: Boolean,
      default: false,
    },
    lazySrc: {
      type: String,
      required: false,
    },
    src: {
      type: String,
      required: true,
    },
    width: {
      type: [ String, Number ],
      required: true,
    },
    height: {
      type: [ String, Number ],
      required: true,
    },
    lazy: {
      type: Boolean,
      default: true,
      required: false,
    },
    circle: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    realWidth () {
      if (this.fullWidth) {
        return this.$vuetify.breakpoint.width
      }
      return this.width
    },
    realHeight () {
      if (!this.height && this.aspectRatio) {
        return Math.round(this.realWidth / this.aspectRatio)
      }
      return this.height
    },
    realLazySrc () {
      return resolveImageSrc(this.lazySrc, this.manipulations)
    },
    realSrc () {
      return resolveImageSrc(this.src, this.manipulations)
    },
    imgArgs () {
      const attrs = {
        src: this.realSrc,
      }
      if (this.lazySrc) {
        attrs.style = { backgroundImage: `url(${this.realLazySrc})` }
      }
      if (this.lazy) {
        attrs.loading = 'lazy'
      }
      return attrs
    },
    manipulations () {
      const manipulations = new Set()
      if (this.contain) {
        manipulations.add('c_fit')
      }
      // crops the image
      if (this.realWidth && (typeof this.realWidth === 'number' || !this.realWidth.includes('%'))) {
        manipulations.add(`w_${this.realWidth}`)
      }
      if (this.realHeight && (typeof this.realHeight === 'number' || !this.realHeight.includes('%'))) {
        manipulations.add(`h_${this.realHeight}`)
      }
      if (this.circle) {
        manipulations.add('r_max')
      }
      return manipulations
    }
  },
}
