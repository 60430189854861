let Toast
function createToast (type) {
  return async function (...args) {
    if (!Toast) {
      const Toasted = await loadToasted()


      Toast = new Toasted({
        position: 'bottom-center',
        duration: 4000,
        className: 'paragraph',
        fullWidth: true
      })
    }

    const _toast = Toast[type](...args)
    _toast.el.setAttribute('data-test-id', 'toast')

    return _toast
  }
}

async function loadToasted () {
  return (await Promise.all([
    import(/* webpackChunkName: "vue-toasted" */'vue-toasted/dist/toasted'),
    import(/* webpackChunkName: "vue-toasted" */'vue-toasted/dist/vue-toasted.min.css'),
  ]))[0].default
}

export default function (ctx, inject) {
  inject('toast', {
    success: createToast('success'),
    error: createToast('error'),
    info: createToast('info'),
  })
}
