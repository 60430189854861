//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BindsAttrs } from '@/mixins'

export default {
  mixins: [
    BindsAttrs,
  ],

  props: {
    isMobile: Boolean,
  }
}
