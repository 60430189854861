interface OptionsIdles {
  idle: number, // idle time in ms
  events: string[], // events that will trigger the idle resetter
  onIdle: () => void | null, // callback function to be executed after idle time
  onActive: () => void | null, // callback function to be executed after back form idleness
  onHide: () => void | null, // callback function to be executed when window become hidden
  onShow: () => void | null, // callback function to be executed when window become visible
  keepTracking: boolean, // set it to false of you want to track only once
  startAtIdle: boolean, // set it to true if you want to start in the idle state
  recurIdleCall: boolean,
}

export class IdleAction {
  settings: OptionsIdles
  visibilityEvents: string[]
  clearTimeout: () => void | null
  idle: boolean
  visible: boolean
  stopListener: () => void
  idlenessEventsHandler: () => void
  visibilityEventsHandler: () => void

  constructor (options: OptionsIdles) {
    const defaults = {
      idle: 1000,
      events: [ 'mousemove', 'scroll', 'keydown', 'mousedown', 'touchstart' ],
      onIdle: null,
      onActive: null,
      onHide: null,
      onShow: null,
      keepTracking: true,
      startAtIdle: false,
      recurIdleCall: false
    }

    this.settings = Object.assign({}, defaults, options)
    this.visibilityEvents = [ 'visibilitychange', 'webkitvisibilitychange', 'mozvisibilitychange', 'msvisibilitychange' ]
    this.clearTimeout = null

    this.reset()

    this.stopListener = () => {
      this.stop()
    }

    this.idlenessEventsHandler = () => {
      if (this.idle) {
        this.idle = false
        this.settings.onActive?.()
      }

      this.resetTimeout()
    }

    this.visibilityEventsHandler = () => {
      // @ts-expect-error
      if (document.hidden || document.webkitHidden || document.mozHidden || document.msHidden) {
        if (this.visible) {
          this.visible = false
          this.settings.onHide?.()
        }
      } else if (!this.visible) {
        this.visible = true
        this.settings.onShow?.()
      }
    }
  }

  resetTimeout (keepTracking = this.settings.keepTracking) {
    if (this.clearTimeout) {
      this.clearTimeout()
      this.clearTimeout = null
    }
    if (keepTracking) {
      this.timeout()
    }
  }

  timeout () {
    const timer = (this.settings.recurIdleCall)
      ? {
        set: setInterval.bind(window),
        clear: clearInterval.bind(window),
      }
      : {
        set: setTimeout.bind(window),
        clear: clearTimeout.bind(window),
      }

    const id = timer.set(() => {
      this.idle = true
      this.settings.onIdle?.()
    }, this.settings.idle)

    this.clearTimeout = () => timer.clear(id)
  }

  start () {
    window.addEventListener('idle:stop', this.stopListener)
    this.timeout()

    bulkAddEventListener({
      object: window,
      events: this.settings.events,
      callback: this.idlenessEventsHandler
    })

    if (this.settings.onShow || this.settings.onHide) {
      bulkAddEventListener({
        object: document,
        events: this.visibilityEvents,
        callback: this.visibilityEventsHandler
      })
    }

    return this
  }

  stop () {
    window.removeEventListener('idle:stop', this.stopListener)

    bulkRemoveEventListener({
      object: window,
      events: this.settings.events,
      callback: this.idlenessEventsHandler
    })
    this.resetTimeout(false)

    if (this.settings.onShow || this.settings.onHide) {
      bulkRemoveEventListener({
        object: document,
        events: this.visibilityEvents,
        callback: this.visibilityEventsHandler
      })
    }

    return this
  }

  reset ({ idle = this.settings.startAtIdle, visible = !this.settings.startAtIdle } = {}) {
    this.idle = idle
    this.visible = visible

    return this
  }
}

interface EventListenerOptions {
  object: Window | Document,
  events: string[],
  callback: () => void,
}


function bulkAddEventListener ({ object, events, callback }: EventListenerOptions) {
  events.forEach(event => {
    object.addEventListener(event, callback)
  })
}

function bulkRemoveEventListener ({ object, events, callback }: EventListenerOptions) {
  events.forEach(event => {
    object.removeEventListener(event, callback)
  })
}
