//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    textColor: {
      type: String,
      default: '#30324B',
    },
    outlineColor: {
      type: String,
      default: '#4E888C',
    },
    bgColor: {
      type: String,
      default: '#4E888C',
    }
  },
}
