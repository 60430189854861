export default {
  data () {
    return {
      displayConfirmOnLeave: false,
      confirmAction: null,
      // whether the false button should take the user to the next page
      falseChoiceStays: false,
    }
  },
  async beforeRouteLeave (to, from, next) {
    if (!this.$refs.confirmLeave) {
      this.$logger.error('You must define a confirmLeave $ref to use DisplaysConfirmOnLeave.')
      return
    }
    // only if there's pending changes
    if (!this.displayConfirmOnLeave) {
      next()
      return
    }

    let actionResult
    const choice = await this.$refs.confirmLeave.confirm(async () => {
      if (this.confirmAction) {
        actionResult = await this.confirmAction()
      }
    })
    // If the component should handle the choice being false as a stay on this page
    // This is used for the sign up page
    if (this.falseChoiceStays && !choice) {
      return
    }
    // they closed the confirm box
    if (choice === null) {
      return
    }
    // if the confirm action can't be performed
    if (actionResult === false) {
      return
    }
    next()
  },
  beforeDestroy () {
    window.removeEventListener('beforeunload', this.unload)
  },
  methods: {
    setPendingChanges (value) {
      if (value) {
        window.addEventListener('beforeunload', this.unload)
      } else {
        window.removeEventListener('beforeunload', this.unload)
      }
      this.displayConfirmOnLeave = value
    },
    unload (e) {
      if (!this.displayConfirmOnLeave) {
        return true
      }
      e.preventDefault()
      e.returnValue = ''
    }
  }
}
