// @ts-nocheck
import { HTTPPromise, HTTPRequestConfig, Model as BaseModel } from 'vue-api-query'
import moment from 'moment-timezone'
import type Echo from 'laravel-echo'
import type { PusherPrivateChannel, PusherChannel } from 'laravel-echo/dist/channel'

interface PaginationOptions {
  page: number,
  limit: number,
}

export default class Model extends BaseModel {
  static $store: unknown
  static $echo: Echo

  id: string
  stats?: Record<string, any>

  constructor (...args: any[]) {
    super(...args)

    if (args.length > 0) {
      Object.assign(this, { ...this, ...this.transform(...args) })
    }
    // make sure we always have a stats object
    if (!this.stats) {
      this.stats = {}
    }
  }

  get $echo () {
    return Model.$echo
  }

  get $store () {
    return Model.$store
  }

  static pagination ({ page, limit }: PaginationOptions) {
    return this.instance().params({ 'page[number]': page, 'page[size]': limit })
  }

  // define a base url for a REST API
  baseURL (): string {
    return process.env.API_URL || 'https://uat.api.kintell.com'
  }

  // implement a default request method
  request (config: HTTPRequestConfig): HTTPPromise {
    return this.$http.request(config)
  }

  pagination ({ page, limit }: PaginationOptions) {
    return this.params({ 'page[number]': page, 'page[size]': limit })
  }

  where (field: string|string[], value: unknown) {
    // only if the value is set to something
    if (value == null || (typeof value === 'string' && !value)) {
      return this
    }
    this._builder.where(field, value)

    return this
  }

  updated () {
    // do something
  }

  created () {
  }

  /**
   * Same function as endpoint but without checking for any relations
   * @returns {string}
   */
  resourceUrl () {
    if (this.hasId()) {
      return `${this.baseURL()}/${this.resource()}/${this.getPrimaryKey()}`
    } else {
      return `${this.baseURL()}/${this.resource()}`
    }
  }

  params (payload) {
    const newParams = {
      ...this._builder.payload,
      ...payload
    }
    // append the params
    this._builder.params(newParams)
    return this
  }

  get channelId () {
    return this.resource() + '.' + this.id
  }

  get publicChannel () {
    return this.$echo.channel(this.channelId) as PusherChannel
  }

  get privateChannel () {
    return this.$echo.private(this.channelId) as PusherPrivateChannel
  }

  transform (data: Record<string, any>) {
    return data
  }

  async _create () {
    const response = await this.$http.$post(this.endpoint(), this.castsForRequest(this))
    const model = Object.assign(this, this.transform(response.data))
    model.created()
    return model
  }

  async _update () {
    const response = await this.$http.$put(this.endpoint(), this.castsForRequest(this))
    const model = Object.assign(this, this.transform(response.data))
    model.updated()
    return model
  }

  castsForRequest (data: Record<string, any>) {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key) && moment.isMoment(data[key])) {
        data[key] = data[key].utc().format('YYYY-MM-DD HH:mm:ss')
      }
    }
    // frontend should never push stat data
    if (data.stats) {
      delete data.stats
    }
    return data
  }

  async getAction (action: string, data = {}) {
    return (await this.$http.$get(this.resourceUrl() + '/' + action, this.castsForRequest(data))).data
  }

  async action (action: string, data = {}, update = true) {
    const updatedModelData = (await this.$http.$post(this.resourceUrl() + '/' + action, this.castsForRequest(data))).data
    const model = Object.assign(this, this.transform(updatedModelData))
    update && model.updated()
    return model
  }

  find (identifier: number | string | undefined) {
    if (identifier === undefined) {
      throw new Error('You must specify the param on find() method.')
    }
    const base = this._fromResource || `${this.baseURL()}/${this.resource()}`
    const url = `${base}/${identifier}${this._builder.query()}`

    return this.request({
      url,
      method: 'GET'
    }).then(response => new this.constructor(response.data.data))
  }
}
