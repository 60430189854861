export default {
  width: 19,
  height: 19,
  svg: `
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.58337 4.75065C1.58337 4.31343 1.93782 3.95898 2.37504 3.95898H16.625C17.0623 3.95898 17.4167 4.31343 17.4167 4.75065C17.4167 5.18788 17.0623 5.54232 16.625 5.54232H2.37504C1.93782 5.54232 1.58337 5.18788 1.58337 4.75065Z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.91663 2.37435C7.70666 2.37435 7.5053 2.45776 7.35683 2.60622C7.20837 2.75469 7.12496 2.95605 7.12496 3.16602V3.95768H11.875V3.16602C11.875 2.95605 11.7916 2.75469 11.6431 2.60622C11.4946 2.45776 11.2933 2.37435 11.0833 2.37435H7.91663ZM13.4583 3.95768V3.16602C13.4583 2.53613 13.2081 1.93204 12.7627 1.48664C12.3173 1.04124 11.7132 0.791016 11.0833 0.791016H7.91663C7.28674 0.791016 6.68265 1.04124 6.23725 1.48664C5.79185 1.93204 5.54163 2.53613 5.54163 3.16602V3.95768H3.95829C3.52107 3.95768 3.16663 4.31212 3.16663 4.74935V15.8327C3.16663 16.4626 3.41685 17.0667 3.86225 17.5121C4.30765 17.9575 4.91174 18.2077 5.54163 18.2077H13.4583C14.0882 18.2077 14.6923 17.9575 15.1377 17.5121C15.5831 17.0667 15.8333 16.4626 15.8333 15.8327V4.74935C15.8333 4.31212 15.4789 3.95768 15.0416 3.95768H13.4583ZM4.74996 5.54102V15.8327C4.74996 16.0426 4.83337 16.244 4.98183 16.3925C5.1303 16.5409 5.33166 16.6243 5.54163 16.6243H13.4583C13.6683 16.6243 13.8696 16.5409 14.0181 16.3925C14.1666 16.244 14.25 16.0426 14.25 15.8327V5.54102H4.74996Z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.91667 7.91602C8.35389 7.91602 8.70833 8.27046 8.70833 8.70768V13.4577C8.70833 13.8949 8.35389 14.2493 7.91667 14.2493C7.47944 14.2493 7.125 13.8949 7.125 13.4577V8.70768C7.125 8.27046 7.47944 7.91602 7.91667 7.91602Z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0833 7.91602C11.5205 7.91602 11.875 8.27046 11.875 8.70768V13.4577C11.875 13.8949 11.5205 14.2493 11.0833 14.2493C10.6461 14.2493 10.2916 13.8949 10.2916 13.4577V8.70768C10.2916 8.27046 10.6461 7.91602 11.0833 7.91602Z"/>
  `
}
