var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.linkPath)?_c('nuxt-link',{attrs:{"to":_vm.linkPath,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var isActive = ref.isActive;
var navigate = ref.navigate;
return [_c('a',{class:[
          _vm.linkClasses,
          { 'nuxt-link-active': isActive }
        ],attrs:{"id":("header-" + _vm.activatorId),"href":href},on:{"click":navigate}},[_vm._v("\n      "+_vm._s(_vm.linkText)+"\n\n      "),_c('k-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideMenu),expression:"!hideMenu"}],ref:"refIcon",staticClass:"ml-3",attrs:{"size":"6px","inline":"","color":_vm.chevronColor}},[_vm._v("\n        chevron\n      ")])],1)]}}],null,false,3403094206)}):_c('button',{class:[_vm.linkClasses],attrs:{"id":("header-" + _vm.activatorId)}},[_vm._t("activator"),_vm._v(" "),_c('k-icon',{ref:"refIcon",staticClass:"ml-3",attrs:{"size":"6px","inline":"","color":_vm.chevronColor}},[_vm._v("\n      chevron\n    ")])],2),_vm._v(" "),_c('v-menu',_vm._b({ref:"refMenu",attrs:{"offset-y":"","bottom":"","allow-overflow":"","open-on-hover":"","open-on-click":false,"close-delay":"300","eager":"","transition":"slide-y-transition","content-class":_vm.menuContentClass,"disabled":_vm.hideMenu,"activator":("#header-" + _vm.activatorId)},model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},'v-menu',_vm.$data.$_attrs,false),[_c('div',{staticClass:"header-menu white relative",class:{
        'header-menu-large': _vm.largeMenu,
      },style:({ '--left-arrow': _vm.leftArrow })},[(_vm.largeMenu)?_vm._l((_vm.items),function(item){return _c('nuxt-link',{key:item.id,staticClass:"header-menu-large__item unstyled--link paragraph--small",attrs:{"to":item.path}},[_c('div',{staticClass:"header-menu-large__icon"},[_c('k-icon',{attrs:{"color":"brand-dark-blue","size":item.iconSize || '24'}},[_vm._v("\n              "+_vm._s(item.id)+"\n            ")])],1),_vm._v(" "),_c('div',{staticClass:"header-menu-large__text ml-3 ml-md-7"},[_c('div',{staticClass:"header-menu-large__title small-caps"},[_vm._v("\n              "+_vm._s(item.title)+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"header-menu-large__desc mt-1",domProps:{"innerHTML":_vm._s(item.description)}})])])}):_vm._t("default")],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }