import { render, staticRenderFns } from "./TheNavigationDrawer.vue?vue&type=template&id=37147ba3&scoped=true&"
import script from "./TheNavigationDrawer.vue?vue&type=script&lang=js&"
export * from "./TheNavigationDrawer.vue?vue&type=script&lang=js&"
import style0 from "./TheNavigationDrawer.vue?vue&type=style&index=0&id=37147ba3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37147ba3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {KIconHamburger: require('/opt/atlassian/pipelines/agent/build/app/components/shared/Icon/KIconHamburger.vue').default,KButton: require('/opt/atlassian/pipelines/agent/build/app/components/shared/Button/KButton.vue').default,KButtonOutline: require('/opt/atlassian/pipelines/agent/build/app/components/shared/Button/KButtonOutline.vue').default,KIcon: require('/opt/atlassian/pipelines/agent/build/app/components/shared/Icon/KIcon.vue').default,KLayoutDrawerItems: require('/opt/atlassian/pipelines/agent/build/app/components/app/Layout/KLayoutDrawerItems.vue').default,KCardText: require('/opt/atlassian/pipelines/agent/build/app/components/shared/Card/KCardText.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VBadge,VNavigationDrawer})
