//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { UsesAuthenticatedUser, UsesKintellGroup } from '@/mixins'
export default {
  mixins: [
    UsesAuthenticatedUser,
    UsesKintellGroup
  ],
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      images: {
        personChattingOnLaptop: require('~images/person-chatting-on-laptop.svg')
      },
      isDev: process.env.NODE_ENV !== 'production'
    }
  },
  head () {
    return {
      title: 'Kintell | ' + this.statusCode + ' Error',
    }
  },
  computed: {
    is404Error () {
      return this.statusCode === 404
    },
    is403Error () {
      return this.statusCode === 403
    },
    statusCode () {
      return (this.error ? this.error.statusCode : 'Client Error')
    },
    message () {
      return this.error.message
    },
  }
}
