export default {
  width: 29,
  height: 28,
  stroke: true,
  fill: false,
  svg: `
<path d="M15.168 6.25c0-2.89975 2.3502-5.25 5.25-5.25 2.8997 0 5.25 2.35025 5.25 5.25s-2.3503 6.4167-5.25 6.4167c-2.8998 0-5.25-3.51695-5.25-6.4167ZM16.332 16.1467c1.1113-.3325 2.4658-.5647 4.0834-.5647 2.9085 0 4.8171.7502 5.9925 1.4893 1.0028.6311 1.5908 1.75 1.5908 2.9347v6.6594h-7.5833M3.33203 13.2487c0-2.5772 2.0895-4.66667 4.66667-4.66667 2.5772 0 4.6667 2.08947 4.6667 4.66667s-2.0895 5.25-4.6667 5.25c-2.57717 0-4.66667-2.6728-4.66667-5.25ZM15 24.6228c0-.9497-.5699-1.8083-1.4513-2.1612C12.3353 21.9762 10.4156 21.418 8 21.418c-2.41558 0-4.33533.5582-5.54867 1.0436C1.56992 22.8145 1 23.6737 1 24.6228v2.0452h14v-2.0452Z" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  `
}

