// pathify
import { make } from 'vuex-pathify'

// base state
const state = () => ({
  showDrawer: false,
  showDarkHeader: false,
  showGroupSidebar: false
})

// automatically generate mutations
const mutations = {
  // create SET_VALUE automatically
  ...make.mutations(state)
}

// export store
export default {
  namespaced: true,
  state,
  mutations,
}
