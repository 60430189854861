//
//
//
//
//
//
//

import { Sizeable } from '@/mixins'

export default {
  mixins: [
    Sizeable,
  ]
}
