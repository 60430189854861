import { render, staticRenderFns } from "./TheHeader.vue?vue&type=template&id=79cbe664&scoped=true&"
import script from "./TheHeader.vue?vue&type=script&lang=js&"
export * from "./TheHeader.vue?vue&type=script&lang=js&"
import style0 from "./TheHeader.vue?vue&type=style&index=0&id=79cbe664&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79cbe664",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {KLayoutHeaderLogo: require('/opt/atlassian/pipelines/agent/build/app/components/app/Layout/KLayoutHeaderLogo.vue').default,KLayoutHeaderAvatar: require('/opt/atlassian/pipelines/agent/build/app/components/app/Layout/KLayoutHeaderAvatar.vue').default,KLayoutHeaderMarketplaceLink: require('/opt/atlassian/pipelines/agent/build/app/components/app/Layout/KLayoutHeaderMarketplaceLink.vue').default,KLayoutHeaderMenu: require('/opt/atlassian/pipelines/agent/build/app/components/app/Layout/KLayoutHeaderMenu.vue').default,KIcon: require('/opt/atlassian/pipelines/agent/build/app/components/shared/Icon/KIcon.vue').default,KLayoutHeaderAccount: require('/opt/atlassian/pipelines/agent/build/app/components/app/Layout/KLayoutHeaderAccount.vue').default,KButton: require('/opt/atlassian/pipelines/agent/build/app/components/shared/Button/KButton.vue').default,KContainer: require('/opt/atlassian/pipelines/agent/build/app/components/shared/Container/KContainer.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VList,VListItem,VSpacer})
