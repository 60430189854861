import Broadcasting from './broadcasting'
import Authentication from './authentication'
import { Context } from '@nuxt/types'

export default class Kintell {
  public ctx: Context
  public inject: Function

  constructor (ctx: Context, inject: Function) {
    this.ctx = ctx
    this.inject = inject
  }

  boot () {
    const $authentication = new Authentication(this.ctx)
    const $broadcasting = new Broadcasting(this.ctx)

    $broadcasting.attachUpdateListener()
    $authentication.boot()

    this.inject('authentication', $authentication)
    this.inject('broadcasting', $broadcasting)
  }
}
