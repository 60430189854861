import Model from './Model'
import KintellCard from './KintellCard'

export default class Category extends Model {
  constructor (...attributtes) {
    super(...attributtes)

    // map the kintell cards
    if (this.kintellCards) {
      this.kintellCards = [ ...this.kintellCards ].map(card => new KintellCard(card))
    }
  }


  resource () {
    return 'categories'
  }
}
