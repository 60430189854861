import { UsesAuthenticatedUser } from '@/mixins/index'

export default {
  mixins: [ UsesAuthenticatedUser ],
  props: {
    selectedKintellGroup: {
      type: [ Object, Array ],
      default: {}
    }
  },
  computed: {
    kintellGroups () {
      const kintellGroups = this.authenticatedUser?.kintellGroupsNonContent

      if (kintellGroups?.length) {
        return kintellGroups
      }

      return false
    },
    loginPage () {
      let groupSlug = ''
      if (!this.onGroupOrKintellCardPage) {
        if (this.$route.query.group) {
          groupSlug = this.$route.query.group
        }
      } else {
        groupSlug = this.activeKintellGroup?.slug
      }

      if (groupSlug) {
        return `/g/${groupSlug}/join?step=login`
      }

      return '/password-free?step=login'
    },
    onGroupOrKintellCardPage () {
      const route = this.$route.name
      return route?.startsWith('advisors-advisor-kintellCard') || this.onGroupKintellPage
    },
    onGroupKintellPage () {
      return this.$route.name?.startsWith('g-kintellGroup')
    },
    activeKintellGroup () {
      if (!this.onGroupOrKintellCardPage) {
        return false
      }
      const route = this.$route.name
      if (route?.startsWith('advisors-advisor-kintellCard')) {
        const activeKintellCard = this.$store.getters['kintellCards/activeKintellCard']
        return activeKintellCard?.kintellGroup
      }

      if (route?.startsWith('g-kintellGroup')) {
        return this.$store.getters['kintellGroup/activeKintellGroup']
      }
    },
  },

  methods: {
    getMemberKintellGroup (member) {
      if (this.kintellGroup) {
        return member.kintellGroups.filter(kintellGroup => kintellGroup.id === this.kintellGroup.id).pop()
      }
      return member.kintellGroups[0]
    },
    getMembersStatus (member) {
      if (this.hasGroupRole(this.groupRolesCollections.IS_VERIFIED_MEMBER, member.kintellGroupRole)) {
        return 'Verified'
      }
      return this.hasGroupRole(this.groupRolesCollections.IS_AWAITING_VERIFICATION, member.kintellGroupRole)
        ? 'Awaiting Verification'
        : 'UnVerified'
    },
    verifiedChipStyles (member) {
      if (this.hasGroupRole(this.groupRolesCollections.IS_VERIFIED_MEMBER, member.kintellGroupRole)) {
        return {
          color: 'brand-light-aqua-50',
          textColor: 'brand-dark-aqua'
        }
      }
      if (this.hasGroupRole(this.groupRolesCollections.IS_AWAITING_VERIFICATION, member.kintellGroupRole)) {
        return {
          color: 'brand-grey',
          textColor: 'white'
        }
      }
      return {
        color: 'brand-grey-30',
        textColor: 'brand-pink'
      }
    },
    getMembersKintellCardsByStatus (member, status) {
      if (!member?.kintellCards) {
        return []
      }
      return member?.kintellCards.filter(kintellCard => kintellCard.status === status)
    },
  },
}
