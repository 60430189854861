//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data () {
    return {
      people: require('~kurfs/girl-and-boy.svg')
    }
  }
}
