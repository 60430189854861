export default {
  width: 22,
  height: 26,
  fill: true,
  svg: `
  <svg viewBox="0 0 22 26" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.1206 11.6474C14.3368 11.6474 16.9442 9.03997 16.9442 5.82368C16.9442 2.60739 14.3368 0 11.1206 0C7.90424 0 5.29688 2.60737 5.29688 5.82368C5.29688 9.03998 7.90424 11.6474 11.1206 11.6474ZM11.1206 8.9851C9.37456 8.9851 7.95913 7.56966 7.95913 5.82368C7.95913 4.07769 9.37456 2.66225 11.1206 2.66225C12.8665 2.66225 14.282 4.07771 14.282 5.82368C14.282 7.56964 12.8665 8.9851 11.1206 8.9851Z"/>
    <path d="M21.4371 23.7939V19.3275C21.4371 17.7939 20.631 16.3805 19.311 15.5994C17.1149 14.2998 14.374 13.4777 11.1209 13.4777C7.82758 13.4777 5.08656 14.296 2.91214 15.5939C1.60239 16.3762 0.804688 17.785 0.804688 19.3111V23.7939C0.804688 24.529 1.40065 25.125 2.13581 25.125H20.106C20.8412 25.125 21.4371 24.529 21.4371 23.7939ZM3.46694 22.4628V19.3111C3.46694 18.721 3.77285 18.1808 4.27697 17.8797C6.05377 16.8191 8.32878 16.1399 11.1209 16.1399C13.8729 16.1399 16.1522 16.8236 17.9552 17.8906C18.4655 18.1925 18.7749 18.7349 18.7749 19.3275V22.4628H3.46694Z"/>
  </svg>
 `
}
