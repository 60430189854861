// From https://codepen.io/cretz/pen/jObGXLK
// Get the best item bounds to fit in the container. Param object must have
// width, height, itemCount, aspectRatio, maxRows, and minGap. The itemCount
// must be greater than 0. Result is single object with rowCount, colCount,
// itemWidth, and itemHeight.
export function getBestItemBounds (config: {
  box: DOMRect,
  itemCount: number,
  aspectRatio: number,
  maxRows: number,
  minGap: number,
}) {
  const actualRatio = config.box.width / config.box.height
  // Just make up theoretical sizes, we just care about ratio
  const theoreticalHeight = 100
  const theoreticalWidth = theoreticalHeight * config.aspectRatio
  // Go over each row count find the row and col count with the closest
  // ratio.
  let best: { rowCount: number, colCount: number, ratio: number } | undefined
  for (let rowCount = 1; rowCount <= config.maxRows; rowCount++) {
    // Row count can't be higher than item count
    if (rowCount > config.itemCount) {
      continue
    }
    const colCount = Math.ceil(config.itemCount / rowCount)
    // Get the width/height ratio
    const ratio = (theoreticalWidth * colCount) / (theoreticalHeight * rowCount)
    if (!best || Math.abs(ratio - actualRatio) < Math.abs(best.ratio - actualRatio)) {
      best = { rowCount, colCount, ratio }
    }
  }
  // Build item height and width. If the best ratio is less than the actual ratio,
  // it's the height that determines the width, otherwise vice versa.
  const result: {
    rowCount: number,
    colCount: number,
    itemHeight: number,
    itemWidth: number,
  } = { rowCount: best!.rowCount, colCount: best!.colCount } as any
  if (best!.ratio < actualRatio) {
    result.itemHeight = (config.box.height - (config.minGap * best!.rowCount)) / best!.rowCount
    result.itemWidth = result.itemHeight * config.aspectRatio
  } else {
    result.itemWidth = (config.box.width - (config.minGap * best!.colCount)) / best!.colCount
    result.itemHeight = result.itemWidth / config.aspectRatio
  }
  return result
}
