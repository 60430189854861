import minutesOrHours from '@/lib/util/minutes-or-hours'
import Model from './Model'
import User from './User'
import KintellCard from './KintellCard'
import { pick, isEmpty } from 'lodash-es'
import moment from 'moment-timezone'
import Video from './Video'
import Review from './Review'
import { MeetingSessionConfiguration } from 'amazon-chime-sdk-js'

interface BookingTimeSuggested {
  date: string,
  duration: number,
  timestampUtc: string|moment.Moment,
  start: moment.Moment,
}

interface Permissions {
  canDownloadRecording: boolean,
  canSeePeopleTab: boolean,
  canRecord: boolean,
  canViewRecording: boolean,
  canChat: boolean,
}

interface stats {
  learnerShowed: boolean,
  advisorShowed: boolean,
}

export default class Booking extends Model {
  public learner: User
  public advisor: User
  public createdBy: User|string
  public users: User[]
  public primaryVideo?: Video
  declare public stats: stats
  public rescheduledBy: string
  public learnerId: string
  public advisorId: string
  public bookingTimeSuggested: BookingTimeSuggested[]
  public status: string
  public bookingTimeUtc: string
  public bookingStartTimeUtc: moment.Moment
  public bookingEndTimeUtc: moment.Moment
  public createdAtUtc: moment.Moment
  public instantBooking: boolean
  public advisorPayoutCents: number
  public learnerPaymentCurrency: string
  public advisorPayoutCurrency: string
  public adminFeePercentage: number
  // TODO: add later
  public kintellCard: any
  public compositionShortLink: string
  public recordingOwner: User
  public isRecording: boolean
  public permissions: Permissions
  // legacy support
  public compositionSlug: string
  public vonageSessionId: string
  public cpass: string
  public chimeMeeting: MeetingSessionConfiguration

  resource () {
    return 'bookings'
  }

  /**
   * Transform an object of data before it is used to fill this model.
   * Note: Make sure you're referring to data and not this as it wil break things.
   * @param data
   * @returns {{bookingTimeUtc}|*}
   */
  transform (data: Record<string, any>) {
    if (!data) {
      return data
    }
    if (data.advisor) {
      data.advisor = new User(data.advisor)
    }
    if (data.learner) {
      data.learner = new User(data.learner)
    }
    if (data.kintellCard) {
      data.kintellCard.user = data.advisor
      data.kintellCard = new KintellCard(data.kintellCard)
    }
    if (data.bookingTimeUtc) {
      data.bookingStartTimeUtc = moment.utc(data.bookingTimeUtc)
      data.bookingEndTimeUtc = moment.utc(data.bookingStartTimeUtc).add(data.duration, 'm')
    }
    if (data.createdAt) {
      data.createdAtUtc = moment.utc(data.createdAt)
    }
    if (data.videoExpiresAt) {
      data.videoExpiresAt = moment.utc(data.videoExpiresAt)
    }
    if (data.bookingTimesSuggested) {
      data.bookingTimesSuggested = data.bookingTimesSuggested
        .map((bookingTimeSuggested: BookingTimeSuggested) => {
          if (typeof bookingTimeSuggested.timestampUtc === 'string') {
            // @ts-expect-error
            const timezone = this.$store.$auth.$state.user ? this.$store.$auth.$state.user.timezone : moment.tz.guess()
            bookingTimeSuggested.timestampUtc = moment(bookingTimeSuggested.timestampUtc).utc()
            bookingTimeSuggested.start = moment(bookingTimeSuggested.timestampUtc).tz(timezone)
          }
          return bookingTimeSuggested
        })
    }
    if (data.users) {
      data.users = data.users.map((user: Object) => new User(user))
    }

    if (data.recordingOwner) {
      data.recordingOwner = new User(data.recordingOwner)
    }

    if (data.reviews) {
      data.reviews = data.reviews.map((review: Object) => new Review(review))
    }

    if (data.primaryVideo) {
      data.primaryVideo = new Video(data.primaryVideo)
    }

    // flag it as real data
    data.isGhost = false
    return data
  }

  confirm (data: Object) {
    return this.action('confirm', data)
  }

  /**
   * Cancel the booking.
   *
   * This is for cancelling bookings at all steps: provisional, confirmed, rescheduled for both the advisor and learner.
   *
   * @param data
   * @returns {Promise<*>}
   */
  cancel (data: Object) {
    /*
     * When submitting the cancel request we want to strip any non-related fields from the data.
     *
     * This to fix a problem where the bookingTimeUtc was being passed into the data object and not being transformed,
     * it's safer to do this anyway.
     *
     * @todo should probably do something similar for the other actions
     */
    return this.action('cancel', pick(data, [
      'cancelMessage',
      'cancelReason'
    ]))
  }

  suggestTimes (data: Object) {
    return this.action('suggest-times', data)
  }

  async fetchChatToken () {
    return (await this.$http.$post(this.resourceUrl() + '/chat-token')).data
  }

  async fetchVideoToken () {
    return (await this.$http.$post(this.resourceUrl() + '/video-token')).data
  }

  async fetctCpassToken () {
    return (await this.$http.$post(this.resourceUrl() + '/cpass-token')).data
  }

  async fetchVideoCompositionUrl () {
    return (await this.$http.$get(this.resourceUrl() + '/video-composition'))
  }

  async fetchAudioCompositionUrl () {
    return (await this.$http.$get(this.resourceUrl() + '/audio-composition'))
  }

  async time () {
    // @ts-expect-error
    return (await Booking.custom(this, 'time').first()).time
  }

  async currentClients () {
    // @ts-expect-error
    return (await Booking.custom(this, 'clients').first()).currentClients
  }

  async toggleRecording () {
    return (await this.$http.$post(this.resourceUrl() + '/record'))
  }

  async fetchVodUrls (vodGuid: string) {
    return (await this.$http.$get(`video/${vodGuid}`))
  }

  updateDetails (userId: string, data: Object) {
    // Updates the booking users table
    return this.action(`details/${userId}`, data, true)
  }

  revokeUser (userId: string) {
    return this.action(`revoke/${userId}`)
  }

  approveUser (userId: string) {
    return this.action(`approve/${userId}`)
  }

  requestApproval () {
    return this.action('requestApproval')
  }

  enableCoHost (userId: string) {
    return this.action(`host/${userId}`)
  }

  disableCoHost (userId: string) {
    return this.action(`host/${userId}/disable`)
  }

  complete () {
    // loadBookingForSlug doesn't do anything if
    // it's already loaded but out of date
    this.status = 'completed'

    return this.action('complete')
  }

  get link () {
    return `/my-kintell/bookings/${this.id}`
  }

  /**
   * Compares the current UTC time to the booking start time UTC.
   * @returns {boolean}
   */
  get hasStarted () {
    return moment.utc().isSameOrAfter(this.bookingStartTimeUtc, 'minute')
  }

  get isStarting () {
    return moment.utc().isSame(this.bookingStartTimeUtc, 'minute')
  }

  get canJoinBooking () {
    // user can join 60 minutes before the booking is supposed to start
    const joinBookingTime = moment.utc(this.bookingStartTimeUtc).subtract('60', 'minutes')
    return moment.utc().isSameOrAfter(joinBookingTime, 'minute')
  }

  get canJoinCall () {
    // user can actually connect to the room 60min before the start time
    const joinBookingTime = moment.utc(this.bookingStartTimeUtc).subtract('60', 'minutes')
    return moment.utc().isSameOrAfter(joinBookingTime, 'minute')
  }

  get hasEnded () {
    return moment.utc().isSameOrAfter(this.bookingEndTimeUtc, 'minute')
  }

  /**
   * A fresh booking in this instance will be a booking that was created in the last 5 minutes. This is
   * useful for when we want to avoid making extra requests when it's not needed (i.e chat tokens)
   * @returns {boolean}
   */
  get isFreshBooking () {
    const minutesSinceMade = moment.utc().diff(this.createdAtUtc, 'minutes')
    return minutesSinceMade < 5
  }

  get isVonageBooking () {
    return this.cpass === 'vonage'
  }

  get isChimeBooking () {
    return this.cpass === 'chime'
  }

  /**
   * Checks the time between the current UTC time to the booking start time UTC.
   * @returns {string}
   */
  get startsInHumanTime () {
    if (this.isStarting) {
      return 'now'
    }
    const diffString = minutesOrHours(
      Math.abs(moment.utc().diff(this.bookingStartTimeUtc, 'minutes') + 1),
      {
        // at what interval to go from hours to minutes
        minutesToHoursThreshold: 240
      }
    )
    if (this.hasStarted) {
      return diffString + ' ago'
    }
    return 'in ' + diffString
  }

  get minutesUntilBookingStart () {
    return moment.utc().diff(this.bookingStartTimeUtc, 'minutes')
  }

  get endsInTime () {
    return moment.utc().to(this.bookingEndTimeUtc)
  }

  get isBookingInThisYear () {
    return moment.utc().isSame(this.bookingStartTimeUtc, 'year')
  }

  get learnerFullName () {
    return this.learner?.fullName
  }

  get humanStatus () {
    if (this.status === 'pending-approval') {
      return 'Awaiting Response'
    } else if (this.status === 'suggested-times') {
      return 'Awaiting Response'
    }
  }

  get isConfirmed () {
    return this.status === 'confirmed' || this.status === 'completed'
  }

  get isCompleted () {
    return this.status === 'completed'
  }

  get isCancelled () {
    return [ 'cancelled-provisional', 'cancelled-confirmed' ].includes(this.status)
  }

  get isExpired () {
    return [
      'expired-learner-no-show',
      'expired-advisor-no-show',
      'expired-both-no-show',
    ].includes(this.status)
  }

  get joinBookingLink () {
    return '/my-kintell/bookings/' + this.id + '/join'
  }

  get confirmBookingLink () {
    return '/my-kintell/bookings/' + this.id + '/confirm'
  }

  get suggestBookingLink () {
    return '/my-kintell/bookings/' + this.id + '/suggest-times'
  }

  get rescheduleBookingLink () {
    return '/my-kintell/bookings/' + this.id + '/reschedule'
  }

  get respondInitiatedBookingRequestLink () {
    return this.kintellCard.link + '/respond?booking=' + this.id
  }

  get isArchived () {
    return [
      'provisional-request-expired',
      'completed',
      'cancelled-confirmed',
      'cancelled-provisional',
      'expired-both-no-show',
      'expired-advisor-no-show',
      'expired-learner-no-show',
    ].includes(this.status)
  }

  get isPendingApproval () {
    return this.status === 'pending-approval'
  }

  get wasRescheduleByAdvisor () {
    return this.rescheduledBy === this.advisorId
  }

  get wasRescheduledByLearner () {
    return this.rescheduledBy === this.learnerId
  }

  get wasCreatedByAdvisor () {
    return this.advisorId === this.createdBy
  }

  get wasCreatedByGroupAdmin () {
    // We don't have access to the group admin id, but at the moment this is true
    return !this.wasCreatedByAdvisor && this.createdById !== this.learnerId && !this.instantBooking
  }

  get isAdvisorInitiatedAndPendingApproval () {
    return this.wasCreatedByAdvisor && this.isPendingApproval
  }

  get isGroupAdminFacilitatedAndPendingApproval () {
    return this.wasCreatedByGroupAdmin && this.isPendingApproval
  }

  get wasNotConfirmed () {
    return this.isPendingApproval || this.status === 'cancelled-provisional' || this.status === 'provisional-request-expired'
  }

  get canUseChat () {
    return this.permissions.canChat
  }

  get canLearnerRespond () {
    return (this.wasCreatedByGroupAdmin || this.wasCreatedByAdvisor) && this.isPendingApproval
  }

  get isGroupBooking () {
    return !isEmpty(this.kintellCard.kintellGroup)
  }

  get isGroupBookingWithPricingDisabled () {
    if (!this.isGroupBooking) {
      return false
    }
    return !this.kintellCard.kintellGroup.pricingEnabled
  }

  get createdById () {
    // @ts-expect-error
    return this.createdBy.id ?? this.createdBy
  }

  hasOwner (user: User) {
    return user.id === this.advisorId || user.id === this.learnerId
  }

  isNotFree () {
    return this.kintellCard.convertedRate > 0
  }
}
