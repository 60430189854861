export default {
  width: 21,
  height: 21,
  stroke: true,
  svg: `
  <g fill="none">
    <path d="M9.625 11.375C7.20869 11.375 5.25 8.54131 5.25 6.125V5.25C5.25 2.83369 7.20869 0.875 9.625 0.875C12.0413 0.875 14 2.83369 14 5.25V6.125C14 8.54131 12.0413 11.375 9.625 11.375Z" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.0467 13.8123C12.0203 13.6618 10.8723 13.5625 9.625 13.5625C6.874 13.5625 4.60381 14.0429 3.06556 14.5005C1.76487 14.8873 0.875 16.0803 0.875 17.437V19.25H10.5" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.1875 20.125C18.3621 20.125 20.125 18.3621 20.125 16.1875C20.125 14.0129 18.3621 12.25 16.1875 12.25C14.0129 12.25 12.25 14.0129 12.25 16.1875C12.25 18.3621 14.0129 20.125 16.1875 20.125Z" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.1875 14.875V17.5" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.875 16.1875H17.5" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  `
}
