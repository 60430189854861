import { keys } from 'lodash-es'
import { make } from 'vuex-pathify'

const findMutationName = field => {
  return keys(make.mutations({ [field]: null }))[0]
}

export {
  findMutationName
}
