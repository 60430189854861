//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    links: {
      type: Array,
      required: true
    },
    hasSub: {
      type: Boolean,
      default: false
    },
    exactLinkActive: {
      type: Boolean,
      default: true
    }
  }
}
