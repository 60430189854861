// fake RTCPeerConnection for safari 9
if (
  typeof RTCPeerConnection === 'undefined' &&
  typeof webkitRTCPeerConnection === 'undefined' &&
  typeof mozRTCPeerConnection === 'undefined'
) {
  window.RTCPeerConnection = {
    __mocked__: true
  }
}

if (
  typeof AudioContext === 'undefined' &&
  typeof webkitAudioContext !== 'undefined'
) {
  /* eslint-disable-next-line no-undef */
  window.AudioContext = webkitAudioContext
}
