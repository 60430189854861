import { Context } from '@nuxt/types'
import { UserMessage } from '@/lib/broadcasting/messages'
import { KintellCard } from '@/models'
import KintellCardSpec from '@/lib/models/KintellCardSpec'
import { endsWith } from 'lodash-es'

export default (ctx: Context) => {
  return (message: UserMessage) => {
    const card = ctx.store.getters['kintellCards/activeKintellCard']
    const activeBooking = ctx.store.getters['myKintell/activeBooking']

    ctx.store.commit('kintellCards/SET_ACTIVE_USER', message.user)
    // @ts-expect-error KintellCard needs to be converted to typescript
    const kintellCard: KintellCardSpec = new KintellCard({ ...card, user: message.user })
    kintellCard.syncToVuex(true)

    const isBooking = endsWith(ctx.route.path, '/book')
    // only redirect if it's published, this it to fix awaiting review issue
    if (kintellCard.status === 'published') {
      if (!activeBooking || isBooking) {
        if (kintellCard.link !== ctx.route.path && !isBooking) {
          ctx.redirect(kintellCard.link)
        } else if (kintellCard.bookLink !== ctx.route.path && isBooking) {
          ctx.redirect(kintellCard.bookLink)
        }
      }
    }
  }
}
