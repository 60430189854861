export default {
  width: 87,
  height: 87,
  svg: `
    <g filter="url(#downcircle)">
      <circle cx="43.5" cy="35.5" r="23.5" />
    </g>
    <mask id="downarrow" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="33" y="29" width="21" height="12">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M34.37 30.3058c-.4881.4402-.507 1.1737-.0421 1.6383l8.6832 8.6784c.2425.2424.5687.3638.8946.3619.3835.0573.7897-.0599 1.0778-.3478l8.6776-8.6729c.4649-.4646.4461-1.1981-.0421-1.6383-.4882-.4401-1.2608-.4203-1.7257.0444l-7.889 7.8847-7.9086-7.9043c-.4649-.4647-1.2375-.4845-1.7257-.0444Z" />
    </mask>
    <g mask="url(#downarrow)">
      <path fill="#fff" d="M34 27.25h20v20H34z"/>
    </g>
    <defs>
      <filter id="downcircle" x="0" y="0" width="87" height="87" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="8"/>
        <feGaussianBlur stdDeviation="10"/>
        <feColorMatrix values="0 0 0 0 0.188235 0 0 0 0 0.196078 0 0 0 0 0.294118 0 0 0 0.08 0"/>
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_26239_34768"/>
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_26239_34768" result="shape"/>
      </filter>
    </defs>
  `,
}
