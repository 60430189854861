import TheFooter from './TheFooter'
import TheHeader from './TheHeader'

export {
  TheHeader,
  TheFooter,
}
export default {
  $_subcomponents: {
    TheFooter,
    TheHeader,
  }
}
