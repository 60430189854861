export default {
  width: 52,
  height: 60,
  stroke: true,
  svg: `
  <path d="M2 10.3398H40.9188" fill="transparent" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M2 47.4043H26.0926" fill="transparent" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M26.0926 57.5984H6.6332C5.4044 57.5984 4.22592 57.1102 3.35703 56.2413C2.48814 55.3724 2 54.194 2 52.9652V6.6332C2 5.4044 2.48814 4.22592 3.35703 3.35703C4.22592 2.48814 5.4044 2 6.6332 2H36.2856C37.5144 2 38.6929 2.48814 39.5618 3.35703C40.4307 4.22592 40.9188 5.4044 40.9188 6.6332V32.5791" fill="transparent" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M31.8398 45.5518C32.2671 43.4588 33.4043 41.5776 35.0592 40.2267C36.714 38.8759 38.7847 38.1382 40.9209 38.1387C42.704 38.1413 44.4466 38.6704 45.9302 39.6595C47.4138 40.6485 48.5724 42.0536 49.2607 43.6985" fill="transparent" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M49.9989 49.2578C49.5717 51.3508 48.4344 53.232 46.7796 54.5828C45.1248 55.9337 43.0541 56.6714 40.9179 56.6709C39.1348 56.6683 37.3922 56.1392 35.9086 55.1501C34.425 54.1611 33.2664 52.756 32.5781 51.1111" fill="transparent" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M49.2599 37.2129V43.6994H42.7734" fill="transparent" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M32.5781 57.5978V51.1113H39.0646" fill="transparent" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  `
}
