export default {
  width: 54,
  height: 53,
  svg: `
    <g fill-rule="nonzero">
      <path d="M32.5887 20.0625H52.3125C53.1409 20.0625 53.8125 19.3909 53.8125 18.5625V1.6875C53.8125 0.859073 53.1409 0.1875 52.3125 0.1875H23.625C22.7966 0.1875 22.125 0.859073 22.125 1.6875V25.3125C22.125 26.5703 23.5799 27.2695 24.562 26.4838L32.5887 20.0625ZM50.8125 3.1875V17.0625H32.0625C31.7219 17.0625 31.3914 17.1784 31.1255 17.3912L25.125 22.1916V3.1875H50.8125Z"/>
      <path d="M42.1875 36.9375C45.8118 36.9375 48.75 33.9993 48.75 30.375C48.75 26.7507 45.8118 23.8125 42.1875 23.8125C38.5632 23.8125 35.625 26.7507 35.625 30.375C35.625 33.9993 38.5632 36.9375 42.1875 36.9375ZM42.1875 33.9375C40.22 33.9375 38.625 32.3425 38.625 30.375C38.625 28.4075 40.22 26.8125 42.1875 26.8125C44.155 26.8125 45.75 28.4075 45.75 30.375C45.75 32.3425 44.155 33.9375 42.1875 33.9375Z"/>
      <path d="M11.8125 36.9375C15.4368 36.9375 18.375 33.9993 18.375 30.375C18.375 26.7507 15.4368 23.8125 11.8125 23.8125C8.18814 23.8125 5.25 26.7507 5.25 30.375C5.25 33.9993 8.18814 36.9375 11.8125 36.9375ZM11.8125 33.9375C9.845 33.9375 8.25 32.3425 8.25 30.375C8.25 28.4075 9.845 26.8125 11.8125 26.8125C13.78 26.8125 15.375 28.4075 15.375 30.375C15.375 32.3425 13.78 33.9375 11.8125 33.9375Z"/>
      <path d="M23.4375 50.625V45.592C23.4375 43.8638 22.5291 42.2711 21.0416 41.391C18.5669 39.9265 15.4783 39 11.8125 39C8.10136 39 5.01259 39.9221 2.56231 41.3847C1.0864 42.2663 0.1875 43.8537 0.1875 45.5735V50.625C0.1875 51.4534 0.859073 52.125 1.6875 52.125H21.9375C22.7659 52.125 23.4375 51.4534 23.4375 50.625ZM3.1875 49.125V45.5735C3.1875 44.9086 3.53222 44.2998 4.1003 43.9605C6.10251 42.7654 8.66614 42 11.8125 42C14.9136 42 17.4821 42.7705 19.5139 43.9728C20.0889 44.313 20.4375 44.9242 20.4375 45.592V49.125H3.1875Z"/>
      <path d="M53.8125 50.625V45.592C53.8125 43.8638 52.9041 42.2711 51.4166 41.391C48.9419 39.9265 45.8533 39 42.1875 39C38.4763 39 35.3875 39.9221 32.9373 41.3847C31.4614 42.2663 30.5625 43.8537 30.5625 45.5735V50.625C30.5625 51.4534 31.2341 52.125 32.0625 52.125H52.3125C53.1409 52.125 53.8125 51.4534 53.8125 50.625ZM33.5625 49.125V45.5735C33.5625 44.9086 33.9072 44.2998 34.4753 43.9605C36.4774 42.7654 39.0411 42 42.1875 42C45.2886 42 47.8571 42.7705 49.8889 43.9728C50.4639 44.313 50.8125 44.9242 50.8125 45.592V49.125H33.5625Z"/>
    </g>
  `
}
