
export default {
  props: {
    svg: {
      required: true,
      type: String
    },
    viewportWidth: {
      required: true,
      type: Number
    },
    viewportHeight: {
      required: true,
      type: Number
    },
  },
  computed: {
    viewBox () {
      return '0 0 ' + this.viewportWidth + ' ' + this.viewportHeight
    }
  },
  render (h, context) {
    return h('svg', {
      ...context,
      attrs: {
        viewBox: this.viewBox,
      },
      domProps: {
        innerHTML: this.svg,
      },
    })
  },
}
