//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BindsAttrs } from '@/mixins'

export default {
  mixins: [ BindsAttrs ],
  props: {
    color: {
      default: 'white',
      type: String,
    },
    closable: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    border: {
      type: [ String, Boolean ],
      default: false,
    },
    borderTop: {
      type: [ String, Boolean ],
      default: false,
    },
    borderSize: {
      type: Number,
      default: 2,
    },
    boxShadow: {
      type: String,
      default: 'medium'
    }
  },
  computed: {
    styles () {
      return {
        border: this.border
          ? (this.borderSize + 'px solid var(--v-' + this.border + '-base) !important')
          : false,
        borderTop: this.borderTop
          ? (this.borderSize + 'px solid var(--v-' + this.borderTop + '-base) !important')
          : false,
      }
    },
    classes () {
      return {
        ['box-shadow--' + this.boxShadow]: !this.flat,
        'box-shadow--none': this.flat,
        'k-card--outline': this.outline
      }
    }
  }
}
