import { Booking, Email, KintellGroup, User } from '@/models'
import { join, single, paginated } from '@/store/build'
import { isEmpty } from 'lodash-es'
import { make } from 'vuex-pathify'

const activeKintellGroup = single('activeKintellGroup', KintellGroup)

const activeKintellGroupMember = single('activeKintellGroupMember', User)

const activeKintellGroupMemberEmail = single('activeKintellGroupMemberEmail', Email)

const activeKintellGroupMembers = paginated('activeKintellGroupMembers', User, async ({ state }) => {
  const params = state.kintellGroup.activeKintellGroupMembersSearchParams

  const query = (new KintellGroup({ id: params.id }))
    .members()
    .params(params)
    .orderBy(params.sortBy ? params.sortBy : 'kg_role')

  if (params.query) {
    query.where('search-name', params.query)
  }

  if (params.role) {
    query.where('group-role', params.role)
  }

  if (params.roleWhenJoined) {
    query.where('group-role-when-joined', params.roleWhenJoined)
  }

  if (params.groupActivityType) {
    // TODO: clean up this code
    const thresholdDays = {
      lastFourWeeks: 28,
      lastSevenDays: 7,
      lastTwentyFourHours: 1,
    }

    const getOptions = (params, type) => {
      // capitalize the first letter of the type, excepts first character in small case
      const capitalizedType = type.charAt(0).toUpperCase() + type.slice(1)
      return {
        status: params[`${type}Status${capitalizedType}Type`] ?? '',
        role: params[`${type}Role${capitalizedType}Type`] ?? '',
        howMany: {
          // Four plus is 4 or more i.e > 3
          moreThan: params[`howMany${capitalizedType}Type`] === 'fourPlus' ? 3 : '',
          between: params[`howMany${capitalizedType}Type`] === 'twoToThree' ? [ 2, 3 ] : '',
          exact: (params[`howMany${capitalizedType}Type`] === 'one' ? 1 : '') || (params[`howMany${capitalizedType}Type`] === 'none' ? 0 : '')
        },
        when: {
          priorDays: thresholdDays[params[`when${capitalizedType}Type`]] || ''
        },
        activity: params[`${type}Activity${capitalizedType}Type`] ?? '',
      }
    }
    // for type safety, change the type of params.groupActivityType to camelcase
    // TODO: This is a hack, need to fix this
    if (params.groupActivityType === 'inmail') {
      params.groupActivityType = 'inMail'
    }
    if ([ 'inMail', 'login' ].includes(params.groupActivityType)) {
      const options = getOptions(params, params.groupActivityType)
      // looks like this: group-booking-activity or group-inmail-activity
      // convert camelcase to lowercase
      params.groupActivityType = params.groupActivityType.toLowerCase()
      query.where(`group-${params.groupActivityType}-activity`, btoa(JSON.stringify(options)))
    }

    if (params.groupActivityType === 'booking') {
      query.where(`group-booking-activity`, btoa(JSON.stringify(params.bookings)))
    }
  }

  return await query
    .pagination({
      page: state.kintellGroup.activeKintellGroupMembersPagination.current_page,
      limit: state.kintellGroup.activeKintellGroupMembersPagination.per_page
    })
    .get()
}, {
  appendResults: false,
  modifyQueryParams: false,
  cacheMinutes: 0
})

const groupMemberEmails = paginated('groupMemberEmails', Email, async ({ state }) => {
  const params = state.kintellGroup.groupMemberEmailsSearchParams

  return await new Email()
    .where('kintell_group_id', params.kintellGroupId)
    .where('recipient_id', params.groupMemberId)
    .orderBy('-created_at')
    .pagination({
      page: state.kintellGroup.groupMemberEmailsPagination.current_page,
      limit: state.kintellGroup.groupMemberEmailsPagination.per_page
    })
    .get()
}, {
  appendResults: false,
  modifyQueryParams: false,
  cacheMinutes: 0
})

const groupMemberBookings = paginated('groupMemberBookings', Booking, async ({ state }) => {
  const params = state.kintellGroup.groupMemberBookingsSearchParams

  return await new KintellGroup({ id: params.kintellGroupId })
    .bookings()
    .where('member_id', params.groupMemberId)
    .append('message_details')
    .pagination({
      page: state.kintellGroup.groupMemberBookingsPagination.current_page,
      limit: state.kintellGroup.groupMemberBookingsPagination.per_page
    })
    .get()
}, {
  appendResults: false,
  modifyQueryParams: false,
  cacheMinutes: 0
})

function generateApplyStore () {
  function state () {
    return {
      applyState: 'IDLE',
      // To be used when user drop off from the application process
      applyReportName: '',
    }
  }

  return {
    state: state(),
    mutations: make.mutations(state),
  }
}

let {
  // eslint-disable-next-line prefer-const
  state,
  // eslint-disable-next-line prefer-const
  getters,
  // eslint-disable-next-line prefer-const
  mutations,
  actions
} = join(
  activeKintellGroup,
  activeKintellGroupMembers,
  activeKintellGroupMember,
  activeKintellGroupMemberEmail,
  groupMemberEmails,
  groupMemberBookings,
  generateApplyStore(),
)
// extend actions
actions = {
  ...actions,
  async loadKintellGroupForSlug ({ commit, state, rootState }, { kintellGroup, error, useCache = true }) {
    // already loaded
    if (
      useCache &&
      // check kintell card exists and slug matches
      state.activeKintellGroup && (state.activeKintellGroup?.slug === kintellGroup)) {
      return true
    }

    const kintellGroupModel = await KintellGroup.where('slug', kintellGroup).first()
    commit('SET_ACTIVE_KINTELL_GROUP', kintellGroupModel)
    return true
  },

  async loadKintellGroupMemberForSlug ({ commit, state, rootState }, { kintellGroup, member, error }) {
    const kintellGroupModel = await KintellGroup.where('slug', kintellGroup).first()
    if (isEmpty(kintellGroupModel)) {
      error({ statusCode: 404, message: 'Sorry, the group cannot be found.' })
      return false
    }
    // Get Group member of the group
    const kintellGroupMember = await new KintellGroup({ id: kintellGroupModel.id })
      .members()
      .where('userId', member)
      .first()
    if (isEmpty(kintellGroupMember)) {
      error({ statusCode: 404, message: 'Sorry, the page not found.' })
      return false
    }
    commit('SET_ACTIVE_KINTELL_GROUP', kintellGroupModel)
    commit('SET_ACTIVE_KINTELL_GROUP_MEMBER', kintellGroupMember)
    return true
  },

  async refreshMember ({ commit, state, rootState }, userId = null) {
    const activeGroupMemberId = userId ?? state.activeKintellGroupMember.id
    const kintellGroupMember = await new KintellGroup({ id: state.activeKintellGroup.id })
      .members()
      .where('userId', activeGroupMemberId)
      .first()
    commit('SET_ACTIVE_KINTELL_GROUP_MEMBER', kintellGroupMember)
    return true
  },

  async loadGroupMemberEmailForSlug ({ commit, state, rootState }, { inmail, member, error }) {
    const memberEmail = await Email
      .where('email_id', inmail)
      .where('recipient_id', member)
      .first()
    if (isEmpty(memberEmail)) {
      error({ statusCode: 404, message: 'Sorry, the page not found.' })
      return false
    }
    commit('SET_ACTIVE_KINTELL_GROUP_MEMBER_EMAIL', memberEmail)
    return true
  }
}

// export store
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
