import consola from 'consola'

// Override the default BrowserReporter
// https://github.com/nuxt/consola/issues/50
export default function Consola (tag) {
  const logger = consola.withTag(tag)
  logger.setReporters(new BrowserReporter())
  return logger
}

export class BrowserReporter {
  constructor (options) {
    this.options = Object.assign({}, options)

    this.defaultColor = '#7f8c8d' // Gray
    this.levelColorMap = {
      0: '#c0392b', // Red
      1: '#f39c12', // Yellow
      3: '#00BCD4' // Cyan
    }
    this.typeColorMap = {
      success: '#2ecc71' // Green
    }
  }

  log (logObj) {
    const consoleLogFn = logObj.level < 1
      // eslint-disable-next-line no-console
      ? (console.__error || console.error)
      // eslint-disable-next-line no-console
      : logObj.level === 1 && console.warn ? (console.__warn || console.warn) : (console.__log || console.log)

    // Type
    const type = logObj.type !== 'log' ? logObj.type : ''

    // Tag
    const tag = logObj.tag ? logObj.tag : ''

    // Styles

    const color = this.typeColorMap[logObj.type] || this.levelColorMap[logObj.level] || this.defaultColor
    const style = `
      background: ${color};
      border-radius: 0.5em;
      color: white;
      font-weight: bold;
      padding: 2px 0.5em;
    `

    // Log to the console
    consoleLogFn.call(
      console,
      '%c' + [ tag, type ].filter(Boolean).join(':'),
      style,
      ...logObj.args
    )
  }
}

consola.setReporters(new BrowserReporter())
consola.level = process.env.CONSOLA_LEVEL

export class BugsnagReporter {
  constructor (options) {
    this.options = Object.assign({}, options)
  }

  log (logObj) {
    const { args, tag, level } = logObj

    // ignore anything more verbose than info
    if (level > 3) { return }

    const [ msg, ...data ] = args

    this.options.bugsnag.notify(
      new Error(msg),
      event => {
        event.severity = [ 'error', 'warning', 'info' ][level - 1]
        event.addMetadata('tag', tag)
        event.addMetadata('data', ...data)
      }
    )
  }
}
