export default {
  width: 50,
  height: 50,
  stroke: true,
  fill: false,
  svg: `
  <path d="M35.9375 25H14.0625" stroke="inherit" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M25 47.6562C37.5127 47.6562 47.6562 37.5127 47.6562 25C47.6562 12.4873 37.5127 2.34375 25 2.34375C12.4873 2.34375 2.34375 12.4873 2.34375 25C2.34375 37.5127 12.4873 47.6562 25 47.6562Z" fill="transparent" stroke="inherit" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  `
}
