//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Sizeable, BindsAttrs } from '@/mixins'

export default {
  mixins: [
    Sizeable,
    BindsAttrs,
  ],
  inheritAttrs: false,
  props: {
    /**
     * Will make the button take the full container width on screen sizes sm and xs.
     * The default behaviour is not to resize.
     */
    responsive: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'brand-pink'
    },
    dark: {
      type: Boolean,
      default: false,
    },
    disabled: Boolean,
  },
  computed: {
    /**
     * Conditionally set the theme to dark if we're using our brand-pink or a dark brand colour
     * @returns {boolean}
     */
    isDarkThemeColour () {
      return this.dark || (!this.disabled && (this.color === 'brand-pink' || this.color === 'brand-light-pink' || this.color.includes('brand-dark')))
    },
    classes () {
      return {
        'k-button': true,
        'k-button--responsive': this.responsive,
        'k-button--x-small': this.xSmall,
        'k-button--small': this.small,
        ...this.sizeClasses
      }
    },
  }
}
