export default {
  height: 40,
  width: 40,
  svg: `
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
<path fill-rule="evenodd" clip-rule="evenodd" d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20 37C29.3888 37 37 29.3888 37 20C37 10.6112 29.3888 3 20 3C10.6112 3 3 10.6112 3 20C3 29.3888 10.6112 37 20 37Z" />
<rect x="18" y="8" width="4" height="16" />
<rect x="18" y="27" width="4" height="4" />
</mask>
<g mask="url(#mask0)">
<rect width="40" height="40" />
</g>
  `
}

