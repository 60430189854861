//
//
//
//
//

import BaseLayout from '@/layouts/base'

export default {
  components: { BaseLayout },
}
