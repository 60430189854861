import { Context } from '@nuxt/types'
import { BookingMessage } from '@/lib/broadcasting/messages'

// '.App\\Events\\Booking\\Status\\SuggestedTimes'
export default (ctx: Context) => {
  return (e: BookingMessage) => {
    let message = 'An advisor you requested a booking with has suggested some alternative times.'
    if (e.booking.rescheduledBy) {
      message = 'One of your confirmed bookings has been rescheduled.'
    }
    ctx.$toast.success(message, {
      duration: 10000
    })
    ctx.store.dispatch('myKintell/maybeReloadActiveBooking', e)
    ctx.store.dispatch('myKintell/reloadBookings')
    ctx.store.dispatch('auth/refresh')
  }
}
