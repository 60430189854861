//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { UsesAuthenticatedUser } from '@/mixins'

export default {
  mixins: [ UsesAuthenticatedUser ],
  props: {
    isPlaceholder: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    description () {
      return this.isPlaceholder
        ? 'Kintell is a modern solution to share knowledge over video - live and recorded.'
        : 'Kintell is a modern solution to share knowledge over video - live and recorded.'
    },
    cta () {
      return this.isPlaceholder ? 'Start for free!' : 'Record for free!'
    },
  },
  methods: {
    onClickCta () {
      if (!this.isPlaceholder) {
        // Instant meeting is a short link which is external to our site
        window.location.href = this.authenticatedUser.instantMeetingLink
        return
      }
      this.$router.push('/password-free?step=signup')
    }
  },
}
