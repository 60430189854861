export default {
  data () {
    return {
      modal: {
        verifyMobile: false,
      },
      phone: ''
    }
  },
  methods: {
    openVerifyMobileModal () {
      this.modal.verifyMobile = true
    },
  }
}
