//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { UsesAuthenticatedUser } from '@/mixins'

export default {
  mixins: [ UsesAuthenticatedUser ],

  computed: {
    nameTitle () {
      if (!this.authenticatedUser || !this.authenticatedUser.firstName) {
        return ''
      }

      if (this.authenticatedUser.fullName.length <= 15) {
        return this.authenticatedUser.fullName
      }

      return this.authenticatedUser.firstName.length > 15 ? 'My Kintell' : this.authenticatedUser.firstName
    },

    alertCount () {
      return this.authenticatedUser?.avatarAlertCount
    },

    userBadgeLabel () {
      let userBadge = ''

      if (this.authenticatedUser.isKintellPlus) {
        userBadge = 'PLUS'
      } else if (this.authenticatedUser.isKintellPro) {
        userBadge = 'PRO'
      }

      return userBadge
    },
  }
}
