export default {
  width: 41,
  height: 36,
  stroke: true,
  svg: `
    <g fill="none">
      <g filter="url(#filter0_d)">
        <rect x="7" y="3" width="27" height="22" rx="5" fill="#fff"/>
        <rect x="7" y="3" width="27" height="22" rx="5" stroke-width="2"/>
      </g>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M18 19V9l7 5-7 5z" fill="#30324B"/>
      <defs>
        <filter id="filter0_d" x="0" y="0" width="41" height="36" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/><feOffset dy="4"/><feGaussianBlur stdDeviation="3"/>
          <feColorMatrix values="0 0 0 0 0.188235 0 0 0 0 0.196078 0 0 0 0 0.294118 0 0 0 0.04 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
      </defs>
    </g>
  `


}
