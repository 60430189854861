

export default {
  width: 40,
  height: 40,
  svg: `
    <mask id="svg-menu-mask" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="7" width="40" height="26">
        <rect y="7" width="40" height="4" rx="2" />
        <rect y="18" width="40" height="4" rx="2" />
        <rect y="29" width="40" height="4" rx="2" />
    </mask>
    <g mask="url(#svg-menu-mask)">
        <rect width="40" height="40""/>
    </g>`
}
