import { render, staticRenderFns } from "./KLayoutHeaderMenu.vue?vue&type=template&id=59163833&scoped=true&"
import script from "./KLayoutHeaderMenu.vue?vue&type=script&lang=js&"
export * from "./KLayoutHeaderMenu.vue?vue&type=script&lang=js&"
import style0 from "./KLayoutHeaderMenu.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./KLayoutHeaderMenu.vue?vue&type=style&index=1&id=59163833&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59163833",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {KIcon: require('/opt/atlassian/pipelines/agent/build/app/components/shared/Icon/KIcon.vue').default})


/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VMenu})
