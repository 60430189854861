//
//
//
//
//
//
//
//
//

import BaseLayout from '@/layouts/base'
import { get } from 'vuex-pathify'

export default {
  components: {
    BaseLayout
  },
  computed: {
    ...get({
      blog: 'myKintell/blog'
    }),
  },
}
