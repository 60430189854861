
export default function ({ app, redirect, route }) {
  const authState = app.$auth.$state
  if (authState.loggedIn && !authState.user.id) {
    app.$auth.reset()
    if (route.path !== '/password-free?step=login') {
      return redirect('/password-free?step=login')
    }
  }
}
