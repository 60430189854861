import Model from './Model'
import Invite from './Invite'
import User from './User'
import KintellCredit from './KintellCredit'

export default class Me extends Model {
  static async invites () {
    const invites = await this.instance()
      .custom('me/invites')
      .pagination({ page: 1, limit: 999 })
      .append('pending_kintell_card')
      .orderBy('-created_at')
      .$get()
    return invites.map(invite => new Invite(invite))
  }

  static async changePassword (input) {
    await this.instance().action('change-password', input)
  }

  static meetingToken (room) {
    return this.$http.$post(this.instance().resourceUrl() + '/join-booking', { room })
  }

  static phoneVerifyRequest () {
    return this.instance().action('phone-verify-request')
  }

  static emailVerifyRequest () {
    return this.instance().action('email-verify-request')
  }

  static recoverAccount (phone) {
    return this.$http.$post(this.instance().resourceUrl() + '/recover-account', {
      phone
    })
  }

  static createPaymentMethod (token) {
    return this.$http.$post(this.instance().resourceUrl() + '/payment-methods', {
      token
    })
  }

  static createCronofyToken (code, redirect) {
    return this.$http.$post(this.instance().resourceUrl() + '/cronofy/token', {
      code,
      redirectUri: redirect
    })
  }

  static createCronofyElementToken () {
    return this.$http.$post(this.instance().resourceUrl() + '/cronofy/element-token')
  }

  static createSetupIntent () {
    return this.$http.$post(this.instance().resourceUrl() + '/setup-intent')
  }

  static createPayoutMethod (token) {
    return this.$http.$post(this.instance().resourceUrl() + '/payout-methods', {
      token
    })
  }

  static disconnectProfile () {
    return this.$http.$post(this.instance().resourceUrl() + '/cronofy/profile-disconnected')
  }

  /**
   * @param code
   * @returns KintellCredit
   */
  static async redeemVoucher (code) {
    return new KintellCredit((await this.$http.$post(this.instance().resourceUrl() + '/redeem-voucher', {
      code
    })).data)
  }

  static createStripeAccountLink (type) {
    return this.$http.$post(this.instance().resourceUrl() + '/stripe-link', {
      type
    })
  }

  static async createOrUpdatePayoutAccount (data) {
    return (await this.$http.$post(this.instance().resourceUrl() + '/payout-account', data)).data
  }

  static async fetchPayoutAccount () {
    return (await this.$http.$get(this.instance().resourceUrl() + '/payout-account')).data
  }

  static removePaymentMethod (id) {
    return this.$http.$delete(this.instance().resourceUrl() + '/payment-methods/' + id)
  }

  /**
   * Verify a code is correct. Will return the updated user if it is succesfull
   * @param code
   * @returns {User}
   */
  static async phoneVerifyCode (code) {
    const userData = await this.instance().action('phone-verify-code', { code })
    const user = new User(userData)
    this.$store.commit('auth/SET_USER', userData)
    return user
  }

  static async emailVerifyCode (code) {
    const userData = await this.instance().action('email-verify-code', { code })
    const user = new User(userData)
    this.$store.commit('auth/SET_USER', userData)
    return user
  }

  resource () {
    return 'me'
  }
}
