export default {
  width: 60,
  height: 61,
  stroke: true,
  svg: `
    <g fill="none">
      <path d="M24.8108 35.2524C21.9681 32.4105 19.4983 29.2187 17.4608 25.7538L23.0769 21.2611C23.735 20.7341 24.2001 20.0041 24.3995 19.1849C24.599 18.3657 24.5216 17.5036 24.1794 16.733L19.062 5.20533C18.6951 4.3783 18.0462 3.70853 17.2313 3.31548C16.4164 2.92243 15.4883 2.83167 14.6126 3.05939L4.86863 5.58727C3.95926 5.82485 3.16864 6.38779 2.6466 7.16939C2.12457 7.95098 1.9074 8.89693 2.03626 9.82795C3.7465 22.0062 9.37496 33.2966 18.0708 41.9924C26.7666 50.6882 38.057 56.3166 50.2352 58.0269C51.166 58.1554 52.1116 57.9381 52.893 57.4161C53.6743 56.8941 54.237 56.1036 54.4746 55.1945L57.0024 45.4492C57.2297 44.5737 57.1389 43.646 56.7462 42.8312C56.3534 42.0164 55.6843 41.3674 54.8578 40.9998L43.3314 35.8811C42.5613 35.5383 41.6994 35.4601 40.8802 35.6589C40.061 35.8576 39.3307 36.322 38.8033 36.9796L34.3106 42.5958C32.6648 41.6305 31.0791 40.5663 29.562 39.4091" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </g>

`
}
