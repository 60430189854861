var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"the-navigation-drawer"},[_c('k-icon-hamburger',{model:{value:(_vm.showDrawer),callback:function ($$v) {_vm.showDrawer=$$v},expression:"showDrawer"}}),_vm._v(" "),_c('v-navigation-drawer',{staticClass:"brand-dark-blue-70",class:{
      'auth': _vm.userIsAuthenticated,
    },style:(_vm.styles),attrs:{"fixed":"","right":"","hide-overlay":""},model:{value:(_vm.showDrawer),callback:function ($$v) {_vm.showDrawer=$$v},expression:"showDrawer"}},[_c('k-card-text',{staticClass:"pa-6"},[(!_vm.userIsAuthenticated || _vm.authenticatedUser.isPlaceholder)?[(!_vm.onGroupKintellPage)?_c('k-button',{attrs:{"color":"brand-dark-aqua","block":"","to":"/password-free?step=signup"}},[_vm._v("\n          Start for free!\n        ")]):_vm._e(),_vm._v(" "),_c('k-button-outline',{staticClass:"mt-10 btn-login",attrs:{"color":"white","block":"","to":_vm.loginPage}},[_vm._v("\n          Log in\n        ")])]:_vm._e(),_vm._v(" "),(_vm.kintellGroupLinks.length > 0)?_c('k-layout-drawer-items',{attrs:{"title":"My Groups","links":_vm.kintellGroupLinks},on:{"close":_vm.toggleClose},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"group-link"},[_c('div',[_c('k-icon',{staticClass:"small",attrs:{"color":"brand-light-aqua","size":"15"}},[_vm._v("\n              groupAdmin\n            ")])],1),_vm._v(" "),_c('span',{staticClass:"ml-5"},[_vm._v("\n            "+_vm._s(item.name)+"\n          ")])])]}}],null,false,1122892519)}):_vm._e(),_vm._v(" "),(_vm.consoleLinks.length > 0)?_c('k-layout-drawer-items',{attrs:{"title":"Admin Dashboard","links":_vm.consoleLinks,"exact-link-active":false},on:{"close":_vm.toggleClose},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))]),_vm._v(" "),(_vm.activeConsoleTab && _vm.activeConsoleTab.name === item.name && _vm.activeConsoleTab.sub)?_c('div',{staticClass:"mb-3"},_vm._l((_vm.activeConsoleTab.sub),function(sub){return _c('nuxt-link',{key:sub.name,staticClass:"d-flex align-center unstyled--link pl-4 mt-3",attrs:{"to":sub.link}},[_c('span',{staticClass:"the-navigation-drawer__sub-link",class:[
                _vm.$route.path === sub.link ? 'brand-dark-aqua--text' : 'brand-grey-40--text'
              ]},[_vm._v("\n              "+_vm._s(sub.name)+"\n            ")])])}),1):_vm._e()]}}],null,false,22505684)}):_vm._e(),_vm._v(" "),(_vm.userIsAuthenticated && !_vm.authenticatedUser.isPlaceholder)?_c('k-layout-drawer-items',{attrs:{"title":"My Dashboard","links":_vm.myDashboardLinks},on:{"close":_vm.toggleClose},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var item = ref.item;
return [_c('span',[_c('v-badge',{staticClass:"v-badge--dense",attrs:{"offset-y":"12","offset-x":"-6","color":"#4EAFB6","value":!!item.alerts && (!item.sub || (item.sub && _vm.activeDashboardTab.name !== item.name))},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_vm._v("\n              "+_vm._s(item.alerts)+"\n            ")]},proxy:true}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(item.name))])])],1),_vm._v(" "),(_vm.activeDashboardTab && _vm.activeDashboardTab.name === item.name && _vm.activeDashboardTab.sub)?_c('div',{staticClass:"mb-3"},_vm._l((_vm.activeDashboardTab.sub),function(sub){return _c('nuxt-link',{key:sub.name,staticClass:"d-flex align-center unstyled--link pl-4 mt-3",attrs:{"to":sub.link}},[_c('span',[_c('v-badge',{staticClass:"v-badge--dense",attrs:{"offset-y":"15","offset-x":"-6","color":"#4EAFB6","value":!!sub.alerts},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_vm._v("\n                  "+_vm._s(sub.alerts)+"\n                ")]},proxy:true}],null,true)},[_vm._v(" "),_c('span',{staticClass:"the-navigation-drawer__sub-link",class:[
                    _vm.$route.path === sub.link ? 'brand-dark-aqua--text' : 'brand-grey-40--text'
                  ]},[_vm._v("\n                  "+_vm._s(sub.name)+"\n                ")])])],1)])}),1):_vm._e()]}}],null,false,3006670617)}):_vm._e(),_vm._v(" "),_c('k-layout-drawer-items',{attrs:{"title":"discover","links":_vm.discoverLinks},on:{"close":_vm.toggleClose}}),_vm._v(" "),_c('k-layout-drawer-items',{attrs:{"title":"get in touch","links":[{ i18n: 'getInTouch', link: '/get-in-touch' }]},on:{"close":_vm.toggleClose}}),_vm._v(" "),(_vm.userIsAuthenticated && !_vm.authenticatedUser.isPlaceholder)?_c('k-button-outline',{staticClass:"mt-10 my-2",attrs:{"color":"white","block":"","to":"/sign-out"}},[_vm._v("\n        "+_vm._s(_vm.$t('app.pages.signOut.title'))+"\n      ")]):_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }