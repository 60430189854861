import difference from './difference'
import optional from './optional'
import minutesOrHours from './minutes-or-hours'
import daysOrWeeks from './days-or-weeks'
import { transformAvailabilityToAPIFormat, transformAvailabilityToCalendarFormat } from './available-times'
import { canPerform, Permissions, hasGroupRole, GroupRolesCollections, getGroupRole } from './group-permissions'
import { truncateString } from './string-helpers'
import { authenticateAsPlaceholder } from './auth'
import { getBestItemBounds } from './item-bounds'

export {
  difference,
  optional,
  minutesOrHours,
  daysOrWeeks,
  transformAvailabilityToAPIFormat,
  transformAvailabilityToCalendarFormat,
  canPerform,
  Permissions,
  hasGroupRole,
  getGroupRole,
  GroupRolesCollections,
  truncateString,
  authenticateAsPlaceholder,
  getBestItemBounds
}

export default {
  difference,
  optional,
  minutesOrHours,
  daysOrWeeks,
  transformAvailabilityToAPIFormat,
  transformAvailabilityToCalendarFormat,
  canPerform,
  Permissions,
  hasGroupRole,
  getGroupRole,
  GroupRolesCollections,
  truncateString,
  authenticateAsPlaceholder,
  getBestItemBounds,
}
