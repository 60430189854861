//
//
//
//
//
//
//
//
//
//
//

import { BindsAttrs } from '@/mixins'
export default {
  mixins: [ BindsAttrs ],
  props: {
    primaryLabel: {
      type: String,
      required: true
    },
    secondaryLabel: {
      type: String,
      default: ''
    }
  }
}
