import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { faSortUp } from '@fortawesome/free-solid-svg-icons/faSortUp'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp'
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars'
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy'
import { faCode } from '@fortawesome/free-solid-svg-icons/faCode'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown'
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink'
import { faGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe'
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons/faMinusCircle'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle'
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers'
import { faShareAlt } from '@fortawesome/free-solid-svg-icons/faShareAlt'
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash'
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons/faCloudUploadAlt'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons/faPaperPlane'
import { faPaperclip } from '@fortawesome/free-solid-svg-icons/faPaperclip'
import { faPause } from '@fortawesome/free-solid-svg-icons/faPause'
import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay'
import { faVideoSlash } from '@fortawesome/free-solid-svg-icons/faVideoSlash'

import { faCheckCircle } from '@fortawesome/free-regular-svg-icons/faCheckCircle'
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons/faCheckSquare'
import { faSquare } from '@fortawesome/free-regular-svg-icons/faSquare'
import { faMinusSquare } from '@fortawesome/free-regular-svg-icons/faMinusSquare'
import { faCircle } from '@fortawesome/free-regular-svg-icons/faCircle'
import { faDotCircle } from '@fortawesome/free-regular-svg-icons/faDotCircle'
import { faEdit } from '@fortawesome/free-regular-svg-icons/faEdit'
import { faEye } from '@fortawesome/free-regular-svg-icons/faEye'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons/faEnvelope'
import { faEyeSlash } from '@fortawesome/free-regular-svg-icons/faEyeSlash'

import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook'
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle'
import { faMicrosoft } from '@fortawesome/free-brands-svg-icons/faMicrosoft'
import { faResearchgate } from '@fortawesome/free-brands-svg-icons/faResearchgate'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin'
import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub'
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube'
import { faMediumM } from '@fortawesome/free-brands-svg-icons/faMediumM'
import { faCcVisa } from '@fortawesome/free-brands-svg-icons/faCcVisa'
import { faCcMastercard } from '@fortawesome/free-brands-svg-icons/faCcMastercard'

Vue.component('FontAwesomeIcon', FontAwesomeIcon) // Register component globally
library.add(
  // regular
  faCheckCircle,
  faCheckSquare,
  faSquare,
  faMinusSquare,
  faCircle,
  faDotCircle,
  faCircle,
  faEdit,
  faEye,
  faEnvelope,
  faEyeSlash,
  // solid
  faPause,
  faPlay,
  faVideoSlash,
  faCheck,
  faTimesCircle,
  faTimes,
  faInfoCircle,
  faExclamationTriangle,
  faChevronLeft,
  faChevronRight,
  faSortUp,
  faChevronUp,
  faBars,
  faCopy,
  faCode,
  faChevronDown,
  faLink,
  faGlobe,
  faMinusCircle,
  faPlusCircle,
  faUsers,
  faShareAlt,
  faTrash,
  faCloudUploadAlt,
  faEllipsisV,
  faPaperPlane,
  faPaperclip,
  // brand
  faFacebook,
  faGoogle,
  faMicrosoft,
  faResearchgate,
  faLinkedin,
  faGithub,
  faTwitter,
  faInstagram,
  faYoutube,
  faMediumM,
  faCcVisa,
  faCcMastercard,
)
