//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseLayout from '@/layouts/base'
import UsesFeatureFlags from '@/mixins/auth/UsesFeatureFlags'
import { UsesAuthenticatedUser, UsesDashboardLinks } from '@/mixins'
import { find, findIndex, endsWith } from 'lodash-es'
import { sync } from 'vuex-pathify'

export default {
  components: { BaseLayout },

  mixins: [
    UsesAuthenticatedUser,
    UsesDashboardLinks,
    UsesFeatureFlags
  ],

  data () {
    return {
      activeSub: '',
    }
  },

  head () {
    const routePath = this.$route.path
    // Exit out if it's not a my kintell page
    if (!routePath.startsWith('/my-kintell')) {
      return {}
    }
    const pages = this.$t('app.pages')
    const pageKey = Object.keys(pages)
      .find(page => pages[page]?.url === routePath)
    const metaTitle = 'The Swiss Army knife of video knowledge sharing'
    const title = pages[pageKey]?.title ? pages[pageKey].title : metaTitle

    const description = 'Whether you want to quickly start a video chat without time limits, or need somewhere to take paid video consultation bookings, Kintell has you covered.'
    return {
      title,
      meta: [
        { hid: 'og:title', property: 'og:title', content: metaTitle },
        { hid: 'description', name: 'description', content: description },
        { hid: 'og:description', property: 'og:description', content: description },
        { name: 'robots', content: 'noindex' }
      ]
    }
  },

  computed: {
    ...sync({
      activeDashboardTabIndex: 'myKintell/activeDashboardTabIndex',
      activeConsoleTabIndex: 'myKintell/activeConsoleTabIndex',
    }),
    // Hacky way to hide the sub tabs when on a booking page
    hideSub () {
      const _path = this.$route.path

      if (_path.includes('/bookings/initiate')) {
        return false
      }

      if (this.$route.name === 'my-kintell-videos-id') {
        return true
      }
      const regex = /\/my-kintell\/bookings\/[^-]+(-[^-]+)+$/g // regex matches url for booking page
      return _path.match(regex) || this.$route.name === 'advisors-advisor-kintellCard-edit'
    },
    activeDashboardTab () {
      if (this.activeDashboardTabIndex === -1) {
        return []
      }
      return this.dashboardLinks[this.activeDashboardTabIndex] || { sub: [] }
    },

    activeConsoleTab () {
      if (this.activeConsoleTabIndex === -1) {
        return []
      }
      return this.consoleLinks[this.activeConsoleTabIndex] || { sub: [] }
    },

    activeConsoleSubTab () {
      return find(this.activeConsoleTab.sub, sub => endsWith(this.$route.path, sub.link)) || {}
    },

    activeDashboardSubTab () {
      return find(this.activeDashboardTab.sub, sub => endsWith(this.$route.path, sub.link)) || {}
    },

    horizontalMobileLinks () {
      return this.consoleLinks[0] ? [ this.consoleLinks[0] ].concat(this.dashboardLinks) : this.dashboardLinks
    }
  },

  watch: {
    $route: {
      handler () {
        this.updateActiveTab()
        this.maybeNavigateToFirstSub()
      },
      immediate: true
    }
  },

  mounted () {
    document.fonts && document.fonts.ready.then(() => this.$nextTick()).then(() => {
      this.$refs.tabs?.$refs.vTabs?.callSlider()
    })
  },

  methods: {
    maybeNavigateToFirstSub () {
      if (this.activeDashboardTab.noTabs && this.activeDashboardTab.link === this.$route.path) {
        this.activeDashboardTab.sub[0] && this.$router.push(this.activeDashboardTab.sub[0].link)
      }
    },
    updateActiveTab () {
      const routeName = this.$route.name
      const path = this.$route.path
      // if it changes to a dashboard page
      if (path.includes('my-kintell')) {
        this.activeDashboardTabIndex = findIndex(this.dashboardLinks, e => path.includes(e.link))
        this.activeConsoleTabIndex = findIndex(this.consoleLinks, e => path.includes(e.link))

        // update subtitle
        if (this.activeDashboardTab?.sub) {
          this.activeSub = this.activeDashboardSubTab.link
        }
      } else if (routeName === 'advisors-advisor-kintellCard-edit') {
        this.activeDashboardTabIndex = findIndex(this.dashboardLinks, e => e.link.includes('/my-kintell/kintell-cards'))
      } else {
        this.activeDashboardTabIndex = 0
      }
    },
  },
}
