export default {
  computed: {
    emailStatusDetails () {
      const iconMapping = {
        Opened: {
          icon: 'emailOpen',
          color: 'brand-dark-aqua--text font-weight-bold'
        },
        Clicked: {
          icon: 'emailClick',
          color: 'brand-pink--text font-weight-bold'
        },
        Unsubscribed: {
          icon: 'emailUnsubscribe',
          color: 'brand-grey--text'
        },
        Bounced: {
          icon: 'emailBounce',
          color: 'brand-grey--text'
        }
      }
      return iconMapping[this.email.recipientFirstEmailStatus] || ''
    },
  }
}
