export default {
  width: 29,
  height: 28,
  stroke: true,
  svg: `
  <g fill="none">
    <path d="M24.1661 20.0344C26.0064 18.0859 27.0937 15.5938 27.0937 12.875C27.0937 6.7125 21.4895 2 14.5469 2C7.60427 2 2 6.7125 2 12.875C2 19.0375 7.60427 23.9766 14.5469 23.9766C15.7597 23.9766 16.9308 23.7953 18.06 23.5234L24.5844 26.4688L24.1661 20.0344Z" stroke-width="2.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.69189 10.1562H20.4023" stroke-width="2.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.69189 15.5938H15.3836" stroke-width="2.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </g>

`
}
