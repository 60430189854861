export default {
  width: 567,
  height: 629,
  stroke: true,
  svg: `
    <circle cx="264.848" cy="293.383" r="244.329" fill="transparent" stroke-width="15.4778"/>
    <path d="M260.844 240.606C268.901 240.606 275.493 247.198 275.493 255.254V262.579C275.493 270.635 268.901 277.227 260.844 277.227C252.787 277.227 246.196 270.635 246.196 262.579V255.254C246.196 247.198 252.787 240.606 260.844 240.606ZM260.844 225.957C244.731 225.957 231.547 239.141 231.547 255.254V262.579C231.547 278.692 244.731 291.876 260.844 291.876C276.958 291.876 290.142 278.692 290.142 262.579V255.254C290.142 239.141 276.958 225.957 260.844 225.957Z"/>
    <path d="M320.594 299.33L326.262 304.998C327.557 306.293 327.557 308.393 326.262 309.689L297.728 338.222C296.433 339.518 294.333 339.518 293.037 338.222L277.012 322.197C275.716 320.901 275.716 318.801 277.012 317.506L282.679 311.838C283.974 310.543 286.074 310.543 287.37 311.838L293.037 317.506C294.333 318.801 296.433 318.801 297.728 317.506L315.904 299.33C317.199 298.035 319.299 298.035 320.594 299.33Z"/>
    <path d="M268.169 339.827C268.169 341.658 266.684 343.143 264.852 343.143H212.891C211.059 343.143 209.574 341.658 209.574 339.827V335.819C209.574 315.311 225.688 299.197 246.196 299.197H264.852C266.684 299.197 268.169 300.682 268.169 302.514V310.529C268.169 312.361 266.684 313.846 264.852 313.846H246.196C238.28 313.846 231.376 317.896 227.589 324.313C226.439 326.262 228.026 328.495 230.289 328.495H264.852C266.684 328.495 268.169 329.98 268.169 331.811V339.827Z"/>
    <path d="M95.3658 252.608C98.2797 252.305 100.099 252.212 100.865 252.399L100.92 252.174C100.209 252 98.6371 251.079 96.1365 249.453L75.544 235.767L77.7871 226.585L112.48 250.64L110.374 259.259L68.4844 264.666L70.7825 255.258L95.3658 252.608Z"/>
    <path d="M106.566 188.387L97.117 205.758L103.843 209.417L112.101 194.237L118.728 197.842L110.47 213.022L118.086 217.165L127.536 199.795L134.361 203.507L120.561 228.876L85.9414 210.043L99.7415 184.675L106.566 188.387Z"/>
    <path d="M155.985 178.589L138.701 176.419L135.76 179.544L146.011 189.19L139.812 195.778L111.109 168.771L122.435 156.735C125.654 153.314 129.017 151.383 132.566 150.98C136.115 150.577 139.325 151.766 142.277 154.543C144.286 156.434 145.452 158.646 145.855 161.175C146.258 163.704 145.813 166.231 144.562 168.876L163.416 170.692L155.985 178.589ZM122.967 167.507L130.471 174.568L135.279 169.457C136.63 168.021 137.335 166.615 137.435 165.276C137.534 163.856 136.99 162.627 135.76 161.47C134.489 160.274 133.188 159.766 131.818 159.99C130.488 160.172 129.126 160.961 127.775 162.397L122.967 167.507Z"/>
    <path d="M155.411 126.694L147.965 131.934L170.645 164.164L178.091 158.925L155.411 126.694Z"/>
    <path d="M204.069 112.051L186.125 119.938L189.704 128.081L205.047 121.338L208.173 128.451L192.831 135.194L198.857 148.904L190.522 152.567L174.664 116.488L200.943 104.938L204.069 112.051Z"/>
    <path d="M227.485 97.9948L218.609 100.021L227.383 138.443L236.258 136.416L227.485 97.9948Z"/>
    <path d="M279.077 100.086L259.308 100.583L259.501 108.238L276.776 107.803L276.966 115.345L259.69 115.779L259.908 124.447L279.677 123.95L279.872 131.717L251.002 132.443L250.012 93.0449L278.881 92.3192L279.077 100.086Z"/>
    <path d="M315.424 97.4787C319.198 98.4008 322.335 99.9784 324.933 102.294C327.475 104.595 329.237 107.402 330.219 110.714C331.187 114.08 331.217 117.622 330.295 121.396C329.373 125.17 327.713 128.3 325.315 130.786C322.904 133.326 320.046 135.004 316.729 135.875C313.356 136.732 309.846 136.685 306.071 135.763L289.96 131.827L299.312 93.543L315.424 97.4787ZM307.352 128.078C310.957 128.959 313.915 128.58 316.212 126.997C318.579 125.373 320.175 122.749 321.056 119.139C321.952 115.475 321.732 112.465 320.38 109.933C319.072 107.469 316.622 105.769 313.017 104.889L306.313 103.251L300.648 126.441L307.352 128.078Z"/>
    <path d="M208.964 466.513C210.829 468.114 212.008 469.94 212.574 471.956C213.068 474.006 212.976 476.015 212.224 478.018C210.958 481.392 208.83 483.54 205.838 484.462C202.899 485.404 199.136 485.014 194.708 483.353L178.578 477.302L192.422 440.402L207.392 446.019C211.504 447.562 214.35 449.592 215.859 452.142C217.368 454.692 217.545 457.585 216.319 460.853C215.706 462.487 214.75 463.753 213.485 464.721C212.167 465.67 210.664 466.309 208.964 466.513ZM203.971 452.733L198.068 450.518L195.18 458.214L201.084 460.429C202.665 461.023 204.029 461.234 205.09 460.97C206.151 460.707 206.929 459.916 207.463 458.493C207.997 457.07 207.951 455.91 207.325 455.014C206.647 454.098 205.553 453.326 203.971 452.733ZM196.97 476.204C200.607 477.569 202.933 476.818 204.021 473.918C205.109 471.019 203.87 468.871 200.233 467.506L192.748 464.698L189.484 473.396L196.97 476.204Z"/>
    <path d="M261.123 459.285L243.509 478.541L241.093 496.338L232.334 495.148L234.75 477.352L222.629 454.059L232.783 455.437L240.264 469.805L251.304 457.952L261.123 459.285Z"/>
    <path d="M330.156 487.213L318.935 489.914L300.349 475.972L304.539 493.379L295.945 495.447L286.723 457.131L295.316 455.062L299.506 472.469L308.837 451.808L319.729 449.187L308.948 471.76L330.156 487.213Z"/>
    <path d="M333.048 442.667L324.949 446.209L340.744 482.316L348.842 478.774L333.048 442.667Z"/>
    <path d="M363.959 423.325L371.159 418.295L393.73 450.602L386.992 455.31L362.923 446.714C359.996 445.669 358.184 444.943 357.501 444.458L357.395 444.601C358.031 445.118 359.252 446.669 361.026 449.207L372.408 465.499L365.208 470.529L342.637 438.223L349.375 433.515L373.444 442.111C376.372 443.156 378.184 443.882 378.866 444.366L378.972 444.224C378.336 443.706 377.115 442.156 375.342 439.617L363.959 423.325Z"/>
    <path d="M398.593 389.092L404.48 394.162L396.764 403.121L420.739 423.769L415.007 430.424L391.032 409.776L383.317 418.734L377.43 413.664L398.593 389.092Z"/>
    <path d="M422.984 359.769L414.155 376.817L420.955 380.338L428.67 365.44L435.369 368.909L427.654 383.807L435.353 387.794L444.182 370.746L451.081 374.319L438.188 399.216L403.191 381.094L416.084 356.196L422.984 359.769Z"/>
    <path d="M452.152 346.714L456.624 329.552L464.143 331.511L457.442 357.227L419.305 347.289L421.534 338.735L452.152 346.714Z"/>
    <path d="M458.806 306.587L459.6 288.87L467.362 289.218L466.172 315.765L426.801 314L427.197 305.17L458.806 306.587Z"/>
  `
}
