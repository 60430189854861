import pluralize from 'pluralize'

export default (minutes, settings = {}) => {
  settings = {
    small: false,
    // how many minutes it needs to be before switching to hours
    minutesToHoursThreshold: 60,
    ...settings
  }
  if (minutes / settings.minutesToHoursThreshold < 1) {
    return pluralize(settings.small ? 'min' : 'minute', minutes, true)
  }
  // Note the plus sign that drops any "extra" zeroes at the end.
  const hours = +(minutes / 60).toFixed(1)
  return pluralize(settings.small ? 'hr' : 'hour', hours, true)
}
