/** Our custom icons */

import Vue from 'vue'
import Vuetify from 'vuetify/lib'

// not sure why but the scroll directive wasn't working without this
import { Scroll } from 'vuetify/lib/directives'
import * as kintellIcons from '../lib/icons'

/**
 * SVG icons we're using from Fontawesome
 */
const ICONS_FONTAWESOME = {
  complete: 'fas fa-check',
  check: 'fas fa-check',
  cancel: 'fas fa-times-circle',
  close: 'fas fa-times',
  delete: 'fas fa-times', // delete (e.g. v-chip close)
  clear: 'fas fa-times', // delete (e.g. v-chip close)
  success: 'far fa-check-circle',
  error: 'fas fa-exclamation-triangle',
  prev: 'fas fa-chevron-left',
  next: 'fas fa-chevron-right',
  checkboxOn: 'far fa-check-square',
  checkboxOff: 'far fa-square', // note 'far'
  checkboxIndeterminate: 'far fa-minus-square',
  delimiter: 'far fa-circle', // for carousel
  sort: 'fas fa-sort-up',
  expand: 'fas fa-chevron-up',
  menu: 'fas fa-bars',
  copy: 'fas fa-copy',
  code: 'fas fa-code',
  subgroup: 'fas fa-chevron-down',
  dropdown: 'fas fa-chevron-down',
  radioOn: 'far fa-dot-circle',
  radioOff: 'far fa-circle',
  edit: 'far fa-edit',
  eye: 'far fa-eye',
  mail: 'far fa-envelope',
  'eye-slash': 'far fa-eye-slash',
  link: 'fas fa-link',
  // brands
  youtube: 'fab fa-youtube',
  twitter: 'fab fa-twitter',
  instagram: 'fab fa-instagram',
  facebook: 'fab fa-facebook',
  microsoft: 'fab fa-microsoft',
  google: 'fab fa-google',
  github: 'fab fa-github',
  researchgate: 'fab fa-researchgate',
  linkedin: 'fab fa-linkedin',
  medium: 'fab fa-medium-m',
  // extra
  globe: 'fas fa-globe',
  minus: 'fas fa-minus-circle',
  add: 'fas fa-plus-circle',
  plus: 'fas fa-plus-circle',
  users: 'fas fa-users',
  share: 'fas fa-share-alt',
  trash: 'fas fa-trash',
  cloud_upload: 'fas fa-cloud-upload-alt',
  more_vert: 'fas fa-ellipsis-v',
  send: 'fas fa-paper-plane',
  visa: 'fab fa-cc-visa',
  mastercard: 'fab fa-cc-mastercard',
  paperclip: 'fas fa-paperclip',
  pause: 'fas fa-pause',
  videoSlash: 'fas fa-video-slash',
}

const convertToComponentDeclarations = (component, iconSet) => {
  const result = {}

  for (const key in iconSet) {
    result[key] = {
      component,
      props: {
        icon: (iconSet[key]).split(' fa-')
      }
    }
  }

  return result
}

Vue.use(Vuetify)

// disabling warning for the svg native
Vue.config.warnHandler = (message, vm, trace) => {
  if (message === 'The .native modifier for v-on is only valid on components but it was used on <svg>.') {
    message = null
    vm = null
    trace = null
  }
}

export default ({ app }) => {
  app.vuetify = new Vuetify({
    directives: {
      Scroll
    },
    theme: {
      dark: false,
      options: {
        customProperties: true,
        cspNonce: 'dQw4w9WgXcQ',
        variations: false
      },
      themes: {
        light: {
          // vuetify pallet - Avoid using
          primary: '#EF5185',
          secondary: '#30324B',
          accent: '#4E888C',
          alert: '#DA1414',
          lightGrey: '#F7F9F9',
          grey: '#cccccc',
          white: '#ffffff',
          error: '#DA1414',
          success: '#4e8c67',

          // brand palette
          'brand-pink': '#EF5185',
          'brand-grey': '#ABB5B5',
          'brand-dark-blue': '#30324B',
          'brand-dark-aqua': '#4E888C',
          'brand-light-aqua': '#B7E5E8',
          'brand-warm-grey': '#9F9FB3',

          // brand variations
          'brand-dark-blue-50': '#585A6F',
          'brand-dark-blue-60': '#4d4e67',
          'brand-dark-blue-70': '#43455F',
          'brand-dark-blue-90': '#44465c',
          'brand-light-pink': '#FF5F93',
          'brand-light-aqua-50': '#E7F6F7',
          'brand-light-aqua-30': '#e6f6f7',
          'brand-light-aqua-20': '#EAF5F5',
          'brand-grey-40': '#DCDCE3',
          'brand-grey-20': '#EDF0F0',
          'brand-grey-10': '#F1F1F5',

          // v2 colours
          'brand-yellow': '#FEF8E9',
          'yellow-2': '#FDF0D1',
          'brand-yellow-border': '#FDEBC2',
          'grey-0': '#ABB5B5',
          'grey-1': '#585A6E',
          'grey-2': '#55596D',
          'grey-3': '#f7f9f9',

          // v2.1 colours

          // greys
          'grey-100': '#F7F9F9',
          'grey-200': '#F1F5F5',
          'grey-300': '#EDF0F0',
          'grey-400': '#E0E3E3',
          'grey-500': '#ABB5B5',
          'grey-600': '#8795A1',
          'grey-700': '#606F7B',
          'grey-800': '#3D4852',
          'grey-900': '#22292F',
        }
      }
    },
    icons: {
      iconfont: 'faSvg',
      values: {
        // font awesome icons
        ...convertToComponentDeclarations('font-awesome-icon', ICONS_FONTAWESOME),
        // our icons
        ...kintellIcons,
      }
    }
  })
}
