
export default {
  props: {
    page: {
      type: String,
      required: true,
    }
  },
  render (createElement) {
    return createElement(
      'nuxt-link', {
        props: {
          to: this.$t('app.pages.' + this.page + '.url')
        }
      }, [
        this.$t('app.pages.' + this.page + '.title')
      ]
    )
  },
}
