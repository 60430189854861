import { Context } from '@nuxt/types'
import { BookingMessage } from '@/lib/broadcasting/messages'

// '.App\\Events\\Booking\\Status\\ProvisionalRequestExpired'
export default (ctx: Context) => {
  return (e: BookingMessage) => {
    ctx.$toast.success('Your provisional booking has expired.', {
      duration: 10000
    })

    // if the user is on the booking page, redirect them to bookings dashboard
    if (ctx.store.getters['myKintell/activeBooking'] && e.booking.id === ctx.store.getters['myKintell/activeBooking'].id) {
      ctx.redirect('/my-kintell/bookings')
    }
    ctx.store.dispatch('myKintell/reloadBookings')
    ctx.store.dispatch('auth/refresh')
  }
}
