import { computed } from 'vue'

export const useBytes = (bytes: number) => {
  return computed(() => {
    if (bytes === 0) { return '0 Bytes' }
    const k = 1024
    const dm = 2
    const sizes = [ 'Bytes', 'KB', 'MB', 'GB', 'TB' ]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  })
}
