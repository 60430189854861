export default {
  async mounted () {
    await this.showBadge()
  },
  beforeDestroy () {
    const recaptcha = this.$recaptchaInstance
    recaptcha.hideBadge()
  },
  watch: {
    '$recaptchaState.badgeHidden': {
      async handler (value) {
        if (!value) {
          return
        }
        await this.showBadge()
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async showBadge () {
      await this.$recaptchaLoaded()
      const recaptcha = this.$recaptchaInstance
      recaptcha.showBadge()
      this.$recaptchaState.badgeHidden = false
    },
    async getCaptchaToken (action = 'login') {
      try {
        return await this.$recaptcha(action)
      } catch (error) {
        return false
      }
    }
  }
}
