import { startsWith } from 'lodash-es'

export default ({ app, store }) => {
  app.router.afterEach((to, from) => {
    // We need to remove the overflow hidden when the drawer is closed
    store.commit('layout/SET_SHOW_DRAWER', false)
    document.documentElement.classList.remove('overflow-hidden')

    store.dispatch('guest/loadIpinfo', app)
    // reset api errors for each page
    store.dispatch('form/setApiErrors', {})
    // only if we're navigation to and from a page
    if (!from.matched[0] || !to.matched[0]) {
      return
    }
    // routes
    const fromName = from.matched[0].name
    const toName = to.matched[0].name
    // if we're moving from a kintell card page to a non-kintell card page we need to reset the active kintell card and user
    if (startsWith(fromName, 'advisors-advisor-kintellCard') &&
    // if the page is not the advisors kintell card page or its not the advisors
      (!startsWith(toName, 'advisors-advisor-kintellCard') && !startsWith(toName, 'advisors-advisor'))
    ) {
      store.dispatch('kintellCards/reset')
      store.dispatch('guest/reset')
    }
  })
}
