import { KintellCard, User, Booking } from '@/models'
import KintellCardSpec from '@/lib/models/KintellCardSpec'
import BookingSpec from '@/lib/models/BookingSpec'
import UserSpec from '@/lib/models/UserSpec'
import { Context } from '@nuxt/types'

export interface ModelResolver {
  resolve(): void,
}

export abstract class Message {
  public constructor (public data: Record<string, any>) {
    Object.assign(this, { ...data })
    return this
  }
}

export abstract class ModelMessage extends Message {
  abstract resolve(): Promise<void>
}

export class UserMessage extends ModelMessage {
  user: UserSpec

  async resolve () {
    this.user = await (new User().find(this.user.id))
  }
}

export interface ModelMessageConstructable {
  new(data: any): Message | ModelMessage,
}

export class KintellCardMessage extends ModelMessage {
  kintellCard: KintellCardSpec

  async resolve () {
    this.kintellCard = await (new KintellCard().find(this.kintellCard.id))
  }
}

export class BookingMessage extends ModelMessage {
  booking: BookingSpec

  async resolve () {
    this.booking = await (new Booking().find(this.booking.id))
  }
}

export class MuteMessage extends BookingMessage {
  audio: boolean
  video: boolean

  static handle (ctx: Context) {
    return (message: MuteMessage) => {
      const { activeBooking } = ctx.store.state.myKintell
      if (message.booking.id === activeBooking.id) {
        if (message.audio) {
          ctx.store.commit('meeting/SET_IS_MICROPHONE_ENABLED', false)
        }
        if (message.video) {
          ctx.store.commit('meeting/SET_IS_CAMERA_ENABLED', false)
        }
      }
    }
  }
}

export class UpdateMessage extends Message {
  version: string
}

