import Model from './Model'
import KintellCard from './KintellCard'

export default class RelevantQualification extends Model {
  resource () {
    return 'relevant-qualifications'
  }

  kintellCard () {
    return this.belongsTo(KintellCard)
  }
}
