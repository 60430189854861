import Kintell from '@/lib/kintell'

export default async (ctx, inject) => {
  // do not init the store for an iframe (embed kintell card)
  if (ctx.app.$inIframe) {
    return
  }

  // set the token from the query if it's set
  const query = ctx.route.query

  // There are times when we want to log a user out and another one in.  For example, when a user is verifying their account
  if (query.token && (!ctx.app.$auth.loggedIn || query.override) && !ctx.app.$auth.busy) {
    try {
      await ctx.app.$auth.setUserToken(query.token)
    } catch (e) {
      ctx.app.$logger.warn('Failed to set user from token')
    }
  }

  (new Kintell(ctx, inject)).boot()
}
