import { clone, upperFirst } from 'lodash-es'
import { make } from 'vuex-pathify'
import all from './all'
import { findMutationName } from './utility'

/**
 * Inherits docs from all.js.
 *
 * Difference with paginated.js is that it has support for pagination of a list.
 *
 * @param field
 * @param model
 * @param load
 */
export default (field, model, load, options = {}) => {
  options = {
    appendResults: true,
    pagination: {
      current_page: 1,
      // we always assume there is at least one additional age
      total_pages: 2,
      per_page: 12
    },
    modifyQueryParams: false,
    ...options
  }
  // fields
  const FIELD_LOADING = field + 'Loading'
  const FIELD_ERROR = field + 'Errors'
  const FIELD_LAST_REQUEST = field + 'LastRequest'
  const FIELD_SEED = field + 'Seed'
  const FIELD_PAGINATION = field + 'Pagination'
  const FIELD_FILTERS = field + 'SearchParams'

  // mutations
  const SET_FIELD_KEY = findMutationName(field)
  const SET_FIELD_LOADING_KEY = findMutationName(FIELD_LOADING)
  const SET_FIELD_ERROR_KEY = findMutationName(FIELD_ERROR)
  const SET_FIELD_LAST_REQUEST_KEY = findMutationName(FIELD_LAST_REQUEST)
  const SET_FIELD_SEED = findMutationName(FIELD_SEED)
  const SET_FIELD_PAGINATION_KEY = findMutationName(FIELD_PAGINATION)
  // actions
  const ACTION_GENERATE_SEED = 'generate' + upperFirst(clone(field)) + 'Seed'
  const ACTION_LOAD_FIELD = 'load' + upperFirst(clone(field))
  const ACTION_LOAD_FIELD_NEXT_PAGE = 'load' + upperFirst(clone(field)) + 'NextPage'
  const ACTION_LOAD_FIELD_PREVIOUS_PAGE = 'load' + upperFirst(clone(field)) + 'PreviousPage'
  const ACTION_LOAD_FIELD_PAGE_NUMBER = 'load' + upperFirst(clone(field)) + 'PageNumber'

  // getters
  const GET_HAS_MORE_PAGES = field + 'HasMorePages'

  // we can't cache paginated data without being clever which we haven't done yet
  const allData = all(field, model, load, { cacheMinutes: 0 })

  const state = () => {
    return {
      ...allData.state,
      [FIELD_PAGINATION]: options.pagination,
      // empty seed by default
      [FIELD_SEED]: ''
    }
  }

  const actions = () => {
    return {
      /**
       * Generate a seed. Used when we are requesting random data so that our pagination will still work as we expected
       */
      [ACTION_GENERATE_SEED] ({ commit }) {
        const seed = Math.random().toString(10).substring(2, 8)
        this.$logger.trace('Generating ' + field + ' pagination seed.', seed)
        commit(SET_FIELD_SEED, seed)
      },
      [ACTION_LOAD_FIELD_NEXT_PAGE] ({ commit, dispatch, state, getters }) {
        const pagination = state[FIELD_PAGINATION]
        // make sure we're not at the last page
        if (!getters[GET_HAS_MORE_PAGES]) {
          this.$logger.trace('At ' + field + ' page limit, not loading more.', pagination)
          return
        }
        this.$logger.trace('Loading ' + field + ' next page.', pagination)
        // increment the page and commit it
        pagination.current_page++
        commit(SET_FIELD_PAGINATION_KEY, pagination)
        // load the next results
        dispatch(ACTION_LOAD_FIELD)
      },
      [ACTION_LOAD_FIELD_PREVIOUS_PAGE] ({ commit, dispatch, state, getters }) {
        const pagination = state[FIELD_PAGINATION]
        // make sure pagination is not the first page
        if (pagination.current_page === 1) {
          this.$logger.trace('Currently on the first page of ' + field + ', can\'t go back anymore.', pagination)
          return
        }
        this.$logger.trace('Loading ' + field + ' previous page.', pagination)
        // reduce the current page and commit it
        pagination.current_page--
        commit(SET_FIELD_PAGINATION_KEY, pagination)
        // load the next results
        dispatch(ACTION_LOAD_FIELD)
      },
      [ACTION_LOAD_FIELD_PAGE_NUMBER] ({ commit, dispatch, state, getters }, page) {
        const pagination = state[FIELD_PAGINATION]
        // make sure we're not at the last page
        this.$logger.trace('Loading ' + field + ' next page.', pagination)
        // increment the page and commit it
        pagination.current_page = page
        commit(SET_FIELD_PAGINATION_KEY, pagination)
        // load the next results
        dispatch(ACTION_LOAD_FIELD)
      },
      async [ACTION_LOAD_FIELD] ({ commit, state }) {
        // if we're already loading the data, or we've reached the final page we ignore the request
        // check we're not loading the resource already
        if (state[FIELD_LOADING]) {
          this.$logger.debug('Skipping action - Already loading', ACTION_LOAD_FIELD,)
          return
        }
        commit(SET_FIELD_LAST_REQUEST_KEY, Date.now())
        commit(SET_FIELD_LOADING_KEY, true)
        try {
          const response = await load(this)
          // Should data be appended or replaced
          if (options.appendResults) {
            // always append data
            commit(SET_FIELD_KEY, [ ...state[field], ...response.data ])
          } else {
            commit(SET_FIELD_KEY, response.data)
          }

          if (options.modifyQueryParams) {
            const usingFn = typeof options.modifyQueryParams === 'function'
            this.$router.push({
              query: {
                page: response.meta.pagination.current_page > 1 ? response.meta.pagination.current_page : undefined,
                seed: state[FIELD_SEED] ? state[FIELD_SEED] : undefined,
                // if we're using a function for the query params, use it
                ...(usingFn ? options.modifyQueryParams(state[FIELD_FILTERS]) : state[FIELD_FILTERS])
              }
            })
          }
          commit(SET_FIELD_PAGINATION_KEY, response.meta.pagination)
        } catch (e) {
          this.$logger.error(e)
          commit(SET_FIELD_ERROR_KEY, e.response)
        } finally {
          commit(SET_FIELD_LOADING_KEY, false)
        }
      },
    }
  }

  const getters = () => {
    return {
      ...allData.getters,
      [GET_HAS_MORE_PAGES]: state => state[FIELD_PAGINATION].current_page < state[FIELD_PAGINATION].total_pages
    }
  }

  const mutations = () => {
    return {
      ...make.mutations(state)
    }
  }


  return {
    getters: getters(),
    state: state(),
    actions: actions(),
    mutations: mutations()
  }
}
