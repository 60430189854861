//
//
//
//
//

import Colorable from 'vuetify/lib/mixins/colorable'
import { Sizeable } from '@/mixins'

export default {
  name: 'KCardText',
  mixins: [ Colorable, Sizeable ],
  props: {
    fields: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    classes () {
      return {
        ...this.sizeClasses,
        'k-card-text--fields': this.fields,
      }
    }
  }
}
