export default {
  width: 52,
  height: 54,
  svg: `
    <g fill-rule="nonzero">
      <path d="M21.0877 53.8125H10.6639C9.40849 53.8138 8.20097 53.3308 7.29286 52.464C6.38475 51.5971 5.84602 50.4134 5.78888 49.159L4.25157 15.2554C4.21284 14.4012 4.89494 13.6875 5.75003 13.6875H26C26.8552 13.6875 27.5373 14.4013 27.4985 15.2556L25.9578 49.1593C25.9007 50.4126 25.3627 51.5956 24.4555 52.4623C23.5485 53.3289 22.3422 53.8125 21.0877 53.8125ZM7.31959 16.6875L8.78579 49.0228C8.80776 49.5052 9.01496 49.9605 9.36424 50.2938C9.7135 50.6272 10.1779 50.813 10.6623 50.8125H21.0877C21.5703 50.8125 22.0342 50.6265 22.3831 50.2931C22.732 49.9598 22.9389 49.5048 22.9609 49.023L24.4303 16.6875H7.31959Z"/>
      <path d="M9.48833 3.1875L14.3866 42.3736C14.4893 43.1956 15.239 43.7787 16.0611 43.6759C16.8831 43.5732 17.4662 42.8235 17.3634 42.0014L12.3009 1.50145C12.2071 0.750805 11.569 0.1875 10.8125 0.1875H2.375C1.54657 0.1875 0.875 0.859073 0.875 1.6875C0.875 2.51593 1.54657 3.1875 2.375 3.1875H9.48833Z"/>
      <path d="M31.6243 27.6848C33.4457 26.9496 35.4197 26.6728 37.3733 26.8785C39.3266 27.0844 41.1997 27.7666 42.8278 28.8652C44.4561 29.9639 45.7896 31.4454 46.7116 33.1798C47.6336 34.9141 48.1158 36.8483 48.1158 38.8125C48.1158 40.7767 47.6336 42.7109 46.7116 44.4452C45.7897 46.1796 44.4561 47.6611 42.8278 48.7598C41.1997 49.8584 39.3266 50.5406 37.3732 50.7465C35.4197 50.9522 33.4457 50.6754 31.6243 49.9402C30.8561 49.6302 29.982 50.0015 29.6719 50.7698C29.3619 51.538 29.7332 52.4121 30.5014 52.7222C32.7782 53.6411 35.2457 53.9872 37.6875 53.7299C40.1294 53.4726 42.4707 52.6199 44.5058 51.2466C46.5411 49.8733 48.2081 48.0214 49.3606 45.8534C50.5131 43.6856 51.1158 41.2678 51.1158 38.8125C51.1158 36.3572 50.5131 33.9394 49.3606 31.7716C48.2081 29.6036 46.5411 27.7517 44.5058 26.3784C42.4707 25.0051 40.1294 24.1524 37.6876 23.8951C35.2457 23.6378 32.7782 23.9839 30.5014 24.9028C29.7332 25.2129 29.3619 26.087 29.6719 26.8552C29.982 27.6235 30.8561 27.9948 31.6243 27.6848Z"/>
      <path d="M40.3438 6.9375C41.1723 6.9375 41.8438 7.60907 41.8438 8.4375V11.8125C41.8438 13.1174 41.5868 14.4097 41.0874 15.6154C40.588 16.8211 39.856 17.9165 38.9332 18.8394C38.0104 19.7621 36.9149 20.4941 35.7093 20.9935C34.5038 21.493 33.2115 21.75 31.9062 21.75C31.0778 21.75 30.4062 21.0784 30.4062 20.25V16.875C30.4062 14.2394 31.4533 11.7118 33.3169 9.84812C35.1806 7.98447 37.7082 6.9375 40.3438 6.9375ZM35.4382 11.9695C34.1372 13.2705 33.4062 15.0351 33.4062 16.875V18.5859C33.8002 18.4987 34.1867 18.377 34.5612 18.2219C35.4029 17.8732 36.1677 17.3622 36.8119 16.718C37.4561 16.0738 37.9671 15.3091 38.3157 14.4674C38.6644 13.6256 38.8438 12.7234 38.8438 11.8125V10.1016C37.564 10.385 36.3797 11.028 35.4382 11.9695Z"/>
      <path d="M6.59375 34.4062H25.1562C25.9846 34.4062 26.6562 33.7347 26.6562 32.9062C26.6562 32.0778 25.9846 31.4062 25.1562 31.4062H6.59375C5.76532 31.4062 5.09375 32.0778 5.09375 32.9062C5.09375 33.7347 5.76532 34.4062 6.59375 34.4062Z"/>
    </g>
  `
}
