// pathify
import { isEmpty, map, groupBy } from 'lodash-es'
import queryString from 'query-string'
import { all, join, paginated, single } from '../build'
import { KintellCard, Invite, Booking, Me, KintellGroup, Video } from '~/models'

const activeBooking = single('activeBooking', Booking)
const awaitingBookingStartTime = single('awaitingBookingStartTime', Booking)
const awaitingBookingSession = single('awaitingBookingSession', Booking)
const awaitingBookingReview = single('awaitingBookingReview', Booking)
const activeDashboardTabIndex = single('activeDashboardTabIndex', null, 0)
const activeConsoleTabIndex = single('activeConsoleTabIndex', null, -1)
const blog = single('blog')
const userActivitySession = single('userActivitySession')

const kintellCards = all('kintellCards', KintellCard, state => {
  const authenticatedUser = state.get('auth/user')
  // check the state if it already exists
  return authenticatedUser
    .withKintellCards()
    .where('kintell_group_id', 'null')
    .where('status', 'awaiting-review,draft,published,unlisted')
    .pagination({ page: 1, limit: 999 })
    .$get()
}, {
  // kintell cards can be updated from other people if they are not published. We check back on them every 6 hours
  cacheMinutes: 360
})

const myKintellCards = paginated('myKintellCards', KintellCard, ctx => {
  const authenticatedUser = ctx.get('auth/user')

  return authenticatedUser
    .withKintellCards()
    .where('kintell_group_id', 'null')
    .where('status', 'awaiting-review,draft,published,unlisted')
    .pagination({
      page: ctx.state.myKintell.myKintellCardsPagination.current_page,
      limit: ctx.state.myKintell.myKintellCardsPagination.per_page
    })
    .get()
}, {
  appendResults: false,
  modifyQueryParams: true,
  pagination: {
    current_page: typeof window !== 'undefined' ? queryString.parse(window.location.search).page || 1 : 1,
    per_page: 5
  }
})

const groupKintellCards = all('groupKintellCards', KintellCard, (state, query) => {
  const authenticatedUser = state.get('auth/user')
  // check the state if it already exists
  return authenticatedUser
    .withKintellCards()
    .where('kintell_group_id', query.group)
    .pagination({ page: 1, limit: 999 })
    .$get()
}, {
  cacheMinutes: 0
})

const myGroupKintellCards = paginated('myGroupKintellCards', KintellCard, ctx => {
  const authenticatedUser = ctx.get('auth/user')

  return authenticatedUser
    .withKintellCards()
    .where('kintell_group_id', ctx.$router.currentRoute.params.group)
    .pagination({
      page: ctx.state.myKintell.myGroupKintellCardsPagination.current_page,
      limit: ctx.state.myKintell.myGroupKintellCardsPagination.per_page
    })
    .get()
}, {
  appendResults: false,
  modifyQueryParams: true,
  pagination: {
    current_page: typeof window !== 'undefined' ? queryString.parse(window.location.search).page || 1 : 1,
    per_page: 5
  }
})


// get all published Standard + KG kintell cards
const publishedKintellCards = all('publishedKintellCards', KintellCard, (state, query) => {
  const authenticatedUser = state.get('auth/user')

  return authenticatedUser
    .withKintellCards()
    .where('status', 'published')
    .pagination({ page: 1, limit: 999 })
    .$get()
}, {
  cacheMinutes: 0
})

// no support for caching paginated data currently
const invites = paginated('invites', Invite, ({ state }) => {
  return Invite
    .custom('me/invites')
    .pagination({
      page: state.myKintell.invitesPagination.current_page,
      limit: state.myKintell.invitesPagination.per_page
    })
    .append('pending_kintell_card')
    .get()
}, {
  appendResults: false,
  pagination: {
    current_page: 1,
    // we always assume there is at least one additional age
    total_pages: 2,
    per_page: 10
  }
})

const bookingRequestsAdvising = all('bookingRequestsAdvising', Booking, state => {
  const authenticatedUser = state.get('auth/user')

  return Booking
    .where('advisor_id', authenticatedUser.id)
    .where('status', 'pending-approval,suggested-times')
    .where('instant_booking', false)
    .orderBy('request_expires_at_utc')
    .pagination({ page: 1, limit: 999 })
    .$get()
}, {
  // bookings will update when a user claims the invite. We will solve this by setting up broadcasting
  cacheMinutes: 360
})
// bookings should not be cached
const bookingRequestsLearning = all('bookingRequestsLearning', Booking, state => {
  const authenticatedUser = state.get('auth/user')

  return Booking
    .where('learner_id', authenticatedUser.id)
    .where('status', 'pending-approval,suggested-times')
    .where('instant_booking', false)
    .orderBy('request_expires_at_utc')
    .pagination({ page: 1, limit: 999 })
    .$get()
}, {
  // bookings will update when a user claims the invite. We will solve this by setting up broadcasting
  cacheMinutes: 360
})

// bookings should not be cached
const bookingRequestsFacilitated = all('bookingRequestsFacilitated', Booking, state => {
  const authenticatedUser = state.get('auth/user')

  return Booking
    .where('facilitated_by', authenticatedUser.id)
    .where('status', 'pending-approval,suggested-times')
    .orderBy('request_expires_at_utc')
    .pagination({ page: 1, limit: 999 })
    .$get()
}, {
  cacheMinutes: 0
})

const bookingsConfirmed = all('bookingsConfirmed', Booking, state => {
  return Booking
    .where('status', 'confirmed')
    .where('instant_booking', false)
    // order by the booking time - show upcoming bookings first
    .orderBy('booking_time_utc')
    .pagination({ page: 1, limit: 999 })
    .$get()
}, {
  // bookings will update when a user claims the invite. We will solve this by setting up broadcasting
  cacheMinutes: 360
})

const bookingsArchived = paginated('bookingsArchived', Booking, ({ state }) => {
  const params = state.myKintell.bookingsArchivedSearchParams

  const bookingQuery = Booking
    .where('archived', 'true')
    .where('role', params.role)
    .where('recorded', params.recorded)
    .where('status', params.status)
    .where('query', params.query)

  if (params.bookingType) {
    bookingQuery.where('instant_booking', params.bookingType === 'instant')
  }

  return bookingQuery.include('users')
    // order by the most recent event
    .orderBy('-created_at')
    .pagination({
      page: state.myKintell.bookingsArchivedPagination.current_page,
      limit: state.myKintell.bookingsArchivedPagination.per_page
    })
    .get()
}, {
  appendResults: false,
  modifyQueryParams: false,
  pagination: {
    current_page: typeof window !== 'undefined' ? queryString.parse(window.location.search).page || 1 : 1,
    // we always assume there is at least one additional age
    total_pages: 2,
    per_page: 12
  }
})

const videos = paginated('videos', Video, ({ state }) => {
  const params = state.myKintell.videosSearchParams

  const videoQuery = Video
    // order by the most recent event
    .orderBy('-created_at')
    .append('reporting,stats,booking_participants')
    .where('query', params?.query)

  if (params?.videoReport) {
    videoQuery.where('video_report', params.videoReport)
  }

  return videoQuery
    .pagination({
      page: state.myKintell.videosPagination.current_page,
      limit: state.myKintell.videosPagination.per_page
    })
    .get()
}, {
  appendResults: false,
  modifyQueryParams: false,
  pagination: {
    current_page: typeof window !== 'undefined' ? queryString.parse(window.location.search).page || 1 : 1,
    // we always assume there is at least one additional age
    total_pages: 2,
    per_page: 8
  }
})

const favourites = paginated('favourites', KintellCard, ({ state }) => {
  return Me
    .custom('me/favourites')
    .where('status', 'published,unlisted')
    .pagination({
      page: state.myKintell.favouritesPagination.current_page,
      limit: state.myKintell.favouritesPagination.per_page
    })
    .get()
}, {
  appendResults: false,
  modifyQueryParams: true,
  pagination: {
    current_page: typeof window !== 'undefined' ? queryString.parse(window.location.search).page || 1 : 1,
    total_pages: 2,
    per_page: 3
  }
})

const repeatBookings = paginated('repeatBookings', KintellCard, ({ state }) => {
  return KintellCard
    .custom('kintell-cards')
    .where('repeat_cards', true)
    .orderBy('last_booked_as_learner')
    .pagination({
      page: state.myKintell.repeatBookingsPagination.current_page,
      limit: state.myKintell.repeatBookingsPagination.per_page
    })
    .get()
}, {
  appendResults: false,
  pagination: {
    current_page: 1,
    per_page: 5
  }
})

const activeGroupMembers = paginated('activeGroupMembers', KintellGroup, async ({ state }) => {
  const params = state.myKintell.activeGroupMembersSearchParams

  const data = await new KintellGroup({ id: params.id })
    .fetchMembers({
      page: state.myKintell.activeGroupMembersPagination.current_page,
      limit: state.myKintell.activeGroupMembersPagination.per_page
    })

  return { data }
}, {
  appendResults: false,
  pagination: {
    current_page: 1,
    total_pages: 2,
    per_page: 10
  }
})


const {
  state,
  getters,
  mutations,
  actions
} = join(
  kintellCards,
  myKintellCards,
  favourites,
  invites,
  bookingRequestsAdvising,
  bookingRequestsLearning,
  bookingsConfirmed,
  activeBooking,
  awaitingBookingStartTime,
  awaitingBookingSession,
  awaitingBookingReview,
  videos,
  bookingsArchived,
  activeDashboardTabIndex,
  activeConsoleTabIndex,
  bookingRequestsFacilitated,
  groupKintellCards,
  myGroupKintellCards,
  publishedKintellCards,
  activeGroupMembers,
  blog,
  userActivitySession,
  repeatBookings
)

getters.confirmedBookingsCalender = (state, getters, rootState, rootGetters) => {
  return groupBy(map(getters.bookingsConfirmed, e => {
    const bookingStartTimeUTC = rootGetters['auth/user'].utcToMyTz(e.bookingStartTimeUtc)
    const date = bookingStartTimeUTC.format('YYYY-MM-DD')
    return {
      date,
      time: bookingStartTimeUTC.format('HH:mm'),
      duration: e.duration,
      booking: e,
    }
  }), 'date')
}


actions.reset = ({ commit }) => {
  // allow the requests to be resubmitted
  commit('SET_KINTELL_CARDS', [])
  commit('SET_KINTELL_CARDS_LAST_REQUEST', null)
  commit('SET_INVITES', [])
  commit('SET_INVITES_LAST_REQUEST', null)
  // reset bookings
  commit('SET_ACTIVE_BOOKING', null)
  // advising requests
  commit('SET_BOOKING_REQUESTS_ADVISING', [])
  commit('SET_BOOKING_REQUESTS_ADVISING_LAST_REQUEST', null)
  // learning requests
  commit('SET_BOOKING_REQUESTS_LEARNING', [])
  commit('SET_BOOKING_REQUESTS_LEARNING_LAST_REQUEST', null)
  commit('SET_BOOKINGS_CONFIRMED', [])
  commit('SET_BOOKINGS_CONFIRMED_LAST_REQUEST', null)
}
actions.resetKintellCards = ({ commit }) => {
  commit('SET_KINTELL_CARDS', [])
  commit('SET_KINTELL_CARDS_LAST_REQUEST', null)
}
actions.reloadInvites = ({ commit, dispatch }) => {
  commit('SET_INVITES', [])
  commit('SET_INVITES_LAST_REQUEST', null)
  dispatch('loadInvites')
}
actions.reloadBookingRequestsAdvising = ({ commit, dispatch }) => {
  commit('SET_BOOKING_REQUESTS_ADVISING', [])
  commit('SET_BOOKING_REQUESTS_ADVISING_LAST_REQUEST', null)
  dispatch('loadBookingRequestsAdvising')
}
actions.maybeReloadActiveBooking = async ({ commit, dispatch, getters }, event) => {
  if (!getters.activeBooking) {
    return
  }

  const bookingId = event.booking ? event.booking.id : event.bookingId
  if (!bookingId) {
    return
  }

  if (getters.activeBooking.id === bookingId) {
    commit('SET_ACTIVE_BOOKING', await Booking.include('reviews,users,recordingOwner').find(bookingId))
  }
}
actions.reloadBookings = async ({ commit, dispatch }) => {
  // requests advising
  commit('SET_BOOKING_REQUESTS_ADVISING', [])
  commit('SET_BOOKING_REQUESTS_ADVISING_LAST_REQUEST', null)
  // unconfirmed
  commit('SET_BOOKING_REQUESTS_LEARNING', [])
  commit('SET_BOOKING_REQUESTS_LEARNING_LAST_REQUEST', null)
  // confirmed
  commit('SET_BOOKINGS_CONFIRMED', [])
  commit('SET_BOOKINGS_CONFIRMED_LAST_REQUEST', null)

  await Promise.all([
    dispatch('loadBookingRequestsAdvising'),
    dispatch('loadBookingRequestsLearning'),
    dispatch('loadBookingsConfirmed'),
  ])
}
actions.reloadKintellCards = ({ commit, dispatch }) => {
  commit('SET_KINTELL_CARDS', [])
  commit('SET_KINTELL_CARDS_LAST_REQUEST', null)
  dispatch('loadKintellCards')
}
actions.loadBookingForSlug = async ({ commit, state, rootState }, { id, error, reload = false }) => {
  // already loaded
  if (
    // check kintell card exists and slug or id matches
    !reload && state.activeBooking && state.activeBooking.id === id
  ) {
    return true
  }

  // if it's a UUID
  const booking = await Booking.include('reviews', 'users', 'recordingOwner', 'networkQuality').find(id)

  if (isEmpty(booking)) {
    error({ statusCode: 404, message: 'Booking not found.' })
    return false
  }

  commit('SET_ACTIVE_BOOKING', booking)
  return true
}

// export store
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
