import { Context } from '@nuxt/types'
import { UpdateMessage } from '@/lib/broadcasting/messages'

// ci.update
export default (ctx: Context) => {
  return (message: UpdateMessage) => {
    const isNewVersion = process.env.VERSION !== message.version
    if (isNewVersion) {
      // Push a toast and set in your vuex that there is a new version
      ctx.$toast.info('A new version of Kintell has been released! We\'ll update to it when you change page :)', {
        duration: 10000
      })
      ctx.store.commit('kintell/SET_LATEST_VERSION', message.version)
    }
    ctx.$logger.info('Kintell Client Version Update', 'New: ' + message.version, 'Old: ' + process.env.version)
  }
}
