
export default function ({ app, redirect }) {
  // only run this middleware on the client side if they are logged in
  // Or is not a placeholder
  if (!app.$auth.$state.loggedIn || app.$auth.$state.user.isPlaceholder) {
    return
  }

  const route = app.router.history.current
  if (route.name === 'forgot-password') {
    redirect('/my-kintell/my-details?password=true')
  } else if (route.query?.video) {
    redirect(`/v/${route.query.video}`)
  } else {
    redirect('/my-kintell/dashboard')
  }
}
