export default {
  height: 19,
  width: 19,
  svg: `
  <g>
    <path d="M9.5 18.85a9.35 9.35 0 1 1 0-18.7 9.35 9.35 0 0 1 0 18.7zm0-1.7a7.65 7.65 0 1 0 0-15.3 7.65 7.65 0 0 0 0 15.3z"/>
    <path d="M10.35 8.65h5.1v1.7h-6.8v-6.8h1.7z"/>
  </g>
`
}
