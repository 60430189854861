// pathify
import { make } from 'vuex-pathify'

// local files
import { User } from '@/models'
import { isFunction } from 'lodash-es'

// base state
const state = () => ({
  // cards is kintell cards
  user: null
})

// getter overrides
const getters = {
  // return Item instances
  isAuthenticated: state => state.loggedIn === true,
  user: state => {
    if (state.loggedIn) {
      return new User(state.user)
    }
    return state.user
  }
}

// automatically generate mutations
const mutations = {
  // create SET_VALUE automatically
  ...make.mutations(state)
}

// manually-created actions
const actions = {
  ...make.actions(state),
  async updateUser ({ commit, state }, user) {
    if (isFunction(user.save)) {
      commit('SET_USER', await user.save())
    } else {
      commit('SET_USER', await new User(user).save())
    }
  },
  /**
   * Refreshes the current authenticated user via the API
   * @param commit
   * @param getters
   * @returns {Promise<void>}
   */
  async refresh ({ commit, getters }) {
    const user = (await this.$axios.$get('/me?append=stats,feature_flags')).data
    commit('SET_USER', user)
  },
  incrementUserStat ({ commit, getters }, stat) {
    const user = getters.user
    user.stats[stat]++
    commit('SET_USER', user)
  }
}

// export store
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
