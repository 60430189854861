import { findIndex, map, filter } from 'lodash-es'
export default {
  data () {
    return {
      categoryIdsSelected: []
    }
  },
  computed: {
    categoriesSelected () {
      return map(this.categoryIdsSelected, id => this.findCategory(id))
    },
  },
  methods: {
    selectedCountOfParent (parentCategory) {
      return filter(parentCategory.children, child => this.hasBeenSelected(child.id)).length
    },
    /**
     * Adds or removes a category based on if it exists in the array
     * @param categoryID
     * @return {null}
     */
    toggleCategoryID (categoryID) {
      const index = findIndex(this.categoryIdsSelected, category => category === categoryID)
      if (index === -1) {
        this.categoryIdsSelected.push(categoryID)
        return null
      }
      this.categoryIdsSelected.splice(index, 1)
    },
    /**
     * Has the category been selected
     * @param categoryID
     * @return {boolean}
     */
    hasBeenSelected (categoryID) {
      return this.categoryIdsSelected.includes(categoryID)
    },

  }
}
