import Vue from 'vue'
import VueI18n from 'vue-i18n'
import english from '~lang/en'

Vue.use(VueI18n)

const messages = {
  en: english
}

const i18n = new VueI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en',
  messages // set locale messages
})

export default ({ app }, inject) => {
  app.i18n = i18n
  app.t = i18n
  // Translate /w default value if not set
  inject('td', (key, defaultValue, data = {}) => i18n.te(key) ? i18n.t(key, data) : defaultValue)
}
