








import Vue from 'vue'

export default Vue.extend({
  props: {
    colorArrow: {
      type: String,
      required: true,
    },
  }
})
