import Model from './Model'
import User from './User'
import moment from 'moment-timezone'
import { Booking } from '@/models/index'
import { groupsHasSidebar } from '~data/group-sidebar.js'

export default class KintellGroup extends Model {
  resource () {
    return 'kintell-groups'
  }

  transform (data) {
    if (data?.usersPermissions) {
      const userPermissions = { ...data.usersPermissions }
      userPermissions.approvedAt = data.usersPermissions?.approvedAt ? moment.utc(data.usersPermissions?.approvedAt) : ''
      userPermissions.createdAt = moment.utc(data.usersPermissions?.createdAt)
      data.usersPermissions = userPermissions
    }
    return data
  }

  get link () {
    return '/g/' + this.slug
  }

  get membersPagePath () {
    return `/my-kintell/console/members/${this.slug}`
  }

  get addGroupCardLink () {
    return `/my-kintell/kintell-cards/${this.id}`
  }

  get joinLink () {
    return `/g/${this.slug}/join`
  }

  get homeLink () {
    return `/g/${this.slug}`
  }

  get fullHomeLink () {
    return `${process.env.APP_URL}${this.homeLink}`
  }

  get fullJoinLink () {
    return `${process.env.APP_URL}${this.joinLink}`
  }

  get fullAddGroupCardLink () {
    return `${process.env.APP_URL}${this.addGroupCardLink}`
  }

  get secondaryLogo () {
    return this.secondaryLogoUrl ? this.secondaryLogoUrl : this.logoUrl
  }

  get showSideBar () {
    return groupsHasSidebar.includes(this.applyForm || this.slug)
  }

  // relations
  members () {
    return this.hasMany(User)
  }

  bookings () {
    return this.hasMany(Booking)
  }

  requestAccess (data) {
    return this.action('request-access', data)
  }

  approve (data) {
    return this.action('approve', data)
  }

  removeUser (userId) {
    return this.$http.$delete(this.resourceUrl() + `/remove/${userId}`)
  }

  downgradeRole (userId) {
    return this.$http.post(this.resourceUrl() + `/downgrade-role/${userId}`)
  }

  join () {
    return this.action('join')
  }

  fetchMembers (pagination) {
    return KintellGroup
      .pagination(pagination)
      .custom(this, 'members')
      .$get()
  }
}
