/**
 * Convert time to minutes and then add the duration to it
 * It is useful here to convert the time into minutes.  E.g so 2:30am would be 150 minutes so (60 + 60 + 30)
 */
function convertTimeToMinutes (time: string): number {
  const splitTime = time.split(':')
  const minutes = parseInt(splitTime[1])
  return (+splitTime[0] * 60) + minutes
}

/**
 * gets the hour of a
 */
function getHour (minutes: string | number): number {
  return Math.floor(+minutes / 60)
}

/**
 * gets the minutes
 */
function getMinute (minutes: string | number): number {
  return +minutes % 60
}

export {
  convertTimeToMinutes,
  getHour,
  getMinute
}
