export default {
  width: 17,
  height: 24,
  svg: `
   <g>
    <path d="M8.966 19.797c.845-.98 1.691-2.04 2.479-3.139 1.058-1.477 1.925-2.909 2.536-4.242.632-1.38.965-2.603.965-3.619 0-3.914-2.972-6.697-6.446-6.697-3.474 0-6.446 2.783-6.446 6.697 0 1.016.333 2.24.965 3.62.61 1.332 1.478 2.764 2.536 4.241a44.414 44.414 0 0 0 2.945 3.67c.148-.166.304-.343.466-.53zm7.822-11c0 1.342-.404 2.827-1.144 4.442-.669 1.459-1.598 2.992-2.722 4.561a46.289 46.289 0 0 1-3.77 4.59l-.652.677-.651-.676a42.981 42.981 0 0 1-1.185-1.316A46.289 46.289 0 0 1 4.078 17.8c-1.124-1.57-2.053-3.102-2.722-4.561-.74-1.615-1.144-3.1-1.144-4.442C.212 3.78 4.05.186 8.5.186c4.451 0 8.288 3.593 8.288 8.611z"/>
    <path d="M8.5 12.146c-1.78 0-3.223-1.5-3.223-3.349 0-1.85 1.443-3.348 3.223-3.348 1.78 0 3.223 1.499 3.223 3.348 0 1.85-1.443 3.35-3.223 3.35zm0-1.913c.763 0 1.381-.643 1.381-1.436 0-.792-.618-1.435-1.381-1.435s-1.381.643-1.381 1.435c0 .793.618 1.436 1.381 1.436z"/>
  </g>
  `
}
