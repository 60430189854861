//
//
//
//
//
//
//
//
//
//
//
//
//

import { TheHeader } from '~/layouts/components'

export default {
  components: {
    TheHeader,
  },
}
