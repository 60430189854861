export default {
  forgotPassword: 'I’ve forgotten my log in details',
  signUp: 'Sign up Here',
  form: {
    submit: 'Log In', // form submit button
    labels: {
      email: 'Email',
      password: 'Password',
      rememberMe: 'Remember Me'
    },
    errors: {
      invalid: 'Oops, looks like those credentials weren’t right. Try again.'
    }
  },
  toast: {
    loggedIn: 'Welcome back to Kintell.',
    loggingIn: 'Logging in...',
    badCredentials: 'Credentials were invalid.',
    generalError: 'Error while authenticating'
  }
}
