//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'vuex-pathify'
import { UsesAuthenticatedUser } from '@/mixins'
import { TheFooter, TheHeader } from '~/layouts/components'

export default {
  components: {
    TheFooter,
    TheHeader,
  },

  mixins: [ UsesAuthenticatedUser ],

  data () {
    return {
      showBookingModal: false,
      showReviewModal: false,
      hideAlertBlog: true,
      showConversionModal: false,
      showDowngradeModal: false
    }
  },

  computed: {
    ...get({
      awaitingBookingSession: 'myKintell/awaitingBookingSession',
      awaitingBookingReview: 'myKintell/awaitingBookingReview',
      showGroupSidebar: 'layout/showGroupSidebar',
    }),

    onHomePage () {
      return this.$route.path === '/'
    },

    onReviewPage () {
      return this.$route.name === 'my-kintell-bookings-id-review'
    },

    // no banner margin if there is something in the beforeFooter slot
    bannerMargin () {
      return !this.$slots['before-footer'] && !this.onHomePage
    },

    pageDisplaysBanner () {
      return this.onHomePage || this.$route.path === '/search' || // on the search page
        this.$route.name === 'advisors-advisor-kintellCard'
    },
    onReviewRedirect () {
      return this.$route.query.reviewRedirect
    },

    // TODO: not scalable, Need to find a better way. Maybe add a vuex global state for `hasFooter` ?
    showFooter () {
      // Special case for UNSW
      const isGroupPage = [ 'g-kintellGroup-apply', 'g-kintellGroup-join' ].includes(this.$route.name)
      const hasSidebar = isGroupPage && this.showGroupSidebar

      return !hasSidebar
    }
  },
  created () {
    this.$nuxt.$on('showConversionModal', () => {
      this.showConversionModal = true
    })
    this.$nuxt.$on('showDowngradeModal', () => {
      this.showDowngradeModal = true
    })
  },
  mounted () {
    Object.entries({
      awaitingBookingSession: 'showBookingModal',
      awaitingBookingReview: 'showReviewModal'
    }).forEach(([ prop, data ]) => {
      this.$watch(prop, val => {
        if (val) {
          setTimeout(() => {
            this[data] = true
          }, 2000)
        }
      }, { immediate: true })
    })
    /**
     * We want to make sure that placeholders are either logged out so show a join now
     * modal and log them out when they close it
     */
    if (this.userIsAuthenticated &&
      this.authenticatedUser.isPlaceholder &&
      ![
        'advisors-advisor-kintellCard-respond',
        'advisors-advisor-kintellCard-instant-meeting',
        'v-slug',
        'g-kintellGroup-videos',
        'g-kintellGroup-videos-id'
      ].includes(this.$route.name)
    ) {
      // Don't use this.$auth.logout() or it could break a initiate or a facilitated booking
      this.$auth.reset()
    }
  },
  beforeDestroy () {
    // removes event listener
    this.$nuxt.$off('showConversionModal')
  },
}
