import { Context } from '@nuxt/types'
import { UserMessage } from '@/lib/broadcasting/messages'

// .App\\Events\\User\\ClaimedInvite
export default (ctx: Context) => {
  return (message: UserMessage) => {
    ctx.$logger.info('Handle invited user signed up event', message)
    ctx.$toast.success(message.user.fullName + ' has just signed up with your invite!', {
      duration: 10000
    })
    ctx.store.dispatch('myKintell/reloadInvites')
    ctx.store.dispatch('auth/refresh')
  }
}
