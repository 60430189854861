export default {
  width: 34,
  height: 34,
  stroke: true,
  fill: false,
  svg: `
<path d="M17 9.5625V24.4375" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M24.4375 17H9.5625" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17 32.4062C25.5086 32.4062 32.4062 25.5086 32.4062 17C32.4062 8.49136 25.5086 1.59375 17 1.59375C8.49136 1.59375 1.59375 8.49136 1.59375 17C1.59375 25.5086 8.49136 32.4062 17 32.4062Z" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    `
}
