import { Context } from '@nuxt/types'
import { BookingMessage } from '@/lib/broadcasting/messages'

// '.App\\Events\\Booking\\Status\\PendingApproval'
export default (ctx: Context) => {
  return (e: BookingMessage) => {
    const isAdvisor = e.booking.advisorId === ctx.$auth.$state.user.id
    // If createdBy is an object then get the id from the object
    const createdById = e.booking.createdBy.id ? e.booking.createdBy.id : e.booking.createdBy
    const bookingInitiatedByLearner = createdById === e.booking.learnerId
    if (isAdvisor && bookingInitiatedByLearner) {
      ctx.$toast.success('You have just been sent a booking request!', {
        duration: 10000
      })
      ctx.store.dispatch('myKintell/reloadBookingRequestsAdvising')
      ctx.store.dispatch('auth/refresh')
    }
  }
}
