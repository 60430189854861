import { clone, findIndex, upperFirst } from 'lodash-es'
import pluralize from 'pluralize'
import { make } from 'vuex-pathify'
import moment from 'moment-timezone'
import { findMutationName } from './utility'

/**
 * Store helper for a list of models from an API endpoint
 * @param field string Store key
 * @param model Model Instance of a modal class which we use to instantiate a model from the API response
 * @param load function Should contain the API request and return response unmodified
 * @param options object This our are store options. Currently supports cacheMinutes. cacheMinutes specifies how long
 * the store should hold on to the API response before re-request it
 */
export default (field, model, load, { cacheMinutes = 0 }) => {
  const fieldTitle = upperFirst(clone(field))
  // fields
  const FIELD_LOADING = field + 'Loading'
  const FIELD_ERROR = field + 'Errors'
  const FIELD_LAST_REQUEST = field + 'LastRequest'
  const FIELD_FILTERS = field + 'SearchParams'
  // actions
  const ACTION_LOAD_FIELD = 'load' + fieldTitle
  const ACTION_DELETE_ITEM = 'delete' + pluralize.singular(upperFirst(clone(field)))
  const ACTION_FIND_ITEM = 'find' + pluralize.singular(upperFirst(clone(field)))
  const ACTION_UPDATE_ITEM = 'update' + pluralize.singular(upperFirst(clone(field)))
  const ACTION_CREATE_ITEM = 'create' + pluralize.singular(upperFirst(clone(field)))
  // mutations
  const SET_FIELD_KEY = findMutationName(field)
  const SET_FIELD_LOADING_KEY = findMutationName(FIELD_LOADING)
  const SET_FIELD_ERROR_KEY = findMutationName(FIELD_ERROR)
  const SET_FIELD_LAST_REQUEST_KEY = findMutationName(FIELD_LAST_REQUEST)
  // getters

  const state = () => {
    return {
      [field]: [],
      [FIELD_LOADING]: false,
      [FIELD_ERROR]: null,
      [FIELD_LAST_REQUEST]: null,
      [FIELD_FILTERS]: null,
    }
  }

  const actions = () => {
    return {
      async [ACTION_LOAD_FIELD] ({ commit, state }, query) {
        // check we're not loading the resource already
        if (state[FIELD_LOADING]) {
          this.$logger.trace('Skipping load - Already loading', ACTION_LOAD_FIELD,)
          return
        }

        // check the cache times
        if (state[FIELD_LAST_REQUEST] && cacheMinutes > 0) {
          // check the cache firstly
          const lastRequestTime = moment(state[FIELD_LAST_REQUEST])
          const cacheTime = lastRequestTime.add(cacheMinutes, 'minutes')
          const hasExpiredCache = cacheTime.isBefore(moment())
          if (!hasExpiredCache) {
            this.$logger.trace(ACTION_LOAD_FIELD + ' - cache hit. Expires ' + cacheTime.fromNow())
            return
          }
          this.$logger.trace(ACTION_LOAD_FIELD + ' - cache missed. Expired ' + cacheTime.toNow())
        }

        this.$logger.trace(ACTION_LOAD_FIELD + ' - loading. cache ' + cacheMinutes + ' mins')
        commit(SET_FIELD_LAST_REQUEST_KEY, Date.now())
        commit(SET_FIELD_LOADING_KEY, true)
        try {
          const response = await load(this, query)
          this.$logger.trace(SET_FIELD_KEY, response.data || response)
          commit(SET_FIELD_KEY, response.data || response)
        } catch (e) {
          this.$logger.error(
            'Vuex action failed',
            ACTION_LOAD_FIELD,
            e
          )
          commit(SET_FIELD_ERROR_KEY, e.response)
          // retry failed requests
          commit(SET_FIELD_LAST_REQUEST_KEY, false)
        } finally {
          commit(SET_FIELD_LOADING_KEY, false)
        }
      },
      [ACTION_FIND_ITEM]: ({ state }, query) => {
        const objArray = JSON.parse(JSON.stringify(state[field]))
        const index = findIndex(objArray, query)
        if (index) {
          // eslint-disable-next-line
          return new model(objArray[findIndex(objArray, query)])
        }
        return false
      },
      async [ACTION_DELETE_ITEM] ({ state, commit }, removing) {
        const items = clone(state[field])
        const index = findIndex(items, { id: removing.id })
        items.splice(index, 1)
        // eslint-disable-next-line
        removing = new model(removing)
        await removing.delete()
        commit(SET_FIELD_KEY, items)
        return removing
      },
      async [ACTION_UPDATE_ITEM] ({ state, commit }, updating) {
        if (state[FIELD_LOADING]) {
          this.$logger.trace('Skipping update - Already loading', ACTION_UPDATE_ITEM)
          return
        }
        const items = clone(state[field])
        const index = findIndex(items, { id: updating.id })
        commit(SET_FIELD_LOADING_KEY, true)
        updating = await updating.save()
        if (index < 0) {
          items.push(updating)
        } else {
          items[index] = updating
        }
        commit(SET_FIELD_KEY, items)
        commit(SET_FIELD_LOADING_KEY, false)
        return updating
      },
      async [ACTION_CREATE_ITEM] ({ state, commit }, item) {
        // @todo add seperate loading field for creating / updating
        // if (state[FIELD_LOADING]) {
        //   this.$logger.trace('Skipping create - Already loading', ACTION_CREATE_ITEM)
        //   return
        // }
        const items = clone(state[field])
        commit(SET_FIELD_LOADING_KEY, true)
        const created = await item.save()
        items.push(created)
        commit(SET_FIELD_KEY, items)
        commit(SET_FIELD_LOADING_KEY, false)
        return created
      }
    }
  }

  const getters = () => {
    return {
      [field]: state => {
        // eslint-disable-next-line
        return state[field].map(data => new model(data))
      }
    }
  }

  const mutations = () => {
    return {
      ...make.mutations(state)
    }
  }

  return {
    state: state(),
    getters: getters(),
    mutations: mutations(),
    actions: actions()
  }
}
