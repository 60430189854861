export default {
  banner: {
    topics: {
      title: 'What topics are you interested in?',
      body: 'Let us know what kind of things you’re interested in learning more about, and we’ll personalise your browsing experience to fit!',
      cta: 'Personalise my kintell'
    },
    favourites: {
      title: 'You haven\'t added any favourites yet',
      body: 'It’s quick and easy - when you see an Advisor you’d like to add to your favourites just click on the heart icon!',
      cta: 'Find an advisor'
    },
    noBookings: {
      title: 'You don\'t have any bookings at the moment',
      body: 'But when you do, we’ll store them here for you so you can edit them at your leisure. We’ll also remind you about upcoming bookings.',
    },
    welcomeLearner: {
      title: 'Welcome to your dashboard',
      body: 'Once you make a booking, or create a Kintell Card and become an Advisor, you’ll be able to see all of your activity here.',
    },
    noKintellCards: {
      title: 'You\'ve not added any Kintell Cards yet',
      body: 'Become an Advisor and make yourself available for video sessions, by creating a Kintell Card!',
    },
    proBono: {
      title: 'Earn some free Pro Bono time',
      body: 'You can earn up to 100 hours of free Pro Bono time by inviting your friends and colleagues to the platform.',
    }
  },
  dashboard: {
    myRewards: {
      title: 'My Invites',
      none: {
        title: 'Invite trusted friends and colleagues to the Kintell platform and get up to 100 hours worth of Pro Bono Credit',
        body: 'We rely on our invited members to help keep the standards on Kintell super-high, and a key part of this is sharing the love and sending invites to friends and colleagues who you think would make Kintell a better place.'
      },
      sendInvite: 'Send out more invites',
      sendFirstInvite: 'Send out invites'
    },
    personalDetails: {
      title: 'Personal Details',
      button: 'Edit my personal details'
    },
    kintellCards: {
      title: 'My Kintell Cards',
      none: {
        heading: 'Time to add your first Kintell Card',
        body: 'Make yourself available for video sessions, and become an Advisor, by creating a Kintell Card. You can add as many as you like.',
        create: 'Add a Kintell Card',
      },
      tour: {
        title: 'Your areas of knowledge',
        body: 'Define the subjects you’d like to advise in by creating Kintell Cards'
      }
    },
    avatar: {
      title: 'Complete your profile',
      body: 'Kintell is about real people, so profile photos are essential. A picture verifies that you\'re genuine to other users.'
    },
    instantMeeting: {
      title: 'Your personal room',
      bullets: [
        'Multi-party video - unlimited duration',
        'Send the link in a calendar invite or via a message',
        'Secure video room - guests knock to join call',
      ],
      button: 'Host a meeting now',
      copyButton: 'Copy link',
      linkText: 'Contact us for more participants and recording'
    },
  },
  rewards: {
    tooltip: {
      title: 'Earn free booking time',
      body: 'When your invitees sign up to Kintell you’ll be rewarded with Pro Bono Credits for you to use on video sessions with your favourite Advisors.'
    },
    progress: {
      alert: 'Woah nice job! You have hit our hard limit of {max} invites, unfortunately you won\'t be able to invite anyone else.'
    }
  }
}
