export default {
  toast: {
    inviteCodeLoaded: 'Your invite code has been loaded.',
    inviteCodeInvalid: 'This invite code is not valid.',
    inviteCodeClaimed: 'This invite code has been claimed.',
    inviteCodeExpired: 'This invite code has expired.',
    submitErrors: 'You have an error in your submission.',
    submitSuccess: 'Sign up Complete!'
  },
  buttons: {
    submitUserDetails: 'Submit',
    agree: {
      body: 'By clicking \'{buttonText}\' you confirm that you have read and agree to our {privacy} and {terms}.',
      privacy: 'privacy policy',
      terms: 'terms and conditions'
    }
  },
  labels: {
    interests: 'Your Learning Interests',
    invite: 'Invite Code',
    proBono: 'Pro Bono Availability',
    proBonoEnable: 'Display Pro Bono Time?',
  },
  placeholders: {
    interests: 'Add an interest',
    currency: 'Select a currency',
    timezone: 'Select your timezone'
  },
  hints: {
    password: 'Hint: Please use at least 12 characters containing numbers, uppercase and lowercase letters.',
    interests: 'What are you interested in as a learner? Let us know so we can customise your Kintell experience.',
    consultationCap: 'You have the option of limiting the amount of hours you are available on Kintell (includes pro bono).'
  },
  welcomeHero: {
    title: 'Sign up to Kintell',
    body: 'Hi {name}, you\'ve been invited to Kintell, the peer-to-peer global knowledge network. ' +
      'Please pop your details in below so we can give you ' +
      'immediate access to our community of amazing advisors.'
  },
  success: {
    title: 'Welcome to the Kintell family :)',
    subtitle: 'Hi {name}, thanks for joining! You’re now officially signed up to Kintell.',
    nextSteps: {
      title: 'You can now:',
      steps: [
        {
          step: 'Start {search} for {groupName} advisors!',
          search: 'browsing',
        },
        {
          step: 'Book in video sessions with your chosen advisors'
        },
        {
          step: 'Become an advisor yourself by {creating}',
          creating: 'creating a Kintell Card',
        },
        {
          step: 'Earn extra Pro Bono time to spend on Kintell by {inviting} your friends and colleagues to Kintell',
          inviting: 'inviting'
        },
      ],
      creating: 'creating a Kintell Card',
      inviting: 'inviting'
    },
    cta: {
      label: 'Go to my homepage',
      link: '/'
    },
  },
  info: {
    theRealYou: {
      title: 'The real you',
      body: 'Kintell is all about people, so make sure your details are as accurate as possible. Fake accounts will be deleted by our team. Thanks!'
    },
    socialLinks: {
      title: 'Showcase your experience',
      body: 'We ask for one or more social network profile so potential learners can check you out! You can choose which profile to show when you create your Kintell Cards.'
    },
    photo: 'Upload a photo of yourself and we\'ll do all the resizing. We accept JPGs and PNGs.'
  }
}
