import { render, staticRenderFns } from "./KSubscriptionButton.vue?vue&type=template&id=42cec112&scoped=true&lang=pug&"
import script from "./KSubscriptionButton.vue?vue&type=script&lang=js&"
export * from "./KSubscriptionButton.vue?vue&type=script&lang=js&"
import style0 from "./KSubscriptionButton.vue?vue&type=style&index=0&id=42cec112&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42cec112",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {KButton: require('/opt/atlassian/pipelines/agent/build/app/components/shared/Button/KButton.vue').default})
