export default {
  width: 53,
  height: 60,
  stroke: true,
  svg: `
   <g fill="none"  fill-rule="evenodd" stroke-linecap="square" stroke-width="3">
    <path d="M1.5 1.5h50v57h-50zm30.423 14.1h7.23m-7.23 9h7.23m-25.307 9h25.308m-25.308 9h25.308"/>
    <path d="M13.846 15.6h10.846v9H13.846z"/>
  </g>`
}
