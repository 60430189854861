const usStates = [
  {
    text: 'Alabama',
    value: 'AL'
  },
  {
    text: 'Alaska',
    value: 'AK'
  },
  {
    text: 'American Samoa',
    value: 'AS'
  },
  {
    text: 'Arizona',
    value: 'AZ'
  },
  {
    text: 'Arkansas',
    value: 'AR'
  },
  {
    text: 'California',
    value: 'CA'
  },
  {
    text: 'Colorado',
    value: 'CO'
  },
  {
    text: 'Connecticut',
    value: 'CT'
  },
  {
    text: 'Delaware',
    value: 'DE'
  },
  {
    text: 'District Of Columbia',
    value: 'DC'
  },
  {
    text: 'Federated States Of Micronesia',
    value: 'FM'
  },
  {
    text: 'Florida',
    value: 'FL'
  },
  {
    text: 'Georgia',
    value: 'GA'
  },
  {
    text: 'Guam',
    value: 'GU'
  },
  {
    text: 'Hawaii',
    value: 'HI'
  },
  {
    text: 'Idaho',
    value: 'ID'
  },
  {
    text: 'Illinois',
    value: 'IL'
  },
  {
    text: 'Indiana',
    value: 'IN'
  },
  {
    text: 'Iowa',
    value: 'IA'
  },
  {
    text: 'Kansas',
    value: 'KS'
  },
  {
    text: 'Kentucky',
    value: 'KY'
  },
  {
    text: 'Louisiana',
    value: 'LA'
  },
  {
    text: 'Maine',
    value: 'ME'
  },
  {
    text: 'Marshall Islands',
    value: 'MH'
  },
  {
    text: 'Maryland',
    value: 'MD'
  },
  {
    text: 'Massachusetts',
    value: 'MA'
  },
  {
    text: 'Michigan',
    value: 'MI'
  },
  {
    text: 'Minnesota',
    value: 'MN'
  },
  {
    text: 'Mississippi',
    value: 'MS'
  },
  {
    text: 'Missouri',
    value: 'MO'
  },
  {
    text: 'Montana',
    value: 'MT'
  },
  {
    text: 'Nebraska',
    value: 'NE'
  },
  {
    text: 'Nevada',
    value: 'NV'
  },
  {
    text: 'New Hampshire',
    value: 'NH'
  },
  {
    text: 'New Jersey',
    value: 'NJ'
  },
  {
    text: 'New Mexico',
    value: 'NM'
  },
  {
    text: 'New York',
    value: 'NY'
  },
  {
    text: 'North Carolina',
    value: 'NC'
  },
  {
    text: 'North Dakota',
    value: 'ND'
  },
  {
    text: 'Northern Mariana Islands',
    value: 'MP'
  },
  {
    text: 'Ohio',
    value: 'OH'
  },
  {
    text: 'Oklahoma',
    value: 'OK'
  },
  {
    text: 'Oregon',
    value: 'OR'
  },
  {
    text: 'Palau',
    value: 'PW'
  },
  {
    text: 'Pennsylvania',
    value: 'PA'
  },
  {
    text: 'Puerto Rico',
    value: 'PR'
  },
  {
    text: 'Rhode Island',
    value: 'RI'
  },
  {
    text: 'South Carolina',
    value: 'SC'
  },
  {
    text: 'South Dakota',
    value: 'SD'
  },
  {
    text: 'Tennessee',
    value: 'TN'
  },
  {
    text: 'Texas',
    value: 'TX'
  },
  {
    text: 'Utah',
    value: 'UT'
  },
  {
    text: 'Vermont',
    value: 'VT'
  },
  {
    text: 'Virgin Islands',
    value: 'VI'
  },
  {
    text: 'Virginia',
    value: 'VA'
  },
  {
    text: 'Washington',
    value: 'WA'
  },
  {
    text: 'West Virginia',
    value: 'WV'
  },
  {
    text: 'Wisconsin',
    value: 'WI'
  },
  {
    text: 'Wyoming',
    value: 'WY'
  }
]

const canadaStates = [
  {
    text: 'Alberta',
    value: 'AB'
  },
  {
    text: 'British Columbia',
    value: 'BC'
  },
  {
    text: 'Manitoba',
    value: 'MB'
  },
  {
    text: 'New Brunswick',
    value: 'NB'
  },
  {
    text: 'Newfoundland and Labrador',
    value: 'NL'
  },
  {
    text: 'Northwest Territories',
    value: 'NT'
  },
  {
    text: 'Nova Scotia',
    value: 'NS'
  },
  {
    text: 'Nunavut',
    value: 'NU'
  },
  {
    text: 'Ontario',
    value: 'ON'
  },
  {
    text: 'Prince Edward Island',
    value: 'PE'
  },
  {
    text: 'Quebec',
    value: 'QC'
  },
  {
    text: 'Saskatchewan',
    value: 'SK'
  },
  {
    text: 'Yukon Territory',
    value: 'YT'
  }
]

export default {
  // User fields
  user: {
    firstName: {
      label: 'First Name',
    },
    lastName: {
      label: 'Surname'
    },
    email: {
      label: 'Email'
    },
    password: {
      label: 'Password'
    },
    availability: {
      label: 'Your working hours',
      hint: 'The times when you’d like to take Kintell bookings (in an average week).'
    },
    calendarSync: {
      label: 'Calendar Sync',
      hint: 'Kintell can auto-check your calendar for busy times to ensure you don’t get double-booked'
    },
    stripeAccount: {
      label: 'Payouts',
      hint: 'We need to know a little bit more about you to send you any earnings you make through Kintell :)'
    },
    paymentMethod: {
      label: 'Your Credit Cards',
      hint: 'Your credit card is used to pay for bookings you make with Advisors.'
    },
    payoutMethod: {
      label: 'Bank Account',
      hint: 'We need your bank account details so we can pay you any fees you earn as an Advisor.'
    },
    phone: {
      label: 'Mobile',
      placeholder: 'Your Number',
    },
    avatar: {
      label: 'Profile Photo',
      hint: 'Please upload a jpg, png, or gif.'
    },
    avatarEditing: {
      hint: 'Make yourself easy to find by setting your profile photo to your face. You can zoom and rotate below. You can position your photo by dragging on it.',
    },
    location: {
      label: 'Location',
      placeholder: 'Your city or town'
    },
    currency: {
      label: 'Currency',
      placeholder: 'Select a currency',
    },
    languagesSpoken: {
      label: 'Languages Spoken',
      placeholder: 'Add a language',
      noData: 'Oops, we can\'t find that language.',
    },
    timezone: {
      label: 'Timezone',
      placeholder: 'Select your timezone',
      noData: 'Oops, we can\'t find that timezone.'
    },
    proBonoMinutes: {
      label: 'Pro Bono Availability',
      placeholder: 'Pro Bono Time',
      hint: 'Volunteer some time every month to enhance your profile and generate new leads, while helping others.'
    },
    proBonoEnable: {
      label: 'Display Pro Bono Time?',
      hint: 'Your Kintell Cards will display the amount of Pro Bono hours you are offering per month.'
    },
    consultationCap: {
      label: 'Monthly Consultation Cap',
      placeholder: 'Monthly Consultation Cap',
      hint: 'You have the option of limiting the amount of hours you are available on Kintell (includes pro bono).',
    },
    interests: {
      placeholder: 'e.g. {skill}'
    },
    categoryInterests: {
      label: 'Your Learning Interests',
      hint: 'What are you interested in as a Learner? Let us know so we can customise your Kintell experience.',
    },
    education: {
      label: 'Education Level',
      placeholder: 'Add your education level',
      hint: 'What is your highest education level? Let us know so people can find you easier.'
    },
    socialLinks: {
      label: 'Social link to display',
      hint: 'We ask for one or more social network URL so potential learners can check you out! You can choose which ones to show when you create your Kintell Cards.'
    },
    linkedInLink: {
      label: 'LinkedIn profile',
      placeholder: 'https://www.linkedin.com/in/myname',
      hint: 'We strongly advise including your LinkedIn profile so that the Kintell team can verify your credentials.'
    },
    researchGate: {
      label: 'your-profile',
      prefix: 'www.researchgate.net/profile/'
    },
    linkedin: {
      label: 'https://www.linkedin.com/in/your-user',
    },
    github: {
      prefix: 'github.com/',
      label: 'your-github',
    },
    twitter: {
      prefix: 'twitter.com/',
      label: 'your-twitter',
    },
    instagram: {
      prefix: 'instagram.com/',
      label: 'your-instagram',
    },
    facebook: {
      label: 'https://www.facebook.com/your-facebook',
    },
    youtube: {
      label: 'https://www.youtube.com/user/your-youtube',
    },
    website: {
      label: 'Your personal site / blog',
    },
    useCalendarBookingBuffer: {
      label: '15 Minute Booking Buffer',
      hint: 'Ensure bookings I take have a 15 minute gap between them.'
    }
  },
  passwordFree: {
    firstName: {
      label: 'First Name',
    },
    lastName: {
      label: 'Last Name',
    },
    email: {
      label: 'Email',
    },
    phone: {
      label: 'Mobile',
    },
    verificationCode: {
      label: 'Your mobile verification code',
    },
    magicCode: {
      label: 'Your magic code',
    }
  },
  search: {
    keyword: {
      placeholder: 'I\'m looking for...',
      fieldHint: 'For example: Legal advice, building a website, or wine selection.',
    }
  },
  // Private kintell Card Fields
  privateCard: {
    title: {
      label: 'Title of the card'
    },
    description: {
      label: 'Description'

    }
  },
  // Kintell Card Fields
  kintellCard: {

    categories: {
      label: 'Categories',
      hint: ' The categories that apply to this card. Please select one or more.'
    },

    yearsOfExperience: {
      label: 'Years of experience in this area?',
      placeholder: 'Please select:',
      items: [
        { text: '1 to 5 years', value: '1-5', },
        { text: '5 to 10 years', value: '5-10', },
        { text: '10 to 20 years', value: '10-20', },
        { text: '20+ years', value: '20+' },
      ]
    },
    socialLinks: {
      label: 'Social links to display (optional)',
      placeholder: 'Select a social link',
      hint: 'We advise including a link to your LinkedIn profile, as this enables users to easily confirm your credentials'
    },
    title: {
      hint: 'Typically either the topic you wish to advise on, or your professional title. ',
      label: 'Title of the card',
    },
    skills: {
      label: 'Your skills',
      singular: 'skill',
      hint: 'Please add 2 to 5 skills',
      placeholder: 'Add a skill',
      fieldHint: 'These tags will be displayed on your Kintell Card and will help people find you.'
    },
    advisingIn: {
      label: 'What can you help with',
      hint: 'The broad areas in which you can provide advice or tuition.',
    },
    supportingLinks: {
      hint: 'Links to websites, articles, or videos that you feel will help to establish your credentials.',
      label: 'Supporting links'
    },
    workingHours: {
      label: 'Working hours',
      hint: 'The hours during which you’d prefer to take bookings.',
      items: [
        { value: false, text: 'Use my standard working hours' },
        { value: true, text: 'Set working hours for this card' }
      ]
    },
    repeatOnly: {
      label: 'Repeat-only',
      hint: 'You can accept bookings from new learners (default mode) or only from past learners.',
      items: [
        { value: false, text: 'New learners can book me' },
        { value: true, text: 'Only my repeat learners can book me' }
      ]
    },
    summary: {
      hint: 'Please add at least 300 characters',
      label: 'Background information',
    },
    proBono: {
      label: 'Set your Pro Bono hours',
      hint: 'How much time per month can you commit to free sessions with active Kintell members? This will enhance your profile and generate new leads, while doing good :)',
    },
    rate: {
      label: 'This card’s rate',
      hint: 'Kintell video bookings are charged per 30 minutes - it’s up to you how much you want to charge.'
    },
    workHistories: {
      label: 'Work History',
      hint: 'Up to 3 highlights from your work history, to give users an idea of your experience and achievements.'
    },
    relevantQualifications: {
      label: 'Relevant Qualifications (Optional)',
      hint: 'Any qualifications you feel are relevant given the topic of the area you’re advising in.'
    },
    bookingSlotDuration: {
      label: 'Booking Slot Durations',
      hint: 'The booking slot durations that you’d like to be available to users when making a booking:'
    },
  },
  // Work History fields
  workHistory: {
    organization: {
      label: 'Organization',
    },
    role: {
      label: 'Role',
    },
    description: {
      label: 'Description (Optional)',
      hint: '(optional)'
    },
    dates: {
      label: 'Dates',
    },
    dateRange: {
      start: {
        label: 'Start Date',
        placeholder: 'Please select Start Date'
      },
      end: {
        label: 'End Date',
        placeholder: 'Please select End Date'
      }
    }
  },
  relevantQualification: {
    qualification: {
      label: 'Qualification',
    },
    subject: {
      label: 'Subject',
    },
    organization: {
      label: 'Organization',
    },
    date: {
      label: 'Completion Date',
    }
  },
  // Supporting link fields
  supportingLink: {
    link: {
      label: 'Link',
      placeholder: 'https://'
    },
    title: {
      label: 'Title',
      placeholder: 'My Link'
    },
    description: {
      label: 'Description',
      hint: '(optional)'
    }
  },
  // Get in touch fields
  contact: {
    name: {
      label: 'Your Name',
      placeholder: 'Enter your full name'
    },
    email: {
      label: 'Your Email',
      placeholder: 'Enter your email'
    },
    phone: {
      label: 'Your Mobile',
      placeholder: 'E.g. +61 413 432 489'
    },
    message: {
      label: 'Your message',
      placeholder: 'Let us know what we can help you with.'
    },
    notes: {
      label: 'Your notes',
      placeholder: 'A few words about your organisation.'
    }
  },
  // bank fields
  payoutAccount: {
    firstName: {
      label: 'First Name',
    },
    companyName: {
      label: 'Company Name',
    },
    middleName: {
      label: 'Middle Name(s)'
    },
    lastName: {
      label: 'Surname'
    },
    taxId: {
      label: 'Company ABN',
    },
    companyPhone: {
      label: 'Company Phone Number',
      hint: 'Used for verification'
    },
    address: {
      label: 'Address 1'
    },
    address2: {
      label: 'Address 2'
    },
    city: {
      label: 'City'
    },
    ssn: {
      label: 'Social Security Number (last 4 digits)'
    },
    state: {
      label: 'State',
      AU: {
        label: 'State',
        items: [
          { text: 'NSW', value: 'NSW' },
          { text: 'VIC', value: 'VIC' },
          { text: 'QLD', value: 'QLD' },
          { text: 'WA', value: 'WA' },
          { text: 'SA', value: 'SA' },
          { text: 'TAS', value: 'TAS' },
          { text: 'ACT', value: 'ACT' },
          { text: 'NT', value: 'NT' },
        ]
      },
      CA: {
        label: 'State',
        items: canadaStates
      },
      US: {
        label: 'State',
        items: usStates
      }
    },
    zip: {
      label: 'Post Code'
    },
  },

  bank: {
    accountHolderName: {
      label: 'Account Holder Name',
      hint: 'Please enter your {legalName} exactly as it appears on your bank statement. This information will not change your display name on Kintell Platform and it’s purely for processing payments to you.',
    },
    bsbNumber: {
      label: 'BSB Number'
    },
    accountNumber: {
      label: 'Account Number'
    },
  },

  country: {
    label: 'Country',
    items: [
      {
        text: 'Afghanistan',
        value: 'AF'
      },
      {
        text: 'Albania',
        value: 'AL'
      },
      {
        text: 'Algeria',
        value: 'DZ'
      },
      {
        text: 'American Samoa',
        value: 'AS'
      },
      {
        text: 'Andorra',
        value: 'AD'
      },
      {
        text: 'Angola',
        value: 'AO'
      },
      {
        text: 'Anguilla',
        value: 'AI'
      },
      {
        text: 'Antarctica',
        value: 'AQ'
      },
      {
        text: 'Antigua and Barbuda',
        value: 'AG'
      },
      {
        text: 'Argentina',
        value: 'AR'
      },
      {
        text: 'Armenia',
        value: 'AM'
      },
      {
        text: 'Aruba',
        value: 'AW'
      },
      {
        text: 'Australia',
        value: 'AU'
      },
      {
        text: 'Austria',
        value: 'AT'
      },
      {
        text: 'Azerbaijan',
        value: 'AZ'
      },
      {
        text: 'Bahamas',
        value: 'BS'
      },
      {
        text: 'Bahrain',
        value: 'BH'
      },
      {
        text: 'Bangladesh',
        value: 'BD'
      },
      {
        text: 'Barbados',
        value: 'BB'
      },
      {
        text: 'Belarus',
        value: 'BY'
      },
      {
        text: 'Belgium',
        value: 'BE'
      },
      {
        text: 'Belize',
        value: 'BZ'
      },
      {
        text: 'Benin',
        value: 'BJ'
      },
      {
        text: 'Bermuda',
        value: 'BM'
      },
      {
        text: 'Bhutan',
        value: 'BT'
      },
      {
        text: 'Bolivia',
        value: 'BO'
      },
      {
        text: 'Bosnia and Herzegovina',
        value: 'BA'
      },
      {
        text: 'Botswana',
        value: 'BW'
      },
      {
        text: 'Bouvet Island',
        value: 'BV'
      },
      {
        text: 'Brazil',
        value: 'BR'
      },
      {
        text: 'British Indian Ocean Territory',
        value: 'IO'
      },
      {
        text: 'Brunei',
        value: 'BN'
      },
      {
        text: 'Bulgaria',
        value: 'BG'
      },
      {
        text: 'Burkina Faso',
        value: 'BF'
      },
      {
        text: 'Burundi',
        value: 'BI'
      },
      {
        text: 'Cambodia',
        value: 'KH'
      },
      {
        text: 'Cameroon',
        value: 'CM'
      },
      {
        text: 'Canada',
        value: 'CA'
      },
      {
        text: 'Cape Verde',
        value: 'CV'
      },
      {
        text: 'Cayman Islands',
        value: 'KY'
      },
      {
        text: 'Central African Republic',
        value: 'CF'
      },
      {
        text: 'Chad',
        value: 'TD'
      },
      {
        text: 'Chile',
        value: 'CL'
      },
      {
        text: 'China',
        value: 'CN'
      },
      {
        text: 'Christmas Island',
        value: 'CX'
      },
      {
        text: 'Cocos (Keeling) Islands',
        value: 'CC'
      },
      {
        text: 'Colombia',
        value: 'CO'
      },
      {
        text: 'Comoros',
        value: 'KM'
      },
      {
        text: 'Congo',
        value: 'CG'
      },
      {
        text: 'Cook Islands',
        value: 'CK'
      },
      {
        text: 'Costa Rica',
        value: 'CR'
      },
      {
        text: 'Croatia',
        value: 'HR'
      },
      {
        text: 'Cuba',
        value: 'CU'
      },
      {
        text: 'Cyprus',
        value: 'CY'
      },
      {
        text: 'Czech Republic',
        value: 'CZ'
      },
      {
        text: 'Denmark',
        value: 'DK'
      },
      {
        text: 'Djibouti',
        value: 'DJ'
      },
      {
        text: 'Dominica',
        value: 'DM'
      },
      {
        text: 'Dominican Republic',
        value: 'DO'
      },
      {
        text: 'East Timor',
        value: 'TP'
      },
      {
        text: 'Ecuador',
        value: 'EC'
      },
      {
        text: 'Egypt',
        value: 'EG'
      },
      {
        text: 'El Salvador',
        value: 'SV'
      },
      {
        text: 'Equatorial Guinea',
        value: 'GQ'
      },
      {
        text: 'Eritrea',
        value: 'ER'
      },
      {
        text: 'Estonia',
        value: 'EE'
      },
      {
        text: 'Ethiopia',
        value: 'ET'
      },
      {
        text: 'Falkland Islands',
        value: 'FK'
      },
      {
        text: 'Faroe Islands',
        value: 'FO'
      },
      {
        text: 'Fiji Islands',
        value: 'FJ'
      },
      {
        text: 'Finland',
        value: 'FI'
      },
      {
        text: 'France',
        value: 'FR'
      },
      {
        text: 'French Guiana',
        value: 'GF'
      },
      {
        text: 'French Polynesia',
        value: 'PF'
      },
      {
        text: 'French Southern territories',
        value: 'TF'
      },
      {
        text: 'Gabon',
        value: 'GA'
      },
      {
        text: 'Gambia',
        value: 'GM'
      },
      {
        text: 'Georgia',
        value: 'GE'
      },
      {
        text: 'Germany',
        value: 'DE'
      },
      {
        text: 'Ghana',
        value: 'GH'
      },
      {
        text: 'Gibraltar',
        value: 'GI'
      },
      {
        text: 'Greece',
        value: 'GR'
      },
      {
        text: 'Greenland',
        value: 'GL'
      },
      {
        text: 'Grenada',
        value: 'GD'
      },
      {
        text: 'Guadeloupe',
        value: 'GP'
      },
      {
        text: 'Guam',
        value: 'GU'
      },
      {
        text: 'Guatemala',
        value: 'GT'
      },
      {
        text: 'Guernsey',
        value: 'GG'
      },
      {
        text: 'Guinea',
        value: 'GN'
      },
      {
        text: 'Guinea-Bissau',
        value: 'GW'
      },
      {
        text: 'Guyana',
        value: 'GY'
      },
      {
        text: 'Haiti',
        value: 'HT'
      },
      {
        text: 'Heard Island and McDonald Islands',
        value: 'HM'
      },
      {
        text: 'Holy See (Vatican City State)',
        value: 'VA'
      },
      {
        text: 'Honduras',
        value: 'HN'
      },
      {
        text: 'Hong Kong',
        value: 'HK'
      },
      {
        text: 'Hungary',
        value: 'HU'
      },
      {
        text: 'Iceland',
        value: 'IS'
      },
      {
        text: 'India',
        value: 'IN'
      },
      {
        text: 'Indonesia',
        value: 'ID'
      },
      {
        text: 'Iran',
        value: 'IR'
      },
      {
        text: 'Iraq',
        value: 'IQ'
      },
      {
        text: 'Ireland',
        value: 'IE'
      },
      {
        text: 'Isle of Man',
        value: 'IM'
      },
      {
        text: 'Israel',
        value: 'IL'
      },
      {
        text: 'Italy',
        value: 'IT'
      },
      {
        text: 'Ivory Coast',
        value: 'CI'
      },
      {
        text: 'Jamaica',
        value: 'JM'
      },
      {
        text: 'Japan',
        value: 'JP'
      },
      {
        text: 'Jersey',
        value: 'JE'
      },
      {
        text: 'Jordan',
        value: 'JO'
      },
      {
        text: 'Kazakhstan',
        value: 'KZ'
      },
      {
        text: 'Kenya',
        value: 'KE'
      },
      {
        text: 'Kiribati',
        value: 'KI'
      },
      {
        text: 'Kuwait',
        value: 'KW'
      },
      {
        text: 'Kyrgyzstan',
        value: 'KG'
      },
      {
        text: 'Laos',
        value: 'LA'
      },
      {
        text: 'Latvia',
        value: 'LV'
      },
      {
        text: 'Lebanon',
        value: 'LB'
      },
      {
        text: 'Lesotho',
        value: 'LS'
      },
      {
        text: 'Liberia',
        value: 'LR'
      },
      {
        text: 'Libyan Arab Jamahiriya',
        value: 'LY'
      },
      {
        text: 'Liechtenstein',
        value: 'LI'
      },
      {
        text: 'Lithuania',
        value: 'LT'
      },
      {
        text: 'Luxembourg',
        value: 'LU'
      },
      {
        text: 'Macao',
        value: 'MO'
      },
      {
        text: 'North Macedonia',
        value: 'MK'
      },
      {
        text: 'Madagascar',
        value: 'MG'
      },
      {
        text: 'Malawi',
        value: 'MW'
      },
      {
        text: 'Malaysia',
        value: 'MY'
      },
      {
        text: 'Maldives',
        value: 'MV'
      },
      {
        text: 'Mali',
        value: 'ML'
      },
      {
        text: 'Malta',
        value: 'MT'
      },
      {
        text: 'Marshall Islands',
        value: 'MH'
      },
      {
        text: 'Martinique',
        value: 'MQ'
      },
      {
        text: 'Mauritania',
        value: 'MR'
      },
      {
        text: 'Mauritius',
        value: 'MU'
      },
      {
        text: 'Mayotte',
        value: 'YT'
      },
      {
        text: 'Mexico',
        value: 'MX'
      },
      {
        text: 'Micronesia, Federated States of',
        value: 'FM'
      },
      {
        text: 'Moldova',
        value: 'MD'
      },
      {
        text: 'Monaco',
        value: 'MC'
      },
      {
        text: 'Mongolia',
        value: 'MN'
      },
      {
        text: 'Montenegro',
        value: 'ME'
      },
      {
        text: 'Montserrat',
        value: 'MS'
      },
      {
        text: 'Morocco',
        value: 'MA'
      },
      {
        text: 'Mozambique',
        value: 'MZ'
      },
      {
        text: 'Myanmar',
        value: 'MM'
      },
      {
        text: 'Namibia',
        value: 'NA'
      },
      {
        text: 'Nauru',
        value: 'NR'
      },
      {
        text: 'Nepal',
        value: 'NP'
      },
      {
        text: 'Netherlands',
        value: 'NL'
      },
      {
        text: 'Netherlands Antilles',
        value: 'AN'
      },
      {
        text: 'New Caledonia',
        value: 'NC'
      },
      {
        text: 'New Zealand',
        value: 'NZ'
      },
      {
        text: 'Nicaragua',
        value: 'NI'
      },
      {
        text: 'Niger',
        value: 'NE'
      },
      {
        text: 'Nigeria',
        value: 'NG'
      },
      {
        text: 'Niue',
        value: 'NU'
      },
      {
        text: 'Norfolk Island',
        value: 'NF'
      },
      {
        text: 'North Korea',
        value: 'KP'
      },
      {
        text: 'Northern Ireland',
        value: 'GB2'
      },
      {
        text: 'Northern Mariana Islands',
        value: 'MP'
      },
      {
        text: 'Norway',
        value: 'NO'
      },
      {
        text: 'Oman',
        value: 'OM'
      },
      {
        text: 'Pakistan',
        value: 'PK'
      },
      {
        text: 'Palau',
        value: 'PW'
      },
      {
        text: 'Palestine',
        value: 'PS'
      },
      {
        text: 'Panama',
        value: 'PA'
      },
      {
        text: 'Papua New Guinea',
        value: 'PG'
      },
      {
        text: 'Paraguay',
        value: 'PY'
      },
      {
        text: 'Peru',
        value: 'PE'
      },
      {
        text: 'Philippines',
        value: 'PH'
      },
      {
        text: 'Pitcairn',
        value: 'PN'
      },
      {
        text: 'Poland',
        value: 'PL'
      },
      {
        text: 'Portugal',
        value: 'PT'
      },
      {
        text: 'Puerto Rico',
        value: 'PR'
      },
      {
        text: 'Qatar',
        value: 'QA'
      },
      {
        text: 'Reunion',
        value: 'RE'
      },
      {
        text: 'Romania',
        value: 'RO'
      },
      {
        text: 'Russian Federation',
        value: 'RU'
      },
      {
        text: 'Rwanda',
        value: 'RW'
      },
      {
        text: 'Saint Helena',
        value: 'SH'
      },
      {
        text: 'Saint Kitts and Nevis',
        value: 'KN'
      },
      {
        text: 'Saint Lucia',
        value: 'LC'
      },
      {
        text: 'Saint Pierre and Miquelon',
        value: 'PM'
      },
      {
        text: 'Saint Vincent and the Grenadines',
        value: 'VC'
      },
      {
        text: 'Samoa',
        value: 'WS'
      },
      {
        text: 'San Marino',
        value: 'SM'
      },
      {
        text: 'Sao Tome and Principe',
        value: 'ST'
      },
      {
        text: 'Saudi Arabia',
        value: 'SA'
      },
      {
        text: 'Senegal',
        value: 'SN'
      },
      {
        text: 'Serbia',
        value: 'RS'
      },
      {
        text: 'Seychelles',
        value: 'SC'
      },
      {
        text: 'Sierra Leone',
        value: 'SL'
      },
      {
        text: 'Singapore',
        value: 'SG'
      },
      {
        text: 'Slovakia',
        value: 'SK'
      },
      {
        text: 'Slovenia',
        value: 'SI'
      },
      {
        text: 'Solomon Islands',
        value: 'SB'
      },
      {
        text: 'Somalia',
        value: 'SO'
      },
      {
        text: 'South Africa',
        value: 'ZA'
      },
      {
        text: 'South Georgia and the South Sandwich Islands',
        value: 'GS'
      },
      {
        text: 'South Korea',
        value: 'KR'
      },
      {
        text: 'South Sudan',
        value: 'SS'
      },
      {
        text: 'Spain',
        value: 'ES'
      },
      {
        text: 'Sri Lanka',
        value: 'LK'
      },
      {
        text: 'Sudan',
        value: 'SD'
      },
      {
        text: 'Suriname',
        value: 'SR'
      },
      {
        text: 'Svalbard and Jan Mayen',
        value: 'SJ'
      },
      {
        text: 'Swaziland',
        value: 'SZ'
      },
      {
        text: 'Sweden',
        value: 'SE'
      },
      {
        text: 'Switzerland',
        value: 'CH'
      },
      {
        text: 'Syria',
        value: 'SY'
      },
      {
        text: 'Tajikistan',
        value: 'TJ'
      },
      {
        text: 'Tanzania',
        value: 'TZ'
      },
      {
        text: 'Thailand',
        value: 'TH'
      },
      {
        text: 'The Democratic Republic of Congo',
        value: 'CD'
      },
      {
        text: 'Timor-Leste',
        value: 'TL'
      },
      {
        text: 'Togo',
        value: 'TG'
      },
      {
        text: 'Tokelau',
        value: 'TK'
      },
      {
        text: 'Tonga',
        value: 'TO'
      },
      {
        text: 'Trinidad and Tobago',
        value: 'TT'
      },
      {
        text: 'Tunisia',
        value: 'TN'
      },
      {
        text: 'Turkey',
        value: 'TR'
      },
      {
        text: 'Turkmenistan',
        value: 'TM'
      },
      {
        text: 'Turks and Caicos Islands',
        value: 'TC'
      },
      {
        text: 'Tuvalu',
        value: 'TV'
      },
      {
        text: 'Uganda',
        value: 'UG'
      },
      {
        text: 'Ukraine',
        value: 'UA'
      },
      {
        text: 'United Arab Emirates',
        value: 'AE'
      },
      {
        text: 'United Kingdom',
        value: 'GB'
      },
      {
        text: 'United States',
        value: 'US'
      },
      {
        text: 'United States Minor Outlying Islands',
        value: 'UM'
      },
      {
        text: 'Uruguay',
        value: 'UY'
      },
      {
        text: 'Uzbekistan',
        value: 'UZ'
      },
      {
        text: 'Vanuatu',
        value: 'VU'
      },
      {
        text: 'Venezuela',
        value: 'VE'
      },
      {
        text: 'Vietnam',
        value: 'VN'
      },
      {
        text: 'Virgin Islands, British',
        value: 'VG'
      },
      {
        text: 'Virgin Islands, U.S.',
        value: 'VI'
      },
      {
        text: 'Wallis and Futuna',
        value: 'WF'
      },
      {
        text: 'Western Sahara',
        value: 'EH'
      },
      {
        text: 'Yemen',
        value: 'YE'
      },
      {
        text: 'Yugoslavia',
        value: 'YU'
      },
      {
        text: 'Zambia',
        value: 'ZM'
      },
      {
        text: 'Zimbabwe',
        value: 'ZW'
      }
    ]
  },

  // card fields
  card: {
    nameOnCard: {
      label: 'Name On Card'
    },
    address: {
      label: 'Address line 1'
    },
    address2: {
      label: 'Address line 2'
    },
    city: {
      label: 'City'
    },
    state: {
      label: 'State',
      items: [
        { text: 'NSW', value: 'NSW' },
        { text: 'VIC', value: 'VIC' },
        { text: 'QLD', value: 'QLD' },
        { text: 'WA', value: 'WA' },
        { text: 'SA', value: 'SA' },
        { text: 'TAS', value: 'TAS' },
        { text: 'ACT', value: 'ACT' },
        { text: 'NT', value: 'NT' },
      ]
    },
    zip: {
      label: 'Postal Code'
    },
    cardNumber: {
      label: 'Card Number'
    },
    expiration: {
      label: 'Expiration'
    },
    cvc: {
      label: 'CVC'
    }
  },
  bookingArchive: {
    role: {
      placeholder: 'Learner, Advisor, or Admin',
      items: [
        { text: 'Advisor', value: 'Advising' },
        { text: 'Learner', value: 'Learning' },
        { text: 'Admin', value: 'Admin' },
      ]
    },
    recorded: {
      label: 'Recorded',
    },
    status: {
      placeholder: 'All booking status',
      items: [
        { text: 'Completed', value: 'completed' },
        { text: 'Request Expired', value: 'provisional-request-expired' },
        { text: 'Cancelled Request', value: 'cancelled-provisional' },
        { text: 'Cancelled Confirmed', value: 'cancelled-confirmed' },
        { text: 'Both No Show', value: 'expired-both-no-show' },
        { text: 'Advisor No Show', value: 'expired-advisor-no-show' },
        { text: 'Learner No Show', value: 'expired-learner-no-show' },
      ]
    },
    type: {
      placeholder: 'Instant or Scheduled',
      items: [
        { text: 'Instant meetings', value: 'instant' },
        { text: 'Scheduled bookings', value: 'scheduled' },
      ]
    },
  },
  booking: {
    useProbonoCredit: {
      label: 'Pro Bono:',
    },
    proBono: {
      label: 'Use my Pro Bono credit for the first 30 minutes of this booking'
    },
    duration: {
      label: 'Video meeting duration:',
      placeholder: 'Select a duration',
    },
    requestExpiresAtUtc: {
      label: 'Expires In:',
      hint: 'They will have this long to respond to your request before it expires.',
      items: [
        { text: '24 hours', value: 24 },
        { text: '48 hours', value: 48 },
        { text: '3 days', value: 72 },
        { text: '4 days', value: 96 },
      ]
    },
    discussion: {
      label: 'What would you like to discuss with {advisor}?',
      placeholder: 'e.g. Developing a simple business website with WordPress'
    },
    reschedule: {
      placeholder: 'Hi {name}, sorry for the inconvenience, but a last-minute meeting came up. One of these times will definitely work for me. Thanks!'
    },
    preferredTimes: {
      label: 'Select your preferred times:',
      hint: 'The times are displayed in your timezone ({timezone})'
    },
  },
  recording: {
    sharePrivate: {
      label: 'Only you can view and download',
    },
    shareView: {
      label: 'Anyone with the link can view (they can’t download)',
    },
    shareDownload: {
      label: 'Anyone with the link can view and download',
    },
    sharePasswordProtected: {
      label: 'Anyone with the link and password can view - coming soon',
    },
  },
  cancelBooking: {
    message: {
      placeholder: 'Hi {name}, sorry for the inconvenience. If you could please send another booking request for next week, that will definitely work. Thanks!'
    }
  },
  initiateBooking: {
    firstName: {
      label: 'First name of the person you\'d like to invite:'
    },
    kintellCard: {
      label: 'Card to use:',
      placeholder: 'Select a Kintell card'
    },
    duration: {
      label: 'Video meeting duration:'
    },
    topic: {
      label: 'Intro message:',
      placeholder: 'Hi there, here is an invitation for a video session on Kintell.'
    },
    suggestTimes: {
      label: 'Select times to suggest:',
      hint: 'The times are displayed in your timezone ({timezone})'
    },
    email: {
      label: '(Optional) Share via email:',
      hint: 'You can share this booking by email or by a share link'
    },
    inviteeName: {
      label: 'Invitee',
      hint: 'The first name of the person you’d like to invite.',
      placeholder: 'Type a first name here'
    },
    message: {
      label: 'Message',
      placeholder: 'Hi there, here is an invitation for a video session on Kintell.'
    },
    dateTime: {
      label: 'Date & Time'
    },
    meetingDuration: {
      label: 'Meeting duration'
    },
    selectCard: {
      label: 'Kintell Card',
      hint: 'Select your {linkCard}. Charge for your time or make it free!'
    },
    rate: {
      label: 'Rate'
    },
  },
  facilitateBooking: {
    selectGroup: {
      label: 'Select a group',
      placeholder: 'Please select'
    },
    selectAdvisor: {
      label: 'Select an advisor',
      hint: 'Choose the card of the group advisor who this booking is for',
      placeholder: 'Please select'
    },
    firstName: {
      label: 'First name of the person you\'d like to invite:'
    },
    email: {
      label: '(Optional) Share via email:',
    },
    duration: {
      label: 'Video meeting duration'
    },
    topic: {
      label: 'Intro message'
    }
  },
  suggestMessage: {
    label: 'Leave a message for {name}',
  },
  review: {
    rating: {
      label: 'How many stars would you give {name}?',
    },
    callQualityRating: {
      label: 'How was the video call quality?',
      hint: 'We love feedback, this helps us to improve Kintell as fast as possible :)'
    },
    stars: {
      label: 'How many stars would you give {name}?',
    },
    commentAdvisor: {
      label: 'You can say a few words as well (optional):',
      placeholder: 'E.g. I learnt heaps from {name}. Very knowledgeable. Highly recommended. Thanks {name}!',
    },
    commentLearner: {
      label: 'You can say a few words as well (optional):',
      placeholder: 'E.g. I enjoyed my booking with {name}, they were keen to learn and raised some interesting points.',
    },
    callQualityComment: {
      label: 'Please give us any feedback you may have about your call experience.',
      placeholder: 'E.g. After 10 minutes we had issues hearing each other'
    }
  },
  demo: {
    input: {
      label: 'i18n Key',
      placeholder: 'Focus me',
      hint: 'i18n-key is a special prop which loads the label + hint + placeholder from en.js! All inputs should be using this to have consistent labels ' +
        'among different components and to allow for easier multilingual support. ' +
        'Note: The i18n-key path is found as `fields.<key>.<value>`',
    },
    grey: {
      label: 'Grey',
      placeholder: 'I have a grey background',
      hint: 'Grey prop is an alternative input style which all fields support. It colours the background a grey instead of white',
    },
    sideHint: {
      label: 'Side Hint',
      placeholder: 'Look right',
      hint: 'Side hints will split the field and hint into two columns and have the hint on the right.'
    },
    topHint: {
      label: 'Top Hint',
      placeholder: 'Look above',
      hint: 'Top hints are the same as side hints but will display the hint above the field'
    },
    bottomHint: {
      label: 'Bottom Hint',
      placeholder: 'Look below',
      hint: 'Bottom hints are the same as top hints but will display the hint below the field'
    },
    fieldHint: {
      label: 'Field Hint',
      placeholder: 'Look below',
      fieldHint: 'Unlike a regular hint, this is displayed without the info icon and is under the field',
    },
    inlineHint: {
      label: 'Inline Hint',
      placeholder: 'Look above',
      hint: '<- Small hint next to the label'
    },
    largeLabel: {
      label: 'Large Label',
      placeholder: 'Woah so big',
      hint: 'Or Conversational labels are bigger labels used for forms with more oomph.'
    }
  },
  groupMembers: {
    search: {
      placeholder: 'Search',
    },
    filters: {
      currentRoleForConsultingVisible: {
        placeholder: 'All Roles, Admin, Advisor, Learner, Drafting or Awaiting Review',
        label: 'Current Role',
      },
      currentRoleForCommunityVisible: {
        placeholder: 'Admin, Advisor, Drafting or Awaiting Review, Learner',
        label: 'Current Role',
      },
      roleWhenJoined: {
        placeholder: 'All Roles, Learner, Advisor, Learner or Advisor',
        label: 'Role When Joined',
        items: [
          { text: 'All Roles', value: 'all' },
          { text: 'Learner', value: 'Learner' },
          { text: 'Advisor', value: 'Advisor' },
          { text: 'Learner or Advisor', value: 'Advisor and Learner' },
        ]
      },
      activityType: {
        placeholder: 'InMail, Log-in, Booking',
        label: 'Activity Type',
        items: [
          { text: 'InMail', value: 'inMail' },
          { text: 'Log-in', value: 'login' },
          { text: 'Booking', value: 'booking' },
        ],
        inMail: {
          when: {
            placeholder: 'All times, Last 4 weeks, Last 7 days, Last 24 hours',
            label: 'When',
            items: [
              { text: 'All times', value: null },
              { text: 'Last 4 weeks', value: 'lastFourWeeks' },
              { text: 'Last 7 days', value: 'lastSevenDays' },
              { text: 'Last 24 hours', value: 'lastTwentyFourHours' },
            ]
          },
          howMany: {
            placeholder: 'Any, 4+, 2-3, 1, None',
            label: 'How Many',
            items: [
              { text: 'Any', value: null },
              { text: '4+', value: 'fourPlus' },
              { text: '2-3', value: 'twoToThree' },
              { text: '1', value: 'one' },
              { text: 'None', value: 'none' },
            ]
          },
          inMailStatus: {
            placeholder: 'All, Sent, Opened, Clicked, Bounced, Unsubscribed',
            label: 'InMail Status',
            items: [
              { text: 'All', value: null },
              { text: 'Sent', value: 'sent' },
              { text: 'Opened', value: 'opened' },
              { text: 'Clicked', value: 'clicked' },
              { text: 'Bounced', value: 'bounced' },
              { text: 'Unsubscribed', value: 'unsubscribed' },
            ]
          }
        },
        login: {
          when: {
            placeholder: 'All times, Last 4 weeks, Last 7 days, Last 24 hours',
            label: 'When',
            items: [
              { text: 'All times', value: null },
              { text: 'Last 4 weeks', value: 'lastFourWeeks' },
              { text: 'Last 7 days', value: 'lastSevenDays' },
              { text: 'Last 24 hours', value: 'lastTwentyFourHours' },
            ]
          },
          activity: {
            placeholder: 'Any, Viewed profile(s), Watched video(s), Li...',
            label: 'Logged-in activity details',
            items: [
              { text: 'Any', value: null },
              { text: 'Viewed profile(s)', value: 'kgCard' },
              { text: 'Watched video(s)', value: 'kgChannel' },
              { text: 'LinkedIn Conversion(s)', value: 'kgExitCardLinkedin' },
            ]
          }
        },
        booking: {
          hoursAgo: {
            placeholder: 'All times, Last 4 weeks, Last 7 days, Last 24 hours',
            label: 'When',
            items: [
              { text: 'All times', value: null },
              { text: 'Last 4 weeks', value: 672 },
              { text: 'Last 7 days', value: 168 },
              { text: 'Last 24 hours', value: 24 },
            ]
          },
          howMany: {
            placeholder: 'Any, 4+, 2-3, 1, None',
            label: 'How Many',
            items: [
              { text: 'Any', value: '1+' },
              { text: '4+', value: '4+' },
              { text: '2-3', value: '2,3' },
              { text: '1', value: '1' },
              { text: 'None', value: '0' },
            ]
          },
          role: {
            placeholder: 'All Roles, Learner, Advisor',
            label: 'Booking Role',
            items: [
              { text: 'All Roles', value: 'learner,advisor' },
              { text: 'Learner', value: 'learner' },
              { text: 'Advisor', value: 'advisor' },
            ]
          },
          status: {
            placeholder: 'All, Confirmed/Completed, Expired, Learner no show, Advisor no show, Both no show',
            label: 'Booking Status',
            items: [
              { text: 'All', value: null },
              { text: 'Confirmed/Completed', value: 'confirmed,completed' },
              { text: 'Expired', value: 'provisional-request-expired' },
              { text: 'Learner no show', value: 'expired-learner-no-show' },
              { text: 'Advisor no show', value: 'expired-advisor-no-show' },
              { text: 'Both no show', value: 'expired-both-no-show' },
              { text: 'Cancelled', value: 'cancelled-provisional,cancelled-confirmed' },
            ]
          }
        }
      },
    },
    sortByCommunity: {
      placeholder: 'Sort by role, joined at, approved at or bookings',
      label: 'Please Select:',
      items: [
        { text: 'Sort by joined at', value: 'kg_joined_at' },
        { text: 'Sort by approved at', value: 'kg_approved_at' },
        { text: 'Sort by bookings', value: 'kg_bookings_count' },
      ]
    },
    sortByConsulting: {
      placeholder: 'Sort by joined at',
      label: 'Please Select:',
      items: [
        { text: 'Sort by joined at', value: 'kg_joined_at' },
      ]
    }
  },
  guestLoginModal: {
    firstName: {
      label: 'First Name',
    },
  },
  groupAdmin: {
    groupRejectCard: {
      placeholder: 'Hi {user},\n\n' +
      'Thank you for joining the \'{kintellGroup}\' group and submitting your Group Card for review. It is great to read about what you would like to offer to others. However, because this is a professional group, we would like to see more details about your work experience, either in the Background Information section or as Supporting Links. \n\n' +
      'Once you have revised your card, please click "Publish" again and it will come to me for review. I appericate your time and effort!\n\n' +
      'Best regards, \n' + '{admin} \n' + '{kintellGroup} \n' + '{adminEmailAddress}'
    }
  },
  groupInMail: {
    subjects: {
      default: `You have an InMail from '{groupName}' team`,
      [`ai-matching`]: '[Action please] You’re matched with a mentor in {groupName} program'
    },
    messages: {
      default: 'Thank you so much for joining {groupName} and being such a wonderful member.\n\nPlease feel free to let me know if you have any feedback or questions about {groupName} as we are constantly looking for ways to serve our members better.\n\nWarm regards,\n{sender}\n{groupName}\n{senderEmail}',
      unverified: 'Thank you for checking out {groupName}! If you are associated with us, please feel free to go ahead and join us by completing your registration by visiting this link:\n\n{joinLink}\n\nYou will have access to wonderful connections and content once we verify your details! If you are not associated with us and are just curious about our programs, thank you for dropping by - curiosity is what makes our world wonderful :)\n\nIf you have any questions, comments, feedback please don\'t hesitate to email me. I’d always love to hear from you! Thanks!.\n\nKind regards,\n{sender}\n{groupName}\n{senderEmail}',
      learner: 'We’re thrilled that you have joined {groupName}! Please browse the interesting content we have, or simply connect with other members by booking a video chat with them.\n\nIf you would also like to share your much-valued knowledge with the community, please go ahead and publish your own profile (‘group card’) in the member directory. It will take you only 10 min after you click here to start:\n\n{cardLink}\n\nIf you have any questions, comments, feedback please don\'t hesitate to email me. I’d always love to hear from you! Thanks!\n\nKind regards,\n{sender}\n{groupName}\n{senderEmail}',
      advisor: 'I can’t thank you enough for sharing your knowledge with our members! I am reaching out to see if there is anything we can do to improve the offerings of {groupName}. If you don’t mind, I’d also love to book a quick chat with you to learn more about your experience and expectations for this platform.\n\nMeanwhile, if you have any questions, comments, feedback please don\'t hesitate to email me. Thank you so much!\n\nKind regards,\n{sender}\n{groupName}\n{senderEmail}',
      [`awaiting-review`]: 'Thank you so much for sending your profile (“group card”) for publishing in {groupName}.\n\nI am currently reviewing your card - which is looking great! I am wondering, however, if you could please add more information about your work experience to the profile. You can add it in the ‘Background Information’ or as ‘Work History’.\n\nOnce it is done, please let me know and I will make your profile live :)\n\nIf you have any questions, comments, feedback please don\'t hesitate to email me. I am always here to help! Thanks!\n\nKind regards,\n{sender}\n{groupName}\n{senderEmail}',
      [`awaiting-verification`]: 'Thank you for joining {groupName}! I would love to provide you access as soon as possible to the group but I will still need your LinkedIn URL to be able to verify your identity. Can you please send it to my email address below? Thank you!\n\nKind regards,\n{sender}\n{groupName}\n{senderEmail}',
      [`ai-matching`]: 'Welcome to {groupName} mentoring program powered by Kintell.\n\nBased on your learning interests, we have matched you with a list of our amazing mentors. Please check the link below to book your mentoring sessions.\n\nIf you have any questions or feedback, please feel free to let me know anytime. Many thanks!\n\nWarm regards,\n{sender}\n{groupName}\n{senderEmail}',
      drafting: 'We\'re thrilled to have you as our much-cherished member in {groupName}!\n\nYou are now just one step away from publishing your profile to make it visible to other members, who can then book a video chat to learn from you - how exciting!\n\nPlease visit the link below to check out your wonderful profile (\'group card\') and click \'Publish\' when you\'re happy:\n\n{cardLink}\n\nYou can always come back to edit it, change your availability or unpublish it  :)\n\nIf you have any questions or feedback, please don\'t hesitate to email me at {senderEmail}. I’d always love to hear from you! Thanks!\n\nKind regards,\n{sender}\n{groupName}\n{senderEmail}',
    }
  }
}
