export default {
  faq: [
    {
      q: 'How does it work?',
      a: 'When making a booking, you’ll be asked if you have a voucher code - enter the code from your gift card to redeem your credits.',
    },
    {
      q: 'How do I buy a Kintell voucher?',
      a: 'If you’d like to share the gift of knowledge with a friend or a loved one, please {getInTouch}, and we’ll sort you out :)',
    },
    {
      q: 'Who can use Kintell gift cards?',
      a: 'Anyone can receive and use a gift card, you just have to sign up to Kintell!',
    },
    {
      q: 'What gift card amounts are there?',
      a: 'There are three gift card amounts available, $25, $50 and $100.',
    },
    {
      q: 'Can I save it for later?',
      a: 'Absolutely! If you go to your Kintell Credit page, you can apply the gift card there and save the amount for when it suits you.',
    },
    {
      q: 'Do I need to use the whole amount for a single booking?',
      a: 'If your booking costs less than the amount of your gift card, the remaining balance will stay in your Kintell account!',
    },
    {
      q: 'When will my gift card expire?',
      a: 'The expiry date is indicated on your gift card, if you have any questions please {getInTouch} :)',
    },
    {
      q: 'Can I use more than one gift card on my account?',
      a: 'You can use as many gift cards as you like! Your Kintell credit will increase with each gift card you add to your account.',
    },
    {
      q: 'Can I book anyone with my Kintell gift cards?',
      a: 'You can book any of our Advisors in supported countries. Check out our {faqAdminFee} to see the list.',
    },
    {
      q: 'Why doesn’t my gift card work?',
      a: 'If your voucher code isn’t working, {getInTouch} with our support team and they’ll get to the bottom of it swiftly.'
    },
    {
      q: 'What happens if the gift card isn’t enough to pay for the entire Kintell session?',
      a: 'If the gift card doesn’t cover the entire booking, you can pay the remaining amount with your credit card. When you make your booking, you’ll be asked to add your payment details, and the gift card amount will be subtracted from the fee.',
    },
  ]
}
