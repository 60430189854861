export default {
  width: 50,
  height: 50,
  stroke: true,
  fill: false,
  svg: `
<path d="M10.4141 26.0423L18.7474 2.08398C18.7474 2.08398 23.9557 2.08398 23.9557 8.33398V19.7923H42.7057C43.5011 19.788 44.2809 20.0126 44.952 20.4394C45.6232 20.8661 46.1574 21.477 46.491 22.199C46.8245 22.9211 46.9432 23.7238 46.833 24.5115C46.7227 25.2992 46.3882 26.0385 45.8693 26.6413C46.5526 27.0419 47.1067 27.6303 47.4655 28.3365C47.8244 29.0427 47.9731 29.837 47.8939 30.6252C47.8147 31.4134 47.511 32.1623 47.0188 32.7829C46.5266 33.4036 45.8666 33.87 45.1172 34.1267C45.487 34.6729 45.7214 35.2993 45.8008 35.9541C45.8802 36.609 45.8023 37.2733 45.5737 37.892C45.3451 38.5107 44.9723 39.066 44.4862 39.5119C44.0001 39.9578 43.4148 40.2814 42.7786 40.4559C43.2307 40.886 43.5433 41.4418 43.6761 42.0515C43.8089 42.6612 43.7558 43.2967 43.5236 43.8758C43.2914 44.455 42.8908 44.9512 42.3736 45.3003C41.8564 45.6494 41.2464 45.8353 40.6224 45.834H23.9557C19.2522 45.9351 14.5977 44.861 10.4141 42.709" fill="transparent" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.33203 21.875H4.16536C3.01477 21.875 2.08203 22.8077 2.08203 23.9583V42.7083C2.08203 43.8589 3.01477 44.7917 4.16536 44.7917H8.33203C9.48262 44.7917 10.4154 43.8589 10.4154 42.7083V23.9583C10.4154 22.8077 9.48262 21.875 8.33203 21.875Z" fill="transparent" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
`
}
