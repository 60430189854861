export default {
  width: 32,
  height: 32,
  stroke: true,
  fill: false,
  svg: `
    <g clip-path="url(#icon-mute)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.097 4.417c-1.812 0-3.581 1.802-3.581 4.43v4.64c0 2.628 1.769 4.43 3.58 4.43 1.812 0 3.581-1.802 3.581-4.43v-4.64c0-2.628-1.769-4.43-3.58-4.43Zm-5.968 4.43c0-3.6 2.506-6.847 5.968-6.847s5.967 3.247 5.967 6.847v4.64c0 3.6-2.505 6.846-5.967 6.846-3.462 0-5.968-3.247-5.968-6.847V8.847ZM8.936 28.79c0-.667.534-1.208 1.193-1.208h11.936c.659 0 1.193.541 1.193 1.208 0 .668-.534 1.209-1.193 1.209H10.129c-.66 0-1.193-.541-1.193-1.209Z" fill="#fff"/><path fill-rule="evenodd" clip-rule="evenodd" d="M16.096 22.75c.66 0 1.193.541 1.193 1.208v4.834c0 .667-.534 1.208-1.193 1.208-.66 0-1.194-.541-1.194-1.208v-4.834c0-.667.535-1.208 1.194-1.208Z" fill="#fff"/><path fill-rule="evenodd" clip-rule="evenodd" d="M6.549 13.082c.66 0 1.194.541 1.194 1.208 0 4.672 3.74 8.459 8.354 8.459 4.615 0 8.355-3.787 8.355-8.459 0-.667.535-1.208 1.194-1.208.659 0 1.193.541 1.193 1.208 0 6.007-4.809 10.875-10.742 10.875-5.933 0-10.742-4.868-10.742-10.875 0-.667.535-1.208 1.194-1.208Z" fill="#fff"/>
      <path d="M4 24 30-2" stroke="#fff" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="icon-mute">
        <path fill="#fff" d="M0 0h32v32H0z"/>
      </clipPath>
    </defs>
    `
}

