export default {
  methods: {
    authenticatedUserHasFeature (feature) {
      // unauthenticated users don't have access to conditional features
      if (!this.userIsAuthenticated) {
        return false
      }
      return this.authenticatedUser.featureFlags[feature]
    },

  },
  computed: {
    isCompanyPayoutAccountEnabled () {
      return false
    },

    isPayoutsEnabled () {
      return this.authenticatedUserHasFeature('booking.payouts')
    },

    isBetaFeaturesEnabled () {
      return this.authenticatedUserHasFeature('beta')
    },

    // for all kintellers
    isExperimentsEnabled () {
      return this.authenticatedUserHasFeature('experiments')
    },
  }
}
