import { KintellCard, User, Review } from '@/models'
import { isEmpty } from 'lodash-es'
import { single, paginated, join, all } from '../build'

const activeKintellCard = single('activeKintellCard', KintellCard)
const activeUser = single('activeUser', User)
const activeRelatedKintellCards = paginated('activeRelatedKintellCards', KintellCard, ({ getters, state }) => {
  const groupId = getters['kintellCards/activeKintellCard']?.kintellGroup?.id ?? 'null'
  return getters['kintellCards/activeUser']
    .withKintellCards()
    .include('user')
    .where('status', 'published')
    .where('kintell_group_id', groupId)
    .where('exclude_id', getters['kintellCards/activeKintellCard'].id)
    .pagination({
      page: state.kintellCards.activeRelatedKintellCardsPagination.current_page,
      limit: 3,
    })
    .get()
}, {
  appendResults: false,
  pagination: {
    current_page: 1,
    // we always assume there is at least one additional age
    total_pages: 2,
    per_page: 3
  },
})

const activeReviews = paginated('activeReviews', Review, ({ getters, state }) => {
  return getters['kintellCards/activeKintellCard']
    .reviews()
    .orderBy('-created_at')
    .pagination({
      page: state.kintellCards.activeReviewsPagination.current_page,
      limit: state.kintellCards.activeReviewsPagination.per_page,
    })
    .get()
}, {
  appendResults: false,
  pagination: {
    current_page: 1,
    // we always assume there is at least one additional age
    total_pages: 2,
    per_page: 5
  },
})

const allKintellCards = all('allKintellCards', KintellCard, ({ state }) => {
  return state.kintellCards.activeUser
    .withKintellCards()
    .include('user', 'workHistories')
    .where('kintell_group_id', 'null')
    .where('status', 'published')
    .get()
}, { cacheMinutes: 0 })

let {
  // eslint-disable-next-line prefer-const
  state,
  // eslint-disable-next-line prefer-const
  getters,
  // eslint-disable-next-line prefer-const
  mutations,
  actions
} = join(
  activeKintellCard,
  activeUser,
  activeRelatedKintellCards,
  allKintellCards,
  activeReviews,
)
// extend actions
actions = {
  ...actions,
  async loadKintellCardForSlug ({ commit, state, rootState }, { advisor, kintellCard, error, isAdvisor, useCache = true }) {
    // already loaded
    if (
      useCache &&
      // check kintell card exists and slug or id matches
      state.activeKintellCard && (state.activeKintellCard.slug === kintellCard || state.activeKintellCard.id === kintellCard) &&
      // check user exists and slug or id matches
      state.activeUser && (state.activeUser.slug === advisor || state.activeUser.id === advisor)
    ) {
      return true
    }

    let userModel = new User(rootState.auth.user)
    if (userModel.slug !== advisor) {
      if (advisor.length === 36) {
        userModel = await User
          .append('pro_bono_availability_for_period')
          .find(advisor)
      } else {
        userModel = await User
          .where('slug', advisor)
          .where('is-advisor', isAdvisor)
          .append('pro_bono_availability_for_period')
          .first()
      }
    }

    if (isEmpty(userModel)) {
      error({ statusCode: 404, message: 'Advisor not found.' })
      return false
    }

    // if it's a UUID
    let kintellCardModel
    const defaultIncludes = 'kintellGroup,skills,workHistories,relevantQualifications,supportingLinks'
    // if the user is an advisor then append categories to the end of string
    const includeInCards = userModel.slug === advisor || userModel.id === advisor
      ? `${defaultIncludes},categories`
      : defaultIncludes
    if (kintellCard.length === 36) {
      kintellCardModel = await KintellCard
        .include(includeInCards)
        .find(kintellCard)
    } else {
      kintellCardModel = await KintellCard
        .include(includeInCards)
        .where('slug', kintellCard)
        .where('createdBy', userModel.id)
        .first()
    }

    if (!isEmpty(kintellCardModel.deletedAt)) {
      error({ statusCode: 404, message: 'Kintell Card has been deleted.' })
      return false
    }

    if (isEmpty(kintellCardModel)) {
      error({ statusCode: 404, message: 'Kintell Card not found.' })
      return false
    }

    kintellCardModel.user = userModel
    commit('SET_ACTIVE_USER', userModel)
    commit('SET_ACTIVE_KINTELL_CARD', kintellCardModel)
    this.$broadcasting?.attachActiveKintellCardListeners()
    return true
  },
  async loadAdvisorForSlug  ({ commit, state, rootState }, { advisor, kintellCard, error }) {
    let userModel = new User(rootState.auth.user)
    if (userModel.slug !== advisor) {
      userModel = await User
        .where('slug', advisor)
        .where('is-advisor', true)
        .first()
    }
    if (isEmpty(userModel)) {
      error({ statusCode: 404, message: 'Advisor not found.' })
      return false
    }
    commit('SET_ACTIVE_USER', userModel)
  },
  reset ({ commit }) {
    this.$broadcasting.detachActiveKintellCardListeners()
    // always reset first
    commit('SET_ACTIVE_USER', null)
    commit('SET_ACTIVE_KINTELL_CARD', null)
  }
}

// export store
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
