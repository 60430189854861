import { render, staticRenderFns } from "./TheFooter.vue?vue&type=template&id=66356421&scoped=true&"
import script from "./TheFooter.vue?vue&type=script&lang=js&"
export * from "./TheFooter.vue?vue&type=script&lang=js&"
import style0 from "./TheFooter.vue?vue&type=style&index=0&id=66356421&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66356421",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {KImg: require('/opt/atlassian/pipelines/agent/build/app/components/shared/Img/KImg.vue').default,KLinkPage: require('/opt/atlassian/pipelines/agent/build/app/components/shared/Link/KLinkPage.vue').default,KIcon: require('/opt/atlassian/pipelines/agent/build/app/components/shared/Icon/KIcon.vue').default,KContainer: require('/opt/atlassian/pipelines/agent/build/app/components/shared/Container/KContainer.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VRow})
