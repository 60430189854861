/* eslint-disable */

import { push, INIT, PAGE_VIEW } from '@/lib/gtm'
import { isEmpty, once } from 'lodash-es'

export default ({ app, $config: { googleTagManagerId, isProduction }}) => {
  // do not init the store for an iframe (embed kintell card)
  if (app.$inIframe) {
    return
  }

  // only if GTM has been setup
  if (isEmpty(googleTagManagerId)) {
    return
  }

  // pollyfill segment, this also catches production cases where segment can not be loaded
  if (!window.analytics) {
    window.analytics = {
      __pollyfill: true,
    }
    // pollyfill segment methods and add debug
    const segmentEvents = [
      'trackSubmit', 'trackClick', 'trackLink', 'trackForm', 'pageview', 'identify', 'reset', 'group', 'track', 'ready',
      'alias', 'debug', 'page', 'once', 'off', 'on'
    ]
    segmentEvents.forEach(method => {
      window.analytics[method] = () => {
        console.debug(`[Segment Pollyfill] Event: ${method}.`)
      }
    })
  }

  const environmentContext = isProduction ?
    // live environment needs no extra configuration
    '' :
    // this string is used to load the gtm content from the UAT environment instead of Live
    '&gtm_auth=sjXH0GsWoNh2P_7OpH_03w&gtm_preview=env-40&gtm_cookies_win=x';

  // Code from Google Tag Manager for the setup
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl+environmentContext;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer',googleTagManagerId);

  const initGTM = once((data) => {
    push(INIT, data)
  })

  // when we change routes we need to push the data to GTM and the page event
  app.router.afterEach((to, from) => {
    let data = {
      virtualPageUrl: to.fullPath,
      virtualReferralUrl: from.fullPath,
      routeName: to.name,
      userAuthenticated: false,
      userId: '',
      userType:  ''
    }
    if (app.$auth.$state.user) {
      const userModel = app.$auth.$state.user
      data = {
        ...data,
        userAuthenticated: true,
        userId: userModel.email,
        userType:  userModel.type,
        userUuid: userModel.id,
        firstName: userModel.firstName,
        lastName: userModel.lastName,
      }
    }
    // maybe initialise GTM if it hasn't already been done
    initGTM(data)

    // need to wait for the meta to update otherwise page data won't be correct
    setTimeout(
      () => {
        push(PAGE_VIEW, data)
      },
      // low numbers seem safe but are arbitrary
      100
    )

  })
}
