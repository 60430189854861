import Model from './Model'
import KintellCard from './KintellCard'

export default class WorkHistory extends Model {
  resource () {
    return 'work-histories'
  }

  kintellCard () {
    return this.belongsTo(KintellCard)
  }
}
