const auapg = {
  title: 'Infinite growth opportunities',

  description: `Your peers at ‘AUAPG Connect’ have worked at various universities across Australia and beyond. Unlock your access to this network today by completing your registration.`,

  testimonials: [
    {
      id: 'keiran',
      imgSrc: '/images/groups/testimonials/keiran.png',
      quote: `“AUAPG Connect is a fantastic platform to support our members and their PD efforts via video content and video chats!”`,
      name: 'Keiran Hargreaves',
      title: 'Alumni relations @ UWA',
    },

    {
      id: 'anthea',
      imgSrc: '/images/groups/testimonials/anthea.png',
      quote: '“Great to see discipline-based alumni relations insights on AUAPG Connect! Thanks for sharing!”',
      name: 'Anthea Cuddihy',
      title: 'Alumni relations @ CQU'
    },

    {
      id: 'zeba',
      imgSrc: '/images/groups/testimonials/zeba.png',
      quote: '“It is absolutely amazing to be able to watch updated insights from other alumni professionals on this platform!”',
      name: 'Zeba Salman',
      title: 'Alumni relations @ ANU',
    },

    {
      id: 'gideon',
      imgSrc: '/images/groups/testimonials/gideon.png',
      quote: `“We're thrilled to be learning from and supporting each other with cutting-edge video practice in engagement.”`,
      name: 'Ken Gideon',
      title: 'Alumni engagement @ USQ'
    },

    {
      id: 'sollis',
      imgSrc: '/images/groups/testimonials/sollis.png',
      quote: '“I enjoy sharing engagement strategies with those new to the sector, helping them quickly join the conversation on AUAPG Connect.”',
      name: 'Mark Sollis',
      title: 'Engagement strategist @ D3'
    }
  ],
  chips: [
    '/images/groups/chips/auapg/chip1.png',
    '/images/groups/chips/auapg/chip2.png',
    '/images/groups/chips/auapg/chip3.png',
    '/images/groups/chips/auapg/chip4.png',
    '/images/groups/chips/auapg/chip5.png'
  ],
}

const unswAlumniConnect = {
  title: 'Infinite growth opportunities',

  description: `Your peers at ‘UNSW Alumni Connect’ have worked at organisations of all sizes worldwide. Unlock your access to this network today by completing your registration.`,

  testimonials: [
    {
      id: 'james',
      imgSrc: '/images/groups/testimonials/james.png',
      quote: '“Martin (Anderson) provided great, actionable advice to support my career growth. He was very open and honest, giving me excellent recommendations to pursue.”',
      name: 'James Murtagh',
      title: 'UNSW Business & Engineering, 2022'
    },

    {
      id: 'christine',
      imgSrc: '/images/groups/testimonials/christine.png',
      quote: '"I found Rony’s (Golczewski) career mentoring session very helpful. He listened, offered diverse perspectives, and gave tips on contacting industry experts."',
      name: 'Christine Yau',
      title: 'UNSW ADA, 2005'
    },

    {
      id: 'nicole',
      imgSrc: '/images/groups/testimonials/nicole.png',
      quote: '"Loved learning from Jaques (Ostrowski)! He was attentive, gave practical tips for public speaking, and was great to work with. Highly recommend him to anyone!"',
      name: 'Nicole Dai',
      title: 'UNSW Business, 2020'
    },

    {
      id: 'gavin',
      imgSrc: '/images/groups/testimonials/gavin.png',
      quote: '“It was a pleasure talking to Amy (Xin). She’s a kind, patient, and knowledgeable mentor who’s bravely pursued her own career path. Highly recommended!”',
      name: 'Gavin Liang',
      title: 'UNSW Business, 2019'
    },

    {
      id: 'grace',
      imgSrc: '/images/groups/testimonials/grace.png',
      quote: '“Lee (Tonitto) is an experienced professional who is generous with her knowledge and truly cares for the younger generation. Highly recommended!”',
      name: 'Grace (Xitong) Yu',
      title: 'UNSW Business, 2023'
    }
  ],

  chips: [
    '/images/groups/chips/unsw-alumni-connect/chip1.png',
    '/images/groups/chips/unsw-alumni-connect/chip2.png',
    '/images/groups/chips/unsw-alumni-connect/chip3.png',
    '/images/groups/chips/unsw-alumni-connect/chip4.png',
    '/images/groups/chips/unsw-alumni-connect/chip5.png'
  ],
}

const unswCareerCompass = {
  title: 'Your Career Bestie',

  description: 'Your free, virtual toolkit for launching into the professional world. Join today and get ready to navigate your career with confidence and clarity.',

  testimonials: [
    {
      id: 'sanjay',
      imgSrc: '/images/groups/testimonials/sanjay.png',
      quote: `“Sanam is extremely positive and possesses great industry knowledge. I found her optimism infectious and learned a lot about how to improve my job applications!”`,
      name: 'Sanjay Chhabaria',
      title: 'UNSW Business, 2023'
    },

    {
      id: 'crystal',
      imgSrc: '/images/groups/testimonials/crystal.png',
      quote: `“I’m working in this office where I am today, thanks to the inspiration I got from speaking to an advisor on the Career Compass platform.”`,
      name: 'Crystal Harrison',
      title: 'UNSW Medicine & Health, 2021'
    },

    {
      id: 'laura',
      imgSrc: '/images/groups/testimonials/laura.png',
      quote: '"I recently graduated, searched for my first full-time job, and had many questions! The advisors’ professional feedback on my applications is a great support."',
      name: 'Laura Golembowski',
      title: 'UNSW ADA, 2024'
    },

    {
      id: 'alexander',
      imgSrc: '/images/groups/testimonials/alexander.png',
      quote: `“The UNSW Career Compass Advisor helped me adjust my mindset. I have much more confidence in how to deal with my work life and find the balance I need.”`,
      name: 'Alexander Wang',
      title: 'UNSW Canberra, 2021'
    },

    {
      id: 'anastasia',
      imgSrc: '/images/groups/testimonials/anastasia.png',
      quote: '"Career paths can be really scary and daunting, so I encourage everyone to try Career Compass—it’s very useful."',
      name: 'Anastasia Dounas',
      title: 'UNSW Medicine & Health, 2021'
    }
  ],

  chips: [
    '/images/groups/chips/unsw-career-compas/chip1.png',
    '/images/groups/chips/unsw-career-compas/chip2.png',
    '/images/groups/chips/unsw-career-compas/chip3.png',
    '/images/groups/chips/unsw-career-compas/chip4.png',
    '/images/groups/chips/unsw-career-compas/chip5.png'
  ],
}

const anuConnect = {
  title: 'Connect, share, and grow',

  description: 'Join our online hub where ANU alumni from organisations worldwide share their career & professional insights via short videos and on-demand video chats providing flash-coaching to others in the network.',

  testimonials: [
    {
      id: 'aakash',
      imgSrc: '/images/groups/testimonials/aakash.png',
      quote: `“To prepare for the future workplace, hone your empathy, critical thinking, and problem-solving abilities now.”`,
      name: 'Aakash Gupta Choudhury',
      title: 'Machine Learning (2020)'
    },

    {
      id: 'kieran',
      imgSrc: '/images/groups/testimonials/kieran.png',
      quote: `“Crafting a fulfilling dual career is possible if you can cultivate synergy between your two jobs.”`,
      name: 'Kieran Pender',
      title: 'Arts & Law (2016)'
    },

    {
      id: 'anya',
      imgSrc: '/images/groups/testimonials/anya.png',
      quote: '“Graduates bring a fresh lens to work; their on-ground insights are gold for seasoned leaders.”',
      name: 'Anya Bonan',
      title: 'Arts & Law (2019)'
    },

    {
      id: 'prime',
      imgSrc: '/images/groups/testimonials/prime.png',
      quote: `“Stories can pass down trauma, but they also carry resilience and hope from generation to generation.”`,
      name: 'Prime Ragandang',
      title: 'International Relations (2023)'
    },

    {
      id: 'chen',
      imgSrc: '/images/groups/testimonials/chen.png',
      quote: `“I want to help other Asians who feel they can't smash the bamboo ceiling; for me, those words never exist in my vocabulary.”`,
      name: 'JJ Chen',
      title: 'Science & Asian Studies (2005)'
    }
  ],

  chips: [
    '/images/groups/chips/anu-connect/chip1.png',
    '/images/groups/chips/anu-connect/chip2.png',
    '/images/groups/chips/anu-connect/chip3.png',
    '/images/groups/chips/anu-connect/chip4.png',
    '/images/groups/chips/anu-connect/chip5.png'
  ],
}

const kintellAkademy = {
  title: '10x your ROI',

  description: 'Kintell’s turnkey solution powers organisations to boost engagement with less work by leveraging cutting-edge video technology. Here’s what Kintell customers love:',

  testimonials: [
    {
      id: 'adry',
      imgSrc: '/images/landing/testimonials/adry.png',
      quote: `“Our Alumni Connect platform runs on Kintell and their service is second to none! The Kintell team is truly collaborative.”`,
      name: 'Adry Awan',
      title: 'Alumni Experience @ UNSW Sydney',
    },
    {
      id: 'gretchen',
      imgSrc: '/images/landing/testimonials/gretchen.png',
      quote: `“Creating a branded video on Kintell doesn’t require any technical skills. The turnaround time and video quality are very impressive.”`,
      name: 'Gretchen Dobson',
      title: 'Assoc. Dir. @ Tetra Tech',
    },
    {
      id: 'henrik',
      imgSrc: '/images/landing/testimonials/henrik.png',
      quote: `“After switching to Kintell to run our tutorial platform, my team has reclaimed 90% of their time that was previously spent on admin tasks.”`,
      name: 'Henrik Røine',
      title: 'Founder @ TEEI NGO',
    },
    {
      id: 'keiran',
      imgSrc: '/images/landing/testimonials/keiran.png',
      quote: `“Kintell is an incredible tool that facilitates peer connections and produces engaging videos that people love.”`,
      name: 'Keiran Hargreaves',
      title: 'Alumni Engagement @ Uni of WA',
    },
    {
      id: 'maddie',
      imgSrc: '/images/landing/testimonials/maddie.png',
      quote: `“Videos produced by Kintell are clean and professional and I feel confident sharing them anywhere.”`,
      name: 'Maddie Massy-Westropp',
      title: 'News & Content @ UNSW Sydney',
    },
  ],

  chips: [
    '/images/landing/chips/chip1.png',
    '/images/landing/chips/chip2.png',
    '/images/landing/chips/chip3.png',
    '/images/landing/chips/chip4.png',
    '/images/landing/chips/chip5.png',
  ],
}

const utasAlumni = {
  title: 'Hack your career',
  description: 'Your online hub exclusive to UTAS recent grads to watch short videos, or book 1-to-1 video chat for career hacks with experienced alumni.',
  testimonials: [
    {
      id: 'leary',
      imgSrc: '/images/groups/testimonials/leary.png',
      quote: `“Your first job isn’t about perfection—it's a chance to develop soft and hard skills, preparing you for exciting career paths.”`,
      name: 'Sarah Leary',
      title: 'Former Diplomat'
    },
    {
      id: 'hope',
      imgSrc: '/images/groups/testimonials/hope.png',
      quote: `“Leadership isn't about 'faking it until you make it'—it's about taking consistent action to build confidence.”`,
      name: 'Lara Hope',
      title: 'Director @ DSS'
    },
    {
      id: 'wijesooriya',
      imgSrc: '/images/groups/testimonials/wijesooriya.png',
      quote: `“Highlight your volunteer work when applying for jobs; it boosts your resume and showcases your teamwork skills.”`,
      name: 'Kalpy Wijesooriya',
      title: 'HR Specialist @ City of Hobart'
    },
    {
      id: 'foskett',
      imgSrc: '/images/groups/testimonials/foskett.png',
      quote: `“Focus on the positives in your workplace—one toxic person shouldn’t define your entire environment.”`,
      name: 'Jaime Foskett',
      title: 'Sr P & C Business Partner @ TasPorts'
    },
    {
      id: 'rees',
      imgSrc: '/images/groups/testimonials/rees.png',
      quote: `“Clear communication and active listening are key skills transferable from academia to industry.”`,
      name: 'Annalise Rees',
      title: 'Stakeholder Engagement Consultant'
    }
  ],
  chips: [
    '/images/groups/chips/utas-career/chip1.png',
    '/images/groups/chips/utas-career/chip2.png',
    '/images/groups/chips/utas-career/chip3.png',
    '/images/groups/chips/utas-career/chip4.png',
    '/images/groups/chips/utas-career/chip5.png'
  ],
}

export const groupSidebar = {
  'unsw-alumni-connect': unswAlumniConnect,

  'auapg-connect': auapg,

  'auapg-metrics-cop': auapg,

  'unsw-career-compass': unswCareerCompass,

  'anu-connect': anuConnect,

  'kintell-akademy': kintellAkademy,

  'utas-alumni': utasAlumni,
}

export const groupsHasSidebar = Object.keys(groupSidebar)
