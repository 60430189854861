import { minutesOrHours, canPerform, Permissions } from '@/lib/util'
import {
  transformAvailabilityToAPIFormat,
  transformAvailabilityToCalendarFormat
} from '@/lib/util/available-times'
import Model from './Model'
import KintellCard from './KintellCard'
import KintellCredit from './KintellCredit'
import KintellGroup from './KintellGroup'
import type { Moment } from 'moment-timezone'
import moment from 'moment-timezone'
import Subscription from './Subscription'
import Email from '@/models/Email'

interface UserStats {
  proBonoCreditMinutes: number,
  creditAvailable: number,
  alerts?: number,
  bookingRequestsLearning: number,
  bookingRequestsAdvising: number,
  bookingsConfirmed: number,
  bookingsArchived: number,
  bookingActive?: any,
  bookingAwaitingReview?: any,
  myDetailsPayoutAlerts?: number,
  kintellCardAwaitingAmendment: any,
  invitesSent: string,
  ev: boolean,
}

interface BookingDetails {
  host: boolean,
  requestJoin: boolean,
  isActive: boolean,
  canJoin: boolean,
}

interface CalendarRequestOptions {
  kintellCardId: string,
  applyCardsWorkingHours: boolean,
  useDefaultTimes: boolean,
}

interface Role {
  roleId: string,
  name: string,
}

interface paymentMethods {
  billingDetails: Object,
  card: any,
  country: string,
  expMonth: number,
  expYear: number,
  fingerprint: string,
  funding: string,
  last4: string,
}

export default class User extends Model {
  public type: 'Advisor' | 'Learner'
  public timezone: string
  public interests: string[]
  public languagesSpoken: string[]
  public categoryInterests: string[]
  public proBonoMinutes: number
  public firstName: string
  public email: string
  public phone: string
  public lastName: string
  public isPlaceholder: boolean
  public slug: string
  public hasRealAvatar: boolean
  public avatarUrl: string
  public stripeIndividualVerificationStatus: string
  public instantMeetingLink: string
  public roles: Role[]
  public planSubscriptions: Subscription[]
  // TODO: implement typescript for kintell groups
  public kintellGroups: any[]
  public declare stats: UserStats
  public details: BookingDetails
  public paymentMethods: paymentMethods[]
  public maxInvites: number
  public activeSubscription: Subscription
  public isGroupAdmin: boolean
  public country: string
  public emails: Email[]
  public isCampaign: boolean

  resource () {
    return 'users'
  }

  withKintellCards () {
    // KintellCard is not typed yet so the below throws an error
    // @ts-expect-error
    return this.hasMany(KintellCard)
  }

  transform (data: Record<string, any>) {
    if (!data) {
      return data
    }

    if (data.createdAt) {
      data.createdAtUtc = moment.utc(data.createdAt)
    }
    if (data.activeKintellCredits) {
      data.activeKintellCredits = data.activeKintellCredits.map((credit: any) => new KintellCredit(credit))
    }

    if (data.unavailableTimes) {
      data.unavailableTimes = transformAvailabilityToCalendarFormat(data.unavailableTimes, this.timezone)
    }

    if (data.kintellGroups) {
      data.kintellGroups = data.kintellGroups.map((group: any) => new KintellGroup(group))
    }

    if (data.kintellCards) {
      data.kintellCards = data.kintellCards.length && data.kintellCards.map((card: any) => new KintellCard(card))
    }

    if (data.planSubscriptions) {
      data.activeSubscription = new Subscription(data.planSubscriptions[0])
    }

    if (data.emails) {
      data.emails = data.emails.map((email: any) => new Email(email))
    }

    if (data.lastLogin) {
      const { lastLogin } = data
      const momentDate = moment.utc(lastLogin)

      if (momentDate.isValid()) {
        data.lastLogin = momentDate
      }
    }

    return data
  }

  castsForRequest (data: Record<string, any>) {
    if (data.unavailableTimes && data.timezone) {
      data.unavailableTimes = transformAvailabilityToAPIFormat(data.unavailableTimes, data.timezone)
    }

    return data
  }

  /**
   * Convert a given UTC time to a moment instance which has been converted to the users time zone
   * @param s The UTC time to parse and convert to the timezone we're using
   * @returns moment
   */
  utcToMyTz (s: Moment) {
    if (moment.isMoment(s)) {
      return s.clone().tz(this.timezone)
    }
    return moment.utc(s).tz(this.timezone)
  }

  hasGroup (kintellGroup: KintellGroup): boolean {
    return this.kintellGroups.filter(group => group.id === kintellGroup.id).length > 0
  }

  get kintellGroupsNonContent () {
    return this.kintellGroups?.filter(group => group.contentOnly === false) ?? []
  }

  get unverifiedGroupCount () {
    return this.kintellGroupsNonContent?.reduce((acc, group) => {
      if (this.can(Permissions.SEE_COMPLETE_REGISTRATION_MODAL, group)) {
        acc++
      }

      return acc
    }, 0)
  }

  get hasProvidedLearningInterests () {
    return (this.interests && this.interests.length > 0) || (this.categoryInterests && this.categoryInterests.length > 0)
  }

  get proBonoHours () {
    const hours = (this.proBonoMinutes / 60).toFixed(1)
    return hours + ' hour' + (hours !== (1).toFixed(1) ? 's' : '') + ' a month'
  }

  get proBonoHoursShort () {
    const hours = (this.proBonoMinutes / 60).toFixed(1)
    return hours + ' Hr' + (hours !== (1).toFixed(1) ? 's' : '') + '/Mth'
  }

  get fullName () {
    return this.firstName + ' ' + this.lastName
  }

  get isLearner () {
    return this.type === 'Learner'
  }

  get isAbleToSendInvites () {
    return this.type !== 'Learner'
  }

  get languagesSpokenFriendly () {
    if (!this.languagesSpoken) {
      return ''
    }
    return this.languagesSpoken.join(', ')
  }

  calendar (options: CalendarRequestOptions) {
    return this.action('calendar', options)
  }

  can (permission: string, kintellGroup: KintellGroup): boolean {
    return canPerform(permission, kintellGroup, this)
  }

  cannot (permission: string, kintellGroup: KintellGroup): boolean {
    return !this.can(permission, kintellGroup)
  }

  get advisorProfileLink () {
    return '/advisors/' + this.slug
  }

  // authenticated user only functions
  // @todo make Me model

  get proBonoString () {
    return minutesOrHours(this.stats.proBonoCreditMinutes)
  }

  get credit () {
    return this.stats.creditAvailable / 100
  }

  get hasActiveBooking () {
    return this.stats.bookingRequestsLearning !== 0 ||
      this.stats.bookingRequestsAdvising !== 0 ||
      this.stats.bookingsConfirmed !== 0
  }

  get hasBookings () {
    return this.stats.bookingRequestsLearning !== 0 ||
      this.stats.bookingRequestsAdvising !== 0 ||
      this.stats.bookingsConfirmed !== 0 ||
      this.stats.bookingsArchived !== 0
  }

  get isKintellPlus (): boolean {
    if (!this.activeSubscription) {
      return false
    }
    return this.activeSubscription?.slug === 'kintell-plus'
  }

  get isKintellPro (): boolean {
    if (!this.activeSubscription) {
      return false
    }
    return this.activeSubscription?.slug === 'kintell-pro'
  }

  get isKintellPlusOrPro (): boolean {
    return this.isKintellPlus || this.isKintellPro
  }

  get groupsUserIsAdmin (): KintellGroup[] {
    // @ts-expect-error
    return this.kintellGroups?.filter((group: KintellGroup) => Boolean(group.usersPermissions?.isAdmin) && Boolean(!group.contentOnly))
  }

  get groupsUserIsAdminWithContentOnly (): KintellGroup[] {
    if (!this.kintellGroups) {
      return []
    }
    // @ts-expect-error
    return this.kintellGroups?.filter((group: KintellGroup) => Boolean(group.usersPermissions?.isAdmin))
  }

  requestVerificationCode (data: Object) {
    return this.$http.$post(`${this.baseURL()}/${this.resource()}/password-free/verification-code`, data)
  }

  requestPhoneVerificationCode (data: Object) {
    return this.$http.$post(`${this.baseURL()}/${this.resource()}/password-free/phone-verification-code`, data)
  }

  userExists (data: Object) {
    return this.$http.$post(`${this.baseURL()}/${this.resource()}` + `/password-free/user-exists`, data)
  }

  get firstGroup (): KintellGroup | null {
    return this.kintellGroups?.length ? this.kintellGroups[0] : null
  }

  get firstGroupEmailStatus () {
    if (!this.emails?.length) {
      return ''
    }

    const status = this.emails[0].details.status ?? 'sent' // Default to sent if no status
    return status.charAt(0).toUpperCase() + status.slice(1)
  }

  get firstGroupPermissions () {
    // @ts-expect-error
    return this.firstGroup?.usersPermissions
  }

  markComplete (infoBoxId: string) {
    return this.$http.$post(this.resourceUrl() + `/complete-info-box/${infoBoxId}`)
  }

  report (data: Object) {
    return this.action('activity', data)
  }

  async getAllCards () {
    const fetchPromises = []
    const allCards = this.withKintellCards()
      .where('kintell_group_id', 'null')
      .where('status', 'awaiting-review,draft,published,unlisted')
      // @ts-expect-error
      .pagination({ page: 1, limit: 999 })
      .$get()

    fetchPromises.push(allCards)

    const groups = this.kintellGroups || []
    groups.forEach(_group => {
      const groupCards = this.withKintellCards()
        .where('kintell_group_id', _group.id)
        // @ts-expect-error
        .pagination({ page: 1, limit: 999 })
        .$get()

      fetchPromises.push(groupCards)
    })

    const responses = await Promise.all(fetchPromises)

    return responses.flat()
  }

  findUsesGroup (kintellGroupId: string) {
    return this.kintellGroups.find(kintellGroup => kintellGroup.id === kintellGroupId)
  }

  groupUserPermission (kintellGroupId: string) {
    return this.findUsesGroup(kintellGroupId)?.usersPermissions
  }

  get avatarAlertCount () {
    const isBookingActive = this.stats?.bookingActive ? 1 : 0
    return this.stats?.alerts + isBookingActive + this.unverifiedGroupCount
  }

  get requiresEmailVerification () {
    return this.stats?.ev
  }
}
