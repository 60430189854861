export default {
  request: {
    title: 'You’re pencilled in!',
    subtitle: 'Thanks {learner}, you’ve made a provisional booking with {advisor}. They\'ll get back to you shortly to confirm.',
    nextSteps: {
      title: 'What happens next?',
      steps: [
        { step: '{advisor} will review your booking details', },
        { step: 'They\'ll either confirm your booking at one of the times requested' },
        { step: 'Or suggest other times that may work', },
        // Payment only
        { step: 'You’ll only be charged if the booking is confirmed by {advisor}' },
        { step: 'We’ll remind you when your booking is coming up' },
        { step: 'You get to enjoy your Kintell video session!' },
      ],
      freeSteps: [
        { step: '{advisor} will review your booking details', },
        { step: 'They\'ll either confirm your booking at one of the times requested' },
        { step: 'Or suggest other times that may work', },
        { step: 'We’ll remind you when your booking is coming up' },
        { step: 'You get to enjoy your Kintell video session!' },
      ]
    },
    cta: {
      label: 'My Bookings',
      link: '/my-kintell/bookings/requests'
    },
    send: 'MAKE PROVISIONAL BOOKING',
  },
  cancelled: {
    title: 'Booking cancelled',
    subtitle: 'Your booking with {name} has been cancelled.',
  },
  confirmed: {
    title: 'Booking confirmed!',
    subtitle: 'Thanks {advisor}, your booking with {learner} is confirmed and in your Kintell Calendar!',
  },
  suggested: {
    title: 'Alternative times sent!',
    subtitle: 'Thanks {advisor}, we\'ve sent your suggested times over to {learner} - we\'ll let you know when they respond.',
  },
  reschedule: {
    title: 'Booking Rescheduled!',
    subtitle: 'Thanks {sender}, we\'ve sent the new times to {recipient} to review - we\'ll let you know when they respond.',
  },
  review: {
    title: 'Please leave a review',
    request: 'Please leave your review of {name}, following your booking on {date}.',
    nextPage: 'And we\'ll show your booking summary',
    button: 'Submit Review'
  },
  initiate: {
    hintTitle: 'Please note that this feature is currently in Beta.',
    hint: 'Use this page when you\'d like to invite someone to book you on Kintell. Select some times to suggest, and then either pop in an email address or copy the booking invite URL and share it!',
    submit: 'Continue to sharing details',
    send: 'Send',

    steps: {
      instantMeeting: {
        title: 'Your personal room',
        subtitle: 'Host a multi-party video meeting with unlimited duration for free. Your guests don’t need to sign-up!',
        cta: 'Host a meeting now',
      },
      vonage: {
        title: 'Quick vonage call',
        subtitle: 'Test a vonage call now',
        cta: 'Host a vonage meeting now',
      },
      chime: {
        title: 'Quick chime call',
        subtitle: 'Test a chime call now',
        cta: 'Host a chime meeting now',
      },
      daily: {
        title: 'Quick daily call',
        subtitle: 'Test a daily call now',
        cta: 'Host a daily meeting now',
      },
      inviteSomeone: {
        title: 'Invite someone to a meeting',
        subtitle: 'Share your knowledge over video and charge for your time. It can be free too!',
      },
      bookSomeone: {
        title: 'Book someone',
        subtitle: 'Pick someone’s brain using Kintell’s powerful booking system.',
      },
      startNow: {
        title: 'Host a meeting now',
        subtitle: 'Host a multi-party video meeting with unlimited duration for free. Your guests don’t need to sign-up!',
      },
      forLater: {
        title: 'Schedule for later',
        subtitle: 'Charge for your time or make it free.',
      },
      youSuggest: {
        title: 'You suggest times',
        subtitle: 'Proactively pick one or more times for someone to confirm. Charge for your time or make it free!',
      },
      yourContactSuggests: {
        title: 'Your contact suggests times',
        subtitle: 'Share a link to receive bookings. Charge for your time or make it free!'
      },
      alreadyAgreed: {
        title: 'Already agreed on a time',
        subtitle: 'Invite others to a multi-party video meeting with unlimited duration - it’s free!'
      },
      newBooking: {
        title: 'New booking',
        subtitle: 'Book a time with someone new using Kintell’s visual directory and scheduling system.'
      },
      repeatBooking: {
        title: 'Repeat booking',
        subtitle: 'Book someone again from your searchable Booking Archive.'
      },
      bookFavourite: {
        title: 'Book a favourite',
        subtitle: 'Book someone from your saved favourite list.'
      },
      groups: {
        subtitle: 'Book someone from your organisational network.'
      },
      marketplace: {
        title: 'Kintell insight marketplace',
        subtitle: 'Book vetted experts to pick their brain.'
      },
    },

    infoBox: {
      youSuggest: {
        title: 'Invite someone proactively',
        subtitle: `Pop in the first name of the person you’re inviting to book you. Then select a {linkCard}. Next you’ll suggest times. Learn more about bookings {linkBookings}.`,
        textLinkCard: 'Kintell Card',
        textBooking: 'here',
      },
      inviteSomeone: {
        title: 'Invite someone proactively',
        subtitle: `Customise the message for {inviteeName} and then suggest times. Next you can share the invite link with {inviteeName} and they would confirm one of the times or suggest alternative times.`,
      },
      yourContactSuggests: {
        title: 'Invite someone to book you',
        subtitle: `Share a {linkCard} link with someone to book you. You can always add new cards. Learn more about bookings {linkBookings}.`,
        textLinkCard: 'Kintell Card',
        textBooking: 'here',
      },
      bookFavouriteEmpty: {
        title: 'Book a favourite',
        subtitle: 'Hmm, you don’t have any favourites yet. Now would be a great time to book an expert on Kintell’s {textLink} to pick their brain.',
        textLink: 'Insight Marketplace',
      },
      bookFavourite: {
        title: 'Book a favourite',
        subtitle: 'We’ve listed your saved favourites here, so you can book a session with them easily. Also you can always find the full list in your {textLink}.',
        textLink: 'dashboard',
      },
      repeatBooking: {
        title: 'Repeat booking',
        subtitle: 'We’ve listed your most recent learning bookings here, so you can book another session easily. Also you can find all of the past learning bookings in your {textLink}.',
        textLink: 'searchable archive',
      },
      repeatBookingEmpty: {
        title: 'Repeat booking',
        subtitle: 'Hmm, you don’t have any past learning bookings. Now would be a great time to book an expert on Kintell’s {textLink} to pick their brain.',
        textLink: 'Insight Marketplace',
      },
    }
  },
  initiateGhost: {
    hintTitle: 'You\'re not an advisor yet',
  },
  archive: {
    hint: 'Find a past booking to send messages, view recording and much much more.',
  },
  response: {
    title: 'Make a Booking',
    expiredBoxTitle: 'Booking expired',
    expiredBoxContent: 'The original booking request that {advisorFirstName} sent to you ' +
      ' has now expired. You can book {advisorFirstName} again ' +
      ' by {link} - we\'ll let {advisorFirstName} know immediately.',
    expiredSelfBoxContent: `
      The original booking request that you sent to {learnerFirstName} has now expired.
      You can initiating another one by {link} to {learnerFirstName}.
    `,
    cannotConfirmBoxTitle: 'Booking cannot be confirmed',
    cannotConfirmBoxContent: 'Sorry you can no longer confirm this booking. ' +
      ' You can book {advisorFirstName} again ' +
      ' by {link} - we\'ll let {advisorFirstName} know immediately.',
    btnBookAgain: 'BOOK AGAIN',
    btnInitiateBookAgain: 'INITIATE BOOK AGAIN',
    linkText: 'sending new times'
  }
}
