
import mixins from 'vuetify/lib/util/mixins'
import Colorable from 'vuetify/lib/mixins/colorable'
import Themeable from 'vuetify/lib/mixins/themeable'

import { convertToUnit, keys } from 'vuetify/lib/util/helpers'

import { BindsAttrs, Sizeable } from '@/mixins'
import KIconSvg from './KIconSvg'

const SIZE_MAP = {
  small: '16px',
  default: '24px',
  medium: '28px',
  large: '36px',
  xLarge: '40px'
}

function isCssColor (color) {
  return !!color && (!!color.match(/^(#|(rgb|hsl)a?\()/) || color === 'currentColor')
}

export default mixins(
  Colorable,
  Sizeable,
  Themeable,
  BindsAttrs
).extend({

  props: {
    default: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'primary'
    },
    inline: {
      default: false,
      type: Boolean,
    },
    left: Boolean,
    right: Boolean,
    size: {
      type: [ Number, String ],
      default: 24,
    }
  },

  data () {
    return {
      iconName: ''
    }
  },
  methods: {
    getIcon () {
      return this.$vuetify.icons.values[this.iconName]
    },
    getSize () {
      const sizes = {
        small: this.small,
        medium: this.medium,
        large: this.large,
        xLarge: this.xLarge
      }

      const explicitSize = keys(sizes).find(key => sizes[key])

      return (explicitSize && SIZE_MAP[explicitSize]) || convertToUnit(this.size)
    },
    // Component data for both font and svg icon.
    getDefaultData () {
      return {
        staticClass: 'v-icon k-icon',
        class: {
          'v-icon--disabled': this.disabled,
          'v-icon--left': this.left,
          'v-icon--link': this.$data.$_listeners.click || this.$data.$_listeners['!click'],
          'v-icon--right': this.right,
          'd-inline': this.inline
        },
        attrs: {
          'aria-hidden': true,
          ...this.$data.$_attrs
        },
        on: this.$data.$_listeners
      }
    },
    applyColors (data) {
      data.class = { ...data.class, ...this.themeClasses }
      this.setTextColor(this.color, data)
    },
    renderComponent (icon, h) {
      const data = this.getDefaultData()
      data.class['v-icon--is-component'] = true

      const size = this.getSize()
      if (size) {
        data.style = {
          fontSize: size,
          height: size,
          width: 'auto'
        }
      } else {
        data.style = {
          fontSize: SIZE_MAP.default,
          height: SIZE_MAP.default,
          width: 'auto'
        }
      }

      this.applyColors(data)

      const component = icon.component
      data.props = icon.props

      return h(component, data)
    },
    renderSvgRaw (icon, h) {
      const data = this.getDefaultData()
      data.class['v-icon--is-component'] = false

      const size = this.getSize() ? this.getSize() : SIZE_MAP.default

      data.style = {
        fontSize: size,
        height: size,
      }

      if (icon.stroke) {
        data.style = {
          ...data.style,
          stroke: isCssColor(this.color) ? this.color : 'var(--v-' + this.color + '-base)',
        }
      }
      let fill = 'none'
      if (icon.fill || typeof icon.fill === 'undefined') {
        fill = isCssColor(this.color) ? this.color : 'var(--v-' + this.color + '-base)'
      }
      data.style = {
        ...data.style,
        fill
      }
      data.nativeOn = data.on

      data.props = {
        svg: icon.svg,
        viewportWidth: icon.width,
        viewportHeight: icon.height,
        ...this.$data.$_attrs
      }

      return h(KIconSvg, data)
    },
  },
  render (h) {
    if (this.$slots.default && this.$slots.default[0].text) {
      this.iconName = this.$slots.default[0].text.trim()
    }

    const icon = this.getIcon()
    // kintell icons are first priority
    if (icon && icon.svg) {
      return this.renderSvgRaw(icon, h)
    }
    // then fontawesome
    if (icon && icon.component) {
      return this.renderComponent(icon, h)
    }

    return this._v('Bad icon: ' + this.iconName)
  }
})
