export default {
  width: 30,
  height: 34,
  stroke: false,
  svg: `
    <g fill="none">
      <path d="M28.3603 19.9433C27.4575 27.3234 20.7428 32.5742 13.3628 31.6714C5.98272 30.7685 0.731915 24.0539 1.63478 16.6738C2.53764 9.29374 9.25227 4.04293 16.6323 4.94579" stroke="white" stroke-width="2" stroke-linecap="round"/>
      <path d="M12.9951 9L17.747 5.83205C18.3408 5.43623 18.3408 4.56377 17.747 4.16795L12.9951 1" stroke="white" stroke-width="2" stroke-linecap="round"/>
      <path d="M11.1279 17.2046C11.9362 17.2046 12.6239 17.4797 13.1909 18.0298C13.758 18.5799 14.0415 19.257 14.0415 20.061C14.0415 20.937 13.7241 21.6733 13.0894 22.27C12.4588 22.8625 11.6738 23.1587 10.7344 23.1587C10.1208 23.1587 9.56217 23.0275 9.05859 22.7651C8.55501 22.4985 8.15934 22.1346 7.87158 21.6733C7.58805 21.2078 7.44629 20.6958 7.44629 20.1372C7.44629 19.2951 7.75098 18.4678 8.36035 17.6553L11.1279 13.8975H13.1973L10.6074 17.2554C10.7471 17.2215 10.9206 17.2046 11.1279 17.2046ZM10.7344 21.5845C11.166 21.5845 11.5236 21.4491 11.8071 21.1782C12.0907 20.9032 12.2324 20.5562 12.2324 20.1372C12.2324 19.714 12.0885 19.3649 11.8008 19.0898C11.5173 18.8105 11.1618 18.6709 10.7344 18.6709C10.307 18.6709 9.95361 18.8084 9.67432 19.0835C9.39502 19.3586 9.25537 19.7098 9.25537 20.1372C9.25537 20.5646 9.3929 20.9137 9.66797 21.1846C9.94303 21.4512 10.2985 21.5845 10.7344 21.5845ZM18.8848 13.7451C19.9681 13.7451 20.8271 14.1471 21.4619 14.9512C22.1009 15.7552 22.4204 16.9253 22.4204 18.4614C22.4204 19.2274 22.3294 19.915 22.1475 20.5244C21.9655 21.1296 21.7137 21.6247 21.3921 22.0098C21.0705 22.3906 20.696 22.6826 20.2686 22.8857C19.8454 23.0846 19.3841 23.1841 18.8848 23.1841C18.3812 23.1841 17.9136 23.0846 17.4819 22.8857C17.0545 22.6826 16.6779 22.3906 16.3521 22.0098C16.0262 21.6247 15.7702 21.1296 15.584 20.5244C15.402 19.915 15.311 19.2274 15.311 18.4614C15.311 17.687 15.3978 16.9993 15.5713 16.3984C15.7448 15.7933 15.9902 15.2982 16.3076 14.9131C16.625 14.528 16.9995 14.2381 17.4312 14.0435C17.867 13.8446 18.3516 13.7451 18.8848 13.7451ZM18.8848 15.3193C17.7041 15.3193 17.1138 16.3667 17.1138 18.4614C17.1138 20.5519 17.7041 21.5972 18.8848 21.5972C20.0443 21.5972 20.624 20.5519 20.624 18.4614C20.624 16.3667 20.0443 15.3193 18.8848 15.3193Z" fill="white"/>
    </g>
`,
}
