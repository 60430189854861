/**
 * This mixin provides `$data.$_attrs` and `$data.$_listeners` to work around
 * vue bug https://github.com/vuejs/vue/issues/10115
 */

function makeWatcher (property) {
  return {
    handler (val, oldVal) {
      for (const attr in oldVal) {
        if (!Object.prototype.hasOwnProperty.call(val, attr)) {
          this.$delete(this.$data[property], attr)
        }
      }
      for (const attr in val) {
        this.$set(this.$data[property], attr, val[attr])
      }
    },
    immediate: true
  }
}

/* @vue/component */
export default {
  data: () => ({
    $_attrs: {},
    $_listeners: {},
  }),
  watch: {
    // Work around unwanted re-renders: https://github.com/vuejs/vue/issues/10115
    // Make sure to use `v-bind="$data.$_attrs"` instead of `v-bind="$attrs"`
    $attrs: makeWatcher('$_attrs'),
    $listeners: makeWatcher('$_listeners')
  },
}
