export default {
  width: 21,
  height: 25,
  svg: `
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
    <path d="M8.56 12.52H3C1.89543 12.52 1 11.6246 1 10.52V3C1 1.89543 1.89543 1 3 1H14.84C15.9446 1 16.84 1.89543 16.84 3V7.12" stroke="#30324B"/>
    <path d="M1.36035 1.36011L7.60845 5.62952C8.28853 6.09423 9.18396 6.09447 9.86429 5.63012L16.1204 1.36011" stroke="#30324B"/>
    <path d="M15.4151 15.6461L13.8197 16.7857C13.5376 16.9872 13.141 16.8475 13.0476 16.5137L11.0312 9.31246C10.9132 8.89078 11.3558 8.5318 11.744 8.73435L18.3345 12.1729C18.6509 12.338 18.6975 12.7718 18.4233 13.0003L17.3007 13.9358C17.0608 14.1357 17.0608 14.5042 17.3007 14.7041L18.514 15.7152C18.7619 15.9218 18.7521 16.3057 18.4939 16.4993L17.9066 16.9398C17.7076 17.0891 17.429 17.0693 17.2531 16.8933L16.0592 15.6995C15.8864 15.5266 15.614 15.5041 15.4151 15.6461Z" stroke="#30324B"/>
  </svg> `
}
