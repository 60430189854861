import moment from 'moment-timezone'
import Model from './Model'
import User from './User'

export default class KintellCredit extends Model {
  resource () {
    return 'kintell-credit'
  }
  /**
   * -------------------------
   * Kintell Credit Relations
   * -------------------------
   * The entities which sit under a KintellCredit in terms of the api
   */

  user () {
    return this.belongsTo(User)
  }

  /**
   * -------------------------
   * Kintell Credit Getters
   * -------------------------
   * Utility computed properties
   */

  get remainingCreditString () {
    return (this.totalCreditCents - this.usedCreditCents) / 100
  }

  get expiryDateString () {
    return moment(this.expiresAt).format('Do MMMM YYYY')
  }
}
