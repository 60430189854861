import single from './single'
import all from './all'
import paginated from './paginated'
import join from './join'

export {
  single,
  all,
  paginated,
  join
}
export default {
  single,
  all,
  paginated,
  join
}
