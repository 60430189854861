import Model from './Model'
import User from '@/models/User'
import moment from 'moment-timezone'

interface Details {
  status: string,
}

export default class Email extends Model {
  public ctaText: string
  public ctaUrl: string
  public subject: string
  public message: string
  public hideCta: boolean
  public createdBy: User|string
  public recipients: Array<User>
  public createdAt: string
  public details: Details;
  public recipientsCount: number;

  resource () {
    return 'emails'
  }

  sendEmail (data: Object) {
    return this.action('send', data)
  }

  transform (data: Record<string, any>) {
    if (!data) {
      return data
    }

    if (data.createdBy) {
      data.createdBy = new User(data.createdBy)
    }

    if (data.recipients) {
      data.recipients = data.recipients.map((recipient: User) => new User(recipient))
    }

    return data
  }

  get recipientFirstEmailStatus () {
    if (!this.recipients.length) {
      return ''
    }

    // @ts-expect-error
    const status = this.recipients[0].details.status || 'sent'
    return status.charAt(0).toUpperCase() + status.slice(1)
  }

  createdAtInTimezone (timezone: string) {
    return moment(this.createdAt).tz(timezone)
  }
}
