import { UsesAuthenticatedUser, UsesKintellGroupPermission } from '@/mixins/index'
import pluralize from 'pluralize'

export default {
  mixins: [ UsesAuthenticatedUser, UsesKintellGroupPermission ],
  data () {
    return {
      isGroupAdminModalMailInOpen: false,
      sendToAll: false,
      recipients: [],
      excludedRecipients: [],
      totalPossibleRecipients: 0,
    }
  },
  computed: {
    numberOfRecipients () {
      // If we are sending to
      if (this.sendToAll) {
        return this.pagination.total - this.excludedRecipients.length
      }
      return this.recipients.length
    },
    sendToInMailButtonText () {
      return this.numberOfRecipients > 1
        ? `Send InMail to ${this.numberOfRecipients} members`
        : `Send InMail to ${this.firstRecipient?.firstName} ${this.firstRecipient?.lastName}`
    },
    totalOrSelectedMembersText () {
      const count = this.numberOfRecipients ? this.numberOfRecipients : this.pagination.total

      const formatted = count.toLocaleString()

      return `${formatted} ${pluralize('Member', formatted)} ${this.numberOfRecipients ? 'selected' : ''}`
    },
    firstRecipient () {
      return this.recipients[0]
    },
  },

  methods: {
    addOrRemoveEmailRecipient (value, member, kintellGroup) {
      if (value) {
        this.appendMember(this.transformUser(member, kintellGroup))
        return
      }
      this.prependMember(member.id)
    },
    appendMember (member) {
      if (this.sendToAll) {
        this.excludedRecipients = this.excludedRecipients.filter(id => id !== member.id)
      }
      this.recipients.push(member)
    },
    prependMember (memberId) {
      if (this.sendToAll) {
        // If the user has already been added
        this.excludedRecipients.push(memberId)
        return
      }
      this.recipients = this.recipients.filter(member => member.id !== memberId)
    },
    addMemberToRecipientsAndOpenSendInMail (member, kintellGroup) {
      this.resetMailIn()
      this.addOrRemoveEmailRecipient(true, member, kintellGroup)
      this.isGroupAdminModalMailInOpen = true
    },
    isEmailRecipient (member) {
      if (this.sendToAll) {
        return !this.excludedRecipients.includes(member.id)
      }
      return this.recipients.map(currentMember => currentMember.id).includes(member.id)
    },
    resetMailIn () {
      this.excludedRecipients = []
      this.recipients = []
      this.member = null
      this.sendToAll = false
    },
    updateRecipientsForSendAll (allUsers, kintellGroup) {
      this.recipients = allUsers
        .map(user => this.transformUser(user, kintellGroup))
        .filter(user => !this.excludedRecipients.includes(user.id))
    },
    transformUser (user, kintellGroup) {
      const aiMatching = user.firstGroupPermissions?.aiMatches
      const membersRole = this.getGroupRole(user.kintellGroupRole, user.kintellCards) ?? ''
      return {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.hiddenEmail,
        role: membersRole.toLowerCase()?.replace(' ', '-'),
        aiMatches: kintellGroup.hasAiMatching ? JSON.parse(aiMatching) : false
      }
    }
  },
}
