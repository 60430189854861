export default {
  width: 56,
  height: 58,
  stroke: true,
  svg: `
  <path fill="transparent" d="M53.1724 20.2754H2" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path fill="transparent" d="M24.8448 50.4307H6.56897C5.3572 50.4307 4.19507 49.9493 3.33822 49.0925C2.48137 48.2356 2 47.0735 2 45.8617V12.0514C2 10.8396 2.48137 9.67749 3.33822 8.82064C4.19507 7.96379 5.3572 7.48242 6.56897 7.48242H48.6035C49.8152 7.48242 50.9774 7.96379 51.8342 8.82064C52.691 9.67749 53.1724 10.8396 53.1724 12.0514V30.3273" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path fill="transparent" d="M13.8789 2V12.9655" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path fill="transparent" d="M41.2969 2V12.9655" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path fill="transparent" d="M41.2969 40.3809V47.6912" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path fill="transparent" d="M37.6406 44.0352H44.951" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path fill="transparent" d="M41.2936 55.0013C47.3497 55.0013 52.2592 50.0919 52.2592 44.0358C52.2592 37.9797 47.3497 33.0703 41.2936 33.0703C35.2376 33.0703 30.3281 37.9797 30.3281 44.0358C30.3281 50.0919 35.2376 55.0013 41.2936 55.0013Z" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
`
}
