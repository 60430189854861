/**
 * We want to prevent too many activity reported for `pageView` events.
 */
let prevPath = ''

export default function ({ $auth, $activity, route }) {
  if ($auth.loggedIn) {
    if (route.path === prevPath) {
      return
    }

    prevPath = route.path

    const payload = getPayload(route)

    $activity.pageView({
      ...payload
    })
  }
}

function getPayload (route) {
  switch (route.name) {
    // Group Home Page
    case 'g-kintellGroup':
      return {
        kintellGroupSlug: route.params.kintellGroup,
        name: 'kgHomePage'
      }
    // Group Video Channel Page
    case 'g-kintellGroup-videos':
      return {
        kintellGroupSlug: route.params.kintellGroup,
        name: 'kgChannel'
      }
    // Group Video Channel Page - Specific Channel
    case 'g-kintellGroup-videos-id':
      return {
        kintellGroupSlug: route.params.kintellGroup,
        name: 'kgChannel' + route.params.id
      }
    // Video Player Page - Not group type
    case 'v-slug':
      return {
        videoSlug: route.params.slug,
        name: 'videoPlayerPage',
        type: 'video'
      }
    // Group Apply Page
    case 'g-kintellGroup-apply':
      return {
        kintellGroupSlug: route.params.kintellGroup,
        name: 'kgApplyPage',
      }
    // Group Welcome Page
    case 'g-kintellGroup-welcome':
      return {
        kintellGroupSlug: route.params.kintellGroup,
        name: route.query.page === 'submitted' ? 'kgSubmitted' : 'kgAwaitingVerification',
      }
    // Video Owner Page View
    case 'my-kintell-videos-id':
      return {
        videoId: route.params.id,
        name: 'videoPlayerPage',
        type: 'video',
        extra: {
          isOwnerView: true
        }
      }
    case 'my-kintell-kintell-cards-create':
      return {
        name: 'addNewCard',
        type: 'createCard'
      }
    case 'my-kintell-kintell-cards-create-group':
      return {
        name: 'addGroupCard',
        type: 'createCard'
      }
  }
}
