import { filter } from 'lodash-es'
import { sync } from 'vuex-pathify'

export default {
  data () {
    return {
      focus: {}
    }
  },
  methods: {
    blurField (field, touch = true) {
      if (touch) {
        this.$v.fields[field].$touch()
      }
      this.$set(this.focus, field, false)
    },
    focusField (field) {
      if (this.apiErrors[field]) {
        const errors = this.apiErrors
        delete errors[field]
        this.apiErrors = errors
      }
      this.$set(this.focus, field, true)
    },
    blurActiveElement () {
      // reset the focus
      if (document.activeElement) {
        document.activeElement.blur()
      }
    }
  },
  computed: {
    ...sync({
      apiErrors: 'form/apiErrors'
    }),
    isOneOfFocused () {
      return fields => {
        return filter(fields, field => this.focus[field]).length > 0
      }
    }
  }
}
