import { render, staticRenderFns } from "./KLayoutHeaderAvatar.vue?vue&type=template&id=cfc51456&"
import script from "./KLayoutHeaderAvatar.vue?vue&type=script&lang=js&"
export * from "./KLayoutHeaderAvatar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {KUserAvatar: require('/opt/atlassian/pipelines/agent/build/app/components/shared/User/KUserAvatar.vue').default})


/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
installComponents(component, {VBadge})
