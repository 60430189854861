export default {
  width: 40,
  height: 52,
  svg: `
    <g fill-rule="nonzero">
      <path d="M11.875 43.75H28.125C28.9534 43.75 29.625 43.0784 29.625 42.25C29.625 41.4216 28.9534 40.75 28.125 40.75H11.875C11.0466 40.75 10.375 41.4216 10.375 42.25C10.375 43.0784 11.0466 43.75 11.875 43.75Z"/>
      <path d="M8.75 13C8.75 12.1716 9.42157 11.5 10.25 11.5H11.875C17.1907 11.5 21.5 15.8093 21.5 21.125V24.375C21.5 25.2034 20.8284 25.875 20 25.875H18.375C13.0593 25.875 8.75 21.5657 8.75 16.25V13ZM11.75 14.5V16.25C11.75 19.9089 14.7161 22.875 18.375 22.875H18.5V21.125C18.5 17.4661 15.5339 14.5 11.875 14.5H11.75Z"/>
      <path d="M21.5 42.25V9.75C21.5 8.92157 20.8284 8.25 20 8.25C19.1716 8.25 18.5 8.92157 18.5 9.75V42.25C18.5 43.0784 19.1716 43.75 20 43.75C20.8284 43.75 21.5 43.0784 21.5 42.25Z"/>
      <path d="M29.75 18H28.125C22.8093 18 18.5 22.3093 18.5 27.625V30.875C18.5 31.7034 19.1716 32.375 20 32.375H21.625C26.9407 32.375 31.25 28.0657 31.25 22.75V19.5C31.25 18.6716 30.5784 18 29.75 18ZM28.25 21V22.75C28.25 26.4089 25.2839 29.375 21.625 29.375H21.5V27.625C21.5 23.9661 24.4661 21 28.125 21H28.25Z"/>
      <path d="M10.375 36.3008V42.25C10.375 47.5658 14.6843 51.875 20 51.875C25.3158 51.875 29.625 47.5658 29.625 42.25V36.3006C35.5885 32.8819 39.375 26.5306 39.375 19.5C39.375 7.96438 29.3673 -1.04557 17.7747 0.249571C9.21844 1.20548 2.17305 7.93591 0.862565 16.4272C-0.398535 24.5986 3.49301 32.3589 10.375 36.3008ZM36.375 19.5C36.375 25.7079 32.8819 31.2882 27.4416 34.0728C26.9404 34.3293 26.625 34.845 26.625 35.408V42.25C26.625 45.9089 23.6589 48.875 20 48.875C16.3412 48.875 13.375 45.9089 13.375 42.25V35.408C13.375 34.8449 13.0597 34.3293 12.5584 34.0728C6.30713 30.8733 2.71716 24.0791 3.82746 16.8848C4.93092 9.73491 10.8957 4.03675 18.1078 3.23102C27.9182 2.13499 36.375 9.74865 36.375 19.5Z"/>
    </g>
  `
}
