//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BindsAttrs, Sizeable } from '@/mixins'

export default {
  mixins: [ BindsAttrs, Sizeable ],
  inheritAttrs: false,
  props: {
    removable: {
      type: Boolean,
      default: false
    },
    textColor: {
      type: String,
      default: 'accent',
    },
    color: {
      type: String,
      default: 'var(--v-light-aqua-base)',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes () {
      return {
        ...this.sizeClasses,
        'k-chip': true,
        'k-chip--removable': this.removable,
        'k-chip--outlined': this.outlined,
      }
    }
  }
}
