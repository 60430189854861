export default {
  width: 19,
  height: 19,
  stroke: true,
  svg: `
  <g clip-path="url(#clip0)">
    <path d="M8.3828 16.625H3.1665C2.7295 16.625 2.37484 16.2703 2.37484 15.8333V3.16667C2.37484 2.72967 2.7295 2.375 3.1665 2.375H12.6665C13.1035 2.375 13.4582 2.72967 13.4582 3.16667V7.97129C13.7178 7.93883 13.9815 7.91667 14.2498 7.91667C14.5182 7.91667 14.7818 7.93883 15.0415 7.97129V2.375C15.0415 1.06558 13.9759 0 12.6665 0H3.1665C1.85709 0 0.791504 1.06558 0.791504 2.375V16.625C0.791504 17.9344 1.85709 19 3.1665 19H10.0746C9.34071 18.354 8.75646 17.5449 8.3828 16.625Z"/>
    <path d="M14.25 19C16.8736 19 19 16.8736 19 14.25C19 11.6264 16.8736 9.5 14.25 9.5C11.6264 9.5 9.5 11.6264 9.5 14.25C9.5 16.8736 11.6264 19 14.25 19ZM13.4583 11.0833H15.0417V13.4583H17.4167V15.0417H13.4583V11.0833Z"/>
  </g>
  `
}
