export default {
  hintDesktop: 'Click to upload or drag your photo here :)',
  hintMobile: 'Click to upload or take your photo :)',
  loading: 'Uploading…',
  noSupported: 'Browser is not supported, please use IE10+ or other browsers',
  success: 'Upload success',
  fail: 'Upload failed',
  togglePreview: 'Preview',
  btn: {
    off: 'Cancel',
    close: 'Close',
    back: 'Upload new',
    save: 'Save',
    confirm: 'Confirm',
    edit: 'Edit Photo',
    upload: 'Upload Photo'
  },
  error: {
    onlyImg: 'Image only',
    outOfSize: 'Image exceeds size limit: {size}',
    lowestPx: 'Image\'s size is too low. Expected at least: width px {width}'
  },
  missing: {
    title: 'Your Kintell Profile',
    body: 'Before you get to creating your first {type} card, we need a bit more information for your profile :)'
  }
}
