export default {
  faq: [
    {
      q: 'What is the vetting process for me to become an advisor?',
      a: 'After drafting your first public Kintell card, you can click "Publish". Don\'t forget to add your LinkedIn, facebook page or a personal website to your profile. Kintell team will be in touch within a few hours. {faqBecomeAdvisor}',
    },
    {
      q: 'Which countries does Kintell cover?',
      a: 'Kintell is global from day one. For free bookings, anyone with internet access can use Kintell. For paid bookings, however, currently advisors in supported countries listed {faqAdminFee} can receive payment from anyone in the world.'
    },
    {
      q: 'What about other countries?',
      a: 'We encourage everyone from anywhere in the world to join Kintell and become an advisor so they\'re all set for when we release payouts in their country.'
    },
    {
      q: 'How do others find me on Kintell?',
      a: 'People can use the "Find an Advisor" page to find you using keywords and filters. You can also share the link of your Kintell cards with others via email, text messaging, or social media.',
    },
    {
      q: 'How is the video quality on Kintell?',
      a: 'Kintell has its own high-quality video platform that works on any browser and device, even when internet is poor. It is web-based so you don\'t need to download anything. {faqVideoConsult}',
    },
    {
      q: 'Is Kintell video platform secure?',
      a: 'Kintell video is highly secure by using DTLS/SRTP protocols. Your IP address and content of your chat is strictly confidential.',
    },
    {
      q: 'How do I manage my calendar and time?',
      a: 'You can set your weekly availability on Kintell and sync multiple calendars with Kintell so your busy time slots are blocked in Kintell. {faqBecomeAdvisor}',
    },
    {
      q: 'How do I get paid?',
      a: 'Once you have verified your bank account with Stripe, the global payment gateway we use, you will start receiving payouts for your paid bookings. {faqReceivingPayments}',
    },
    {
      q: 'Can I provide my service for free?',
      a: 'Yes, absolutely! Kintell is free for you to use if you contribute free advice to the community. {faqBookingFee}',
    },
    {
      q: 'How should I price my service?',
      a: 'It is up to you but initially you can refer to others\' fee level in your own industry on {findAnAdvisor} page to decide.',
    },
    {
      q: 'What fee do I pay to Kintell?',
      a: 'Please see our {pricing} page. You only pay Kintell if there is a paid transaction, otherwise everything is free to use.',
    },
    {
      q: 'What if a learner is not happy with the advice?',
      a: 'Most of our learners are very friendly. In case if someone is not happy, they can make a complaint to us. We will investigate and may refund the learner. Improving your rating as an advisor is important. {faqRatings}',
    },
  ]
}
