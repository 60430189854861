import { URL_APP_STORE, URL_PLAY_STORE } from '@/constants/links'

function internalLink (href, text) {
  return `<a href="${href}">${text}</a>`
}

function kintellLink (href, text) {
  return `<a href="${process.env.APP_URL}${href}" target="_blank">${text}</a>`
}

function externalLink (href, text) {
  return `<a href="${href}" target="_blank">${text}</a>`
}

const FAQ_LINKS = {
  device: text => internalLink('#device', text),
  kintellAdminFee: text => internalLink('#kintell-admin-fee', text),
  kintellBasicAccount: text => internalLink('#kintell-basic', text),
  kintellProAccount: text => internalLink('#kintell-pro', text),
  kintellPlusAccount: text => internalLink('#kintell-plus', text),
  personalRoom: text => internalLink('#personal-room', text),
  ratingAndReviews: text => internalLink('#ratings-and-reviews', text),
  setupAccount: text => internalLink('#setup-account', text),
  typesOfKintellCards: text => internalLink('#types-of-kintell-cards', text),
}


const LINKS = {
  appStore: text => externalLink(URL_APP_STORE, text),
  articleCoaches: text => kintellLink('/articles/for-coaches', text),
  articleConsultants: text => kintellLink('/articles/for-consultants', text),
  articleCommunities: text => kintellLink('/articles/for-communities', text),
  articleUniversities: text => kintellLink('/articles/for-mentoring', text),
  bookings: text => kintellLink('/my-kintell/bookings', text),
  bookingInitiate: text => kintellLink('/my-kintell/bookings', text),
  cancellationPolicy: text => kintellLink('/cancellation-policy', text),
  calendarAndBookings: text => kintellLink('/features/calendar-and-bookings', text),
  codeOfConduct: text => kintellLink('/code-of-conduct', text),
  exampleJane: text => externalLink('https://l.kintell.com/PzxNab', text),
  featureKCards: text => kintellLink('/f/k-cards', text),
  forBusiness: text => kintellLink('/kb', text),
  getInTouch: text => kintellLink('/get-in-touch', text),
  gretchenDobson: text => externalLink('https://l.kintell.com/NJHqe0', text),
  home: text => kintellLink('/', text),
  marketplace: text => kintellLink('/search', text),
  myArchiveBookings: text => kintellLink('/my-kintell/bookings/archive', text),
  myBilling: text => kintellLink('/my-kintell/my-details/billing', text),
  myCalendar: text => kintellLink('/my-kintell/my-calendar', text),
  myDashboard: text => kintellLink('/my-kintell/dashboard', text),
  myDetails: text => kintellLink('/my-kintell/my-details', text),
  myKCards: text => kintellLink('/my-kintell/kintell-cards', text),
  myWorkingHours: text => kintellLink('/my-kintell/my-calendar/working-hours', text),
  playStore: text => externalLink(URL_PLAY_STORE, text),
  pricing: text => kintellLink('/pricing', text),
  stripeObligation: text => externalLink('https://support.stripe.com/questions/know-your-customer-obligations', text),
}

export default {
  items: [
    {
      id: 'user-account',
      title: 'User account',
      description: 'There are a few things you need to know about your Kintell account.',
      image: require('~images/faq/user-account.png'),
      sections: [
        {
          id: 'setup-account',
          title: 'Setting up your account',
          questions: [
            {
              q: 'Do I need to sign up at all?',
              a: `If you just want to join a video call as a guest, no. Also if you just want to watch the video recording from a past meeting, again, nope! You can also browse our ${LINKS.marketplace('marketplace')} without any user account. But if you want to make a booking or host a video meeting, then you’ll need a user account. It only takes you 60 seconds to join Kintell, and it’s free :)`,
            },
            {
              q: 'What details do I need to provide when signing up?',
              a: `We need your real name (so we know what to call you), your email (so we can get in touch) and your mobile number (so we can send you alerts). That's it! Or, if you’ve got a LinkedIn profile, you can sign up using that, too. Easy peasy.`,
            },
            {
              q: 'Why do I need a photo for my account?',
              a: `Kintell is about real people, and profile pictures are essential – especially for becoming an advisor on our ${LINKS.marketplace('marketplace')}. A picture puts a face to a name and verifies that you are genuine to other users, and lets them know that they’ve got the right person when you jump onto your session. Although it is not compulsory to have a profile photo when you first sign up to Kintell, you will need one when you publish a Marketplace Card into our ${LINKS.marketplace('marketplace')} or a Group Card into a community. You can learn more about ‘Kintell for Business’ ${LINKS.forBusiness('here')}.`,
            },
            {
              q: 'Should I register a different Kintell account for my activities associated with my organisation?',
              a: `Nope, no need. You’ll only need one ${LINKS.myDetails('Kintell account')} that is matched with your email address and mobile number. That’ll give you the flexibility to do all kinds of activities for yourself, on our ${LINKS.marketplace('marketplace')}, or for your organisation. Take advantage of our ${LINKS.featureKCards('Kintell Card')} system, and publish cards into your organisation’s group on Kintell, and have a separate Private Card for your own clients to book you. That way, you’re building your reputation through many cards, rather than splitting up your impact across several accounts.`
            },
          ]
        },
        {
          id: 'kintell-basic',
          title: 'Kintell Basic',
          questions: [
            {
              q: 'What is a Basic account?',
              a: `Entry, free, just-starting-out, testing-the-waters; call it what you will. Kintell Basic is your free Kintell account, where anyone in the world can enjoy the wonderful features of Kintell like free scheduling, video calls from one-on-one to groups up to 10, and connections to the Kintell ${LINKS.marketplace('marketplace')} where a world of learning and mentoring awaits. Learn more about them ${LINKS.pricing('here')}.`
            },
            {
              q: 'What features does a Basic account have?',
              a: `Our Basic plan includes all the features Kintell users love: multi-party video meetings, no time limits, cloud recording, payment tools, and advanced scheduling that’ll save you from back-and-forth emails trying to find a time to chat. We’ve made it as easy as possible for you to start learning and mentoring. Learn more ${LINKS.pricing('here')}.`,
            },
            {
              q: 'Will a Basic account always be free?',
              a: `Yes, as long as you follow the ${LINKS.codeOfConduct('Code of Conduct')} of our community! The Basic account is what reflects what we value here at Kintell: sharing knowledge with the world, with kindness and intelligence. We want to empower everyone in the world to have access to video conferencing tools and knowledge – and we take that mission seriously!`,
            },
          ]
        },
        {
          id: 'kintell-plus',
          title: 'Kintell Plus',
          questions: [
            {
              q: 'What is a Plus account?',
              a: `Serious about your impact? Consider upgrading to a Kintell Plus account. It’s a paid subscription upgrade that offers much, much more powerful features than the free Basic account. Subscribe monthly, or on an annual basis, and start making change happen. It’s a small subscription fee too, so you’ll get great value. Learn more about Kintell Plus ${LINKS.pricing('here')}.`,
            },
            {
              q: 'What features does a Plus account have in comparison to the free Basic account?',
              a: `You’re in for a treat. First of all, as a Plus user you can host meetings with up to 25 participants.
              You'll also enjoy unlimited cloud recording storage for your video recordings, and have the option to download your recordings - something not available on Kintell Basic. Finally, if you are having paid bookings from your Marketplace Cards on the ${LINKS.marketplace('marketplace')}, the admin fee charged by Kintell will drop to a tiny tiny 5% if you are a Plus user. You can learn more about the Kintell admin fee ${FAQ_LINKS.kintellAdminFee('here')}.`,
            },
            {
              q: 'Will having a Plus account reduce the admin fees of Kintell for my paid bookings?',
              a: `Absolutely! Upgrade to a Kintell Plus account (${LINKS.pricing('here')}), and the admin fee drops to just 5% for all your paid bookings, including bookings on both Private and Marketplace Cards. Having a Kintell Plus account means you get the advantages of the lower admin fee, and you still collect ratings and reviews on your Marketplace Card on our ${LINKS.marketplace('marketplace')}. How’s that for magic!`,
            },
            {
              q: 'How do I upgrade to a Kintell Plus account?',
              a: `Ah, we’re glad you asked. There are two ways to do it, so it depends on which one grabs your fancy. The first way to upgrade to Kintell Plus is by paying a subscription fee (see ${LINKS.pricing('Pricing')}), but that’s a no-brainer. Kintell’s better with friends, so we’ve got a second way: invite new users to Kintell, and for every new user who signs up to Kintell with your personal invite link (or invite email), we’ll give you Kintell Plus subscription credit. Just ${LINKS.getInTouch('get in touch')} to learn more and we’ll sort you out.`,
            },
            {
              q: 'After I subscribe to a Plus account, can I downgrade back to the free Basic account?',
              a: `For sure! Sometimes that’s the right choice, so we try to make it easy. You can downgrade to the Basic tier any time on both monthly and annual plans – but sorry, there’s no refund for the subscription fees paid. The downgrade will be effective immediately, so we recommend downgrading closer to the next billing date.`,
            },
            {
              q: 'Can I roll over my one month free trial credit?',
              a: `How’s this for a sweet deal: Everyone can upgrade to Kintell Plus for a free trial month. But, take note! This trial credit can only be claimed once and once only. So, if for instance you upgrade to a Plus account today, and after one week you downgrade back to your Basic free account, the remaining three weeks won’t roll over and they can’t be used another time. For instance if you try to upgrade to Plus again after three months, you are no longer qualified for the free trial.`,
            },
          ]
        },
        {
          id: 'kintell-pro',
          title: 'Kintell Pro',
          questions: [
            {
              q: 'What is a Pro account?',
              a: `Looking for something a little more top shelf? You’ve come to the right place, my friend. Kintell Pro is our top tier subscription with the most powerful features. Think data analytics, personal branding, and the ability to host video meetings for up to 50 participants. Pro is currently in beta, and available to selected power users. If you are interested in using it now, just ${LINKS.getInTouch('get in touch')} with us and we’ll fix you up. Learn more about Kintell Pro ${LINKS.pricing('here')}.`,
            },
            {
              q: 'What unique features does a Pro account have?',
              a: `Prepare to be impressed. A Pro account user will be able to host a meeting for up to 50 participants, and they have their personal branding on their Private Cards and associated email communications with their learners or clients. You can also upload video messages on your Cards to make them much more engaging and personal. Also, if a Pro user has a Marketplace Card published in our ${LINKS.marketplace('marketplace')}, their cards will enjoy a boost in our search algorithm that could lead to new clients. Among many other premium features, a Pro user will also have a minimum Kintell admin fee (5%) on all of their paid bookings, including Private and Marketplace Cards. Kintell Pro is what other platforms wished they were. See Gretchen’s ${LINKS.articleConsultants('success story')} and her ${LINKS.gretchenDobson('Pro Private Card')}.`,
            },
            {
              q: 'What does the data analytic feature offer to Pro users?',
              a: `
                The Pro users will have access to a list of email addresses of all their learners who booked them on Kintell, as well as reports about the total number of bookings and the booking income they have in any given period of time (which can be handy when it’s tax reporting season).`,
            },
            {
              q: 'Why is Pro still in beta and when will it be officially launched?',
              a: `It is coming soon! We want to take the time to make Kintell Pro the best it can be when it comes to knowledge sharing and not launch anything onto the world that’s half-cocked or underbaked. If you are interested in using it now, just ${LINKS.getInTouch('get in touch')} with us and we’ll work some magic.`
            },
            {
              q: 'How can I subscribe to Kintell Pro?',
              a: `Let’s get the ball rolling! Please ${LINKS.getInTouch('contact us')} if you are interested in having a Pro account, and we’ll get back to you within 24 hours to understand if this suits your practice.`
            },
          ]
        },
        {
          id: 'deleting-account',
          title: 'Deleting account',
          questions: [
            {
              q: `Do I need to delete my account if I want to remove my offering on the ${LINKS.marketplace('insight marketplace')}?`,
              a: `No, not at all! Deleting your account for the sake of a single offering will undo all your hard work. Instead, go to your ${LINKS.myKCards('Kintell Card')} listed on the ${LINKS.marketplace('marketplace')}, click “Edit” and then click “Unpublish”. It will remove your Card from the marketplace. You can always edit your Card, too, to better reflect your offerings.`
            },
            {
              q: `How do I delete my Kintell account?`,
              a: `Just checking: If you want to remove your marketplace card on the ${LINKS.marketplace('marketplace')} you just need to go to ‘My Cards’ and go into the ‘Edit’ mode and ‘Unpublish’ it. You don’t need to delete your account! But if you want to remove your entire Kintell account, well, we’re sorry to see you go but we respect your decision. Just send us a request via the ${LINKS.getInTouch('Get in Touch')} section, and we’ll follow your instructions and let you know promptly.`
            }
          ]
        }
      ]
    },
    {
      id: 'video-meetings',
      title: 'Video meetings',
      description: 'Find out how our video conferencing works best for you!',
      image: require('~images/faq/video-meetings.png'),
      sections: [
        {
          id: 'device',
          title: 'Device',
          questions: [
            {
              q: `Do I need any special equipment or device for the video session?`,
              a: `All of our Kintell video sessions are held on ${LINKS.home('Kintell.com')}, or via the iPhone or Android Kintell Companion App (available at the ${LINKS.appStore('App Store')} or
              ${LINKS.playStore('Google Play')}), and you can use any device you like. If you’re using your laptop or desktop computer, you just need to be sure you can use your camera and microphone (pretty important if you’re doing a video chat, I reckon!). We recommend throwing on your headphones to block out surrounding noises and to avoid echo :)`
            },
            {
              q: `How can I test my device before a video session?`,
              a: `As a Kintell user – it only takes you 60 seconds to join Kintell, and it’s free :) – you can go to your ${LINKS.myDashboard('dashboard')} and start an instant meeting using your ${FAQ_LINKS.personalRoom('Personal Room')}. This will allow you to know if your camera and microphone are working properly. If you are not a Kintell user but receive a calendar invite with a Kintell video link, you can test your device during the process when you click the link to join the meeting.`
            },
            {
              q: `Does Kintell work on mobile devices and tablets?`,
              a: `Yes! Kintell works on PC, laptop, smartphones and tablets. For devices that use iOS and Android operating systems, we have companion apps to hold the video conferencing which makes the experience tidier and, if you don’t mind us tooting our own horns, that much better. We support iOS 14.0 or later and Android 8.0 or later. For completing your payment or making a booking, you can just use a browser installed on your phone, PC, or tablet.`
            },
            {
              q: `What if my device is too old?`,
              a: `Some older devices (usually tablets or phones over five years old) can't process the video in real-time (especially multi-party video), so you might not get the best video experience. Sorry! If you're having problems with the device you're using, jump onto another device and rejoin the booking so you can continue your session and keep on learning.`
            },
            {
              q: `What browser or operating system works best with Kintell?`,
              a: `Google Chrome – whatever the latest version is – installed on Mac or Windows. Other browsers including Edge, Firefox and Safari work as well, but we’ve found Chrome is the most stable and is going to give you the best Kintell experience.`
            },
          ]
        },
        {
          id: 'personal-room',
          title: 'Personal Room',
          questions: [
            {
              q: `What is the Kintell Personal Room?`,
              a: `If you’re a Kintell user, then you’ll have a unique URL (available in your ${LINKS.myDashboard('dashboard')}) consisting of your full name. It’s your own personal suite, where you can host multi-party video with unlimited duration – and record the meetings. Think of it as your digital office! And your guests can join the meeting ${FAQ_LINKS.setupAccount('without signing up to Kintell')}.`
            },
            {
              q: `How secure is my Personal Room?`,
              a: `Very secure! By default, the door is locked and your guests will need to request to join – to knock on the door before you let them in. We’ve added a literal knocking sound so that you know when someone’s waiting to enter. You won’t miss a beat! You can also disable knocking and let guests join the call without needing your approval, if you like to dance on the edge.`
            },
            {
              q: `When should I use my Personal Room?`,
              a: `The Personal Room is great for instant meetings – just share the link and dive on in. You can also use it for scheduled meetings, but you need to know the time of the meeting in advance so that means you’ll still be relying on your normal calendar sharing practices - Google, Outlook, etc. If you want more advanced scheduling features of Kintell (such as rescheduling and payment integration), then use our powerful ${FAQ_LINKS.typesOfKintellCards('Private Card')} feature - it’s free!`
            },
            {
              q: `Can I schedule video meetings with my Personal Room link?`,
              a: `Absolutely you can. Copy the URL from your dashboard and put it in a Google or Outlook calendar invite, and your guests can join the meeting ${FAQ_LINKS.setupAccount('without signing up to Kintell')}. It’s fast and easy (if we don’t say so ourselves)!`
            }
          ]
        },
        {
          id: 'video-functionalities',
          title: 'Video functionalities',
          questions: [
            {
              q: `How many people can join my Kintell video meeting?`,
              a: `For a free Kintell Basic account, you can have up to 10 people (including yourself) in the call for as long as you want. For a paid Kintell Plus account, you can have up to 25 people and really get the party started. For a paid Kintell Pro account (still in beta for a limited number of users), you can have up to 50 of your closest chums in one session. Learn more ${LINKS.pricing('here')}.`
            },
            {
              q: `Do guests need to sign-up to join a video call?`,
              a: `No, the guests can simply pop in their name and join the call. No login needed! The host of a video call, however, needs a Kintell account to manage guests, record the session, and take advantage of all the other lovely Kintell features.`
            },
            {
              q: `Can I send text messages or files before, during and after a scheduled booking?`,
              a: `Good question! Yes indeed – we've included a text chat in your Kintell session and on your booking page so that you can send messages, links and files before, during and after a booking! After the booking, you can find the booking in ‘${LINKS.myArchiveBookings('Archive')}’ and the chat channel of the booking still works for you to contact the other person, who will receive an email notification about your new message.`
            },
            {
              q: `Can I send text messages or files before, during and after a meeting in my Personal Room?`,
              a: `Another great question. (You’re on good form!) When a meeting is hosted in someone’s ${FAQ_LINKS.personalRoom('Personal Room')}, you can send text messages in the chat channel during a meeting. But you can’t send a text message before or after this meeting. It is similar to a Zoom room, which only takes care of communication during the live meeting.`
            },
            {
              q: `Can I share my screen?`,
              a: `If you’re on a laptop or desktop, then absolutely. Share to your heart’s content! If you’re on the Kintell Companion App on your mobile device, then you won’t be able to share your screen – but you will be able to see the other party's screen if they share it.`
            },
            {
              q: `Can I stream an audio file from my computer?`,
              a: `Yeah you can. When sharing your screen from a computer, there is an option to ‘share audio’ too. Kintell will stream the audio from your computer to your guest’s or guests’ with crisp, high quality. For best results, we reckon you should wear a headset to avoid echo from your own loudspeaker and the dreaded feedback loop. This feature is currently available in Chrome both on Mac and Windows, but not all browsers support it so keep that in mind when you’re giving it a go.`
            },
            {
              q: `When will I be the ‘host’ of a multi-party meeting?`,
              a: `The privilege and pleasure – no wonder you want to jump right in and host your own. You’re able to host when the meeting takes place in your ${FAQ_LINKS.personalRoom('Personal Room')}, or you are one of the two parties of a ${LINKS.calendarAndBookings('booking')}. If you are invited to someone else’s ${FAQ_LINKS.personalRoom('Personal Room')} or as a guest to a confirmed booking, then you are a guest. So try not to leave a mess!`
            },
            {
              q: `As a host of a meeting, what can I do?`,
              a: `As a host, you will need to let others come in if they ‘knock’ to join your room. You’re the bouncer and the party host, and you will be notified with a knocking sound and visual prompts, unless you change the setting to let anyone come in without knocking. You also have the infinite cosmic power of being able to mute others or disable their cams, or even remove them from the meeting. Last but not least, you can record the meeting and preserve it for posterity (and also share it with others).`
            },
            {
              q: `Can I be the ‘co-host’ of a multi-party meeting?`,
              a: `Why yes, you can. The host of the meeting can enable you as a co-host. The great news is that the co-host has all the privileges of the host, but – and it’s a small but – they can’t record the session.`
            },
            {
              q: `Can I invite others to join a meeting?`,
              a: `Absolutely! You can invite others before a booking or during a meeting. Just click ‘invite others’ on top of your video conferencing interface and you will have the invite link to share with the guests to join you. When you confirm a booking, you can find an invite link to invite your guests in the booking details page under ${LINKS.bookings('confirmed bookings')} on your dashboard. Easy as pie!`
            },
            {
              q: `Can the host turn off and on the guests’ mic and cam?`,
              a: `As a host you can disable a guest’s cam or mic. But for privacy reasons, once you did that, you can’t enable them again. The guest will need to enable their own cam/mic after being disabled by the host.`
            },

          ]
        },
        {
          id: 'recording',
          title: 'Recording',
          questions: [
            {
              q: `Can I record a video?`,
              a: `Yes you sure can – and it’s free! How’s that for a sweet deal. Kintell offers unlimited cloud recording storage, so your videos will be preserved in the cloud for free! Learn more ${LINKS.pricing('here')}.`
            },
            {
              q: `How can I share the recording with someone else?`,
              a: `This is so easy! Share it with a short link so that your invitee can play the video natively on the Kintell Video Player. Kintell Plus users also have the option of allowing others to download their recording – so no more messing trying to lug around big files through shared drives or via email.`
            },
            {
              q: `When will the recording be ready after a meeting?`,
              a: `Kintell will start the preparation of a recording as soon as you press the ‘End the booking’ button. After that, the cogs will whirr away in the background for a while as we do some heavy lifting. Kintell will also notify you via email when the recording is ready – you can share the recording with your guests using the share link or simply forward the email, easy!`,
            },
            {
              q: `Who will have access to the recording files after a meeting?`,
              a: `If you press the ‘record’ button, then you (and only you, as the Platters once sang) have the link to the recorded video and audio. You can decide who you want to share the recording with. We’re very serious about protecting your IP!`,
            },
            {
              q: `Is there an audio recording as well?`,
              a: `Well of course there is. After the recording is ready, Kintell player allows you to play either the video or audio stream separately, which is handy when you’re out and about on your mobile data plan. Also, if you’re a Plus user, you can download either of the audio or video files individually.`,
            },
            {
              q: `What about transcripts?`,
              a: `You read our minds. That’s also on the roadmap, and we’ll be coming to that attraction soon enough. Stay tuned :)`,
            },
            {
              q: `Where can I download my videos?`,
              a: `Plus account users can download their video anytime in the booking details page in the ${LINKS.myArchiveBookings('booking archive')}. Go to ‘Archive’, find the booking, and then go to ‘Booking details’. For a free user (${FAQ_LINKS.kintellBasicAccount('Basic account')}), you can only play your recordings. To download your first video, you can start the free trial of your ${FAQ_LINKS.kintellPlusAccount('Plus account')} for a month to try the experience. Learn more ${LINKS.pricing('here')}.`,
            },
            {
              q: `Can others download my videos?`,
              a: `If you press the ‘record’ button, then you and only you have the link to the recorded video and audio. It’s safe under proverbial lock and key. Also, only you can change the access permissions of the recording. And, according to the Platters, only you, and you alone can thrill me like you do. By default, if you share the recording link, anyone with the link can only play the recording (but not download). If you have a ${FAQ_LINKS.kintellPlusAccount('Plus account')}, then you’ll have access to more advanced sharing permissions. Those with the link won’t need to have a Kintell account.`,
            },
            {
              q: `Do people need a Kintell account to watch my shared recordings?`,
              a: `Nope! All they need to do is to pop in their first name and they can immediately start to play the recording. No sign-ups or log-ins or accounts needed! If you’ve been generous and enabled download permission, they can also download it. In industry jargon, we call that ‘super easy’.`,
            },
            {
              q: `How do I know who has watched my recordings?`,
              a: `If you are the owner of a recording, you’ll be able to find the information on the video player page.`,
            },
            {
              q: `How secure and private are my videos?`,
              a: `It’s the Fort Knox of video recordings. Your video recordings are only visible and accessible by you unless you share it with others. It is securely stored in our AWS (Amazon Web Services) cloud storage, which has the shiniest and most robust cybersecurity standards globally.`,
            },
          ]
        },
        {
          id: 'troubleshooting',
          title: 'Troubleshooting',
          questions: [
            {
              q: `If I am on a call and the audio and camera don't seem to be working what can I do?`,
              a: `If it’s the first time you’re using a device for a Kintell call, then we recommend that you ${FAQ_LINKS.device('test that device before your first session')}. If you’ve used it before and it’s suddenly packed it in, then you can refresh the page if you are on a computer, or leave and join the call again if you are using a mobile. Usually this will allow Kintell to capture the mic and camera again. You can also try to join from another device, including computers, smartphones and tablets, and either via the mobile app or the browser. Remember, Chrome is the most reliable browser for Kintell at this time, so make sure you’re using that.`,
            },
            {
              q: `What happens if I have a poor internet connection?`,
              a: `You’ll see an icon indicating your network quality level during your video session (the oh-so familiar wifi icon). Besides, we’ve designed the Kintell video chat to prioritise audio over video, so even if you get knocked a step closer to dial-up, your conversation should go on uninterrupted. If you wish to share your screen when your internet is slow, you can turn off your camera temporarily so your internet only needs to deliver one video stream – your shared screen – and keep things light on the bandwidth.`,
            },
            {
              q: `What if I encounter other problems related to video conferencing?`,
              a: `You can always switch to another device as Kintell works on major browsers on a computer, smartphones and tablets across Android and iOS. Again, Google Chrome performs the best, in our experience. Please also ${LINKS.getInTouch('get-in-touch')} anytime and we will try our best to assist you.`,
            }
          ]
        }
      ]
    },
    {
      id: 'calendar-management',
      title: 'Calendar management',
      description: 'Get to know more about our time management features.',
      image: require('~images/faq/calendar-management.png'),
      sections: [
        {
          id: 'working-hours',
          title: 'Working hours',
          questions: [
            {
              q: `Can I set a daily time window so people can only book me within this window?`,
              a: `Absolutely! You can set your availability window in ‘${LINKS.myWorkingHours('Working Hours')}’ under ‘My Calendar’ to define your standard availability. On each ${FAQ_LINKS.typesOfKintellCards('Kintell Card')}, you can also define a card specific window. If you create a card for your grandma on the other side of the globe to book you, then you may want to offer her a time window that is different from your general working hours – you blessed grandchild, you.`,
            },
            {
              q: `How are time zone differences handled?`,
              a: `Kintell will automatically consider the time zone differences according to the ${LINKS.myDetails('location')} of the two parties. You’ll never have to fuss with calendar juggling, either, since it’ll sync to your calendar too. If you’ve moved to a new location, please make sure you update your timezone in ${LINKS.myDetails('My Account')} so that people don’t try to book you according to the wrong timeline!`,
            },
            {
              q: `Can I offer someone overseas a different time window to book me so it is easier for their time zone?`,
              a: `You’re in luck. Such flexibility is exactly what Kintell is designed for! You can create a dedicated ${LINKS.featureKCards('Kintell card')} for your contacts in another time zone, and set card-specific working hours that are different from your ${LINKS.myWorkingHours('standard working hours')} on Kintell. How cool is that!`,
            },
            {
              q: `When I am booking someone, are my working hours on Kintell still relevant?`,
              a: `When you are booking someone, Kintell also looks at how your ${LINKS.myWorkingHours('working hours')} are overlapping with the other person’s working hours so that you don’t accidentally double-book yourself (or them!). You can, however, always select the shiny ‘Ignore my synced calendar and working hours’ button. In this way, Kintell only looks at the other person’s working hours.`,
            },
            {
              q: `Can I block my availability on Kintell for a period of time?`,
              a: `First option is to sync your Kintell account with your calendar and block out that period of time as ‘busy’ in your calendar. We’ll automatically block your Kintell availability, just like any other event you have in your schedule. If you only have one or two Kintell Cards listed on the ${LINKS.marketplace('marketplace')}, another option is to temporarily unpublish them – to take them off the market for as long as you need. Simple!`,
            },
          ]
        },
        {
          id: 'calendar-syncing',
          title: 'Calendar syncing',
          questions: [
            {
              q: `Can I integrate my calendar with Kintell?`,
              a: `But of course! Google, Outlook and any other digital calendar are easily synced. Plus we’ll block out those entries in your ${LINKS.myCalendar('Kintell calendar')}, so you can be sure you won’t get double-booked.`,
            },
            {
              q: `Will anyone see the content in my personal calendar after it is synced with Kintell?`,
              a: `Nope. Your privacy is our top priority! Kintell has no access to the content of your calendar. It only ever blocks the time that is shown as ‘Busy’ in your personal calendars so no one can send a booking request to you for those busy times. Syncing your calendar also allows Kintell to push a confirmed booking into your calendar or remove it when it is rescheduled – and that’s it.`,
            },
            {
              q: `How far in advance can people send me a booking?`,
              a: `People can send you a booking request up to one whole year in advance – which is a long time to look ahead, but if that’s how you like to plan things, then go ahead. Once you confirm a booking Kintell automatically pushes a calendar event to your synced calendar up to one year into future. However, to read your calendar and block your busy times we currently support seven whole months into the future – which we think you’ll agree is still plenty of time :)`,
            },

          ]
        }
      ]
    },
    {
      id: 'bookings',
      title: 'Bookings',
      description: 'Everything you need to know about booking others and getting booked.',
      image: require('~images/faq/bookings.png'),
      sections: [
        {
          id: 'basic-concepts',
          title: 'Basic concepts',
          questions: [
            {
              q: `What is a booking?`,
              a: `A booking is simply a video meeting scheduled and carried out between two people via Kintell’s integrated scheduling and video conferencing system. It can be free, or paid. Simple!`,
            },
            {
              q: `Who is an advisor and who is a learner?`,
              a: `The Kintell system refers to people who get booked as the ‘advisor’, and people who book others as the ‘learner’, just to make things easier. The actual relationship between the two parties can be anything, of course. For example, a Professor can book her student for a follow up chat using Kintell. We’re all about equitable access to knowledge – but that sometimes means we’ve got to put a label so we know who’s who.`,
            },
            {
              q: `Do I need to be signed up to Kintell to make a booking?`,
              a: `To join a video call as a guest you don’t need to sign up, but to make a booking you do! We want to make sure that our users only receive valid booking requests from actual real people and not three dogs in an overcoat, so you'll have to sign up to Kintell when making a booking. Luckily it’s as easy as 1-2-3!`,
            },
            {
              q: `How do I book someone using Kintell?`,
              a: `Brace yourself, because this can get complicated: you will receive a short link from someone if she invites you to book her. Just click the link and go from there. If you are browsing our ${LINKS.marketplace('Insight Marketplace')} and you've found the right advisor (yay!) you can book them through their Marketplace Card by clicking 'Book now'. Did we say complicated? We meant easy! We've designed a simple process for you to suggest times and duration for the booking, and set up the payment.`,
            },
            {
              q: `How much does a booking cost?`,
              a: `Advisors decide their own fees per half hour, which is the typical length of a session (but they can go as long as you like). If it’s a free ${LINKS.featureKCards('Kintell Card')}, then it doesn't cost anything! How’s that for nice?`,
            }
          ]
        },
        {
          id: 'booking-request',
          title: 'Booking request',
          questions: [
            {
              q: `How does a booking request work?`,
              a: `The learner will suggest some times from an advisor’s available slots. If one of them works for you, then just select it and confirm your booking! If the time slots don't work, you can either reschedule the booking by suggesting other times, or cancel it. The quicker either of you can respond, the better – since that way things keep moving and shaking and you don’t disappoint. You can find out more in the Kintell ${LINKS.codeOfConduct('Code of Conduct')}.`,
            },
            {
              q: `What can I expect after I've sent a booking request?`,
              a: `The advisor will check out your suggested times and select one of them – or suggest alternative times if the ones you’ve offered don’t quite fit the bill. And if the advisor cancels a paid booking, you won't be charged and the money will stay snug in your back pocket. Check out our ${LINKS.cancellationPolicy('Cancellation Policy')} for more details.`,
            },
            {
              q: `How will I know if my booking request was accepted?`,
              a: `We keep the comms coming as and when you need them, so we'll send you email and SMS notifications during each phase of the booking process. You'll always know what's going on. We'll also pop the booking into your Dashboard under ${LINKS.bookings('Bookings')}.`,
            },
            {
              q: `How do I better secure a booking?`,
              a: `Knowledgeable people are usually busy, so when placing a booking request, try to give as many time slots as possible, and preferably a few days’ notice! This will increase the chances of striking a time that’ll be golden for you both.`,
            },

          ]
        },
        {
          id: 'rescheduling-bookings',
          title: 'Rescheduling bookings',
          questions: [
            {
              q: `How can I reschedule a new booking request if the proposed times don’t suit me?`,
              a: `Easy. Instead of confirming one of the proposed times, you click ‘Suggest alternative times’ when responding to the request. You can then select time slots that work for you and send them back, no stress. If your proposal doesn’t work for the other party, they can cancel the booking. Both parties can send messages to each other during the process to sort things out.`,
            },
            {
              q: `How can I reschedule a confirmed booking?`,
              a: `That’s easy too! You can always request to reschedule a confirmed booking. If the other party can't accept the new times, the booking will be cancelled, and you'll only pay the admin fee if it is a paid booking.`,
            },
            {
              q: `What happens to last-minute rescheduling of a confirmed paid booking?`,
              a: `If you initiate a last-minute rescheduling of a booking you confirmed earlier, and the other party can’t make it, then it’s treated as if you are cancelling the booking. If it is a paid booking, check out our ${LINKS.cancellationPolicy('Cancellation Policy')} to learn more. It’s not ideal, but things sometimes get in the way, so we’ve tried to make it work best for everyone.`,
            },
          ]
        },
        {
          id: 'responding-to-booking-request',
          title: 'Responding to booking request',
          questions: [
            {
              q: `What if I don’t want to accept a booking request sent to me?`,
              a: `It depends on the reason. If you don’t want to accept the booking because the topic is not the one you wish to discuss, you can cancel the booking and offer a reason. If the timing doesn’t work for you, you can propose alternative times, or cancel the booking but inform the other party when you are open to a booking. You can find out more in the Kintell ${LINKS.codeOfConduct('Code of Conduct')}.`,
            },
            {
              q: `What if I let a booking request expire?`,
              a: `If this is a booking using a Private Card, you can invite your contact to book again. If the booking is made on your Marketplace Card on our ${LINKS.marketplace('marketplace')}, it’ll harm your rating (oh no!) and visibility on the ${LINKS.marketplace('marketplace')} – so make sure you’re keeping on top of them! If you keep letting booking requests expire, we will unpublish your Marketplace Card from the ${LINKS.marketplace('marketplace')}.`,
            },
            {
              q: `What happens if my booking request expires?`,
              a: `If someone doesn't respond to your booking request within the expiry time, the booking will be cancelled, you won't be charged if it’s a paid booking. If someone has listed their expertise on the ${LINKS.marketplace('marketplace')} and they haven’t responded to booking requests multiple times, we will unpublish their Marketplace Cards from the ${LINKS.marketplace('marketplace')}. It’s a small way we’re trying to build a fair, equitable and open community :)`,
            },
          ]
        },
        {
          id: 'during-a-booking',
          title: 'During a Booking',
          questions: [
            {
              q: `How do I start my booking?`,
              a: `Don't worry – when your booking is coming up, we'll send you an email notification from which you can click through and join the session on your web browser. If you are using a phone, you can click through the join link in the SMS we send to you 10 min before a booking. You can also visit your Kintell account and follow the prompts in the ${LINKS.bookings('Booking')} section of your Dashboard :)`,
            },
            {
              q: `What if I want to start or end the booking earlier (or later)?`,
              a: `If both parties agree, you can start a Kintell booking 60 min before the scheduled time, or you can join later as long as it is no later than 30 min after the scheduled start time. That’s a pretty generous window, you’d have to admit – and gives you more than enough wiggle room. You can end the call whenever you like – as far as both parties agree. You can also reschedule a confirmed booking, if you need to. There’s plenty of ways to go about it!`,
            },

          ]
        },
        {
          id: 'cancelling-or-missing-a-booking',
          title: 'Cancelling or missing a booking',
          questions: [
            {
              q: `Is there a cancellation policy?`,
              a: `Yep, sure there is! Please check it out ${LINKS.cancellationPolicy('here')}. This policy only applies to paid bookings, though, and it’s designed to protect the time of both advisors and learners in a fair way. We want to make Kintell a great space for knowledge sharing, so we’ve built our rules around doing right by everyone.`,
            },
            {
              q: `What if one of the two parties needs to cancel a booking?`,
              a: `Life happens, and you can always cancel a confirmed booking. You’ll be able to message the other party during the cancellation process and let them know the reason. However, if you made a booking on our ${LINKS.marketplace('marketplace')} and cancel it within 24 hours before the start time, it will harm your Kintell rating on the ${LINKS.marketplace('marketplace')}. Check out our ${LINKS.cancellationPolicy('Cancellation Policy')} to learn more.`,
            },
            {
              q: `What if one of the two parties misses a booking?`,
              a: `Oops! If it is a booking with your existing contact using a ${LINKS.featureKCards('Private Card')}, then you owe the other party an apology! If the booking is made on our ${LINKS.marketplace('marketplace')} and you fail to show up for the video session, it will harm your Kintell rating. Sorry, but fair’s fair! For a paid booking, regardless it is made via a Private Card or a ${LINKS.featureKCards('Marketplace Card')} on the ${LINKS.marketplace('marketplace')}, Kintell will act according to our ${LINKS.cancellationPolicy('Cancellation Policy')}.`,
            },
            {
              q: `Do I still get paid if my learner missed our booking?`,
              a: `If you have shown up to the booking by joining the video call, you will get paid the full amount, regardless of the attendance of your learner. And, we hope, you’ll get an apology from your learner, too. More details can be found in our ${LINKS.cancellationPolicy('Cancellation Policy')}.`,
            },
            {
              q: `Do I still get paid if I, as the advisor, missed a booking?`,
              a: `Sorry, you won’t get paid because our system considers this a no show from you. If you don’t turn up to the show then you can’t enjoy the free drinks at the afterparty now, can you? The payment will be fully refunded to your learner – and, we hope, it’ll come with an apology from you. This includes the scenario that both parties did the booking through Kintell but met physically in person instead of joining the video booking.`,
            },
            {
              q: `What if neither the advisor nor the learner show up to a confirmed paid booking?`,
              a: `We strongly recommend not to do so, because either of you could have so easily cancelled the booking in advance via your ${LINKS.myDashboard('dashboard')}. If this unfortunately happens, no one will get refunded from Kintell. This also covers the scenario where both parties booked via Kintell but met physically in person instead of joining the video booking. Because our system doesn’t know what has happened, it will treat this as no show from both parties and act according to our ${LINKS.cancellationPolicy('Cancellation Policy')}. If you have to meet physically in person, please log in to your video booking for 1 min so we know this is a successful booking!`,
            },
            {
              q: `If I can’t attend a booking because of emergencies, what should I do?`,
              a: `You can just visit your Kintell ${LINKS.myDashboard('Dashboard')}, cancel the booking and let the other party know about the reason. Once you are free again, you can communicate with the other party via the chat channel attached to the cancelled booking to organise another booking. If you are the advisor and didn’t show up nor cancel the booking, regardless of the reason, we will fully refund the learner.`,
            },
          ]
        },
        {
          id: 'recurring-bookings',
          title: 'Recurring bookings',
          questions: [
            {
              q: `Can I initiate the next booking instead of waiting passively for someone to book me?`,
              a: `You can initiate a booking with a Private Card or a Marketplace Card. Under '${LINKS.bookingInitiate('Initiate a Booking')}' in your Dashboard, you can send an invitation for a Kintell session by getting a link to the booking that you can share with whomever you want :)`,
            },
            {
              q: `How can I contact someone who I met on kintell a while ago?`,
              a: `Kintell is designed to support ongoing relationships. You can contact someone via the chat channel attached to a previous booking and they will receive an email notification about it. Just go to ‘${LINKS.myArchiveBookings('Archive')}’ and search for that particular booking with a name or time, and you’re done and dusted.`,
            },
            {
              q: `If I have met someone on the ${LINKS.marketplace('marketplace')} can we connect on LinkedIn or contact each other on other platforms?`,
              a: `Sure. We would love our users to stay connected and if you ever think of booking each other again, we will make sure Kintell is the best platform for you to do so! If you need to organise another video meeting with your contact, sharing a Private Card with them to book you can be very convenient and it also has a lower Kintell admin fee. Learn more ${LINKS.featureKCards('here')}.`,
            },
          ]
        }
      ]
    },
    {
      id: 'payment-integration',
      title: 'Payment Integration',
      description: 'Learn about Kintell’s integrated payment features.',
      image: require('~images/faq/payment-integration.png'),
      sections: [
        {
          id: 'receiving-payouts',
          title: 'Receiving payouts',
          questions: [
            {
              q: `What countries are supported internationally for a booking?`,
              a: `For a free Kintell booking, anyone can book anyone as far as they have internet access. For a paid booking, anyone in the world – yes, anyone – with a valid credit/debit card can make and pay for a booking. To take a paid booking and receive payouts from Kintell, we support users in Australia, Canada, EU, New Zealand, UK, and the U.S. We're working around the clock to support more countries, but for now, users from other countries can only take free bookings.`,
            },
            {
              q: `How will I get paid?`,
              a: `When you receive your first paid booking request, we'll ask you for your bank details so that we can pay you. For a bit of Ts & Cs, it’s important to know that, due to financial regulations, our payment processing partner Stripe has to ask for your date of birth so you can be verified as a real person (more information ${LINKS.stripeObligation('here')}). You can update your payment details at any time on your '${LINKS.myBilling('Billing')}' page.`,
            },
            {
              q: `How long does it take to set up?`,
              a: `Less time than a lamb’s tail shake. Putting your details in is easy, and after that it usually takes a day or so before your account details are verified. Stripe, our payment processing partner, will be in touch if they need further information and to confirm you're valid. It’s super easy!`,
            },
            {
              q: `When do I get paid?`,
              a: `After your booking is complete, you'll have the money in your account within a week, if you've provided your account details. Now you’re talking!`,
            },
          ]
        },
        {
          id: 'making-payments',
          title: 'Making payments',
          questions: [
            {
              q: `When do I pay for a booking?`,
              a: `If you’re booking a free ${LINKS.featureKCards('Kintell Card')}, then it’s free! For paid Cards, after you’ve made a booking, we’ll ask for your payment details. We accept all the major credit and debit cards, and you'll only be charged once the advisor has confirmed the booking.`,
            },
            {
              q: `How do refunds work?`,
              a: `If you're entitled to a refund, then we’ll get onto it and it’ll be paid to your credit card or bank account within a few days.`,
            },
          ]
        },
        {
          id: 'kintell-admin-fee',
          title: 'Kintell admin fee',
          questions: [
            {
              q: `Is there an admin fee?`,
              a: `For all paid bookings, there’s an admin fee ranging from 5% for a ${LINKS.featureKCards('Private Card')} to 15% for a Marketplace Card on our ${LINKS.marketplace('marketplace')}, which helps us keep our servers running to connect our users. If a person’s Card is free, or if someone is booking you with their Pro Bono Credit, there's no admin fee. Pretty easy! Head over to our ${LINKS.pricing('pricing page')} to find out more.`,
            },
            {
              q: `Do I still need to pay the payment gateway fee on top of the Kintell admin fee?`,
              a: `No need. The Kintell admin fee includes the fees charged by the payment gateway so you don’t need to worry about that anymore. As a matter of fact, the 5% Kintell admin fee for ${LINKS.featureKCards('Private Cards')} are mainly there to cover the payment gateway fees we are paying to Stripe, our payment processor.`,
            },
            {
              q: `Why do the Marketplace Cards (15%) have a higher admin fee than the Private Cards (5%)?`,
              a: `Marketplace Cards on the marketplace have a 10% higher admin fee because Kintell also promotes your Cards on Google and other channels, which takes a bit of bandwidth and resources at our end. When you use Private Cards, you are asking your existing contacts to book you, which doesn’t involve our marketing efforts.`,
            },
            {
              q: `Will the admin fees change if I scale my consultation/coaching business on Kintell? `,
              a: `If you are a free ${FAQ_LINKS.kintellBasicAccount('Basic account')} user, you pay 5% for your Private Card bookings and 15% for your Marketplace Card bookings regardless of the total volume of your transactions on Kintell. For example, if you only use Private Cards, you will pay $5 to Kintell if you have $100 of bookings, and $500 to Kintell if you scale it to $10,000 worth of bookings in total. But you might find that, as your impact grows, you can change to a ${FAQ_LINKS.kintellPlusAccount('Kintell Plus')} or ${FAQ_LINKS.kintellProAccount('Pro account')} and take advantage of different fees.`,
            },
            {
              q: `How can I lower the total amount of admin fee charged by Kintell?`,
              a: `Always share a Private Card (5%) with contacts you already know to book you. Keep in mind that private cards don’t have ${FAQ_LINKS.ratingAndReviews('ratings and reviews')}. Also, if you upgrade to a Plus or Pro account (${LINKS.pricing('here')}), you will only pay 5% for all your ${LINKS.featureKCards('Kintell Cards')}, including Private and Marketplace Cards.`,
            },
            {
              q: `Will the rates of Kintell admin fees change?`,
              a: `If they do change, then you’ll be the first to know. You can always check the ${LINKS.pricing('Pricing')} page for updates! In general, we’re committed to keep the admin fees of Kintell minimum so we can deliver maximum value to you while also keeping the lights on and the server running.`,
            },
          ]
        },
        {
          id: 'receipt-tax-and-more',
          title: 'Receipt, tax and more',
          questions: [
            {
              q: `Will I get a receipt?`,
              a: `Certainly! We'll send a summary and receipt once you've completed a booking.`,
            },
            {
              q: `Will Kintell summarise my income in a year for tax purposes?`,
              a: `Yes, this feature is currently available in beta to our ${FAQ_LINKS.kintellProAccount('Pro account')} users! If you are on your way to becoming a power user of Kintell, please ${LINKS.getInTouch('get in touch')} now and we will share more details with you.`,
            },
            {
              q: `Will Kintell handle my income tax?`,
              a: `It is your responsibility to report your income and expenses to the government, so we’ll leave that in your capable hands.`,
            },
            {
              q: `Do you store my card details?`,
              a: `Nope! We use Stripe’s encrypted and secure payment gateway to handle your details, and we don’t store that info on our servers at all.`,
            },
            {
              q: `If we met face-to-face for a paid booking, and we didn’t show up on Kintell, how does payment work?`,
              a: `Kintell does not know what is happening outside of the system, so showing up online counts. In a nutshell, if you’re the advisor in a paid booking, you should show up online (for at least one minute) to get paid. If it is a paid booking, Kintell will act according to our ${LINKS.cancellationPolicy('Cancellation Policy')} which we’ve tried to keep simple and straightforward for you.`,
            },
          ]
        }
      ]
    },
    {
      id: 'k-cards',
      title: 'Kintell Cards',
      description: 'Learn about everything a Kintell Card can do for you.',
      image: require('~images/faq/k-cards.png'),
      sections: [
        {
          id: 'types-of-kintell-cards',
          title: 'Types of Kintell Cards',
          questions: [
            {
              q: `How many types of Kintell Cards are there?`,
              a: `There are three types of Kintell Cards: Private Card for your existing contacts to book you, Marketplace Cards for anyone to book you on our ${LINKS.marketplace('marketplace')}, and Group Card for you to list your profile in your organisation’s Kintell Group if you are invited. You can learn more about ‘Kintell for Business’ ${LINKS.forBusiness('here')}.`,
            },
            {
              q: `What is a Marketplace Card?`,
              a: `You can showcase your expertise and experience on our active ${LINKS.marketplace('Insight Marketplace')} by listing a Kintell Card. You can price it, define your availability and collect ratings and reviews after each booking. You’ll also be findable in Google search results. Anyone around the world with an internet connection can now book and learn from you. Nice! Learn more about it ${LINKS.featureKCards('here')}.`,
            },
            {
              q: `What is a Private Card?`,
              a: `They’re the ‘incognito mode’ of Kintell Cards ;) Private Kintell Cards aren't searchable, and don't have ratings and reviews. They also have a lower admin fee (see ${LINKS.pricing('Pricing')} for details), so they’re perfect for ongoing meetings with new or old clients. If you create a free Private Card, you can hold meetings through Kintell and take full advantage of the integrated calendar and booking system, without paying a penny. Learn more ${LINKS.featureKCards('here')}.`,
            },
            {
              q: `What is a Group Card?`,
              a: `When you are invited by an organisation or community to join their member-only group on Kintell, the admin of that group on Kintell may also invite you to publish a Group Card into the group so other members can see you. Learn more about ‘Kintell for Business’ ${LINKS.forBusiness('here')}.`,
            },
          ]
        },
        {
          id: 'using-kintell-cards-effectively',
          title: 'Using Kintell Cards effectively',
          questions: [
            {
              q: `How many Kintell Cards can I have?`,
              a: `You can have as many Cards as you like, on as many topics as you want. If you are publishing Marketplace Cards into our ${LINKS.marketplace('marketplace')}, you can have different Marketplace Cards as long as you believe you can provide useful advice on the subject. For example, a language teacher may want to list one Card on 'English Speaking & Writing' and another one on 'Bonsai Gardening', if those are their specialties. As long as you’re passionate and knowledgeable about the subject, go for gold and ${LINKS.myKCards('create a new card')} today!`,
            },
            {
              q: `How do I use different types of Kintell Cards?`,
              a: `You can create multiple Kintell Cards for specific purposes. You might have a free Discovery Marketplace Card on the ${LINKS.marketplace('marketplace')} to accumulate ratings & reviews, and a paid Marketplace Card with your standard offering. If you have a follow-up session, you might want to use a Private Kintell Card. For paid sessions on a Private Card, there's only a 5% admin fee as opposed to 15% for a Marketplace Card (see ${LINKS.pricing('pricing')}). Learn more from ${LINKS.articleCoaches('Danielle’s case')}.`,
            },
            {
              q: `How do I share my Kintell Cards with others?`,
              a: `In the '${LINKS.myKCards('My Cards')}' section of your Dashboard, each Card will have a share icon at the bottom from which you can copy the link to your Card, and share with whomever you want! You can also post on your LinkedIn profile or on a Slack group to invite bookings. Learn more ${LINKS.featureKCards('here')} and from ${LINKS.articleConsultants('Gretchen’s case')}.`,
            },
            {
              q: `Can I edit, unpublish or even delete my Kintell Cards?`,
              a: `Sure! You can do it anytime you’d like. But take heed, traveller: if you unpublish a card, it is no longer visible to others nor available for booking. But you can republish it anytime with just one click. `,
            },
            {
              q: `Are Kintell Cards searchable on Google?`,
              a: `Marketplace Cards on the ${LINKS.marketplace('marketplace')} are listed publicly on the ${LINKS.home('Kintell.com')} site, which means Google will pick them up. This helps you to generate new leads for your listed expertise, and get to build your global reputation as an expert if you field! Private Cards and Group Cards will not appear on Google, however, so use the difference between these types judiciously.`,
            },
          ]
        }
      ]
    },
    {
      id: 'marketplace',
      title: 'Marketplace',
      description: `Leverage Kintell’s Insight marketplace to share your insights generating extra income.`,
      image: require('~images/faq/marketplace.png'),
      sections: [
        {
          id: 'becoming-an-advisor',
          title: 'Becoming an advisor',
          questions: [
            {
              q: `How do I become a Kintell advisor on the ${LINKS.marketplace('marketplace')}?`,
              a: `Once you've signed up to Kintell, you can create your first Marketplace Card to show off your expertise, you clever thing you. You can create a Private Card, share it with whomever special you want, and start advising straight away. If you want to create a Marketplace Card that’s visible on the ${LINKS.marketplace('marketplace')}, the Kintell team will review the Card; once it's approved you can start taking bookings. Didn’t we say it was easy peasy?  Learn more ${LINKS.featureKCards('here')} :)`,
            },
            {
              q: `What can I advise on?`,
              a: `You can advise on any topic you'd like, as long as it doesn't break our ${LINKS.codeOfConduct('Code of Conduct')}, and that you're confident that you can provide sound advice to our community. We expect you to be professional, regardless of the topic, and a credit to your knowledge.`,
            },
            {
              q: `Is there a minimum amount of time I have to commit?`,
              a: `Nope! You decide when to take bookings and how much time you want to put in and can even set your ${LINKS.myWorkingHours('Working hours')} to control your availability. If you have removed ALL your availabilities, however, we will unpublish your Marketplace Card from the ${LINKS.marketplace('marketplace')} and you can republish it when you have availability.`,
            },
          ]
        },
        {
          id: 'listing-a-card-on-the-marketplace',
          title: `Listing a Card on the marketplace`,
          questions: [
            {
              q: `What's considered a 'good' Marketplace Card?`,
              a: `A clearly defined topic with distinct skill categories, subtopics to show what you can advise on, and a well-written overview of your knowledge and experience will make a banger of a Card. Adding your LinkedIn to give significantly more weight to your Kintell Card, your work history highlights, your qualifications, and if you have supporting links such as websites or articles, pop them in too! Check out ${LINKS.exampleJane('this example')} for inspiration :)`,
            },
            {
              q: `Why does my Marketplace Card need to be approved?`,
              a: `We review the first Marketplace Card of a new member to keep quality high and make sure you’re going to add to the shared knowledge of our users. It's our way of keeping the community trustworthy and safe, but also to help you create a great Marketplace Card that will generate some amazing bookings.`,
            },
            {
              q: `How do you know information on a Marketplace Card is real?`,
              a: `When someone tries to publish their first Kintell Card into our ${LINKS.marketplace('marketplace')}, it comes to the Kintell team for vetting. We will check their LinkedIn profile and personal website to make sure you’re a real person and not two kids in an overcoat (we won’t be fooled by that one twice!). If we find anything suspicious, we will not approve the card to be published and contact the advisor for further verification.`,
            },
          ]
        },
        {
          id: 'promoting-your-kintell-profile',
          title: 'Promoting your Kintell Profile',
          questions: [
            {
              q: `How can I make my Kintell Card more bookable?`,
              a: `Make sure your social media links on your Kintell Cards, especially LinkedIn, are up to date to verify that you are who you say you are. When writing your Kintell Card, make sure to have relevant and popular keywords (lawyer, digital marketing, accountant, etc.). It's through the keywords that people will find you! And make sure that you and your personality really shine ⭐`,
            },
            {
              q: `How can I make my Kintell Profile more visible?`,
              a: `Kintell is the multitool of video knowledge sharing, so you can really build an ecosystem around it for all your teaching, mentoring and coaching activities. You can use Kintell to host video meetings during which you can let people know about your ${LINKS.featureKCards('Kintell Cards')} for future conversations, paid or free. You can also record a video message in your ${FAQ_LINKS.personalRoom('Personal Room')} and send it to your network while letting them know if they want a 1-to-1 chat with you about the topic so they can book your Card. Just make sure you also let them know the short link of your Card! Learn more from ${LINKS.articleCoaches('Danielle’s experience')}.`,
            },
            {
              q: `What is the best way to invite my existing clients to book me via the ${LINKS.marketplace('marketplace')} so I can have their review?`,
              a: `Simply share the short link of your Marketplace Card with your existing clients and invite them to book from there. If they are already using Kintell, then it’s easy! If they are new to Kintell, it only takes 60 seconds to sign up. You'll get paid your full rate, and accumulate ratings and reviews to build your global reputation as an advisor. Learn more from ${LINKS.articleConsultants('Gretchen’s experience')}.`,
            },
            {
              q: `What are the benefits of bringing my existing clients or students to book me on Kintell?`,
              a: `They will be able to schedule and reschedule bookings with you seamlessly. If it’s a paid booking, the payment is just a few clicks to complete. Our ‘${LINKS.myArchiveBookings('booking archive')}’ feature will allow both of you to search the history of your bookings, chat messages and attachments, which helps you to keep track of the relationship and topics you have discussed in the past. Also, our SMS and email reminders will make sure no one misses a session :) Finally, you made it easy for people to refer others to you – they just need to pass your Kintell Card share link to their contacts!`,
            },
            {
              q: `How do I spread the word on social media?`,
              a: `Sharing your Kintell Cards on your social networks is a great way to bring users to your profile – and get sessions and reviews heading your way, of course! Share the short link of your card on your Linkedin's personal description section to reach a professional audience, or share your latest review from a Kintell learner on Instagram and Linkedin to spread the word about your offer – and the love! To do so, go to ‘${LINKS.myKCards('My Cards')}’, pick the card and click “Share”. Learn more from ${LINKS.articleConsultants('Gretchen’s case')}.`,
            },
            {
              q: `How do I promote my Kintell Cards during a workshop?`,
              a: `After each workshop or class, you can share the short link of an existing Kintell Card or create a dedicated Card for this workshop. Why not invite participants to book you for a 1-to-1 follow up chat! Usually people want to discuss their own situation with you and this is a great way to let them know your offerings on Kintell, without feeling like you’re tied to an office. When they want to book a repeat session, you can also provide them a paid ${LINKS.featureKCards('Private Card')} to continue the relationship.`,
            },
            {
              q: `What can I do to get the word out?`,
              a: `Do you give workshops or facilitate meetups? Make sure you give out the URL of your ${LINKS.featureKCards('Kintell Cards')} to offer them follow-up sessions. Met a new potential client who's offered you a coffee to "pick your brain"? Ask them to book you through Kintell! You'll get paid your full rate, and accumulate ratings and reviews to build your global reputation as an advisor. Go you!`,
            },
            {
              q: `What else can I do to make my profile more visible?`,
              a: `Read our ${FAQ_LINKS.ratingAndReviews('Ratings and reviews')} FAQs for more tips.`,
            },
          ]
        },
        {
          id: 'ratings-and-reviews',
          title: 'Ratings and reviews',
          questions: [
            {
              q: `Do all Kintell calls need rating and review afterwards?`,
              a: `Nope, not always. Only those ${LINKS.marketplace('marketplace')} bookings that happen on a Marketplace Card. If a booking is scheduled using a Private Card, there will be no rating and review afterwards. Also, video meetings that take place in your ${FAQ_LINKS.personalRoom('Personal Room')} won’t have ratings and reviews. Make sense?`,
            },
            {
              q: `How do I accumulate ratings and reviews on the ${LINKS.marketplace('marketplace')}?`,
              a: `A good idea to build your profile is to set the fee of your Marketplace Card lower at the beginning, just as you’re starting your journey and building your reputation on Kintell. This will help generate your early ratings and reviews – which are needed for other users to book you. Our algorithms favour cards with more reviews, so when you've acquired a few, you can raise your fee and charge more for your skills and valued time. You can also share your Marketplace Card with existing clients for follow-up chats, and build your reviews that way.`,
            },
            {
              q: `What impact do reviews have?`,
              a: `Giving a great review means that you’ve gained a lot from your session. So Kintell Cards with good reviews get higher visibility on the platform, and among the search results. That means more opportunities to connect, and more chances to showcase your knowledge and ask the right questions! Soon we will also make them visible on Google search results!`,
            },
            {
              q: `Are there other factors that can impact an advisors’ ratings?`,
              a: `It’s not just ratings, it’s true. We also take into account people’s behaviour on the platform. It won’t do your account visibility any favours if you frequently cancel bookings, let booking requests expire, or don’t show up for Kintell sessions. Check out our ${LINKS.codeOfConduct('Code of Conduct')} for more info on how to be the best kind of Kintell user.`,
            },
            {
              q: `When can I rate and review an advisor?`,
              a: `Once you've finished a Kintell session, we'll ask you to leave a review. You will have the option to skip reviews, we will also send you a reminder email to collect your review because your ratings matter! You can also leave a review later in your ${LINKS.myArchiveBookings('booking archive')}.`,
            },
            {
              q: `Do advisors review learners as well?`,
              a: `For sure – reviews are a two-way street. learners' ratings and reviews aren’t public, though; when a learner sends a booking request, the advisor will see their rating and have a chance to see what kind of learner they’ve been.`,
            },
            {
              q: `Do I have to leave a review after a booking on the ${LINKS.marketplace('marketplace')}?`,
              a: `It's optional, however, we do encourage reviews as sharing your experience will help others to make a better-informed decision before making a booking on our ${LINKS.marketplace('marketplace')}. Thanks for making a difference : )`,
            },
          ]
        }
      ]
    },
    {
      id: 'k-for-business',
      title: 'Kintell For Business',
      description: `Know more about hosting a ‘Kintell Group’ for your organisation on Kintell.`,
      image: require('~images/faq/k-for-business.png'),
      sections: [
        {
          id: 'kb-basic-concepts',
          title: 'Basic Concepts',
          questions: [
            {
              q: `What is a Kintell Group?`,
              a: `When an organisation or business subscribes to Kintell’s B2B solution, and they start to use Kintell to connect their members, the group that is hosted on Kintell is called a ‘Kintell Group’. A Kintell Group is branded with the organisation’s logo, and has a clear description about what it is and what it does. Think of it a bit like white labelling – they get the full power of Kintell, but with their own branding and images. Learn more about Kintell for Business ${LINKS.forBusiness('here')} and from ${LINKS.articleUniversities('UNSW’s case')}.`,
            },
            {
              q: `How to join a Kintell Group?`,
              a: `Either you receive an invitation with a link to join, or on your organisation’s website you can click a link to visit the group. Once you are on the group page, Kintell will prompt you to join and you just need to follow the steps, which are designed to make it so easy that anyone can do it.`,
            },
            {
              q: `Can I join multiple Kintell Groups?`,
              a: `Yes! You can join multiple groups that belong to the same organisation, or groups that are hosted on Kintell for various organisations, as far as you are associated with that organisation as a member, a graduate, a customer, or any role it might be.`,
            },
            {
              q: `Would Kintell for Business work for an individual user?`,
              a: `Kintell for Business is designed to scale from one individual to thousands of advisors and learners in an organisation. If you’re a one-man-show or a one-woman-shop we recommend using ${FAQ_LINKS.kintellProAccount('Kintell Pro')} (pricing ${LINKS.pricing('here')}) which does come with loads of cool features including white-labelling to promote your own branding. Learn more from ${LINKS.articleConsultants('Gretchen’s case')}.`,
            },
          ]
        },
        {
          id: 'group-admin',
          title: 'Group admin',
          questions: [
            {
              q: `Who are the group admins?`,
              a: `When an organisation starts their digital community on Kintell, Group admins are nominated by the organisation to curate and manage the group. They can approve members to join or remove them from the group. They can also facilitate bookings to connect two members. Think of them a bit like the Yodas of the group, you should.`,
            },
            {
              q: `Will the Group admin of an organisation have access to my chat history or video recordings associated with my Group Card?`,
              a: `Absolutely not. Your privacy is our top priority, so not even these people will be able to see. Your Group admin will only have access to high level data such as how many hours of video chat have taken place in a month. The ‘content’ of your bookings or messages you are exchanging with other members in the group is your privacy and will not be shared with the Group admin.`,
            },
            {
              q: `Will payments in a paid Kintell Group go to the Group admins?`,
              a: `It depends on the group setting selected by the almighty group admins. The payout will go to the nominated Group admin (less Kintell admin fee, of course) who will later pay the individual advisors/consultants according to your sets of rules. Kintell also supports three-way-split (currently in beta) between Kintell, the individual advisor and the Group admin – but watch this space, as it’s in the works.`,
            },

          ]
        },
        {
          id: 'using-kintell-groups',
          title: 'Using Kintell Groups',
          questions: [
            {
              q: `If I sign up to Kintell when joining a group associated with my organisation, can I also use Kintell personally?`,
              a: `Yes, sure you can! Kintell is designed from the ground up so that your individual usage is not restricted or in conflict with your Kintell activities related to your organisation. It’s all open for business (and pleasure). Also, your personal use of Kintell is your privacy and the organisation will not have access to any information about it. Learn more from ${LINKS.articleCommunities('The Rural Woman’s case')}.`,
            },
            {
              q: `If I have published a Group Card can anyone else outside of the group see this card?`,
              a: `The Group admin will let you know the visibility setting of the group when they invite you to join. If it’s a standard community group, then only other community members that are verified by the admin can see your card and book you. If it is a visible consulting group with fees on the group cards, then anyone can book you after they finalise the payment. Makes sense? Great.`,
            },
            {
              q: `Do I need to pay if I book someone in my organisation’s Kintell Group?`,
              a: `You don’t need to pay Kintell for the software service we provide as a tech company. However, it is up to your organisation to define whether an advisor in this group will be charging or it is free. For instance, university alumni networks are usually free for everyone, but consulting networks can have a fee attached to each card. Up to you, really!`,
            },

          ]
        }
      ],
    },
  ]
}
