import { User } from '@/models'
import { IdleAction } from '../lib/idle'
import { v4 as uuidv4 } from 'uuid'

import Cookies from 'js-cookie'

const COOKIE_ACTIVITY_NAME = 'kintell-activity'

export default (context, inject) => {
  setNewSession(context.store)

  function sendReport (data) {
    const { store } = context

    if (!store.state.auth.loggedIn) {
      return
    }

    data.session = store.state.myKintell.userActivitySession

    return (new User()).action('activity', {
      route: context.route.path,
      ...data,
    })
  }

  function pageView (data = {}) {
    return sendReport({
      event: 'pageView',
      ...data,
    })
  }

  function clickOn (data) {
    return sendReport({
      event: 'clickOn',
      ...data,
    })
  }

  inject('activity', {
    pageView,
    clickOn,
  })

  const idleAction = new IdleAction({
    idle: 3600000,
    onIdle: () => {
      Cookies.remove(COOKIE_ACTIVITY_NAME)
      setNewSession(context.store)
    },
    recurIdleCall: true
  })

  idleAction.start()
}

function setNewSession (store) {
  const hash = uuidv4()
  let userActivitySession = hash
  const cookieSession = Cookies.get(COOKIE_ACTIVITY_NAME)

  if (!cookieSession) {
    Cookies.set(COOKIE_ACTIVITY_NAME, userActivitySession)
  } else {
    userActivitySession = cookieSession
  }

  store.commit('myKintell/SET_USER_ACTIVITY_SESSION', userActivitySession)
}
