import { AUTHENTICATED, push } from '@/lib/gtm'
import { Context } from '@nuxt/types'
import { User } from '@/models'

export default class Authentication {
  public ctx: Context

  constructor (ctx: Context) {
    this.ctx = ctx
  }

  /**
   * Initialise the users authentication events. This should only be called once.
   * @returns {Promise<void>}
   */
  boot () {
    // watch the user object. This changes on login / logout
    this.ctx.store.watch<User>(state => state.auth.user, (val, oldValue) => {
      /**
       * If they were previously logged out and are logging in
       *
       * Note: If the user just signed in or refreshed the page then the oldValue may be null so we don't
       * want to check for this value
       */
      if (val) {
        this.refreshed(oldValue, val)
      }
    }, { immediate: true })

    if (this.ctx.$auth.loggedIn) {
      this.signedIn()
    }
  }

  refreshed (oldValue: User | undefined, val: User) {
    // If they are logged in and are being refreshed for whatever reason
    const activeBooking = val.stats?.bookingActive
    // if the authenticated user has an active booking in the stats data, we set the session
    // if they previously did have one for the same booking id, we don't re-trigger it otherwise
    // the modal will pop up again
    const lastActiveBookingMatches = activeBooking && oldValue &&
      oldValue.stats?.bookingActive &&
      oldValue.stats?.bookingActive.id === activeBooking.id
    // if new active booking and not last
    if (activeBooking && !lastActiveBookingMatches) {
      // If the user is NOT in the session
      const isInSession = val.id === activeBooking.learnerId ? activeBooking.isLearnerInSession : activeBooking.isAdvisorInSession
      if (!isInSession) {
        this.ctx.store.commit('myKintell/SET_AWAITING_BOOKING_SESSION', activeBooking)
      }
    }

    // Check for completed booking pending review
    const awaitingReview = val.stats?.bookingAwaitingReview
    this.ctx.store.commit('myKintell/SET_AWAITING_BOOKING_REVIEW', awaitingReview)
  }

  signedIn () {
    // we need to set the Authorization bearer token
    if (window.localStorage.getItem('auth._token.local')) {
      this.ctx.$echo.connector.pusher.config.auth.headers.Authorization = window.localStorage.getItem('auth._token.local')
    }
    // authenticated event
    push(AUTHENTICATED, {
      firstName: this.ctx.$auth.$state.user.firstName,
      lastName: this.ctx.$auth.$state.user.lastName,
      email: this.ctx.$auth.$state.user.email,
      userAuthenticated: true,
      userId: this.ctx.$auth.$state.user.email,
      userType: this.ctx.$auth.$state.user.type,
      userUuid: this.ctx.$auth.$state.user.id,
      credit: this.ctx.$auth.$state.user.stats?.creditAvailable
    })
    setTimeout(() => {
      this.ctx.$broadcasting.attachPrivateChannelEvents()
      // This number is arbitrary
    }, 2500)
  }

  async signOut () {
    // destroy channel
    this.ctx.$broadcasting.detachPrivateChannelEvents()
    await this.ctx.$auth.logout()
    await this.ctx.$auth.reset()
    this.ctx.$auth.$storage.setUniversal('redirect', '/')
    this.ctx.store.dispatch('myKintell/reset')
    this.ctx.app.router!.replace('/')
    window.location.href = '/'
  }

  async signIn (email: string, password: string, redirect = true, data = {}) {
    this.ctx.$logger.info('Logging in as user', email)

    let currentToken = null
    /**
     * Some users may be logged in as a placeholder when they are attempting to sign in.  This can happen
     * during the password free flow. If this is the case, we need to store the current token so that we can
     * log them back in as a placeholder if their login fails.  Otherwise
     */
    if (this.ctx.$auth.$state.loggedIn && this.ctx.$auth.$state.user.isPlaceholder) {
      currentToken = localStorage.getItem('auth._token.local')
    }

    try {
      await this.ctx.$auth.loginWith('local', {
        auth: {
          username: email,
          password
        },
        data
      })
    } catch (e) {
      // Exit out if the user is not logged in as a placeholder
      if (!currentToken) {
        return false
      }
      // If user is a placeholder
      localStorage.setItem('auth._token.local', currentToken)
      this.ctx.$auth.ctx.app.$axios.setHeader('Authorization', currentToken)
      await this.ctx.store.dispatch('auth/refresh')
      this.ctx.$auth.$storage.setState('loggedIn', true)
      return false
    }

    await this.ctx.store.dispatch('myKintell/reset')
    this.signedIn()

    if (redirect) {
      const redirectTo = this.ctx.$auth.$state.redirect ? this.ctx.$auth.$state.redirect : this.ctx.$auth.$storage.ctx.from.fullPath
      // if there is a redirect link go to that otherwise go home
      await this.ctx.app.router!.push(redirectTo || '/')
    }
    return true
  }

  async signInSocial (service: string, code: string, callback: string, options = {}) {
    const data = {
      ...options,
      code,
      callback
    }

    try {
      await this.ctx.$auth.loginWith(service, {
        data
      })
    } catch (e) {
      if (e.response && e.response.data) {
        if (e.response.data?.meta) {
          return e.response.data.meta
        } else {
          this.ctx.$toast.error(e.response.data.error)
        }
      } else {
        this.ctx.$logger.error(e)
      }
      return false
    }
    this.ctx.store.dispatch('myKintell/reset')
    this.signedIn()

    // Nuxt Auth package uses the name of strategy as the token key and there is not an easy way to change this
    const token = window.localStorage.getItem('auth._token.' + service)
    // Our system checks for the key local in our local storage
    token && window.localStorage.setItem('auth._token.local', token)
    return true
  }
}
