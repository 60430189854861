import { Context } from '@nuxt/types'
import { BookingMessage } from '@/lib/broadcasting/messages'

// '.App\\Events\\Booking\\Status\\CancelledProvisional'
export default (ctx: Context) => {
  return (e: BookingMessage) => {
    const isAdvisor = e.booking.advisorId === ctx.$auth.$state.user.id
    const otherUser = isAdvisor ? e.booking.learner : e.booking.advisor
    ctx.$toast.success(`${otherUser.firstName + ' ' + otherUser.lastName} has cancelled your provisional booking with them.`, {
      duration: 10000
    })
    ctx.store.dispatch('myKintell/maybeReloadActiveBooking', e)
    ctx.store.dispatch('myKintell/reloadBookings')
    ctx.store.dispatch('auth/refresh')
  }
}
