import {
  StatusCodes
} from 'http-status-codes'
import { startsWith } from 'lodash-es'

export default ({ env, $axios, redirect, app, route }, inject) => {
  /**
   * A network error is caused from the endpoint not being available
   * @param error
   * @param meta
   */
  function networkError (error, meta) {
    app.$toast.error(error)
    app.$toast.error('Looks like you a problem with your network :(')
    // catch general response errors
    notifyBugsnag(
      new Error('Network Error'),
      {
        metaData: {
          Error: error,
          'Response Data': error.response,
          'Request Data': meta.lastRequest.data
        },
        request: error.request,
        severity: 'error'
      }
    )
  }

  /**
   * Handle a HTTP error
   * @param status
   * @param error
   * @param lastRequest
   */
  function httpErrorWithStatus (error, { lastRequest = null }) {
    const { response, request } = error

    // Authentication error - User tried to make a request when they aren't authenticated
    // Take them to the login page
    if (response.status === StatusCodes.UNAUTHORIZED) {
      if (
        startsWith(route.path, '/password-free') ||
        route.path.includes('/password-free') ||
        route.path.includes('/callback') ||
        app.$auth?.$storage?.ctx.route.name === 'password-free' ||
        route.name === 'g-kintellGroup-join' ||
        route.name === 'g-kintellGroup'
      ) {
        app.$auth.reset()
        app.$toast.error('Oops, looks like the verification code is not right')
        return
      }

      // if not on the sign in or book page
      if (
        !startsWith(route.path, '/password-free') &&
        !route.path.includes('/book') &&
        // If the learner is responding to a booking request then we don't want to redirect them
        // Or it can break the experience
        route.name !== 'advisors-advisor-kintellCard-respond'
      ) {
        // app.$toast.info('Oops, looks like your session has expired. Please log in again.')
        app.$logger.warn('Found invalid session. Redirecting user to login page.')
        app.$auth.reset()
        redirect('/password-free?step=login')
      }
      return
    }
    // This is a standard form validation error, we don't really care about these
    if (response.status === StatusCodes.UNPROCESSABLE_ENTITY || response.status === StatusCodes.BAD_REQUEST) {
      return
    }

    if (response.status >= 500) {
      if (!response.request.responseURL.endsWith('chat-token')) {
        app.$toast.info('Oops, looks like our server is having some issues at the moment.')
      }
    } else if (response.status === StatusCodes.FORBIDDEN) {
      // 403
      if (response.data.message) {
        app.$toast.info(response.data.message)
      } else {
        app.$toast.info('You are not allowed to do that :(')
      }
    } else if (response.status === StatusCodes.NOT_FOUND) {
      // 404
      app.$toast.info('We cannot seem to find that :(')
    } else if (response.status === StatusCodes.TOO_MANY_REQUESTS) {
      app.$toast.info('Looks like you are sending too many requests :( Please try again soon.')
    } else {
      // bad requests are usually validation errors so we ignore them
      app.$toast.info(error)
    }

    notifyBugsnag(new Error(response.status + ' Error ' + (response.data.message || '')),
      {
        metaData: {
          'Response Data': response.data,
          'Request Data': lastRequest.data
        },
        request,
        severity: 'error'
      }
    )
  }

  /**
   * We route the error to the appropriate error handler depending on the contents of error
   * @param error
   * @param meta
   */
  function httpError (error, meta = {}) {
    app.$logger.error(error, meta)
    if (error.response && error.response.status) {
      return httpErrorWithStatus(error, meta)
    }
    return networkError(error, meta)
  }

  /**
   * Handle a general error, this would most likely be a logic error
   * @param error
   * @param meta
   */
  function generalError (error, meta) {
    app.$logger.error(error, meta)

    notifyBugsnag(new Error('General Error' + error),
      {
        severity: 'error'
      }
    )
  }

  /**
   * Send the data to bugsnag
   * @param error
   * @param data
   */
  function notifyBugsnag (error, data = {}) {
    app.$bugsnag.notify(error, event => {
      if (data.severity) {
        event.severity = data.severity
      }
      if (data.metaData) {
        event.addMetadata('meta', data.metaData)
      }
    })
  }

  /**
   * Add exception catcher for $axios
   */
  $axios.onError(error => {
    httpError(error, {
      lastRequest: $axios.lastRequest
    })
  })

  //
  inject('errorHandler', {
    handleHttpError: httpError,
    handle: generalError
  })
}
