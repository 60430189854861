export default {
  width: 30,
  height: 34,
  stroke: false,
  svg: `
    <g fill="none">
      <path d="M1.63477 19.9433c.90287 7.3801 7.6175 12.6309 14.99753 11.7281 7.3801-.9029 12.6309-7.6175 11.728-14.9976-.9028-7.38006-7.6175-12.63087-14.9975-11.72801" stroke="#fff" stroke-width="2" stroke-linecap="round"/>
      <path d="m17 9-4.7519-3.16795c-.5938-.39582-.5938-1.26828 0-1.6641L17 1" stroke="#fff" stroke-width="2" stroke-linecap="round"/>
      <path d="M12.3975 13.8975V23h-1.7012v-7.5664H9.4585v-1.5361h2.939Zm5.2558 3.2373c.4528 0 .8633.0867 1.2315.2602.3681.1693.6644.3957.8886.6792.2286.2793.4021.5882.5205.9268.1228.3385.1841.6834.1841 1.0346 0 .4867-.0931.931-.2793 1.3331-.1862.3977-.438.7257-.7553.9838-.3174.2582-.6835.4571-1.0982.5967-.4105.1397-.8442.2095-1.3013.2095-.6897 0-1.2885-.1333-1.7963-.3999-.5036-.2666-.9395-.675-1.3077-1.2251l1.2442-.9775c.2624.3512.5459.6114.8506.7807.3047.1651.6538.2476 1.0473.2476.4951 0 .8824-.1333 1.1617-.3999.2835-.2709.4252-.6284.4252-1.0728 0-.4528-.1375-.8146-.4126-1.0854-.2708-.2709-.6432-.4063-1.1171-.4063-.5713 0-1.0051.2074-1.3013.6221h-1.4473l.876-5.3447h4.9766v1.5361h-3.6499l-.3555 2.1582c.3935-.3047.8654-.457 1.4155-.457Z" fill="#fff"/>
    </g>
`,
}
