//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'vuex-pathify'
import { BindsAttrs } from '@/mixins'

export default {
  mixins: [ BindsAttrs ],

  inheritAttrs: false,

  props: {
    linkText: {
      type: String,
      required: true,
    },
    linkPath: {
      type: String,
      required: true,
    },
    linkClasses: String,
    activatorId: String,
    items: {
      type: Array,
      required: true,
    },
    largeMenu: {
      type: Boolean,
      default: false,
    }
  },

  data: () => ({
    showMenu: false,
    leftArrow: '0px',
    hideMenu: false,
  }),


  computed: {
    ...get({
      showDarkHeader: 'layout/showDarkHeader',
    }),

    menuContentClass () {
      const classes = [ 'header-menu__content' ]

      if (this.largeMenu) {
        classes.push('header-menu__content--large')
      }

      return classes.join(' ')
    },

    isOnFeatures () {
      return this.$route.path.includes('/features')
    },

    chevronColor () {
      if (this.showDarkHeader) {
        return 'white'
      }

      return 'grey-1'
    }
  },

  watch: {
    showMenu (val) {
      if (val) {
        this.updateArrowLocation()
      }
    }
  },

  mounted () {
    if (this.largeMenu) {
      this.onResize()

      window.addEventListener('resize', this.onResize, { passive: true })
    }
  },

  beforeDestroy () {
    if (this.largeMenu) {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },

  methods: {
    updateArrowLocation () {
      setTimeout(() => {
        const rectIcon = this.$refs.refIcon.$el.getBoundingClientRect()
        const rectContent = this.$refs.refMenu.$refs.content
        const leftAmount = parseFloat(rectContent.style.left.replace('px', ''))
        this.leftArrow = (rectIcon.left - (leftAmount + 6)) + 'px'
      }, 100)
    },

    onResize () {
      // disable the menu when there's only three items in the header
      this.hideMenu = window.innerWidth <= 881
    }
  }
}
