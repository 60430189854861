export default {
  methods: {
    onBannerClick (value) {
      this.$activity.clickOn({
        type: 'group',
        name: 'kgExitContentBoard',
        kintellGroupId: this.kintellGroup.id,
        extra: {
          bannerName: value.title,
          bannerLink: value.ctaUrl,
          bannerDescription: value.description,
        },
      })
    }
  }
}
