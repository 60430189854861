import { make } from 'vuex-pathify'

export default (field, model = null, initialValue = null) => {
  const state = () => {
    return {
      [field]: initialValue,
    }
  }

  const getters = () => {
    return {
      // eslint-disable-next-line new-cap
      [field]: state => model && state[field] ? new model(state[field]) : state[field]
    }
  }

  const mutations = () => {
    return {
      ...make.mutations(state)
    }
  }

  return {
    getters: getters(),
    state: state(),
    mutations: mutations()
  }
}
