export default {
  resources: {
    dataAnalytics: {
      title: 'Data analytics',
      subtitle: 'See detailed data about your users and their activities over time. Export a report as a CSV or Google sheet.',
      cta: 'View Dashboard',
      key: 'dataAnalytics'
    },
    insightVideoProduction: {
      title: 'Insight video production',
      subtitle: 'Have a recording? Send it to our video production team to produce an insight video to be hosted in your video channel.',
      cta: 'Submit an order',
      key: 'insightVideoProduction',
      url: 'https://kintell.typeform.com/video-order'
    },
    socialMediaAsset: {
      title: 'Social media asset',
      subtitle: 'Have an insight video or an amazing 5-star review? Order an asset to engage your network via newsletters or social media posts!',
      cta: 'Submit an order',
      key: 'socialMediaAsset',
      url: 'https://kintell.typeform.com/social-media',
    },
    updateContentBoards: {
      title: 'Update content boards',
      subtitle: 'Add or remove the content boards for best engagement with your community.',
      cta: 'Submit an order',
      key: 'updateContentBoards',
      url: 'https://kintell.typeform.com/content-board'
    },
    oneToOneCustomerSupport: {
      title: '1-to-1 customer support',
      subtitle: 'Book us for an in-depth 1-to-1 video call to answer all your product questions. You can invite your colleagues too!',
      cta: 'Book a video call',
      key: 'oneToOneCustomerSupport'
    },
    inviteNewMembers: {
      title: 'Invite new members',
      subtitle: 'Send this link to your network so they can join your group. Kintell will notify you when they do!',
      cta: 'Copy link',
      key: 'inviteNewMembers'
    },
    adminSharedFolder: {
      title: 'Admin shared folder',
      subtitle: 'A central hub for all your project documents for easy collaboration with Kintell.',
      cta: 'Go to folder',
      key: 'adminSharedFolder'
    },
    shareVideoChannel: {
      title: 'Share video channel',
      subtitle: 'Share this link with anyone so they can watch your video channel!',
      cta: 'Copy link',
      key: 'videoChannelUrl'
    },
    akademy: {
      title: 'Kintell Akademy',
      subtitle: 'Quickly master video engagement by watching mini-guide videos or exploring our customers’ inspiring videos.',
      cta: 'Go to Akademy',
      key: 'akademy',
    },
    digitalAssets: {
      title: 'Digital assets',
      subtitle: 'Share your content on socials or via email or on your website.',
      cta: 'View assets',
      key: 'digitalAssets',
    },
    downloadPortraitVideos: {
      title: 'Vertical videos',
      subtitle: 'Best for Instagram, WhatsApp, WeChat, TikTok. These video assets come with captions burnt in.',
      cta: 'Copy link',
      key: 'downloadPortraitVideos',
    },
    downloadLandscapeVideos: {
      title: 'Horizontal videos',
      subtitle: 'Best for website, YouTube, Vimeo. These video assets come with captions as a separate file (SRT).',
      cta: 'Copy link',
      key: 'downloadLandscapeVideos',
    },
    downloadSquareVideos: {
      title: 'Square videos',
      subtitle: 'Best for LinkedIn, Facebook, Twitter (X). These video assets come with captions as a separate file (SRT).',
      cta: 'Copy link',
      key: 'downloadSquareVideos',
    },
    downloadKuotes: {
      title: 'Kuotes',
      subtitle: 'Suits all social media platforms. These image assets are great for engaging your audience.',
      cta: 'Copy link',
      key: 'downloadKuotes',
    },
    downloadRawFootage: {
      title: 'Raw footage',
      subtitle: 'Watch the full length recording of a kinterview. The raw footage is for internal use and not recommended for sharing directly on socials.',
      cta: 'Copy link',
      key: 'downloadRawFootages',
    },
    shareVideoLibrary: {
      title: 'Video library',
      subtitle: 'Best for email campaigns. Get the URL for each of your videos. Track your email campaigns with Kintell’s intuitive data analytic dashboards.',
      cta: 'Copy link',
      key: 'shareVideoLibrary',
    },
  },

  applyForm: {
    adviseHelpOthers: {
      title: 'Advise and help others',
      subtitle: 'Change lives with your expertise! Others can book you for 1-to-1 video chats. You have full control over your offer and availability.'
    },
    learnFromOthers: {
      title: 'Learn from others',
      subtitle: 'Grow your knowledge with 1-to-1 video chats and content from others sharing their expertise.'
    },
    adviseAndLearn: {
      title: 'Both advise and learn',
      subtitle: 'Unlock infinite opportunities by both sharing your expertise and learning from others via 1-to-1 video chats and content.'
    },
    'kintell-akademy': {
      apply: {
        title: 'Unlock your full access',
        subtitle: 'Please give us some quick details to unlock full access to ‘Kintell Akademy’ and become a video engagement master!',
      }
    },
    'unsw-career-compass': {
      apply: {
        title: 'Unlock your full access',
        subtitle: `Career Compass is your free, virtual, go-to toolkit for launching into the professional world. Please enter your details below, it takes less than ten seconds and the team will review your application in less than 48 hours.`,
      }
    },

    'auapg-metrics-cop': {
      apply: {
        subtitle: `Please provide the details below so the ‘AUAPG Metrics CoP’ team can approve your access. This takes less than a minute and the team will get back to you within 48 hours.`
      }
    },

    'auapg-connect': {
      apply: {
        subtitle: `Please provide the details below so the ‘AUAPG Connect’ team can approve your access and prepare your profile. This takes 10 seconds and the team will get back to you in 48 hours.`
      }
    },

    careerconversations: {
      apply: {
        title: 'Unlock your full access',
        subtitle: `CareerConversations is your digital hub to watch short videos or book 1-to-1 video advice for career hacks with experienced alumni. Please enter your details below, it takes less than ten seconds and the team will review your application in less than 48 hours.`
      }
    },
  }
}
