export default {
  availability: {
    title: 'When can you take bookings?',
    paragraph: 'Let us know when you\'d like to take Kintell bookings by selecting the appropriate weekly time slots in the calendar below. We\'ll make sure you\'re only available at those times displayed in green.',
  },
  bank: {
    errors: {
      termsAndConditions: {
        required: 'You must agree to the terms and conditions.'
      }
    }
  }
}
