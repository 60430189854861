//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { UsesAuthenticatedUser } from '@/mixins'

export default {
  mixins: [ UsesAuthenticatedUser ],

  props: {
    value: Boolean,
  },

  computed: {
    classes () {
      return {
        'k-icon-hamburger__burger--opened': this.value,
      }
    },

    alertCount () {
      if (this.$vuetify.breakpoint.smAndUp || this.value) {
        return 0
      }

      return this.authenticatedUser?.avatarAlertCount ?? 0
    },
  }
}
