export function useNodeEnv () {
  return process.env.NODE_ENV
}

export function useAppUrl () {
  return process.env.APP_URL
}

export function useCloudinaryUrl () {
  return process.env.CLOUDINARY_URL || 'https://res.cloudinary.com/kintell/image/fetch/'
}
