export default {
  computed: {
    learnerIsViewingAPendingBookingInitiatedByAdvisor () {
      return this.booking.isAdvisorInitiatedAndPendingApproval && !this.isAuthenticatedUserAdvisorOfBooking
    },
    learnerIsViewingAPendingBookingInitiatedByAdvisorOrFacilitatedBooking () {
      return this.isAuthenticatedUserLearnerOfBooking &&
        (this.booking.isAdvisorInitiatedAndPendingApproval || this.booking.isGroupAdminFacilitatedAndPendingApproval)
    },
    advisorIsViewingAPendingBookingTheyInitiated () {
      return this.booking.isAdvisorInitiatedAndPendingApproval && this.isAuthenticatedUserAdvisorOfBooking
    },
    groupAdminIsViewingAPendingBookingTheyFacilitated () {
      return this.booking.isGroupAdminFacilitatedAndPendingApproval && this.isAuthenticatedUserCreatorOfBooking
    },
    advisorOrGroupAdminIsViewingAnInitiatedOrFacilitatedBooking () {
      return this.advisorIsViewingAPendingBookingTheyInitiated || this.groupAdminIsViewingAPendingBookingTheyFacilitated
    },
    isAuthenticatedUserAdvisorOfBooking () {
      return this.userIsAuthenticated && this.booking.advisorId === this.authenticatedUser.id
    },
    isAuthenticatedUserLearnerOfBooking () {
      return this.userIsAuthenticated && this.booking.learnerId === this.authenticatedUser.id
    },
    isAuthenticatedUserCreatorOfBooking () {
      return this.userIsAuthenticated && this.booking.createdById === this.authenticatedUser.id
    },
    isAuthenticatedUserGroupAdminOfBooking () {
      return this.booking.createdById === this.authenticatedUser.id &&
        this.booking.learnerId !== this.authenticatedUser.id &&
        this.booking.advisorId !== this.authenticatedUser.id
    },
    groupAdminIsViewingBooking () {
      return (this.authenticatedUser.id === this.booking.createdBy.id || this.authenticatedUser.id === this.booking.createdBy) && this.booking.wasCreatedByGroupAdmin
    },
    initiatedByYou () {
      return this.booking.createdById === this.authenticatedUser.id
    },
    otherUser () {
      if (this.booking.instantBooking) {
        return this.booking.advisor
      }
      if (this.isAuthenticatedUserAdvisorOfBooking) {
        return this.booking.learner
      }
      return this.booking.advisor
    },
    otherUserName () {
      return this.advisorIsViewingAPendingBookingTheyInitiated ? this.otherUser.firstName : this.otherUser.fullName
    },
    otherUserFirstName () {
      return this.otherUser.firstName
    },
    advisor () {
      return this.booking.advisor
    },
    learner () {
      return this.booking.learner
    },
    roleLabel () {
      const prefix = this.booking.isArchived || this.isKintellGroupBooking ? '' : 'You\'re '
      if (this.isAuthenticatedUserGroupAdminOfBooking && this.booking.wasCreatedByGroupAdmin) {
        return prefix + 'admin'
      }
      return prefix + (this.isAuthenticatedUserAdvisorOfBooking ? 'advising' : 'learning')
    },
    kChipColor () {
      if (this.isAuthenticatedUserGroupAdminOfBooking) {
        return {
          body: 'brand-grey',
          text: 'white'
        }
      }
      return {
        body: this.isAuthenticatedUserAdvisorOfBooking ? 'brand-light-aqua-30' : 'brand-grey-20',
        text: this.isAuthenticatedUserAdvisorOfBooking ? 'brand-dark-aqua' : 'brand-pink'
      }
    },
    mobileRoleTextColour () {
      if (this.isAuthenticatedUserGroupAdminOfBooking) {
        return 'brand-grey--text'
      }
      return this.isAuthenticatedUserAdvisorOfBooking ? 'brand-dark-aqua--text' : 'brand-light-pink--text'
    },
    bookingDuration () {
      const duration = this.booking.bothInVideoTime || this.booking.duration
      const postfix = duration > 1 ? 'mins' : 'min'
      return `${duration} ${postfix}`
    },
  }
}
