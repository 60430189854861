import { render, staticRenderFns } from "./KLayoutHeaderLogo.vue?vue&type=template&id=44058dd3&"
import script from "./KLayoutHeaderLogo.vue?vue&type=script&lang=js&"
export * from "./KLayoutHeaderLogo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {KLayoutBaseLogo: require('/opt/atlassian/pipelines/agent/build/app/components/app/Layout/KLayoutBaseLogo.vue').default,KImg: require('/opt/atlassian/pipelines/agent/build/app/components/shared/Img/KImg.vue').default})
