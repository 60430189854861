import Vue from 'vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import { isEmpty } from 'lodash-es'
import Consola from '@/lib/consola'
const logger = new Consola('kintell:bugsnag')

export default ({ env, app, $config: { bugsnagKey } }, inject) => {
  if (isEmpty(bugsnagKey)) {
    inject('bugsnag', {
      notify: e => {
        logger.error('Bugsnag error thrown, captured locally because of missing Bugsnag API Key.', e)
        return e
      }
    })
    return
  }
  // setup bugsnag
  const bugsnagClient = Bugsnag.start({
    apiKey: bugsnagKey,
    appVersion: env.VERSION,
    logger,
    maxEvents: 25,
    releaseStage: process.env.NODE_ENV,
    enabledReleaseStages: [ 'production', 'uat' ],
    plugins: [ new BugsnagPluginVue(Vue) ]
  })
  inject('bugsnag', bugsnagClient)

  app.router.afterEach(() => {
    if (app.$auth.loggedIn) {
      const user = app.$auth.$state.user
      bugsnagClient.setUser(user.id, user.email, user.firstName + ' ' + user.lastName)
    } else {
      bugsnagClient.setUser(null, null, null)
    }
  })
}
