const groupCaseStudies = [
  {
    id: 'mentoring',
    title: 'For Mentoring',
    text: 'UNSW Sydney uses Kintell to connect graduates around the globe for professional development.',
    imgId: 'unsw-2',
    link: 'for-mentoring',
  },
  {
    id: 'ukraine',
    title: 'For Ukraine',
    text: 'Thousands of Ukrainians are connected with foreign language tutors using Kintell.',
    imgId: 'lcu',
    link: 'for-ukraine',
  },
  {
    id: 'engagement-and-comms',
    title: 'For Engagement & Comms',
    text: 'Video-first engagement for students & alumni by G8 universities ANU, UNSW, and UWA.',
    imgId: 'engagement-and-comms',
    link: 'for-engagement-and-comms',
  },
  {
    id: 'lighter',
    title: 'GUIDES & PLAYBOOKS',
    text: 'Create engaging and authentic bite-size video content using Kintell’s LIGHT<sup>&reg;</sup> approach.',
    imgId: 'lighter',
    link: 'light-video-production',
  },
  {
    id: 'nicer',
    title: 'GUIDES & PLAYBOOKS',
    text: 'Stand out from the crowd with video-first strategies from Kintell’s NICER<sup>&reg;</sup> playbook.',
    imgId: 'nicer',
    link: 'nicer-digital-engagement',
  },
]

export default {
  profileShare: {
    title: 'Share your Kintell profile',
    body: ' If you’d like to share your Kintell profile page with a friend or colleague, simply copy the URL below.',
    copy: 'Copy',
    share: 'Share',
    preview: 'preview'
  },
  welcome: {
    learner: {
      title: 'Welcome to the Kintell community!',
      body: 'Hi {name}, you’re now signed up to Kintell! Woop! You can now either browse our Advisors or become an Advisor yourself by creating a Kintell Card.',
    },
  },
  whatIsKintell: {
    title: 'What is Kintell?',
    body: 'Kintell is the global knowledge network - a new way of getting and giving independent advice.'
  },
  caseStudies: {
    group: [ ...groupCaseStudies ],
    individual: [
      {
        id: 'consultants',
        title: 'For Consultants',
        text: 'Gretchen Dobson is a global engagement consultant who connects with her clients around the world using Kintell.',
        imgId: 'gretchen',
        link: 'for-consultants',
      },
      {
        id: 'coaches',
        title: 'For Coaches',
        text: 'Discover how Danielle Neale uses Kintell to add flexibility and visibility to her startup coaching practice.',
        imgId: 'danielle',
        link: 'for-coaches',
      },
      ...groupCaseStudies
    ],
  }
}
