
// src: https://dev.to/pichardoj/optional-null-safe-in-javascript-1b7k

// Here is where the magic happens
function optional (obj, evalFunc, def) {
  // if the object is undefined or null then just return the default
  if (!obj) {
    return def
  }
  // Our proxy handler
  const handler = {
    // Intercept all property access
    get (target, prop, receiver) {
      // eslint-disable-next-line prefer-rest-params
      const res = Reflect.get(...arguments)

      // If our response is an object then wrap it in a proxy else just return
      return typeof res === 'object' ? proxify(res) : res != null ? res : def
    }
  }

  function proxify (target) {
    return new Proxy(target, handler)
  }

  // Call function with our proxified object
  return evalFunc(proxify(obj, handler))
}

export default optional
