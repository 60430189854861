//
//
//
//

import { BindsAttrs } from '@/mixins'
export default {
  mixins: [ BindsAttrs ],
  props: {
    label: {
      type: String,
      required: true
    }
  },
  computed: {
    color () {
      return this.label.toLowerCase() === 'pro' ? 'brand-dark-blue-70' : 'white'
    },
    classes () {
      return this.label.toLowerCase() === 'plus' ? 'has-border' : 'grey-3--text'
    }
  }
}
