import { render, staticRenderFns } from "./KChip.vue?vue&type=template&id=1e1e7189&scoped=true&lang=pug&"
import script from "./KChip.vue?vue&type=script&lang=js&"
export * from "./KChip.vue?vue&type=script&lang=js&"
import style0 from "./KChip.vue?vue&type=style&index=0&id=1e1e7189&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e1e7189",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {KIcon: require('/opt/atlassian/pipelines/agent/build/app/components/shared/Icon/KIcon.vue').default})


/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
installComponents(component, {VChip})
