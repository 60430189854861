export default {
  methods: {
    openConversionModal () {
      this.$nuxt.$emit('showConversionModal')
    },
    openDowngradeModal () {
      this.$nuxt.$emit('showDowngradeModal')
    }
  }
}
