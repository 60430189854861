//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { sync, get } from 'vuex-pathify'
import { UsesAuthenticatedUser, UsesKintellGroup } from '@/mixins'
import TheNavigationDrawer from './TheNavigationDrawer'

export default {
  components: {
    TheNavigationDrawer,
  },

  mixins: [
    UsesAuthenticatedUser,
    UsesKintellGroup,
  ],

  data: () => ({
    features: [
      {
        id: 'featureVideoProduction',
        path: '/video-production',
        title: 'VIDEO PRODUCTION',
        description: '1-to-1 <span style="color:#ef5185;font-weight: bold">k</span>interviews by our remote video producers',
      },
      {
        id: 'featureVideo',
        path: '/features/video-meetings',
        title: 'VIDEO MEETINGS',
        description: 'Instant or scheduled meetings',
      },
      {
        id: 'featureCal',
        path: '/features/calendar-and-bookings',
        title: 'CALENDAR & BOOKINGS',
        description: 'Integrate Kintell into your busy schedule',
      },
      {
        id: 'featurePay',
        path: '/features/payment-integration',
        title: 'PAYMENT INTEGRATION',
        description: 'Get paid easily for your knowledge',
      },
    ],

    resources: [
      {
        id: 'playCircular',
        iconSize: '38',
        path: '/testimonials',
        title: 'TESTIMONIALS',
        description: 'Why Kintell? Hear it from our customers!',
      },
      {
        id: 'award',
        iconSize: '30',
        path: '/articles/category/success-stories',
        title: 'SUCCESS STORIES',
        description: 'Real life cases for inspiration',
      },
      {
        id: 'question',
        iconSize: '34',
        path: '/faq',
        title: 'PRODUCT FAQs',
        description: 'Frequently asked questions',
      },
      {
        id: 'edit',
        iconSize: '30',
        path: '/articles/category/playbooks',
        title: 'GUIDES & PLAYBOOKS',
        description: 'Practical and proven video-first strategies',
      },
    ],
  }),

  computed: {
    ...sync({
      showDarkHeader: 'layout/showDarkHeader',
    }),

    ...get({
      showDrawer: 'layout/showDrawer',
      showGroupSidebar: 'layout/showGroupSidebar',
    }),

    classes () {
      // TODO: the `white` class is not scalable. Need to find a better way. Maybe add a vuex global state for `hasSidebar` ?
      const isGroupPage = [ 'g-kintellGroup-apply', 'g-kintellGroup-join' ].includes(this.$route.name)
      const hasSidebar = (isGroupPage && this.showGroupSidebar) || this.$route.path.includes('/password-free')

      return {
        'k-header--dark': this.showDarkHeader,
        white: hasSidebar || this.$route.path === '/book-a-demo',
      }
    },

    isOnArticles () {
      return this.$route.path.includes('/articles')
    },

    ctaGetStarted () {
      return this.$route.path === '/for-business'
        ? { path: '/book-a-demo', text: `Book a demo` }
        : { path: '/password-free?step=signup&redirectTo=my-kintell-dashboard', text: `Start for free!` }
    },

    hideSearchField () {
      const routeName = this.$route.name
      // match search page or any group page
      return routeName?.match(/(search|g -.+)/) || !this.notOnGroupWhiteLabelPage
    },

    notOnGroupWhiteLabelPage () {
      return !this.onGroupKintellPage && !this.isOnGroupCard
    },

    isOnGroupCard () {
      const activeKintellCard = this.$store.getters['kintellCards/activeKintellCard']

      return !!activeKintellCard?.kintellGroupId
    }
  },

  watch: {
    isOnArticles: {
      handler (val) {
        this.showDarkHeader = val
      },
      immediate: true,
    }
  },
}
