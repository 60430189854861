export default {
  height: 19,
  width: 20,
  stroke: true,
  svg: `
  <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.4577 13.8751H16.291V10.813C16.2909 10.6715 16.2484 10.5332 16.169 10.416C16.0896 10.2989 15.9769 10.2082 15.8455 10.1557L13.1949 9.09319C13.0637 9.04077 12.9511 8.9503 12.8717 8.83342C12.7923 8.71653 12.7497 8.57857 12.7493 8.43727V7.81536C13.179 7.56882 13.5361 7.21349 13.7847 6.78513C14.0334 6.35677 14.1649 5.87047 14.166 5.37515V3.95848C14.1661 3.46107 14.0353 2.9724 13.7867 2.54159C13.538 2.11078 13.1804 1.75302 12.7496 1.50427C12.3189 1.25551 11.8302 1.12454 11.3328 1.12451C10.8354 1.12448 10.3468 1.2554 9.91602 1.50411" stroke="#ABB5B5" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.1791 10.864L7.52855 9.80154C7.39733 9.74912 7.2848 9.65865 7.2054 9.54176C7.126 9.42488 7.08338 9.28692 7.08301 9.14562V8.5237C7.51261 8.27716 7.86972 7.92184 8.11841 7.49348C8.36709 7.06511 8.49859 6.57881 8.49967 6.0835V4.66683C8.49967 3.91538 8.20116 3.19471 7.66981 2.66336C7.13846 2.13201 6.41779 1.8335 5.66634 1.8335C4.91489 1.8335 4.19423 2.13201 3.66287 2.66336C3.13152 3.19471 2.83301 3.91538 2.83301 4.66683V6.0835C2.83409 6.57881 2.96559 7.06511 3.21428 7.49348C3.46296 7.92184 3.82007 8.27716 4.24967 8.5237V9.14562C4.24959 9.28716 4.20709 9.42543 4.12768 9.54259C4.04827 9.65976 3.93557 9.75044 3.80413 9.80295L1.15355 10.8655C1.02233 10.9179 0.909795 11.0083 0.830399 11.1252C0.751003 11.2421 0.70838 11.3801 0.708008 11.5214V13.8752H10.6247V11.5214C10.6246 11.3798 10.5821 11.2416 10.5027 11.1244C10.4233 11.0072 10.3106 10.9165 10.1791 10.864Z" stroke="#ABB5B5" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
`
}
