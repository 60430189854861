export default {
  width: 87,
  height: 87,
  svg: `
  <g filter="url(#upcircle)">
    <circle r="23.5" transform="matrix(-1 0 0 1 43.5 35.5)" />
  </g>
  <mask id="uparrow" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="33" y="30" width="21" height="12">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M34.37 40.6942c-.4881-.4402-.507-1.1737-.0421-1.6383l8.6832-8.6784c.2425-.2424.5687-.3638.8946-.3619.3835-.0573.7897.0599 1.0778.3478l8.6776 8.6729c.4649.4646.4461 1.1981-.0421 1.6383-.4882.4401-1.2608.4203-1.7257-.0444l-7.889-7.8847-7.9086 7.9043c-.4649.4647-1.2375.4845-1.7257.0444Z" />
  </mask>
  <g mask="url(#uparrow)">
    <path fill="#fff" d="M34 43.75h20v-20H34z"/>
  </g>
  <defs>
    <filter id="upcircle" x="0" y="0" width="87" height="87" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="8"/>
      <feGaussianBlur stdDeviation="10"/>
      <feColorMatrix values="0 0 0 0 0.188235 0 0 0 0 0.196078 0 0 0 0 0.294118 0 0 0 0.08 0"/>
      <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_26239_34760"/>
      <feBlend in="SourceGraphic" in2="effect1_dropShadow_26239_34760" result="shape"/>
    </filter>
  </defs>
  `,
}
