export default {
  features: [
    {
      title: 'Testimonial videos',
      description: 'Hear directly from Kintell customers and users why they love kintell.',
      image: require('~images/resource/testimonial.png'),
      to: '/testimonials',
    },
    {
      title: 'Success stories',
      description: 'Get Inspired by real life cases of Kintell customers and users.',
      image: require('~images/resource/success-stories.png'),
      to: '/articles/category/success-stories',
    },
    {
      title: 'Kintell for Business',
      description: 'Video-first engagement via insight-sharing for organisations.',
      image: require('~images/resource/k-business.png'),
      to: '/for-business',
    },
    {
      title: 'Product FAQs',
      description: 'Got a question about Kintell? Most likely you can find the answer here.',
      image: require('~images/resource/product-faq.png'),
      to: '/faq',
    },
    {
      title: 'Guides & playbooks',
      description: 'Proven methods and practical step-by-step guides for video-first engagement via insight-sharing.',
      image: require('~images/resource/guides.png'),
      to: '/articles/category/playbooks',
    },
    {
      title: 'Articles',
      description: `Enjoy articles from industry experts on Kintelll's Insight Marketplace.`,
      image: require('~images/resource/articles.png'),
      to: '/articles',
    },
  ]
}
