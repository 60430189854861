import { startsWith, endsWith } from 'lodash-es'

export default function ({ app, redirect, route }) {
  const authState = app.$auth.$state
  // only run this middleware on the client side if they are logged in
  if (
    (!authState.loggedIn ||
    authState.user.phoneVerificationVerified ||
    // Exit out if the user is a placeholder
    authState.user.isPlaceholder ||
    // A user may have trouble verifying a phone number so we want them to be able to get in touch with us
    route.name === 'get-in-touch') &&
    !authState.user?.stats?.requiresEmailVerification
  ) {
    return
  }

  // if they aren't on the sign-up or sign-out page and they aren't on the personal details page - redirect them
  if (
    !startsWith(route.path, '/sign') &&
    !startsWith(route.path, '/my-kintell/my-details') &&
    // group join page
    !endsWith(route.path, '/join') &&
    // apply group page
    !endsWith(route.path, '/apply') &&
    // book page
    !endsWith(route.path, '/book') &&
    // initiate a booking page
    !endsWith(route.path, '/respond')
  ) {
    app.$logger.info('User has not verified their phone number, redirecting them to details page.', route)
    redirect('/my-kintell/my-details')
  }
}
