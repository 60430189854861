import { Context } from '@nuxt/types'
import { KintellCardMessage } from '@/lib/broadcasting/messages'

export default (ctx: Context) => {
  return (message: KintellCardMessage) => {
    const card = ctx.store.getters['kintellCards/activeKintellCard']

    const activeBooking = ctx.store.getters['myKintell/activeBooking']

    if (message.kintellCard.id !== card.id || activeBooking) {
      return
    }

    card.removeFromVuex()
    ctx.redirect('/')
  }
}
