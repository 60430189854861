export default {
  width: 12,
  height: 12,
  stroke: true,
  fill: false,
  svg: `
    <rect x=".5" y=".5" width="11" height="11" rx="1.5" stroke="#fff"/>
    <path d="M4.523 4.003c0-.705.661-1.276 1.477-1.276.816 0 1.477.571 1.477 1.276 0 .705-.512 1.702-1.477 1.702-.985 0-1.477-.997-1.477-1.702ZM8.121 9.273c.335 0 .606-.235.606-.524v-.517c0-.248-.169-.476-.447-.602-.442-.201-1.208-.452-2.28-.452-1.085 0-1.847.25-2.284.45-.276.126-.443.353-.443.6v.521c0 .29.271.524.606.524h4.242Z" stroke="#fff"/>
  `
}
