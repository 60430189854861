export default {
  data () {
    return {
      isInviteRequestModalOpen: false,
      timeToPopUpModal: 7000
    }
  },
  mounted () {
    this.startRequestInvitePopupTimer()
  },
  methods: {
    startRequestInvitePopupTimer () {
      setTimeout(() => (this.isInviteRequestModalOpen = true), this.timeToPopUpModal)
    },
  }
}
