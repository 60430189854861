import Vue from 'vue'
import Vue2Filters from 'vue2-filters'
import pluralize from 'pluralize'
import formatMoney from 'accounting-js/lib/formatMoney.js'

export default () => {
  Vue.use(Vue2Filters)


  Vue.filter('pluralize', (value, count) => pluralize(value, count))

  Vue.mixin({
    methods: {
      pluralize,
    }
  })

  const symbols = {
    USD: '$', // US Dollar
    AUD: '$', // AUD
    CAD: '$', // CAD
    NZD: '$', // NZD
    EUR: '€', // Euro
    CRC: '₡', // Costa Rican Colón
    GBP: '£', // British Pound Sterling
    ILS: '₪', // Israeli New Sheqel
    INR: '₹', // Indian Rupee
    JPY: '¥', // Japanese Yen
    KRW: '₩', // South Korean Won
    NGN: '₦', // Nigerian Naira
    PHP: '₱', // Philippine Peso
    PLN: 'zł', // Polish Zloty
    PYG: '₲', // Paraguayan Guarani
    THB: '฿', // Thai Baht
    UAH: '₴', // Ukrainian Hryvnia
    VND: '₫', // Vietnamese Dong
  }

  Vue.filter('dollarsWithoutCents', (value, currency) => {
    if (symbols[currency]) {
      return formatMoney(value, { symbol: symbols[currency], precision: 0 })
    }
    return formatMoney(value, { symbol: currency, precision: 0, format: '%v %s' })
  })

  Vue.filter('dollarsWithCents', (value, currency) => {
    if (symbols[currency]) {
      return formatMoney(value, { symbol: symbols[currency], precision: 2 })
    }
    return formatMoney(value, { symbol: currency, precision: 2, format: '%v %s' })
  })
}
