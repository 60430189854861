//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseLayout from '@/layouts/base'
import { UsesAuthenticatedUser, UsesGroupQueryString } from '@/mixins'

export default {
  components: { BaseLayout },
  mixins: [
    UsesAuthenticatedUser,
    UsesGroupQueryString
  ],
  data () {
    return {
      activeTab: this.$route.path
    }
  }
}
