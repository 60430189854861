import { forEach } from 'lodash-es'

/**
 * Utility to function join multiple module stores into one object exporting the state, getters, mutations and actions
 * @param args
 * @returns {{mutations, state, getters, actions}}
 */
export default (...args) => {
  let {
    state,
    getters,
    mutations,
    actions
  } = {}

  forEach(args, store => {
    state = {
      ...state,
      ...store.state
    }
    getters = {
      ...getters,
      ...store.getters
    }
    mutations = {
      ...mutations,
      ...store.mutations
    }
    actions = {
      ...actions,
      ...store.actions
    }
  })

  return {
    state,
    getters,
    mutations,
    actions
  }
}
