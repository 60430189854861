export default {
  width: 61,
  height: 46,
  stroke: true,
  fill: false,
  svg: `
<path d="M2 39.6977V5.76977C2 4.76996 2.39717 3.81111 3.10414 3.10414C3.81111 2.39717 4.76996 2 5.76977 2H54.7767C55.7766 2 56.7354 2.39717 57.4424 3.10414C58.1493 3.81111 58.5465 4.76996 58.5465 5.76977V39.6977C58.5465 40.6975 58.1493 41.6563 57.4424 42.3633C56.7354 43.0703 55.7766 43.4674 54.7767 43.4674H5.76977C4.76996 43.4674 3.81111 43.0703 3.10414 42.3633C2.39717 41.6563 2 40.6975 2 39.6977Z" fill="transparent" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.4258 34.0449H34.0444" fill="transparent" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M41.582 34.0449H49.1216" fill="transparent" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20.8502 11.4258H11.4258V18.9653H20.8502V11.4258Z" fill="transparent" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M42.5391 19.8573C43.1396 19.0548 43.4641 18.0794 43.4641 17.0771C43.4641 16.0748 43.1396 15.0994 42.5391 14.2969" fill="transparent" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M47.1094 23.2049C48.4196 21.4312 49.1265 19.2841 49.1265 17.079C49.1265 14.8739 48.4196 12.7268 47.1094 10.9531" fill="transparent" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  `
}
