export default {
  width: 58,
  height: 53,
  stroke: true,
  svg: `
<path d="M47 2H11C6.02944 2 2 6.02944 2 11V41.6593C2 46.6299 6.02944 50.6593 11 50.6593H47C51.9706 50.6593 56 46.6299 56 41.6593V11C56 6.02944 51.9706 2 47 2Z" fill="transparent" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M29.522 23.2198C32.3021 23.2198 34.5557 20.9661 34.5557 18.1861C34.5557 15.406 32.3021 13.1523 29.522 13.1523C26.742 13.1523 24.4883 15.406 24.4883 18.1861C24.4883 20.9661 26.742 23.2198 29.522 23.2198Z" fill="transparent" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M29.5197 28.5996C26.6271 28.5996 23.853 29.7487 21.8077 31.794C19.7623 33.8394 18.6133 36.6135 18.6133 39.506H40.4261C40.4261 36.6135 39.277 33.8394 37.2317 31.794C35.1863 29.7487 32.4122 28.5996 29.5197 28.5996V28.5996Z" fill="transparent" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  `
}
