export default {
  create: {
    private: {
      title: 'Add a Private Card',
      subtitle: 'Share a private link with your contacts to book you for a video meeting without rating and review afterwards.',
    },
    group: {
      title: 'Add a Group Card',
      subtitle: 'Make yourself visible only to your organisational networks so your peers can book you for a video meeting.',
    },
    marketplace: {
      title: 'Add a Marketplace Card',
      subtitle: 'List your expertise on Kintell Insight Marketplace so anyone can book you and leave you reviews afterwards!',
    }
  },
  private: {
    title: 'Private Kintell Meeting',
    summary: 'Hi, please book a session with me using this card. \n\n' +
      '- This will be a private video session (without rating/review afterwards).\n\n' +
      '- Please keep the link of this card to yourself as it is only for private sessions with my direct contacts.\n\n' +
      'I look forward to our Kintell session. Thank you very much!'
  },
  edit: {
    errors: {
      skills: {
        minLength: 'Oops! Please enter a minimum of {min} related skills :)',
        maxLength: 'Ah sorry we limit the amount of skill tags to {max}'
      },
      title: {
        notInArray: 'Oops! Looks like you already have a Kintell Card with this Topic.'
      },
      minLength: 'Oops! It looks like you’re below our character minimum. We like to keep our Kintell Card {field} fields to over {min} characters :)',
      maxLength: 'Oops! It looks like you’ve hit our character limit. We like to keep our Kintell Card {field} fields to under {max} characters, to keep things simple and snappy :)',
      advisingIn: {
        minLengthNotEmpty: 'Oops! We like to keep our Kintell Cards to having at least {min} Advising In fields :)'
      },
      categories: {
        minLength: 'Oops! Please enter a minimum of {min} categories :)',
        maxLength: 'Oops!, You reached your limit of {max} categories :)'
      },
      rate: {
        maxValue: 'Sorry, the highest rate you can set is {currency}{max} / 30 mins :)',
        kintellCardRate: 'Sorry, the lowest rate you can set is {currency}15 / 30 mins or FREE :)',
        kintellCardNoPayouts: 'Sorry, since we don\'t support payouts in your country currently, you can only set the rate to $0.',
      }
    },
    placeholders: {
      title: 'Your Card Title',
      advisingIn: 'Advising In',
      summary: 'Describe the service you will be offering as an advisor and relevant experience.',
    },
    hints: {
      title: 'E.g. Web Designer, English Teacher, or Aviation Advisor.',
      socialLink: 'Select the social network for this card',
      supportingLinks: {
        description: '(optional)'
      }
    }
  },
  // k-layout-split-image-left
  sentForReview: {
    title: 'Nearly there!',
    subtitle: 'As this is your first Marketplace Card, we’ve sent it to The Kintell Team to review it before it goes live.',
    nextSteps: {
      steps: [
        { step: 'You should hear back from The Kintell Team as soon as they have approved it' },
        { step: 'If it needs tweaking in any way they’ll let you know how' },
        { step: 'Once it’s live you can start taking bookings straight away!' },
      ]
    }
  },
  sentForReviewGroupCard: {
    title: 'Nearly there!',
    subtitle: 'As this is your first Group Card in \'{kintellGroup}\' we\'ve sent it to the Admin of this group to review it before it goes live.',
    nextSteps: {
      steps: [
        { step: 'You should hear back from the admin of \'{kintellGroup}\' as soon as they have approved it' },
        { step: 'If it needs tweaking in any way they’ll let you know how' },
        { step: 'Once it’s live you can start taking bookings straight away!' },
      ]
    }
  },
  ghostCards: [
    {
      rate: 68,
      title: 'Craft Beer Brewing',
      summary: 'Our small team started brewing pale ale in our micro-brewery 5 years ago. Moving steadily from producing one barrel a month, our current brew length (amount of beer per brew) is 6HL (600 litres), per quarter. We have now grown to a team of 8 dedicated connoisseurs, perfecting our craft to produce award-winning ale. \n\n' +
        'If you’re looking to tap into years of brewing experience and would like to taste what it\'s like to turn a garage project into a thriving and scalable business, reach out for a consultation.',
      skills: [
        'Milling and Mashing',
        'Tasting',
        'Beer Chemistry'
      ],
      status: 'published',
      stats: {
        reviewsCount: 137,
        reviewsAverage: 5,
      },
      advisingIn: [
        'Craft beer history in Australia & New Zealand',
        'Brewing & branding market ready craft beer',
        'Balancing malt & hop profiles',
        // Repeating because we have three hidden fields
        'Craft beer history in Australia & New Zealand',
        'Brewing & branding market ready craft beer',
      ],
      workHistories: [
        {
          organization: 'The Bold Dog Brewery',
          role: 'Owner',
          description: 'I work with a team of eight to perfect our craft which has a 100% increase of sales in 6 months.',
          workingPeriod: '2015-present'
        },
        {
          organization: 'New Zealand International Beer Awards',
          role: 'Judge',
          description: 'I was one of the three judges to select the most irresistible pale ale.',
          workingPeriod: '2019-2019'
        },
        {
          organization: 'The Blue Mountain Brewery',
          role: 'Manager',
          description: 'I was managing a team of five and coaching new staff.',
          workingPeriod: '2014-2015'
        }
      ],
      relevantQualifications: [
        {
          qualification: 'Certificate 3',
          subject: 'Gastronomy',
          organization: 'TAFE College NZ',
          completedYear: '2002'
        },
        {
          qualification: 'Diploma',
          subject: 'Management',
          organization: 'Griffith University',
          completedYear: '2001'
        },
        {
          qualification: 'Certificate 1',
          subject: 'Hospitality',
          organization: 'TAFE College Australia',
          completedYear: '1998'
        }
      ],
      supportingLinks: [
        {
          title: 'Website of my brewery',
          link: 'https://bolddogbrewery.com.au',
          description: 'Browse our diverse category of beers',
        },
        {
          title: 'Secrets of brewing',
          link: 'https://youtube.com/watch?v=AVV3UJCFnA4',
          description: 'In this video I share my experience and some “secrets” of crafting the perfect beer.',
        },
        {
          title: 'My expert view on beer industry',
          link: 'https://kintell.com/articles/craft-beer-evolution',
          description: 'In this article I share my insights on how the beer industry is evolving.',
        },
      ]
    },
    {
      rate: 75,
      stats: {
        reviewsCount: 1235,
        reviewsAverage: 5,
      },
      title: 'Esports Coaching',
      status: 'published',
      summary: 'Since high school, I have developed an everlasting love for clan gaming :) \n\n' +
        'Streaming and competing for 5 years now, I have over 50,000 Twitch followers. My subscriptions continue to grow every month and generate income weekly. If you want to become a professional gamer or even an E-sports Coach like me, I have years of experience and wisdom that you could tap into. \n\n' +
        'Reach out for a consultation and I can offer coaching tips, effective scrim design, and team management advice.',
      skills: [
        'League of Legends',
        'Recruitment',
        'Strategy'
      ],
      advisingIn: [
        'Building a team culture & E-team management',
        'Designing effective training for competition',
        'History of ‘World of Warcraft’ game',
        // Repeating because we have three hidden fields
        'Building a team culture & E-team management',
        'Designing effective training for competition',

      ],
      workHistories: [
        {
          organization: 'League of Legends World Championship',
          role: 'Coach',
          description: 'I was the coach of the Korean team which won the championship.',
          workingPeriod: '2019-2019'
        },
        {
          organization: 'League of Legends Champions Korea',
          role: 'Assistant Coach',
          description: 'I was the assistant coach of the Korean team and helped the team to make it to top 2 in 2018.',
          workingPeriod: '2016-2018'
        },
        {
          organization: 'League of Legends World Championship',
          role: 'Member of U.S. Team',
          description: 'For two years the U.S. team made it to the top 5.',
          workingPeriod: '2014-2015'
        }
      ],
      relevantQualifications: [
        {
          qualification: 'Certificate',
          subject: 'Esport Management',
          organization: 'University of California',
          completedYear: '2018'
        },
        {
          qualification: 'Diploma',
          subject: 'Positive Psychology',
          organization: 'University of Technology Sydney',
          completedYear: '2016'
        },
        {
          qualification: 'Certificate',
          subject: 'Coaching',
          organization: 'Queensland University of Technology',
          completedYear: '2015'
        }
      ],
      supportingLinks: [
        {
          title: 'My personal coaching website',
          link: 'https://esportexcel.com/',
          description: 'You can learn more about my coaching experience and service here.',
        },
        {
          title: '“Best Coach” award in 2019',
          link: 'https://youtube.com/watch?v=X1GFO0vx0LU',
          description: 'I was interviewed by The League of Legends after winning the Best Coach award in 2019',
        },
        {
          title: 'My expert view on Esports industry',
          link: 'https://kintell.com/articles/future-of-esports',
          description: 'In this article I share my insights on where the Esport industry is heading.',
        },
      ]
    },
    {
      rate: 30,
      title: 'Astronaut',
      stats: {
        reviewsCount: 35,
        reviewsAverage: 4,
      },
      status: 'published',
      summary: 'I have 15 years of experience working for NASA and SpaceX. I am passionate about space exploration and embracing the unknown universe. \n\n' +
        'I have a degree in Aerospace Engineering. Over the years I have accumulated a wealth of knowledge preparing teams for future missions, teaching them how to handle high-stress environments and solve complicated engineering and human-in-the-loop problems. \n\n' +
        'I can offer advice to explore your own path to become a successful Astronaut.',
      skills: [
        'Piloting spacecraft',
        'SpaceX',
        'NASA'
      ],
      advisingIn: [
        'Developing pre & post mission mental fitness ',
        'Atmospheric entry - key control considerations',
        'Long-duration astronaut physical examination',
        // Repeating because we have three hidden fields
        'Developing pre & post mission mental fitness ',
        'Atmospheric entry - key control considerations',
      ],
      workHistories: [
        {
          organization: 'SpaceX',
          role: 'Research Engineer',
          description: 'My research project is about agriculture on Mars and we successfully grew some pumpkins.',
          workingPeriod: '2019-2019'
        },
        {
          organization: 'NASA',
          role: 'Astronaut',
          description: 'My main responsibility was to coordinate engineering, medical, and scientific experiments on the moon.',
          workingPeriod: '2000-2015'
        },
        {
          organization: 'UC Berkeley',
          role: 'Teaching Assistant',
          description: 'I was teaching the “How to Thrive on the Moon” course for the School of Engineering.',
          workingPeriod: '1997-1999'
        }
      ],
      relevantQualifications: [
        {
          qualification: 'PhD',
          subject: 'Aerospace Engineering',
          organization: 'UC Berkeley',
          completedYear: '2000'
        },
        {
          qualification: 'BE',
          subject: 'Aerospace Engineering',
          organization: 'Caltech',
          completedYear: '1995'
        },
        {
          qualification: 'Online Certificate',
          subject: 'Renewable Energy',
          organization: 'Coursera',
          completedYear: '2017'
        }
      ],
      supportingLinks: [
        {
          title: 'My interview with BBC',
          link: 'https://youtube.com/watch?v=iIlAkbUKHKU',
          description: 'BBC interviewed me about my NASA project on the moon.',
        },
        {
          title: 'My published study',
          link: 'https://frontiersin.org/articles/10.3389/frspt.2020.593523/full',
          description: 'My study compares the pumpkin growth speed on Mars and the Moon.',
        },
        {
          title: 'My expert view on space farming',
          link: 'https://kintell.com/articles/space-farming',
          description: 'My insights on where space farming is heading to in the next decade.',
        },
      ]
    },
    {
      rate: 60.00,
      stats: {
        reviewsCount: 67,
        reviewsAverage: 5,
      },
      status: 'published',
      title: 'Motion Creative',
      summary: 'I have worked in the Broadcasting and TV industry for many years as a Design Lead and Motion Graphic Designer, turning ideas into visually stunning motion graphics. \n\n' +
        'Collaborating with various advertising agencies and production companies throughout my career, I’ve spearheaded a number of award-winning projects, such as Tron - Uprising. \n\n' +
        'Feel free to hit me up! I would love to share my experience with you.',
      skills: [
        'CGI',
        'Adobe suite',
        'Final Cut Pro'
      ],
      advisingIn: [
        'Motion graphics research & design',
        'Broadcast media programming & scheduling',
        'Computer Generated Imagery (CGI) ',
        // Repeating because we have three hidden fields
        'Motion graphics research & design',
        'Broadcast media programming & scheduling',
      ],
      workHistories: [
        {
          organization: 'BuhiStar Inc.',
          role: 'Animation Supervisor',
          description: 'I led the team to successfully complete a series of Pokémon projects.',
          workingPeriod: '2010-2018'
        },
        {
          organization: 'Polygon Pictures',
          role: 'Senior Animator',
          description: 'I was promoted after my first year because of my contribution to the Frozen series.',
          workingPeriod: '2002-2005'
        },
        {
          organization: 'Polygon Pictures',
          role: 'Animator',
          description: 'I joined the team to work on a project that supports the production of Ice Age.',
          workingPeriod: '2001-2002'
        }
      ],
      relevantQualifications: [
        {
          qualification: 'Diploma',
          subject: 'Advanced Multimedia',
          organization: 'Raffles Institution',
          completedYear: '2018'
        },
        {
          qualification: 'Certificate',
          subject: 'Management',
          organization: 'University of Tokyo',
          completedYear: '2017'
        },
        {
          qualification: 'Diploma',
          subject: 'Multimedia',
          organization: 'Raffles Institution',
          completedYear: '2001'
        }
      ],
      supportingLinks: [
        {
          title: 'My interview with CNN',
          link: 'https://youtube.com/watch?v=bUB0HcmGxAw',
          description: 'CNN interviewed me about my work for the Frozen series',
        },
        {
          title: 'Behind the scene of Pokémon',
          link: 'https://animationmagazine.net/shorts/behind-the-scene-of-Pokémon/',
          description: 'In this article I explained the team work to create successful animations.',
        },
        {
          title: 'My views on CGI industry trends',
          link: 'https://kintell.com/articles/CGI-future',
          description: 'In this article I share my insights on trending tools used by CGI experts.',
        },
      ]
    },
    {
      rate: 0,
      stats: {
        reviewsCount: 12,
        reviewsAverage: 5,
      },
      title: 'Stunt coordinating',
      status: 'unlisted',
      summary: 'I’ve worked on hundreds of movie and television sets as a Stuntman and Action performer, doubling for well - known actors all over the world. \n\n' +
        'I’ve performed with horses, chimps, and elephants and have developed my craft to become a leading Stunt Coordinator throughout the industry.I love helping Action Performers develop their careers. \n\n' +
        'I offer a wide variety of skills and techniques that can strengthen your understanding of Action Design',
      skills: [
        'Consulting',
        'Action Design',
        'Backflips'
      ],
      advisingIn: [
        'Effectively casting stuntwomen & men',
        'Understanding African animals on set',
        'Collaborative design between humans & animals',
        // Repeating because we have three hidden fields
        'Effectively casting stuntwomen & men',
        'Understanding African animals on set',
      ],
      workHistories: [
        {
          organization: 'Stunt Action Consultants',
          role: 'Stunt Action Consultant',
          description: 'Assisted my clients to deliver excellent stunt actions and was involved in the production of Jumanji and Mulan.',
          workingPeriod: '2002-present'
        },
        {
          organization: 'Marvel Studios',
          role: 'Stunt Coordinator',
          description: 'Coordinated stunt actions for Iron Man.',
          workingPeriod: '2000-2001'
        },
        {
          organization: 'Marvel Studios',
          role: 'Stunt Assistant',
          description: 'Assisting the Stunt Coordinator for safety related measures.',
          workingPeriod: '1999-2000'
        }
      ],
      relevantQualifications: [
        {
          qualification: 'Licence',
          subject: 'High Risk Work',
          organization: 'MEAC',
          completedYear: '2000'
        },
        {
          qualification: 'Certificate 1',
          subject: 'High Risk Work',
          organization: 'MEAC',
          completedYear: '1999'
        },
        {
          qualification: 'Diploma',
          subject: 'Film & TV',
          organization: 'Tasmania Film School',
          completedYear: '1998'
        }
      ],
      supportingLinks: [
        {
          title: 'My interview with BBC',
          link: 'https://youtube.com/watch?v=bUB0HcmGxAw',
          description: 'BBC interviewed me about my work for Iron Man.',
        },
        {
          title: 'Behind the scene of Mulan',
          link: 'https://klook.com/en-MY/blog/behind-the-scenes-disney-mulan-movie/',
          description: 'In this article I explained how our team worked on the stunt actions of Mulan.',
        },
        {
          title: 'My personal journey',
          link: 'https://kintell.com/articles/how-to-start-a-career-as-a-stunt',
          description: 'In this blog article I share my personal journey of becoming a stunt coordinator',
        },
      ]
    },
    {
      rate: 38,
      stats: {
        reviewsCount: 27,
        reviewsAverage: 5,
      },
      status: 'draft',
      title: 'Chocolate',
      summary: 'I’m a certified Master Chocolatier who has been practicing and competing for over 15 years. \n\n' +
        'I’ve developed key skills such as sourcing quality ingredients and creating delicious pieces of art. I also know how to create a sustainable business based on my passion for chocolate! I can speak to a wide variety of culinary topics including tempering, sculpting and decorating. \n\n' +
        'If you are looking to advance your career as a culinary artist, I can offer some tips :) ',
      skills: [
        'Chemistry ',
        'Decoration',
        'Tasting'
      ],
      advisingIn: [
        'Decorative cake construction & food sculpture',
        'A culinary history of desserts in Switzerland',
        'Balancing flavour & textures with chocolate',
        // Repeating because we have three hidden fields
        'Decorative cake construction & food sculpture',
        'A culinary history of desserts in Switzerland',
      ],
      workHistories: [
        {
          organization: 'Ladurée Patisserie',
          role: 'Owner',
          description: 'I have taken over the business and rebranded it with a 50% increase in revenue in 3 months.',
          workingPeriod: '2017-present'
        },
        {
          organization: 'Ganache Patisserie',
          role: 'Patisserie Chef',
          description: 'I led a team of 4 to create 1000+ items for the Patisserie.',
          workingPeriod: '2015-2017'
        },
        {
          organization: 'Ganache Patisserie',
          role: 'Assistant Chef',
          description: 'I am responsible for procurement and ingredient preparation.',
          workingPeriod: '2014-2015'
        }
      ],
      relevantQualifications: [
        {
          qualification: 'Certificate V',
          subject: 'Patisserie',
          organization: 'TAFE NSW',
          completedYear: '2002'
        },
        {
          qualification: 'Certificate IV',
          subject: 'Baking',
          organization: 'TAFE NSW',
          completedYear: '2001'
        },
        {
          qualification: 'Diploma',
          subject: 'Hospitality',
          organization: 'TAFE NSW',
          completedYear: '2015'
        }
      ],
      supportingLinks: [
        {
          title: 'How to make the perfect chocolate',
          link: 'https://youtube.com/watch?v=Jl0IDXkYKbk',
          description: 'I was interviewed by the Eater channel about how to make delicious chocolate.',
        },
        {
          title: 'My blog about Patisserie',
          link: 'https://patisseriemakesperfect.co.uk/',
          description: 'I share my recipes and videos on my blog about everything related to Patisserie.',
        },
        {
          title: 'My personal journey',
          link: 'https://kintell.com/articles/patisserie-chef',
          description: 'In this blog article I share my personal journey of becoming a professional patisserie chef.',
        },
      ]
    }
  ],
}
