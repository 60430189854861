//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  middleware: 'dev-only',
  data () {
    return {
      drawer: false,
      uiPages: [
        'colours',
        'icons',
        'typography',
        'grid',
        'spacing',
        'kurfs',
      ],
      componentPages: [
        'inputs',
        'loaders',
        'tooltips',
        'buttons',
        'calendar',
      ],
      pagesPages: [
        'layouts',
        'confirms',
        'error',
        '404',
      ],
      logoUrl: require('~images/kintell-logo-light.svg')
    }
  },
}
