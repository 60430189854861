export default {
  width: 55,
  height: 53,
  svg: `
    <g fill-rule="nonzero">
      <path d="M20.4066 4.63022L30.4743 8.54542L20.4066 13.0199V4.63022ZM17.4066 15.3623V39.3906C17.4066 40.219 18.0782 40.8906 18.9066 40.8906C19.7351 40.8906 20.4066 40.219 20.4066 39.3906V16.3029L34.9846 9.8238C36.2034 9.28215 36.1621 7.53846 34.9191 7.05507L19.4503 1.03945C18.467 0.657058 17.4066 1.38242 17.4066 2.43746V15.2955C17.4061 15.3177 17.4061 15.34 17.4066 15.3623Z"/>
      <path d="M13.3796 30.0953C5.36354 32.138 0.21875 35.7777 0.21875 40.25C0.21875 47.1114 12.5964 52.0625 27.5 52.0625C42.4036 52.0625 54.7812 47.1114 54.7812 40.25C54.7157 38.5188 54.0289 36.9298 52.8541 35.7362C52.5329 35.3773 52.0683 35.0705 51.5497 34.8931C51.0316 34.7157 50.4764 34.6736 49.937 34.7708L32.6079 37.8275C32.5626 37.8355 32.5153 37.8288 32.4735 37.8081C32.432 37.7876 32.3979 37.7543 32.3764 37.713C32.3549 37.6718 32.3472 37.6249 32.3542 37.579C32.3613 37.5328 32.3828 37.4902 32.4158 37.4572L35.6557 34.2174C36.0873 33.788 36.3893 33.2409 36.5236 32.643C36.6577 32.0447 36.6184 31.4208 36.4102 30.8444C36.2019 30.268 35.8334 29.7631 35.348 29.3889C34.8626 29.0147 34.2805 28.7869 33.67 28.7322C31.6662 28.5365 29.5914 28.4375 27.5 28.4375C26.3412 28.4375 25.171 28.4706 23.9813 28.5351C23.1541 28.5799 22.5199 29.2868 22.5647 30.1141C22.6095 30.9413 23.3164 31.5755 24.1437 31.5307C25.2805 31.4691 26.3966 31.4375 27.5 31.4375C29.495 31.4375 31.4726 31.5319 33.3905 31.7191C33.4438 31.7239 33.4833 31.7394 33.5164 31.7649C33.5495 31.7904 33.5745 31.8247 33.5886 31.8638C33.6028 31.9029 33.6055 31.9455 33.5963 31.9862C33.5873 32.0265 33.5667 32.0638 33.5371 32.0933L30.2936 35.3367C29.8093 35.8218 29.4927 36.4474 29.3889 37.1237C29.285 37.7999 29.3995 38.492 29.7157 39.099C30.0317 39.7057 30.5331 40.1962 31.1468 40.4988C31.7607 40.8015 32.4549 40.9007 33.1288 40.7819L50.4636 37.7242C50.5056 37.7166 50.5432 37.7195 50.5784 37.7315C50.614 37.7437 50.6456 37.7645 50.6705 37.7922C51.3665 38.5014 51.7467 39.3811 51.7823 40.3077C51.7812 44.7033 40.8833 49.0625 27.5 49.0625C14.1167 49.0625 3.21875 44.7033 3.21875 40.25C3.21875 37.6295 7.30404 34.7394 14.1204 33.0023C14.9232 32.7978 15.4081 31.9812 15.2035 31.1784C14.999 30.3756 14.1824 29.8907 13.3796 30.0953Z"/>
    </g>
  `
}
