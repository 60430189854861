import { render, staticRenderFns } from "./KLandingSuccessStory.vue?vue&type=template&id=40cef862&scoped=true&"
import script from "./KLandingSuccessStory.vue?vue&type=script&lang=js&"
export * from "./KLandingSuccessStory.vue?vue&type=script&lang=js&"
import style0 from "./KLandingSuccessStory.vue?vue&type=style&index=0&id=40cef862&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40cef862",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {KButton: require('/opt/atlassian/pipelines/agent/build/app/components/shared/Button/KButton.vue').default,KImg: require('/opt/atlassian/pipelines/agent/build/app/components/shared/Img/KImg.vue').default,KLandingNewNavCarousel: require('/opt/atlassian/pipelines/agent/build/app/components/app/LandingNew/KLandingNewNavCarousel.vue').default,KContainer: require('/opt/atlassian/pipelines/agent/build/app/components/shared/Container/KContainer.vue').default})


/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VRow})
