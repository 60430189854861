import Echo from 'laravel-echo'
import { Model } from 'vue-api-query'

export default ({ app: { $auth, $logger, $inIframe }, store, redirect, $config: { pusherKey }, env }, inject) => {
  // do not init the store for an iframe (embed kintell card)
  if (!pusherKey || $inIframe) {
    return
  }

  window.Pusher = require('pusher-js')

  window.Pusher.log = msg => {
    $logger.trace(msg)
  }

  const token = window.localStorage.getItem('auth._token.local')

  window.Echo = new Echo({
    broadcaster: 'pusher',
    key: pusherKey,
    cluster: 'ap1',
    host: env.API_URL,
    authEndpoint: env.API_URL + '/broadcasting/auth',
    auth: {
      headers: {
        Authorization: token
      }
    }
  })

  Model.$echo = window.Echo

  inject('echo', window.Echo)
}
