//
//
//
//
//
//
//
//
//
//
//
//
//

import { sync } from 'vuex-pathify'

export default {
  computed: {
    ...sync({
      blogAlertOpen: 'guest/blogAlertOpen',
    })
  },
  mounted () {
    if (this.$route.path === '/articles') {
      this.blogAlertOpen = false
    }
  },
  methods: {
    close () {
      this.blogAlertOpen = false
    }
  }
}
