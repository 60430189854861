import { render, staticRenderFns } from "./KLandingInfiniteLearning.vue?vue&type=template&id=71e2993f&lang=pug&"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {KImg: require('/opt/atlassian/pipelines/agent/build/app/components/shared/Img/KImg.vue').default,KButton: require('/opt/atlassian/pipelines/agent/build/app/components/shared/Button/KButton.vue').default})


/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VRow})
