export default {
  videoProduction: {
    id: 'video-production',
    title: 'Video production',
    description: 'Produce short videos remotely via \'kinterviews\' to feature your customers, alumni, speakers, and even yourself!',
    image: require('~images/faq/video-production.png'),
    link: '/video-production',
  },
  videoMeetings: {
    id: 'video-meetings',
    title: 'Video meetings',
    description: 'Host your next video meeting with Kintell. All in browser, free unlimited cloud recording, and with no time limit. How’s that for gold?',
    image: require('~images/faq/video-meetings.png'),
    link: '/features/video-meetings',
  },
  calendarManagement: {
    id: 'calendar-management',
    title: 'Calendar and bookings',
    description: 'Take advantage of Kintell’s integrated calendar and scheduling features to effortlessly plan your next video session.',
    image: require('~images/faq/calendar-management.png'),
    link: '/features/calendar-and-bookings',
  },
  paymentIntegration: {
    id: 'payment-integration',
    title: 'Payment integration',
    description: 'Get paid for your consultations or coaching and rewarded for your expertise with Kintell’s payment integration.',
    image: require('~images/faq/payment-integration.png'),
    link: '/features/payment-integration',
  },
  kCards: {
    id: 'k-cards',
    title: 'Kintell Cards',
    description: 'Offer your time for free, or for a fee, and get booked for your expertise with Kintell Cards – the better way to define how you want your time utilised. ',
    image: require('~images/faq/k-cards.png'),
    link: '/features/kintell-cards',
  },
  marketplace: {
    id: 'marketplace',
    title: 'Marketplace',
    description: `Find your next mentor or brain to pick by searching the Kintell Marketplace, where you’ll find experts on everything from digital marketing to mental health and more.`,
    image: require('~images/faq/marketplace.png'),
    link: '/search',
  },
  kForBusiness: {
    id: 'k-for-business',
    title: 'Kintell For Business',
    description: `Lead your industry and empower your people to connect and support each other with Kintell’s turnkey video solution.`,
    image: require('~images/faq/k-for-business.png'),
    link: '/for-business',
  },
}
