const rescheduleBookingTimesCacheId = 'rescheduleBookingTimes'

export default {
  methods: {
    getCachedRescheduleBookingDetails () {
      const rescheduleBookingTimes = localStorage.getItem(rescheduleBookingTimesCacheId)
      return rescheduleBookingTimes ? JSON.parse(rescheduleBookingTimes) : null
    },
    cachedRescheduleBookingDetails (rescheduleBookingTimes) {
      localStorage.setItem(rescheduleBookingTimesCacheId, JSON.stringify(rescheduleBookingTimes))
    },
    removeCachedRescheduleBookingDetails () {
      localStorage.removeItem(rescheduleBookingTimesCacheId)
    }
  }
}
