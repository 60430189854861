export default {
  computed: {
    kintellGroupSlug () {
      return this.$route.query.group || ''
    },
    getSignInLink () {
      return this.getLink('/password-free?step=login')
    },
    getJoinNowLink () {
      return this.getLink('/password-free?step=signup')
    }
  },
  methods: {
    getLink (link) {
      const groupSlug = this.kintellGroupSlug ? '?group=' + this.kintellGroupSlug : ''
      // If on group page and
      return link + groupSlug
    }
  }
}
