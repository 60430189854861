import { render, staticRenderFns } from "./KButtonOutline.vue?vue&type=template&id=42369016&scoped=true&lang=pug&"
import script from "./KButtonOutline.vue?vue&type=script&lang=js&"
export * from "./KButtonOutline.vue?vue&type=script&lang=js&"
import style0 from "./KButtonOutline.vue?vue&type=style&index=0&id=42369016&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42369016",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {KButton: require('/opt/atlassian/pipelines/agent/build/app/components/shared/Button/KButton.vue').default})
