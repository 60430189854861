// local files
import { Skill, Category, KintellCard, Subscription } from '@/models'
import { groupBy, clone } from 'lodash-es'
import { all, join, single, paginated } from '../build'
import queryString from 'query-string'

const search = paginated('search', KintellCard, ({ state }) => {
  const params = state.kintell.searchSearchParams
  return KintellCard
    .queryForCard()
    .where('swift_search', params.query)
    .where('from_rate', params.priceRange ? params.priceRange[0] : 0)
    .where('to_rate', params.priceRange ? params.priceRange[1] : 500)
    .where('category', params.category)
    .where('user_timezone', params.userTimezone)
    .where('location', params.location)
    .where('geolocation', params.geolocation)
    .where('kintell_group_id', params.kintellGroupId)
    .where('education', params.education)
    .where('years_of_experience', params.yearsOfExperience)
    .where('pro_bono', params.proBono === 'either' ? null : params.proBono)
    .where('languages', params.languages)
    .whereIn('skills', params.skills || [])
    // only published cards
    .where('status', 'published')
    // pagination because the inherit one is broken
    .params({
      seed: state.kintell.searchSeed,
      meta: params.searchBoosts
    })
    .pagination({
      page: state.kintell.searchPagination.current_page,
      limit: 28
    })
    .orderBy(params.sortBy !== 'random' ? params.sortBy : '')
    .custom('kintell-cards/search')
    .get()
}, {
  appendResults: false,
  modifyQueryParams (params) {
    if (!params.kintellGroupId) {
      delete params.kintellGroupId
    }
    return params
  },
  pagination: {
    current_page: typeof window !== 'undefined' ? queryString.parse(window.location.search).page || 1 : 1,
    // we always assume there is at least one additional age
    total_pages: 2,
    per_page: 28
  }
})

const categories = all('categories', Category, (store, { kintellGroupSlug }) => {
  const category = Category
    .orderBy('order')
  if (kintellGroupSlug) {
    // If we are getting categories for a group then we only want categories that have a published kintell card
    category.where('kintell_group_slug', kintellGroupSlug)
  }
  return category
    .pagination({ page: 1, limit: 999 })
    .$get()
}, {
  // No caching since each group could have different categories
  cacheMinutes: 0
})

const subscriptions = all('subscriptions', Subscription, () => {
  return Subscription
    .where('slug', 'kintell-plus')
    .$get()
}, {
  cacheMinutes: 240
})

const subscriptionsPricing = all('subscriptionsPricing', Subscription, () => {
  return Subscription
    .where('slug', 'kintell-b2b-starter')
    .$get()
}, {
  cacheMinutes: 240
})


const skills = all('skills', Skill, () => {
  return Skill
    .pagination({ page: 1, limit: 999 })
    .$get()
}, {
  // skills will update slightly more often, however these are just for autocompletes so defiantly not critical
  cacheMinutes: 120
})

const exploreCategories = paginated('exploreCategories', Category, ({ state } /**/) => {
  return Category
    .pagination({
      page: state.kintell.exploreCategoriesPagination.current_page,
      limit: state.kintell.exploreCategoriesPagination.per_page,
    })
    .params({
      seed: state.kintell.exploreCategoriesSeed
    })
    .custom('categories/explore')
    .get()
}, {
  pagination: {
    per_page: 4
  },
})

const latestVersion = single('latestVersion', null, process.env.VERSION)

const {
  state,
  getters,
  mutations,
  actions
} = join(search, skills, categories, latestVersion, exploreCategories, subscriptions, subscriptionsPricing)

/**
 * Categories sorted by parent
 */
getters.sortedCategories = (state, getters) => {
  const categories = clone(getters.categories)

  // If categories are not set then exit out
  if (!categories) {
    return []
  }
  // Group by Parent
  const categoriesGroupByParents = groupBy(categories, category => !category.parentCategoryId ? 'parents' : category.parentCategoryId)

  // If the categories cache did not update correctly then categoriesGroupByParents.parents may not be defined and cause an error
  if (!categoriesGroupByParents.parents) {
    return []
  }
  // If a parent category does not have children it will cause an error
  const parentCategoriesWithChildrenOnly = categoriesGroupByParents.parents.filter(parent => (categoriesGroupByParents[parent.id]))

  return parentCategoriesWithChildrenOnly
    // Sort parent categories by the set order
    .sort((a, b) => b.order - a.order)
    // Other should always be on the bottom
    .sort(a => a.name === 'Other' ? 1 : -1)
    // Attach children to the parent category
    .map(parent => {
      const categoryChildren = categoriesGroupByParents[parent.id]
      // Sort all children categories alphabetically
      categoryChildren.sort((a, b) => b.name.toLowerCase() > a.name.toLowerCase() ? 1 : -1)
      // make sure other is last again
      categoryChildren.sort(a => a.name.includes('Other -') ? 1 : -1)
      // Attach children to the parent category
      parent.children = categoryChildren
      return parent
    })
}

actions.reset = ({ commit }) => {
  commit('SET_SEARCH_LAST_REQUEST', null)
  commit('SET_SEARCH', [])

  commit('SET_EXPLORE_CATEGORIES_LAST_REQUEST', null)
  commit('SET_EXPLORE_CATEGORIES', [])
}

// export store
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
