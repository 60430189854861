import Consola from '@/lib/consola'
const logger = new Consola('kintell:axios')

export default ctx => {
  ctx.$axios.onRequest(config => {
    ctx.$axios.lastRequest = config
    logger.debug('Request', config.method, config.url)
  })
  ctx.$axios.onResponse(response => {
    logger.debug('Response', response.data)
  })
}
