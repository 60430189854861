import { Context } from '@nuxt/types'
import { KintellCardMessage } from '@/lib/broadcasting/messages'

// .App\\Events\\User\\ClaimedInvite
export default (ctx: Context) => {
  return (message: KintellCardMessage) => {
    const whoLeftMessage = message.kintellCard.kintellGroup ? message.kintellCard.kintellGroup.name : 'Kintell Team'
    const cardType = message.kintellCard.kintellGroup ? 'Group Card' : 'Kintell Card'
    ctx.$toast.success(
      `The ${whoLeftMessage} has left comments on your ${cardType} "${message.kintellCard.title}".`,
      { duration: 10000 }
    )
    // we need to check if the user has rejected the card we're looking at
    const kintellCard = ctx.store.getters['kintellCards/activeKintellCard']
    if (kintellCard && kintellCard.id === message.kintellCard.id) {
      kintellCard.status = message.kintellCard.status
      ctx.store.commit('kintellCards/SET_ACTIVE_KINTELL_CARD', kintellCard)
    }
    ctx.store.dispatch('myKintell/reloadKintellCards')
    ctx.store.dispatch('auth/refresh')
  }
}
