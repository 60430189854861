import moment from 'moment-timezone'
import { forEach, every, isEmpty } from 'lodash-es'
import { getHour, getMinute, convertTimeToMinutes } from './uses-time'

interface APIFormattedAvailableTimes {
  day: 1 | 2 | 3 | 4 | 5 | 6 | 7,
  duration: number,
  time: number,
}

interface CalendarFormattedAvailableTimes {
  // YYYY-MM-DD
  date: string,
  // 21:00
  time: string,
  duration: number,
  selected: boolean,
  timestampUtc: moment.Moment,
}

interface AvailabilityTimeBlocks {
  1: number[],
  2: number[],
  3: number[],
  4: number[],
  5: number[],
  6: number[],
  7: number[],
}

function isApiFormattedTimes (times: any[]): times is APIFormattedAvailableTimes[] {
  return every(times, time => isEmpty(time.timestampUtc))
}

/**
 * Take the available times from the API and converted them to the calendar format.
 *
 * Note: Assumption is that it will be converted to user's selected timezone
 */
function transformAvailabilityToCalendarFormat (
  apiFormattedAvailabilityTimes: APIFormattedAvailableTimes[],
  timezone: string
): CalendarFormattedAvailableTimes[] {
  // apiFormattedAvailabilityTimes may have already been transformed into the calendar format
  if (!isApiFormattedTimes(apiFormattedAvailabilityTimes)) {
    return apiFormattedAvailabilityTimes
  }

  const hasTimesAvailable = apiFormattedAvailabilityTimes.length > 0

  // If the advisor has no times available then exit out
  const availabilityTimeBlocks = createAvailabilityTimeBlocks(hasTimesAvailable ? 1 : 0)

  // Only fill the unavailable times if the advisor has selected times to be available
  if (hasTimesAvailable) {
    // Fill in the availability based on the apiFormatedAvailabilityTimes
    forEach(apiFormattedAvailabilityTimes, interval => {
      const isoWeekDay = interval.day
      const start30MinuteBlock = interval.time / 30
      const end30MinuteBlock = start30MinuteBlock + (interval.duration / 30)
      availabilityTimeBlocks[isoWeekDay].fill(0, start30MinuteBlock, end30MinuteBlock)
    })
  }

  const calendarFormattedAvailabilityTimes: CalendarFormattedAvailableTimes[] = []
  // Push calendar formatted intervals to calendarFormattedAvailabilityTimes
  forEach(availabilityTimeBlocks, (weekDayThirtyMinuteBlocks, isoWeekDay) => {
    // Start at the first 30 minute block of the day
    let thirtyMinuteBlock = 0
    let start = null

    // Continue until the last 30 minute block at the end of the day 23:30-00:00
    while (thirtyMinuteBlock < 48) {
      // if the interval has reached the end or the last interval is available
      if ((weekDayThirtyMinuteBlocks[thirtyMinuteBlock] === 0 && start !== null) || (thirtyMinuteBlock === 47 && weekDayThirtyMinuteBlocks[thirtyMinuteBlock] === 1)) {
        // last interval could be available
        if (start === null) {
          start = thirtyMinuteBlock
        }
        // set end to 48 if it is available
        const end = (thirtyMinuteBlock === 47 && weekDayThirtyMinuteBlocks[thirtyMinuteBlock] === 1) ? 48 : thirtyMinuteBlock
        const duration = (end - start) * 30
        // The select availability calendar will always start from monday,
        // Need to convert API info to the next weekday from today for the calendar select to work
        // so if today is Thursday, and the API has an interval on monday, need to set this date to the next monday
        const timestamp = moment.tz(timezone)
          // using .day(7) will go to the next sunday so if unavailable.day is 1, it will go to the next monday
          .day(7 + parseInt(isoWeekDay))

        const time = start * 30
        // Set time
        timestamp
          .hour(getHour(time))
          .minute(getMinute(time))
          .second(0)
        const timestampUtc: moment.Moment = timestamp.clone().utc()
        calendarFormattedAvailabilityTimes.push({
          duration,
          // Conversion is done on the back end do not use utc()
          timestampUtc,
          date: timestamp.format('YYYY-MM-DD'),
          // The time in the users timezone
          time: timestamp.format('HH:mm'),
          selected: true
        })
        start = null
      } else if (weekDayThirtyMinuteBlocks[thirtyMinuteBlock] === 1) {
        if (start === null) {
          start = thirtyMinuteBlock
        }
      }
      thirtyMinuteBlock += 1
    }
  })
  return calendarFormattedAvailabilityTimes
}

/**
 * Get the format from the calendar and converts it to the format the API uses.
 */
function transformAvailabilityToAPIFormat (
  calendarFormattedAvailability: CalendarFormattedAvailableTimes[],
  timezone: string
): APIFormattedAvailableTimes[] {
  const availabilityTimeBlocks = createAvailabilityTimeBlocks(0)

  // Fills in the availability to see what 30 minute blocks are available.
  forEach(calendarFormattedAvailability, interval => {
    // Converted from users timezone
    const isoWeekDay = moment.tz(interval.date + ' ' + interval.time, 'YYYY-MM-DD HH:mm', timezone)
      .isoWeekday() as APIFormattedAvailableTimes['day']
    const timeInMinutes = convertTimeToMinutes(interval.time)
    const start30MinuteBlock = timeInMinutes / 30
    const end30MinuteBlock = start30MinuteBlock + (interval.duration / 30)

    availabilityTimeBlocks[isoWeekDay].fill(1, start30MinuteBlock, end30MinuteBlock)
  })
  const apiFormattedAvailabilityTimes: APIFormattedAvailableTimes[] = []
  forEach(availabilityTimeBlocks, (weekDayThirtyMinuteBlocks, isoWeekDay) => {
    // Start at the first 30 minute block of the day
    let thirtyMinuteBlock = 0
    let start = null

    while (thirtyMinuteBlock < 48) {
      if ((weekDayThirtyMinuteBlocks[thirtyMinuteBlock] === 1 && start !== null) || (thirtyMinuteBlock === 47 && weekDayThirtyMinuteBlocks[thirtyMinuteBlock] === 0)) {
        if (start === null) {
          start = thirtyMinuteBlock
        }
        const end = (thirtyMinuteBlock === 47 && weekDayThirtyMinuteBlocks[thirtyMinuteBlock] === 0) ? 48 : thirtyMinuteBlock
        const duration = (end - start) * 30
        apiFormattedAvailabilityTimes.push({
          // Day is stored as an iso weekday on the api
          day: +isoWeekDay as keyof AvailabilityTimeBlocks,
          duration,
          // Time is always stored in minutes on the api
          time: start * 30
        })
        start = null
      } else if (weekDayThirtyMinuteBlocks[thirtyMinuteBlock] === 0) {
        if (start === null) {
          start = thirtyMinuteBlock
        }
      }
      thirtyMinuteBlock += 1
    }
  })
  return apiFormattedAvailabilityTimes
}

/**
 * Fill availability object with times when they are available or not available
 *
 * Keys are the ISO weekday (1-7)
 * Value is Array(48) with 1 and 0 (1-available, 0 unavailable) for each half hour
 */
function createAvailabilityTimeBlocks (value: number): AvailabilityTimeBlocks {
  return {
    1: Array(48).fill(value),
    2: Array(48).fill(value),
    3: Array(48).fill(value),
    4: Array(48).fill(value),
    5: Array(48).fill(value),
    6: Array(48).fill(value),
    7: Array(48).fill(value),
  }
}


export {
  transformAvailabilityToAPIFormat,
  transformAvailabilityToCalendarFormat
}
