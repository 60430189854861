export default {
  saveUnsavedChanges: {
    yes: 'Yes - Save',
    no: 'No - Leave',
    body: 'You have unsaved changes. Do you want to save your changes?',
    header: 'Unsaved Changes',
  },
  unsavedChanges: {
    yes: 'Yes - Leave',
    no: 'No - Stay',
    body: 'You have unsaved changes. Are you sure you want to navigate away?',
    header: 'Unsaved Changes',
  },
  unpublishGroupCards: {
    yes: 'Yes - Unpublish',
    no: 'No - Cancel',
    body: 'If unpublished, new learners can\'t book {name} anymore, but {name} will be able to complete any confirmed bookings from before. Also {name}\'s role will change from Advisor to Drafting, because their published cards will become draft cards.',
    header: 'Unpublish group card(s)?'
  },
  kintellCard: {
    delete: {
      yes: 'Delete',
      no: 'Cancel',
      body: 'Deleting your Kintell Card is permanent. You will no longer be able to access this card.',
      header: 'Delete Kintell Card',
    },
    deleteListed: {
      yes: 'Delete',
      no: 'Cancel',
      body: 'Deleting your Kintell Card is permanent. You will no longer be able to access it and your reviews and ratings from this card will be lost.',
      header: 'Delete Kintell Card',
    },
    publish: {
      yes: 'Publish',
      no: 'Cancel',
      body: 'Publishing your Kintell Card will make it publicly available, Kintell learners and other advisors will be able to book your services for this card.',
      header: 'Publish Kintell Card'
    },
    unpublish: {
      yes: 'Convert',
      no: 'Cancel',
      body: 'Unpublishing your Kintell Card will make it hidden, Kintell learners and other advisors won\'t be able to book your services for this card.',
      header: 'Unpublish'
    },
    sendForReview: {
      yes: 'Confirm',
      no: 'Cancel',
      body: 'Please confirm you\'re happy to proceed. Once your card is submitted for publishing it will be sent to the {isReviewingBy} to review.',
      header: 'Publish your card'
    },
  },
  booking: {
    addTime: {
      yes: 'Confirm',
      no: 'Cancel'
    },
    cancelUnconfirmed: {
      body: 'As the booking hasn\'t been confirmed by the Advisor you are free to cancel it now. Once the Advisor has accepted a booking there will be a penalty.',
      yes: 'Yes',
      header: 'Cancel Booking',
    },
    cancelUnconfirmedAdvisor: {
      yes: 'Cancel',
      header: 'Please let {name} know why you’re cancelling the booking.',
    },
    cancelConfirmedAdvisor: {
      header: 'Cancel booking - are you sure?',
      body: 'Please confirm whether you want to cancel the booking with {name} on the {dateString}.',
      yes: 'yes',
      no: 'no',
    },
    cancelConfirmedLearner: {
      header: 'Cancel booking - are you sure?',
      body: 'Please confirm whether you want to cancel the booking with {name} on the {dateString}.',
      yes: 'yes',
      no: 'no',
    },
    confirm: {
      body: 'You are confirming a booking for {date} ({timezone}) with {name}. If something comes up, you can always reschedule :)',
      yes: 'Confirm',
      header: 'Confirm Booking',
    },
    cancel: {
      yes: 'Confirm',
      no: 'Cancel',
      header: 'Cancel booking - are you sure?',
    },
    reschedule: {
      body: 'You are rescheduling a booking with {name}. We\'ll let you know when they respond.',
      yes: 'Confirm',
      header: 'Confirm Rescheduling',
    },
  }
}
