import moment from 'moment-timezone'
import { isEmpty } from 'lodash-es'
import Model from './Model'
import KintellCard from './KintellCard'
import User from './User'


export default class Invite extends Model {
  resource () {
    return 'invites'
  }

  transform (data) {
    if (!data) {
      return data
    }
    if (data.pendingKintellCard) {
      data.pendingKintellCardModel = new KintellCard(data.pendingKintellCard)
    }

    if (data.claimedByUser) {
      data.claimedByUser = new User(data.claimedByUser)
      if (data.claimedByUser.invites) {
        data.claimedByUser.invites = data.claimedByUser.invites.map(invite => new Invite(invite))
      }
    }

    return data
  }

  resend () {
    return this.action('resend')
  }

  flagNotInterested () {
    return this.action('not-interested')
  }

  get canBeResent () {
    return this.resendCount < 2 && !this.isNotInterested
  }

  get redeemUrl () {
    return window.location.origin + '/password-free?step=signup&invite=' + this.code
  }

  get isClaimed () {
    return this.claimedBy !== null
  }

  get isExpired () {
    return moment(this.validUntil).isBefore(moment.now())
  }

  get validUntilFriendly () {
    return moment(this.validUntil).fromNow()
  }

  get hasIndirectInvite () {
    return this.claimedByUser && this.claimedByUser.invites && this.claimedByUser.invites.length > 0
  }

  get indirectInvitesCount () {
    return (!this.claimedByUser || !this.claimedByUser.invites) ? 0 : this.claimedByUser.invites.length
  }

  get hasPendingKintellCard () {
    return !isEmpty(this.pendingKintellCard)
  }

  get isEmailInvite () {
    return typeof this.for === 'string' && this.for.length > 0
  }
}
