export default {
  width: 21,
  height: 25,
  svg: `
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
    <path d="M8.56 12.52H3C1.89543 12.52 1 11.6246 1 10.52V3C1 1.89543 1.89543 1 3 1H14.84C15.9446 1 16.84 1.89543 16.84 3V7.12" stroke="#30324B"/>
    <path d="M1.35986 1.35999L7.60796 5.6294C8.28804 6.09411 9.18348 6.09435 9.8638 5.62999L16.1199 1.35999" stroke="#30324B"/>
    <circle cx="14.3201" cy="12.88" r="4.18" stroke="#30324B"/>
    <line x1="14.46" y1="10.36" x2="14.46" y2="13.96" stroke="#30324B"/>
    <line x1="14.46" y1="14.6801" x2="14.46" y2="15.4001" stroke="#30324B"/>
  </svg> `
}
