import Url from 'url-parse'

export default {
  computed: {
    iconForLink () {
      return link => {
        const parsedUrl = new Url(link)
        switch (parsedUrl.host) {
          case 'github.com':
          case 'www.github.com':
            return 'github'
          case 'www.youtube.com':
          case 'youtube.com':
          case 'youtu.be':
            return 'youtube'
          case 'twitter.com':
            return 'twitter'
          case 'linkedin.com':
          case 'www.linkedin.com':
            return 'linkedin'
          case 'instagram.com':
          case 'www.instagram.com':
            return 'instagram'
          case 'facebook.com':
          case 'www.facebook.com':
            return 'facebook'
          case 'researchgate.net':
          case 'www.researchgate.net':
            return 'researchgate'
          case 'medium.com':
          case 'www.medium.com':
            return 'medium'
          case 'scholar.google.com':
          case 'www.scholar.google.com':
          case 'scholar.google.com.au':
          case 'www.scholar.google.com.au':
            return 'google'
        }
        return 'article'
      }
    },
    hasIconForLink () {
      return link => {
        return this.iconForLink(link)
      }
    }
  }
}
