//
//
//
//
//
//
//
//
//

import { BindsAttrs } from '@/mixins'

export default {
  mixins: [ BindsAttrs ]
}
