import Vue from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'

const recaptchaState = Vue.observable({
  badgeHidden: false,
})

export default ({ app, store, redirect, $config: { captchaKey }, env }, inject) => {
  if (!captchaKey) {
    return
  }
  Vue.use(VueReCaptcha, { siteKey: captchaKey })
  Vue.prototype.$recaptchaState = recaptchaState

  // Wait for the badge to render, then hide it
  const hideBadge = () => {
    setTimeout(() => {
      const recaptchaInstance = Vue.prototype.$recaptchaInstance

      if (recaptchaInstance && recaptchaInstance.hideBadge) {
        recaptchaState.badgeHidden = true
        recaptchaInstance.hideBadge()
      } else {
        hideBadge()
      }
    }, 500)
  }
  hideBadge()
}
