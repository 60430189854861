import { KintellCard, User } from '@/models'

// Group Permissions
const Permissions = {
  VIEW_HOME_PAGE: 'view-home-page',
  CLICK_LOGO: 'click-logo',
  VIEW_ADVISORS: 'view-advisors',
  VIEW_CARD: 'view-card',
  BOOK: 'book',
  SAVE_FAVOURITE: 'save-favourite',
  VIEW_SIMILAR_GROUP_CARD: 'view-similar-group-cards',
  PUBLISH_GROUP_CARDS: 'publish-group-cards',
  JOIN_NOW_MODAL: 'see-join-now-modal',
  SEE_COMPLETE_REGISTRATION_MODAL: 'see-complete-registration-modal',
  SEE_VERIFICATION_IN_PROGRESS: 'see-verification-in-progress',
  VIEW_ADVISOR_OTHER_CARDS: 'view-advisor-other-cards',
  VIEW_KINTELL_GROUP_LOGO: 'view-kintell-group-logo'
}

const GroupRoles = {
  ROLE_ADMIN: 'admin',
  ROLE_ADVISOR: 'advisor',
  ROLE_AMBASSADOR: 'ambassador',
  ROLE_BETA_TESTER: 'beta-tester',
  ROLE_KINTELL_PLUS: 'kintell-plus',
  ROLE_KINTELL_PRO: 'kintell-pro',

  ROLE_CONSULTING_VISIBLE_MEMBER: 'consulting-visible-member',
  ROLE_CONSULTING_VISIBLE_ADVISOR: 'consulting-visible-verified-advisor',

  ROLE_CONSULTING_HIDDEN_MEMBER: 'consulting-hidden-member',
  ROLE_CONSULTING_HIDDEN_ADVISOR: 'consulting-hidden-verified-advisor',

  ROLE_COMMUNITY_VISIBLE_UNVERIFIED_MEMBER: 'community-visible-unverified-member',
  ROLE_COMMUNITY_VISIBLE_AWAITING_VERIFICATION_MEMBER: 'community-visible-awaiting-verification-member',
  ROLE_COMMUNITY_VISIBLE_VERIFIED_MEMBER: 'community-visible-verified-member',
  ROLE_COMMUNITY_VISIBLE_VERIFIED_ADVISOR: 'community-visible-verified-advisor',

  ROLE_COMMUNITY_HIDDEN_UNVERIFIED_MEMBER: 'community-hidden-unverified-member',
  ROLE_COMMUNITY_HIDDEN_AWAITING_VERIFICATION: 'community-hidden-awaiting-verification-member',
  ROLE_COMMUNITY_HIDDEN_VERIFIED_MEMBER: 'community-hidden-verified-member',
  ROLE_COMMUNITY_HIDDEN_VERIFIED_ADVISOR: 'community-hidden-verified-advisor',
}

const GroupRolesCollections = {
  IS_VERIFIED_MEMBER: [
    GroupRoles.ROLE_COMMUNITY_HIDDEN_VERIFIED_ADVISOR,
    GroupRoles.ROLE_COMMUNITY_HIDDEN_VERIFIED_MEMBER,
    GroupRoles.ROLE_COMMUNITY_VISIBLE_VERIFIED_ADVISOR,
    GroupRoles.ROLE_COMMUNITY_VISIBLE_VERIFIED_MEMBER,
    GroupRoles.ROLE_CONSULTING_HIDDEN_ADVISOR,
    GroupRoles.ROLE_CONSULTING_HIDDEN_MEMBER,
    GroupRoles.ROLE_CONSULTING_VISIBLE_ADVISOR,
    GroupRoles.ROLE_CONSULTING_VISIBLE_MEMBER
  ],
  IS_AWAITING_VERIFICATION: [
    GroupRoles.ROLE_COMMUNITY_VISIBLE_AWAITING_VERIFICATION_MEMBER,
    GroupRoles.ROLE_COMMUNITY_HIDDEN_AWAITING_VERIFICATION
  ],
  IS_UNVERIFIED_MEMBER: [
    GroupRoles.ROLE_COMMUNITY_VISIBLE_UNVERIFIED_MEMBER,
    GroupRoles.ROLE_COMMUNITY_HIDDEN_UNVERIFIED_MEMBER
  ],
  IS_GROUP_ADVISOR: [
    GroupRoles.ROLE_COMMUNITY_HIDDEN_VERIFIED_ADVISOR,
    GroupRoles.ROLE_COMMUNITY_VISIBLE_VERIFIED_ADVISOR,
    GroupRoles.ROLE_CONSULTING_HIDDEN_ADVISOR,
    GroupRoles.ROLE_CONSULTING_VISIBLE_ADVISOR
  ]
}

const checkPermissionValue = (groupRole: any, permissionName: string) => {
  return groupRole?.name.split('-').includes(permissionName)
}

const canPerform = (permissionName: string, kintellGroup?: any, user?: User): boolean => {
  if (!kintellGroup) {
    return true
  }

  if (!permissionName) {
    return false
  }

  const defaultGroupPermissions = kintellGroup.defaultPermissions
  if (!user && defaultGroupPermissions.includes(permissionName)) {
    return true
  }

  if (!user || !user.kintellGroups) {
    return false
  }

  const activeKintellGroup = user.kintellGroups?.filter(
    (currentGroup: { id: string }) => currentGroup.id === kintellGroup.id
  ).pop()

  const groupRole = activeKintellGroup?.role
  const groupPermissions = groupRole?.permissions || []
  const attachedUserPermissions = groupPermissions.length > 0
    ? groupPermissions.map(
      (permission: { name: string }) => permission.name
    )
    : []
  // we need to merge default group permissions and additional user permissions
  const mergedGroupPermissions = new Set([
    ...defaultGroupPermissions,
    ...attachedUserPermissions
  ])
  // join-now-modal permission needs to be removed if the user is already verified or awaiting verification
  if (checkPermissionValue(groupRole, 'awaiting') || checkPermissionValue(groupRole, 'verified')) {
    mergedGroupPermissions.delete(Permissions.JOIN_NOW_MODAL)
  }

  return mergedGroupPermissions.has(permissionName)
}

const hasGroupRole = (rolesToCheck: Array<string>, userGroupRoles: Array<{ name: string }>): boolean => {
  // @ts-expect-error hacky types
  return !!userGroupRoles?.some(role => rolesToCheck?.includes(role?.name ?? role))
}

const getGroupRole = (roles: Array<{ name: string }>, kintellCards: KintellCard[]): string => {
  if (hasGroupRole(GroupRolesCollections.IS_GROUP_ADVISOR, roles)) {
    return 'Advisor'
  } else if (hasGroupRole(GroupRolesCollections.IS_VERIFIED_MEMBER, roles)) {
    let name = 'Learner'
    if (kintellCards && kintellCards.some(kintellCard => kintellCard.isAwaitingReview)) {
      name = 'Awaiting Review'
    } else if (kintellCards && kintellCards.some(kintellCard => kintellCard.isDraft)) {
      name = 'Drafting'
    }
    return name
  } else if (hasGroupRole(GroupRolesCollections.IS_AWAITING_VERIFICATION, roles)) {
    return 'Awaiting Verification'
  } else if (hasGroupRole(GroupRolesCollections.IS_UNVERIFIED_MEMBER, roles)) {
    return 'UnVerified'
  }
}

export {
  canPerform,
  hasGroupRole,
  getGroupRole,
  GroupRoles,
  GroupRolesCollections,
  Permissions
}
