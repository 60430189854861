import { Validates, UsesAuthenticatedUser, ChecksFocus } from '@/mixins'

export default {
  mixins: [
    Validates,
    UsesAuthenticatedUser,
    ChecksFocus,
  ],
  props: {
    value: {
      default: false,
      type: Boolean,
    },
  },
  data () {
    return {
      errors: ''
    }
  },
  methods: {
    checkSubmitCode () {
      if (this.fields.code.length === 6) {
        this.submit()
      }
    },
    onPasteCode (pastedData) {
      if (pastedData) {
        this.fields.code = pastedData
        this.checkSubmitCode()
      }
    },
  }
}
