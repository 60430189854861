import Vuex from 'vuex'
import pathify from 'vuex-pathify'
import createPersistedState from 'vuex-persistedstate'

import layout from './modules/layout'
import kintellCards from './modules/kintell-cards'
import auth from './modules/auth'
import kintell from './modules/kintell'
import myKintell from './modules/my-kintell'
import tour from './modules/tour'
import form from './modules/form'
import guest from './modules/guest'
import meeting from './modules/meeting'
import kintellGroup from './modules/kintell-group'

const plugins = [ pathify.plugin ]

// only push persisted state if we're client side
if (typeof window !== 'undefined') {
  plugins.push(createPersistedState({
    key: 'app.v18',
    paths: [
      // cache categories and skills
      'kintell.categories',
      'kintell.categoriesLastRequest',
      'kintell.skills',
      'kintell.skillsLastRequest',
      'guest.activeBooking',
      'guest.invitedBy',
      'guest.ipinfo',
      'guest.blogAlertOpen',
      'myKintell.bookings',
      'tour'
    ],
    arrayMerger (store, saved) {
      if (saved.length > 0) {
        return saved
      }
      return store
    }
  }))
}

const createStore = () => {
  return new Vuex.Store({
    plugins,
    modules: {
      layout,
      kintellCards,
      auth,
      kintell,
      myKintell,
      tour,
      form,
      guest,
      meeting,
      kintellGroup
    }
  })
}

export default createStore
