export default {
  personalFinance: {
    banner: {
      title: 'Marie Kondo your finances',
      blurb: 'Declutter your approach to money, with guidance on how to invest in shares, making budgets, and planning your own finances.',
      slug: '/personal-finance',
      img: '/images/roundup/personal-finance.png',
      fullImg: '/images/roundup/personal-finance-full.png',
    },
    advisors: [
      {
        id: '271b44e2-443b-4624-b3e1-0092dcd8bc1e',
        blurb: 'Chris has worked as a financial adviser and been involved in all sorts of financial strategies and situations across personal finance. He believes we need to change the complex nature of personal money and he\'s more than happy to give you advice and insights into anything money-related.'
      },
      {
        id: 'dbb41f31-ee2b-4614-8bea-f3ccd88bd9fe',
        blurb: 'Andrew believes in providing holistic financial planning advice to everyday Australians, helping them to feel secure and achieve their personal and lifestyle goals through smart sound, financial strategies through strong long term relationships. He can help with anything from investment management to retirement planning.'
      },
      {
        id: '6f38915c-ae6e-4cf3-a85f-f0dccfa0398f',
        blurb: 'With over 10 years of accounting experience, Neha has worked in a wide variety of (financial and accounting related) roles within various industries. Some of her key strengths and interests include accounting procedures, reporting systems and processes, tax and regulatory requirements, internal financial controls and financial modelling.'
      },
      {
        id: '3a7ca351-4472-4324-b6da-5547ea919796',
        blurb: 'Kurt is the Co-founder of Pearler, an online sharemarket investment platform for long-term investors. Before starting Pearler he was an Investment Analyst, and he has been investing in the sharemarket since he was 15. Needless to say, he knows a lot about investing.'
      },
      {
        id: 'b6163929-b50e-4b31-a4c8-eb3c21a5762c',
        blurb: 'David has extensive experience assisting businesses in managing their tax affairs and helping entrepreneurs to structure their investments and protect their assets. He knows that the unique needs, challenges and goals of each client are essential to providing the right advice and achieving superior results.'
      },
      {
        id: '4ba4fbd8-65d4-49ad-83e3-4a5c510ee50e',
        blurb: 'Andrew has been an Accountant for 22 years. He can help you with any issue regarding tax and accounting, including superannuation guarantee, payroll tax, fringe benefits tax, income tax and more. His clients say that he explains things in a way that is easy to understand. If you have any questions about tax or bookkeeping, reach out to Andrew!'
      },
    ],
  },
  mentalHealth: {
    banner: {
      title: 'Prioritise your mental health',
      blurb: 'Nothing is more important than your mental health, so check out our roundup of the best counsellors, psychotherapists and meditation advisors on Kintell.',
      slug: '/mental-health',
      img: 'https://images.ctfassets.net/0n6k7bbqdbo7/77z1Q7252QWwtwAy90AEOM/471226371d522c93c9cc0272097f4478/image_15.png?h=333',
    },
    advisors: [
      {
        id: '3c8067df-d6d9-4601-bb8a-73ae663440c4',
        blurb: 'As a Clinical Psychotherapist, Natajsa works from an Ecological perspective looking at the individual\'s experience as well as the influence professional, family, and internal systems have. This informs her supervision work as focuses on the collaborative process and working from a place of partnership. She has been featured across a range of publications including House of Wellness, Cosmopolitan, ABC Online, and many more.'
      },
      {
        id: 'b5ca5ab8-6a12-437a-92b1-bdfe6eb2e577',
        blurb: 'Julie-Anne is an inner work educator that helps her clients through deep self-inquiry, creativity, the journey of self-discovery, life-long learning, and personal and interpersonal relationship explored through the medium of journal writing. She enjoys working with a variety of people helping them to untangle their lives, find their innate wisdom and achieve their dreams.'
      },
      {
        id: 'fd9fd895-4fbb-4b04-b43c-f12f37074fe5',
        blurb: 'Damien is one of the only on-call, mobile, rapid response counsellors in his area of Australia. Since getting into the field, he has learned a significant amount about emergency trauma responses and theories within the mental health space. He\'s a passionate supporter of early intervention, proactive, integrative approaches to mental and social health, and loves to help get new ideas off the ground.'
      },
      {
        id: '751e0dd4-1eba-4402-b6b5-588ef00037f1',
        blurb: 'Gillian loves to solve problems. The last ten years have seen her helping people problem-solve their life through life coaching, kinesiology, and workshops. She can help to define problems and desired outcomes, developing action plans to move forward.  Navigating change is more than a to-do list, Gillian will guide you through the internal turmoil and emotional processing.'
      },
      {
        id: '21977440-8a19-4556-8c28-609991421573',
        blurb: 'Will is a qualified Mental Health practitioner who focuses on working with creative minds and the performing arts industry. He\'s passionate about providing strategies to help with negative self-talk, stress and overwhelm, performance anxiety, career confusion and burnout. With a background as a stage performer himself, he now provides one to one counselling, life coaching and workshops to creative arts students, teachers and industry professionals.\n'
      },
      {
        id: '57962f6c-390e-44a9-b5f9-8cdc663ef95e',
        blurb: 'With over two decades of experience in meditation, Helene has gained a deep understanding of both traditional practices and application of mindfulness across contemporary situations and society. She advises individuals and organisations on wellbeing strategies to support sustainable performance, engagement and resilience. After finishing Mindfulness-Based Stress Reduction and Yoga Teacher Training, she\'s currently completing a Master focusing on the research and application of mindfulness.'
      },
    ],
  },
  facilitatePublicSpeaking: {
    banner: {
      title: 'Facilitate With Confidence',
      blurb: 'Whether you’re prepping for a presentation, planning a speech, or looking for tips on running workshops, we’ve got an advisor who can help.',
      slug: '/facilitate-public-speaking',
      img: '/images/roundup/facilitate-public-speaking.png',
      fullImg: '/images/roundup/facilitate-public-speaking-full.png'
    },
    advisors: [
      {
        id: 'f9a797c0-6a1a-4488-805d-4620a6155903',
        blurb: 'Verity is the General Manager at Intelligent Ink, where she helps leaders transform their ideas into thought leadership. If you\'re looking to leverage your ideas and expertise to elevate your impact and become known as a thought leader, chat to Verity!'
      },
      {
        id: 'ba07be10-2c76-46b3-8ea0-99e1e9d9027e',
        blurb: 'Jaques has a passion for public speaking and empowering others to put their best foot forward. He\'s a certified speaker and leader from Toastmasters International with lots of experience and awards under his belt. He\'ll work with you to leave a lasting impression on your audience.'
      },
      {
        id: 'b6ff24fc-c67d-4f63-9215-322fbcfda7b9',
        blurb: 'Phil has worked with leaders in a broad spectrum of industries, including; technology, finance, retail, government, travel, not for profit and social enterprise. He\'s experienced in coaching and leadership development, strategy and experience design, as well as facilitation.'
      },
      {
        id: '63399715-b93e-4b03-8269-ef790e1168de',
        blurb: 'Chris facilitates and workshops solutions to complex business problems for small to medium size organisations. He\'s got insights and tools to create workshops that will reach your desired outcome and develop solutions and strategies that build customer loyalty and profits.'
      },
      {
        id: '4262ac2c-08ae-45ae-8a2f-071961936898',
        blurb: 'Patrick has been teaching presentation skills for many years. As a sought after presenter himself, he knows how to make a presentation impactful. He\'s a believer in the power of presence and presenting - a skill everyone needs to succeed in life.'
      }
    ],
  },
  homeLiving: {
    banner: {
      title: 'Make your house a home',
      blurb: 'We\'ve rounded up a supergroup of advisors that can help you create your dream home, from decluttering to creating a colour scheme.',
      slug: '/home-living',
      img: '/images/roundup/home.png',
      fullImg: '/images/roundup/home-full.png'
    },
    advisors: [
      {
        id: '4b33cafb-1393-400c-bd58-21475ec3fe99',
        blurb: 'Raquel is a French architect experienced in both commercial and residential projects. Over the years, she has developed an immense interest and skillset when it comes to interiors, and she\'s passionate about helping her clients create spaces that are both functional and feel fantastic. She loves to discuss anything creative and provide guidance on creative home projects'
      },
      {
        id: '5f4b6e10-ea53-4449-aa88-db44d9dcd3ef',
        blurb: 'Olivier has as a sustainability consultant, advised leading builders and property groups on everything from Sustainable supply chain strategies, thermal modelling and energy/financial savings reports from onsite renewables. If you want to make your own home more sustainable, Olivier can help you make the right choices!'
      },
      {
        id: 'ee07c179-7a7b-4f12-a191-88c9ca245c12',
        blurb: 'With more than 30 years of experience in face to face and remote retreats, Martha can help you to create a retreat in your own home, specific to your needs, with or without daily support. Whether you\'d like to find rest, rejuvenation or to open creativity or work through inner issues, what better place to do it than in your own home?'
      },
      {
        id: '5d4285ad-a828-4294-a92a-c0a2baf2ae8d',
        blurb: 'What is co-living, and how does one set up a coliving business? Lachlan knows all the ins and outs of co-living, whether it\'s the latest trends, how to plan and site selection or how to build a community around it. If this future of living sparks interest with you, he can speak to all steps involved in getting a coliving business.'
      },
      {
        id: '7c9f8a2a-592d-4087-98f6-0a1321a84c09',
        blurb: 'Ruth understands how challenging the task of decluttering your home can be, that\'s why her approach is to gently but efficiently help you get your house in order and provide practical advice to help you maintain a clutter-free life. She can help you develop a plan of action, advice you on how to start and share tips she\'s learnt the hard way.'
      },
    ],
  },
  career: {
    banner: {
      title: 'Transform your career',
      blurb: 'We\'ve rounded up some of the best Kintell career coaches, who can help you switch careers, optimise your social presence, polish your CV, or build a personal brand.',
      slug: '/career-coach',
      img: '/images/roundup/career.png',
      fullImg: '/images/roundup/career-full.png',
    },
    advisors: [
      {
        id: '45168ce1-e38c-4924-b3b0-70de619c56a2',
        blurb: 'Amalia is a Coach, Speaker and Trainer, passionate about helping others improve their work, capability and wellbeing. Bringing expertise from more than 20 years of experience in Human Resources Leadership roles with large complex global corporations, helping organisations, teams and individuals identify and build capability to create meaningful work and realise a better future.'
      },
      {
        id: '4a0a22b1-a1a0-4760-af01-ec5a1105df4b',
        blurb: 'Toni is a highly-skilled learning and development consultant with a passion for continuous learning, strengths-based approaches, realising human potential and positive psychology. She applies this passion to coaching in all aspects of goal-setting, career transition and development planning as well as learning strategies.'
      },
      {
        id: '47d226c0-40e8-456b-854b-7cc1a8a8499f',
        blurb: 'Are you using LinkedIn for job search? Are you attracting the attention your skills deserve? Barbara has been a career coach for 20 years and guided thousands of people to create and improve their LinkedIn profile. She can help you make tangible changes to your profile in a short amount of time.',
      },
      {
        id: '5cfb8c3c-4ac1-4af0-9fe7-afbea28f708b',
        blurb: 'How can introverted women confidently harness their strengths to re-energise their current career or transition into a new career path? Helen has the answer. She is a Professional Career Development Counsellor and qualified Strengths-Based Career Coach who is driven by the mission to help introverted women realise their full potential.'
      },
      {
        id: 'f0692f76-5e08-4c31-ae87-48308521ac31',
        blurb: 'Mags mentors and coaches women in digital to develop and grow their skills, helping them move to the next level in their careers. She works one-to-one with each person to understand their individual needs and challenges helping them navigate the political minefield and how to play the game to get their way for the greater good of the project, the company and ultimately their careers.'
      },
    ]
  },
  startup: {
    banner: {
      title: 'Accelerate your startup',
      blurb: 'Tap in to a world of knowledge on starting, funding, growing, marketing, and leading your own business, with insights from our experienced startup advisors.',
      slug: '/startup',
      img: '/images/roundup/startup.png',
      fullImg: '/images/roundup/startup-full.jpeg',
    },
    advisors: [
      {
        id: 'b2edb108-46fb-4bd4-96f3-cba71f80cf86',
        blurb: 'Peter works at a boutique law firm where he represents mostly early-stage tech companies. He helps navigate the formation of new relationships with investors, co-founders, customers, employees, suppliers and regulatory environments while getting their disruptive product out there! Having worked in a scale-up tech company himself, he understands both the pain and the pleasure of the tech world.'
      },
      {
        id: '398e7d2c-1bd2-49e0-85fc-514d953156ec',
        blurb: 'Danielle is a business coach with 20 years of experience in entrepreneurship, innovation and digital transformation - in roles ranging from startup founder, mentor, consultant, director, advisor and board member. Through her coaching, she can help you to optimise your business strategy, achieve impactful outcomes and introductions. She\'s a pro at grant writing and loves to mentor women.'
      },
      {
        id: '51d6973f-69f0-4c71-b7c8-1397cea37fe7',
        blurb: 'Matt is an experienced entrepreneur and coach that have mentored over 500 founders of early-stage startups and scale-ups across Australia, Europe, Nordic & US. He can share his knowledge and a unique lens into the workings, systems, successes & failures from hundreds of business models across a variety of tech, software & services business.'
      },
      {
        id: 'd2a85297-c2c4-493c-9aa1-b0d0b825b7c8',
        blurb: 'When it comes to legal advice for startups, few have as much experience as My-Lihn, having worked with over 1,000 startups for more than 20 years. She can help with everything from ensuring that the startup structure is optimal for asset protection and future growth of the business, negotiating and accepting capital from investors to designing and implementing exit strategies (and everything in between).'
      },
      {
        id: 'dadb5fbf-8d96-4e95-acaa-0499035d1a6f',
        blurb: 'With 21 years of experience in the creative and brand consulting industry with clients such as Harley-Davidson, 3M and Nestle, the last four years Vinesh has brought forward the work he does for large organisations to help smaller startups. He helps startups without resources to strategically engineer their brands with a mix of brand equity growth, brand science, and competitive intelligence.'
      },
      {
        id: 'ee75a112-4887-4a64-ab80-b5a948d44e59',
        blurb: 'Shahe is passionate about helping others discover their own "gift of the gab". He enjoys the challenge of distilling complex concepts into concise messages that listener not only understands but pass on. Shahe believed persuasive communication is not only about making an impact, but also sharing "sticky" ideas that will spread from the audience through their networks, amplifying the reach of your message.'
      },
      {
        id: 'ad04b370-1be4-44a7-96b3-8928ff3d1c20',
        blurb: 'Phil is the type of leader that leads from behind to develop others to be leaders that can help him change the world. When hearing fresh ideas from founders, he builds on them and motivates to expand and think bigger. He\'s been through the whole startup journey from idea to acquisition and would love to share his failures and wins with other leaders and entrepreneurs.'
      },
      {
        id: '53e35372-b2b2-4b86-95fc-322518259432',
        blurb: 'Having first-hand experience of having an idea and wanting to find someone to manufacture it, Peter understands the process well. He can guide you on how to approach finding a manufacturer, how to discuss production costs, basic things to consider and other parameters to have in mind when making your product.'
      },
      {
        id: '55be90c5-371e-4178-9341-c4189b609e3f',
        blurb: 'Pasha is Co-founder of a Sydney based startup and former head of growth at a marketplace platform. With his experience, he can help you with most startup, product and growth problems you may face. He\'s especially knowledgeable when it comes to tactics at a week by week level to reach your goals, whether it be product-market fit or growth.'
      },
    ],
  },
  digitalMarketing: {
    banner: {
      title: 'Demystify digital marketing',
      blurb: 'We\'ve rounded up 8 of the best digital marketing advisors who can help you with Google Ads (PPC), Anaytics, Facebook Ads, content marketing, SEO and more.',
      slug: '/digital-marketing',
      img: '/images/roundup/digital-marketing.png',
      fullImg: '/images/roundup/digital-marketing-full.jpeg',
    },
    advisors: [
      {
        id: 'ad4f6bac-5a32-4748-bf11-4a2ae0516e33',
        blurb: 'Bilal is a Stanford alumni with over 15 years of experience across analytics and growth product management/marketing. He has helped over 16 startups grow, including a few unicorns like Thumbtack and Revolve Clothing. Having worked across US, Australian and the South Asian market, he\'s got a tonne of experience in his field.\n'
      },
      {
        id: '6d684a85-058e-4182-92f4-4399a2953d10',
        blurb: 'Aiming to banish the beige from online and business copy, Connie\'s got a passion for demographics, trends, current affairs and small business. She has written and handled social media accounts for almost every type of industry you can think of - from trades and hospitality to not-for-profits and public figures.'
      },
      {
        id: '35cb398e-8037-466c-ba5b-71a7bdceb6e5',
        blurb: 'Adam brings a rare combination of Leadership, Digital Marketing and Team development from senior roles based in London, Seoul, and Australia. He has led multinational media accounts, established companies in South Korea and provided Digital Marketing leadership across a range of industries. He currently runs his own Digital Marketing business.'
      },
      {
        id: '53e61a70-4ac7-452e-9428-b12e7b8ce42b',
        blurb: 'Suellen runs a Digital Marketing Agency focusing on brands that make the world and the people in it better. She could help you with anything from Facebook Pixel, or reviewing your website to landing pages and funnels. If you have questions about digital marketing, don\'t waste hours trying to find answers on Google, ask Suellen!'
      },
      {
        id: '540b321c-cd2c-4a64-9f11-5cc43f0bfd9b',
        blurb: 'Mark has been Head of Marketing at Dish Cult and CMO at digital marketing agency ROCKETSHP. He has authored a book on Growth Hacking and is deeply passionate and knowledgeable on the subject. There\'s nothing in the world of Growth Hacking that he can\'t help you with.'
      },
      // {
      //   id: '81b16894-8ec4-4604-bff8-7a2e81c0a1a2',
      //   blurb: 'Chris has over 15 years of experience across all aspects of marketing, both for global brands such as eBay and PayPal, and a variety of start-ups. He\'s built award-winning campaigns and strategies that have helped his clients achieve sustainable growth. If you’d like to formulate impactful marketing plans, Chris can contribute with light bulb and Aha moments to help you realise your full potential and start impacting your customers’ lives!'
      // },
      {
        id: '130faa37-9678-49b3-b8d4-1022d8c77e0b',
        blurb: 'Kristi has been passionately involved with digital marketing and web analytics for most of her career with clients like Expedia and large Australian organisations AFL and Suncorp. She can help you get started on your digital analytics journey, all the way through to troubleshooting large corporate implementations.'
      },
      {
        id: '13b45ad0-4370-4bd4-b7aa-f87b9cff7d3c',
        blurb: 'Katherine is deeply passionate about storytelling and has experience working across brand strategy and marketing communications. With experience creating integrated campaigns for leading brands across multiple industries, Katherine can provide you with digital media solutions, including creative copywriting, brand development and PR strategies.'
      },
      {
        id: '07676410-2cfb-4ced-ae49-094361dc78b8',
        blurb: 'An experienced marketing automation guru and tech wiz, Pawan can help your business build effective marketing campaigns to engage with customers at a whole new level. Leverage the power of data to create customer profiles and segment your customer base to target them with the right offer at the right time.'
      },
      // {
      //   id: '6f6f233f-c878-407d-8c16-ea47d197862e',
      //   blurb: 'Renata is a Marketing Specialist and Copywriter with more than eight years of experience in digital and social media marketing. She has worked in a diverse set of industries, such as education, tourism, fashion and health, and is able to adapt and create content for your specific field. She\'s a creative marketer that\'s always ready to help with anything from strategy to content.'
      // },
    ],
  },
  greenConsciousness: {
    banner: {
      title: 'Become a sustainable business',
      blurb: 'We’ve rounded a collection of industry-leading advisors to help you create sustainable, environmentally-conscious, energy efficient business practices.',
      slug: '/green-consciousness',
      img: '/images/roundup/green-consciousness.png',
      fullImg: '/images/roundup/green-consciousness-full.png'
    },
    advisors: [
      {
        id: 'ce4719af-3aa1-43ef-8cf6-8ceb16b151b9',
        blurb: 'Jo is a passionate and energetic sustainability and health communicator with postgraduate health science and environmental qualifications. With nearly 20 years of experience in behaviour change, she knows that entertainment and empowerment is key to informing audiences and shaping communities. She\'s the Director of Good for the Hood, a consultancy which offers media, engagement and communication services.'
      },
      {
        id: 'e86d53ca-2e35-48af-896d-f4628c14b7c4',
        blurb: 'Danny is the founder and Principal Advisor at Solar-e, an advisory service and marketplace for New Energy solutions - solar, batteries and EVs. We combine advanced techno-economic modelling capability with deep industry and project expertise to help our customers ‘cut through the noise’ and access the best energy solutions'
      },
      {
        id: 'b65ca2e6-d737-4fd4-b4e6-d28f64b70038',
        blurb: 'Lenka has worked in the energy industry for over 10 years and is passionate about preserving nature while empowering people and communities through sustainable development. She\'s the co-founder of Helixos, a collective of people working to make the world a better place for everyone through projects that focus on the UN Sustainable Development Goals'
      },
      {
        id: '3b05a9dc-7269-4064-8085-a1d2e4ba050a',
        blurb: 'Rodrigo works with providing supply chain solutions to Kering luxury brands like Gucci, Saint Laurent and Balenciaga. He has diverse experience in the supply chain space, food processing and agribusiness industry and sees his strengths in sales, sourcing, logistics and sustainable production. He\'s a driven team player with strong leadership and communication skills'
      },
      {
        id: 'b1c50d5b-395e-410f-a566-f64a947b9de9',
        blurb: 'Brent Clark is the CEO and founder of energy consultancy, Wattblock where he specialises in breaking down the barriers to community solar and has worked in consultation with Tesla on an EV charging in strata study. Wattblock has assisted apartment buildings housing over 65,000 residents with solar feasibility, energy/water efficiency, benchmarking and electric vehicle charging reports.'
      },
      {
        id: '67dae8a9-a084-4029-9810-2db923ef747e',
        blurb: 'Alex has worked in the waste management, pharmaceutical and aerospace industries and is the co-founder of Helixos. He has proven success in navigating complex stakeholder environments with diverse international teams and wants to help companies of all sizes on their sustainability goals.'
      },
    ],
  },
  health: {
    banner: {
      title: 'Power-up your diet',
      blurb: 'Speak to one of our amazing health advisors to get practical guidance on everything from boosting your natural energy levels to fitting good nutrition into a busy lifestyle.',
      slug: '/health',
      img: '/images/roundup/health.png',
      fullImg: '/images/roundup/health-full.jpeg',
    },
    advisors: [
      {
        id: 'd957ee21-bf7c-49b6-b6e1-03d65927aa0f',
        blurb: 'Reaching your health goals can be hard as a stressed and busy professional. Alexandra Joy practises evidence-based medicine and treatments that she tailors to your needs to provide you with an achievable and practical guide. With her help, you can make your health your #1 priority!'
      },
      {
        id: '708489f8-b1cf-47e4-8691-96e29a191084',
        blurb: 'Phoebe is an accredited dietitian and nutritionist who loves to support people in achieving their nutritional goals while improving their relationship with food. She has experience with issues such as food allergies and intolerance, IBS, weight management and much more. With Phoebe, there will be no judgement, only genuine curiosity about your desires for health and wellbeing.'
      },
      {
        id: '1bafb42c-0ec3-4da3-aa84-a2d1c8ac6860',
        blurb: 'Having a holistic approach to well-being, Nina helps her clients not only with nutritional support but also with various lifestyle techniques. Her philosophy is that it\'s not only what you eat but also your emotions and behaviours surrounding food that matters. If you\'re interested in a career in nutrition, Nina is happy to share her experiences and tips.'
      },
      {
        id: '92539c6a-c812-41b9-a6e0-f455b4e5ed3d',
        blurb: 'Jeremy is a qualified hypnotherapist and meditation coach and author of the healthy eating book \'Healthy, Wealthy FAS\'. He has coached clients worldwide on how to have greater physical and mental strength, for clarity, performance and happiness. Jeremy hasn\'t had a sick-day since 2005 and would love to tell you all about it.'
      },
      {
        id: '9c74345e-0198-4861-bdee-a01736ebb55b',
        blurb: 'Rachelle is a dietitian who believes that healthy eating should be fun and enjoyable and is passionate about helping individuals develop a positive and healthy relationship to food. She has years of experience of working with patients as well as a research dietitian, with advanced skills in designing and delivering nutrition interventions. She can give nutrition counselling such as mindfulness, goal setting, and health coaching.'
      },
      {
        id: '0d333e62-62c4-4000-ae4a-11f4b0ab870e',
        blurb: 'Dee is a qualified nutritionist and a personal trainer and has spent years helping people to improve their physical body and emotional wellness. She has developed three pillars of health that are at the core of her practise; what you put in your mouth, how you move your body and what to focus on.'
      },
      {
        id: '7df6a5ec-c786-49bc-a922-602870f307f1',
        blurb: 'Bianca is an Accredited Practising Dietitian with a twist. She has a passion and a drive to improve people\'s confidence in navigating their nutrition and health, as well as maintaining a healthy lifestyle. She believes the key to life is balance and moderation and understands that life is best when it\'s enjoyable.'
      },
    ]
  },
  coaches: {
    banner: {
      title: 'Reach peak performance',
      blurb: 'We have a range of amazing coaches to help you be the best you can be, in everything from communication techniques to effective leadership.',
      slug: '/coaches',
      img: '/images/roundup/coaches.png',
      fullImg: '/images/roundup/coaches-full.jpeg',
    },
    advisors: [
      {
        id: 'fe69fb7d-a6cd-4cb4-979b-77da12f4d745',
        blurb: 'As a coach and virtual speaker, Lison delights in helping individuals and teams make breakthroughs in their thinking and doing, so they can become bigger than they are today. Her personal development program equips clients with the life skills to navigate your way to your preferred outcomes, and the goals you set.'
      },
      {
        id: 'a8679d89-d23b-4e4d-813c-d3ec8eeb99f7',
        blurb: 'For over 20 years, Jessica has lead and worked with cross-cultural teams all over the world. She\'s seen first-hand what it takes to use empathy and communications skills to lead people with different backgrounds efficiently. When leading and influencing people from other cultures, we have to understand several dimensions of behaviours like communication, decisions making authority.'
      },
      {
        id: '5bea750f-a900-40ff-85ad-37b3787e9d67',
        blurb: 'Kevin is a high-performance coach and is dedicated to helping you feel good while performing at your best. He has developed unique strategies to help you reach heightened levels of Clarity, Energy, Courage, Productivity, Influence and Necessity while you\'re on your journey to reach your greatest potential in you personal, and professional life.'
      },
      {
        id: 'cab6142f-6110-42ff-b9c2-475117bb53eb',
        blurb: 'Kirsty is a Master NLP practitioner and a certified meta-coach experienced in working with both individuals and groups. She specialises in helping “people pleasers” get in touch with their values and truth, so they can more confidently assert themselves, and feel a greater sense of freedom and connection with themselves and others.'
      },
      {
        id: 'e0a44ccc-69d9-4db0-b45c-680d964a4c09',
        blurb: 'Susanne provides highly personalised executive, business and career coaching experiences. She\'s passionate about working with exceptional, conscious leaders who are ready to outperform their status quo and make new conscious decisions. She has over 25 years of experience in the field of guiding business leaders to live authentic and fulfilled lives. '
      },
      {
        id: 'b57db624-cd56-4123-bae2-9a4960a5f449',
        blurb: 'Anand advises executives and aspiring leaders on how to use strategies developed by Anand to become a thought leader in their industry or domain. He can help with creating compelling positioning, publications, and personal brand amplification strategies that are actionable and work in real life. He can help you with both your personal brand and growth.'
      },
      {
        id: 'b0d1de61-73d5-4be0-a9fb-f0a536f7a36b',
        blurb: 'When facing a problem or a hard decision, you might need help getting underneath it to get you unstuck. Clare offers a different approach to coaching, where she uses her intuition to ask you the questions that will help you unlock your potential, whether professional, personal or spiritual.'
      },
      {
        id: 'e9ff19ec-3c21-4f09-be38-803bce5fb896',
        blurb: 'Guy Newman has been a Trainer, Keynote Speaker, Leadership Coach, and Consultant for over 30 years. He has worked with everything from Australias largest companies and other international clients to individuals, including Hollywood actors. This is your opportunity to be coached by a former Olympic athlete who knows a thing or two about performance and personal development.'
      }
    ]
  },
  buildWebsite: {
    banner: {
      title: 'Build your own website',
      blurb: 'There are a lot of tools out there to simply create a beautiful website, but if you\'ve never worked in the digital space it might be hard to know where to start.',
      slug: '/build-website',
      img: '/images/roundup/build-website.png',
      fullImg: '/images/roundup/build-website-full.png',
    },
    advisors: [
      {
        id: 'd5d469d7-7adb-4e69-b691-fcb435c72945',
        blurb: 'With 15+ years in digital, Alex has experience in tech startups, product management, user research, digital strategy, data analytics, and business growth. He\'s known for his personal approach, people-first mentality and genuine interest in business growth. Alex has helped early-stage startups, hyper-growth technology companies, and enterprises achieve their goals.'
      },
      {
        id: '7a50a20a-e154-496c-8d21-987986c0cb08',
        blurb: 'Irina specialises in creating digital experiences and content to engage customers, inspire action and drive business value through Website creation, Content strategy and User experience. She has a decade’s experience leading and delivering digital projects for global brands, including Google and Microsoft, as well as small businesses and startups. '
      },
      {
        id: '0671981d-b751-413a-802c-7594848d0545',
        blurb: 'Harlan is passionate about building great web experiences and has worked with many clients, from e-commerce, blogging websites, web apps and APIs. He can help you discover what’s essential in building websites for the modern user by focusing on data-driven design, lean agile development, and the latest and greatest tech available.'
      },
      {
        id: 'a044cfe2-1e12-4c33-a559-24592ec4761b',
        blurb: 'Gretchen helps multidisciplinary creators combine their gifts and design their ideas to life. With a background in branding design, brand marketing and tech, she\'ll help you create and grow a brand that sits right at the intersection of what you love, do well, are rewarded for, and the world needs.'
      },
      {
        id: 'fae02e31-cd87-4b90-81ad-881f77fc2271',
        blurb: 'Bernard is the Co-founder of Ucidity, which incorporates a broad range of skills from Brand Strategy, Graphic Design, Web Development, Social Media and Inbound Marketing, delivering end-to-end solutions for today\'s businesses. If you\'re unsure how to transfer your brand online or your website is just not speaking volumes about the business you\'ve created, chat to Bernard!'
      },
      {
        id: 'de594155-f691-4359-8ffd-f342f5127de1',
        blurb: 'Anshul is a Growth Hacker, User Experience Evangelist, Hardcore Techie and Digital Explorer. In other words, he can help you with your digital project from all different angles, more specifically with user experience design and strategy, application architecture and development, leading a digital team, and growth hacking and CRO.'
      },
    ]
  },
  learnToCode: {
    banner: {
      title: 'Learn to code',
      blurb: 'We’ve gathered some of our top advisors with an array of knowledge in coding for different purposes and languages, so that you can get a head-start on your coding journey.',
      slug: '/learn-to-code',
      img: '/images/roundup/learn-to-code.png',
      fullImg: '/images/roundup/learn-to-code-full.png',
    },
    advisors: [
      {
        id: 'da75af7f-8e4b-4879-884c-df57d7d9b982',
        blurb: 'Leo is an experienced developer and research scientist used to building products from the ground up. His passion in computer science lies in Artificial Intelligence and Multi Agent Systems as well as Functional Programming. He\'s super keen to guide you on your journey to learn coding, from what steps to take to what resources to tap into. He knows what he\'s talking about - Leo\'s been coding since he was 6!'
      },
      {
        id: 'eb7b8d5a-bafc-4579-9d2a-3b1298ce0c25',
        blurb: 'Liam is a seasoned developer who can help you with various subjects related to software development and coding.  For example, suppose you\'re looking to build a performing, secure, scalable, modern and user-facing web/mobile/desktop app. In that case, he can guide you and give you advice on setting up its backend as cost and time effectively as possible and tracking its revenue.'
      },
      {
        id: 'cd62217a-945d-4b90-9705-6b05146dacd9',
        blurb: 'Andrew is a software engineer with broad experience in different technologies and programming languages, as well as clients and projects. He can advise on how to plan and design web systems, develop modern websites and manage tasks and goals along the way.'
      },
      {
        id: 'f39a9334-b18a-4699-b12d-6c29870e4873',
        blurb: 'With over 20 years of professional experience, software developer Leon Bezuidenhout has honed invaluable skills and insights into the field. For the past ten years, his focus has been coaching others to adopt better ways of programming to create business value faster as well as sustainable value.'
      },
      {
        id: '2615e6a3-a960-4c39-9871-57b377de6ed3',
        blurb: 'Kael has been part of the Vuetify core team since 2017 and knows its frameworks very well. In addition, he knows the ins and outs of automated testing, performance, component design and a multitude of programming languages.'
      }
    ]
  },
  personalBrand: {
    banner: {
      title: 'Sharpen your personal brand',
      blurb: 'We’ve gathered some of our top personal branding advisors to help you leverage your creative skills and refine the way you present yourself online and in person.',
      slug: '/personal-brand',
      img: '/images/roundup/personal-brand.png',
      fullImg: '/images/roundup/personal-brand-full.png',
    },
    advisors: [
      {
        id: 'b6ff24fc-c67d-4f63-9215-322fbcfda7b9',
        blurb: 'Phil helps his clients take new perspectives, leverage their creative gifts, become aware of, and mitigate, their blind spots, ultimately developing the habits and strategies that allow them to show up and perform their life\'s work. Phil is your guide if you\'re searching for lasting changes while improving your personal brand.'
      },
      {
        id: 'c1cd30e9-f55e-4008-b5de-c345b39f4b1a',
        blurb: 'With over 20 years of experience as a career coach, Barbara is a true expert in career transition. Her \'Designing Your Life\' sessions can guide you to craft and clarify your story, explore your potential, network authentically and strategically apply for roles and new ventures according to your goals.'
      },
      {
        id: 'e957aecf-4425-4915-8822-c648dc26bd56',
        blurb: 'Olejuru believes there\'s a book in all of us, and your personal brand will grow from it. Today, getting published is no longer reserved for the lucky few, and after having embarked on her own self-publishing journey, she\'s happy to guide you through the steps, from writing your first draft to publish.'
      },
      {
        id: '46603b8d-b61b-4906-8501-649ce8ff288e',
        blurb: 'As an experienced business developer working in HR, Eva has interviewed and reviewed countless CVs applying for both startups as well as large multinational companies. She can help you improve your CV and LinkedIn profile to maximise your professional impact.'
      },
      {
        id: '001e2b40-fc19-49a1-bf11-eba6006e0fe0',
        blurb: 'Are you looking for a styling makeover but not sure where to start? Natalia has over 10 years of experience in working with corporate as well as individual clients as a professional personal stylist. Natalia can help you align your wardrobe and personal style with your personal brand.'
      },
      {
        id: '5cfb8c3c-4ac1-4af0-9fe7-afbea28f708b',
        blurb: 'Helen helps introverted women find their courage and confidently re-enter the workforce, re-energise their current or transition into a new career and life path, or start a new business of their own. She believes in a strength-based approach, helping her clients understand theirs and how they motivate and energise them.'
      }
    ]
  }
}
