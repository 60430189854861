import { $URL } from 'ufo'
import { useAppUrl, useCloudinaryUrl, useNodeEnv } from './env'

type Manipulations = 'c_fit' | 'f_auto' | 'q_auto' | `r_${string}` | `w_${string}` | `h_${string}`

/**
 * Resolve an images provided src. Here we check if we should be using the Cloudinary CDN as a proxy for the image.
 *
 * This lets us do runtime manipulations of the image.
 */
export function resolveImageSrc (src: string, manipulations: Set<Partial<Manipulations>> = new Set()) {
  if (!src) {
    return src
  }
  const isDev = useNodeEnv() === 'development'

  /*
   * Don't transform inline data
   */
  if (src.startsWith('data:')) {
    return src
  }
  const $url = new $URL(src)
  const isAbsolute = !!$url.origin
  /*
   * Relative URLs are pointing to project images.
   * We can't proxy requests for the CDN in development
   * For example: /images/landing/heroes/home.png
   */
  if (!isAbsolute && isDev) {
    return src
  }
  /*
   * For URLs which are absolute and not being served from Kintell, just return the src.
   * For example: https://randomuser.me/api/portraits/men/75.jpg
   */
  if (isAbsolute && !$url.origin.includes('kintell.com')) {
    return src
  }

  // fix relative paths
  if (!isAbsolute) {
    src = useAppUrl() + src
  }
  // fix protocol relative paths
  if (src.startsWith('//')) {
    src = src.replace('//', 'https://')
  }

  // use an automatic format, will selectively use webp if the browser supports it
  manipulations.add('f_auto')
  // apply automatic quality compressions
  manipulations.add('q_auto')

  return `${useCloudinaryUrl()}${Array.from(manipulations).join(',')}/${src}`
}
