export default {
  width: 18,
  height: 18,
  svg: `
    <circle cx="9" cy="9" r="9" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4142 4.41421L6.1716 8.65685 4.75739 7.24264 9.00003 3l1.41417 1.41421z" fill="#fff"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4142 4.41421l2.8285 2.82843-1.4142 1.41421-2.82847-2.82842 1.41417-1.41422z" fill="#fff"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 5v9H8.00003V5H10z" fill="#fff"/>
  `
}
