export default {
  width: 45,
  height: 45,
  stroke: false,
  svg: `
    <g fill="none">
      <circle cx="22.5" cy="22.5" r="21.5" stroke="white" stroke-width="2"/>
      <line x1="18.5" y1="15.5" x2="18.5" y2="30.5" stroke="white" stroke-width="3" stroke-linecap="round"/>
      <line x1="26.5" y1="15.5" x2="26.5" y2="30.5" stroke="white" stroke-width="3" stroke-linecap="round"/>
    </g>
`,
}
