/**
 * ChangesScroll mixin handles page movement.
 */
export default {
  /**
   * On mount we scroll to any hashes that are set
   */
  mounted () {
    // if the user has a url hash then we try and scroll to that ref
    if (this.hash && this.hash.length > 0 && this.$refs[this.hash]) {
      setTimeout(async () => {
        await this.scrollToRef(this.hash, { offset: 50, hashURL: false })
        this.lastScrolledRef = this.hash
      }, 1000)
    }
  },
  data () {
    return {
      lastScrolledRef: null,
    }
  },
  computed: {
    hash () {
      return window.location.hash.replace('#', '')
    }
  },
  methods: {
    /**
     * @param duration default is 300, defines the amount of time the scroll should go for
     * Scrolls to the top of the page when called
     * @returns {void | Promise<void>}
     */
    scrollToTop (duration = 300) {
      this.$nextTick(() => {
        this.$vuetify.goTo(0, {
          duration
        })
      })
    },
    /**
     * Scrolls to a given target with modifiers if passed
     * @param target the target component that the page should be scrolled to
     * @param offset is the verticle offset of the scroll
     * @param duration of the scroll
     * @returns {void | Promise<void> | *}
     */
    scrollToTarget (target, {
      offset = 0,
      duration = 300
    } = {}) {
      if (!target) {
        return
      }
      return this.$vuetify.goTo(target, {
        duration,
        offset
      })
    },
    /**
     * Scroll to a specific ref using the ref name
     * @param ref
     * @param offset
     * @param duration
     */
    scrollToRef (ref, options) {
      options = {
        offset: 0,
        duration: 300,
        hashURL: true,
        ...options
      }
      if (!this.$refs[ref]) {
        this.$logger.warn('Trying to scroll to invalid ref: ' + ref)
        return
      }
      // Add the window location to the URL
      if (options.hashURL) {
        window.location.hash = ref
      }
      // checking if the ref is array
      // this was for one edge case on the faq page, it was an array for some reason...
      return this.scrollToTarget(
        this.$refs[ref].constructor === Array ? this.$refs[ref][0] : this.$refs[ref],
        options
      )
    }
  }
}
