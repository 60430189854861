import User from './User'
import { JSONValue, Message } from '@twilio/conversations'

export interface ChatMessageAction {
  text: string,
  callback: () => void,
  loading?: boolean | (() => boolean),
}

export class ChatMessage {
  system = false
  id = String(Math.random())
  body: string
  sent = false
  disabled: boolean | (() => boolean) = false
  filename: string | null | undefined
  isImage: boolean | undefined
  date: Date
  twilioMessage?: Message
  actions?: ChatMessageAction[]
  author: User | undefined
  attributes?: JSONValue

  constructor (data: any) {
    Object.assign(this, data)
    this.date = this.twilioMessage?.dateCreated ?? new Date()
  }
}
