import Vue from 'vue'

export const DownloadsFiles = Vue.extend({
  methods: {
    async downloadFile (url: string, fileName: string) {
      const response = await this.$axios.get(url, {
        responseType: 'blob',
      })
      const blob = window.URL.createObjectURL(new Blob([ response.data ]))
      const link = document.createElement('a')
      link.href = blob
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
    }
  }
})
