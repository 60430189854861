// pathify
import { make } from 'vuex-pathify'

const state = () => {
  return {
    tooltips: {},
    cards: {},
  }
}

// getter overrides
const getters = {
  ...make.getters(state)
}

// automatically generate mutations
const mutations = {
  // create SET_VALUE automatically
  ...make.mutations(state)
}

// manually-created actions
const actions = {
  ...make.actions(state),
}

// export store
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
