import Consola, { BugsnagReporter } from '@/lib/consola'
const logger = new Consola('kintell:app')

export default ({ app: { $bugsnag } }, inject) => {
  if (typeof window !== 'undefined') {
    window.$consola = logger
  }
  inject('logger', logger)
  if ($bugsnag) {
    logger.addReporter(new BugsnagReporter({ bugsnag: $bugsnag }))
  }
  logger.trace('Logger Ready!')
}
