import { Permissions, canPerform, hasGroupRole, GroupRolesCollections, getGroupRole } from '@/lib/util'
import { UsesAuthenticatedUser } from '@/mixins/index'

export default {
  data () {
    return {
      permissions: Permissions,
      groupRolesCollections: GroupRolesCollections
    }
  },
  mixins: [ UsesAuthenticatedUser ],
  methods: {
    canPerform (permissionName, kintellGroup) {
      return canPerform(permissionName, kintellGroup, this.authenticatedUser || null)
    },
    hasGroupRole (rolesToCheck, userGroupRoles) {
      return hasGroupRole(rolesToCheck, userGroupRoles)
    },
    getGroupRole (userGroupRoles, kintellCards) {
      return getGroupRole(userGroupRoles, kintellCards)
    },
    authenticatedUserHasGroupRole (rolesToCheck, kintellGroup) {
      if (!this.userIsAuthenticated) {
        return false
      }
      const matchingKintellGroup = this.authenticatedUser
        .kintellGroups
        .find(currentGroup => currentGroup.id === kintellGroup.id)

      if (!matchingKintellGroup) {
        return false
      }

      return this.hasGroupRole(rolesToCheck, [ matchingKintellGroup.role ])
    }
  },
}
