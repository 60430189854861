import Model from './Model'
import User from './User'
import moment from 'moment-timezone'
import { useBytes } from '@/composables'


interface VideoReportEntry {
  avatar: string,
  name: string,
  lastViewed: string,
  lastViewedFriendly: string,
  numberOfViews: number,
}

export default class Video extends Model {
  public status: 'pending-callback' | 'complete' | 'ingest' | 'error'
  public createdBy: User|string
  public audioSource: string
  public videoSource: string
  public egressDash: string
  public egressHls: string
  public slug: string
  public visibility: 'public-with-link' | 'private'
  public canLinkDownload: boolean
  public permissions: Permissions
  public createdAt: moment.Moment
  public reporting: VideoReportEntry[]

  public sourceBytes: number
  public audioSourceBytes: number
  public audioSizeFormatted: string
  public videoSizeFormatted: string

  resource () {
    return 'videos'
  }

  downloadUrl (type = 'video') {
    return this.resourceUrl() + `/download?type=${type}`
  }

  signedUploadUrl (data: Object) {
    return this.$http.$post(this.resourceUrl() + `/upload-url`, data)
  }

  /**
   * Transform an object of data before it is used to fill this model.
   * Note: Make sure you're referring to data and not this as it wil break things.
   * @param data
   * @returns {{bookingTimeUtc}|*}
   */
  transform (data: Record<string, any>) {
    if (!data) {
      return data
    }
    if (data.createdBy && typeof data.createdBy !== 'string') {
      data.createdBy = new User(data.createdBy)
    }
    if (data.createdAt) {
      data.createdAt = moment.utc(data.createdAt)
    }
    if (data.videoExpiresAt) {
      data.videoExpiresAt = moment.utc(data.videoExpiresAt)
    }
    data.videoSizeFormatted = useBytes(this.sourceBytes || 0).value
    data.audioSizeFormatted = useBytes(this.audioSourceBytes || 0).value
    // flag it as real data
    // data.isGhost = false
    return data
  }

  get privateUrl () {
    return `/my-kintell/videos/${this.id}`
  }

  get publicLink () {
    return `/v/${this.slug}`
  }

  get publicFullLink () {
    return `${process.env.APP_URL}${this.publicLink}`
  }
}
