export default {
  width: 38,
  height: 24,
  svg: `
    <path fill-rule="evenodd"
        d="M35.153 2.67521c.4086.22855.6595.64559.6595 1.09627V20.2275c0 .4507-.2509.8678-.6595 1.0963-.4086.2286-.9139.2345-1.3283.0156l-10.3862-5.4853c-.646-.3412-.8787-1.1155-.5199-1.7296.3589-.6141 1.1735-.8354 1.8194-.4942l8.3985 4.4355V5.9332l-8.3985 4.4355c-.6459.3412-1.4605.1199-1.8194-.49415-.3588-.61408-.1261-1.38846.5199-1.72962l10.3862-5.48535c.4144-.21887.9197-.21293 1.3283.01563Z"
        clip-rule="evenodd"/>
    <path fill-rule="evenodd"
          d="M4.28729 2.59253c-.39833 0-.78034.1503-1.06201.41783-.28166.26753-.43989.63037-.43989 1.00871V19.9809c0 .3784.15823.7412.43989 1.0087.28167.2676.66368.4179 1.06201.4179H21.0252c.3983 0 .7804-.1503 1.062-.4179.2817-.2675.4399-.6303.4399-1.0087V4.01907c0-.37834-.1582-.74118-.4399-1.00871-.2816-.26753-.6637-.41783-1.062-.41783H4.28729ZM1.3883 1.26555C2.15716.535267 3.19996.125 4.28729.125H21.0252c1.0873 0 2.1301.410267 2.899 1.14055.7689.73028 1.2008 1.72075 1.2008 2.75352V19.9809c0 1.0328-.4319 2.0233-1.2008 2.7535-.7689.7303-1.8117 1.1406-2.899 1.1406H4.28729c-1.08733 0-2.13013-.4103-2.89899-1.1406-.768859-.7302-1.2008-1.7207-1.2008-2.7535V4.01907c0-1.03277.431941-2.02324 1.2008-2.75352Z"
          clip-rule="evenodd"/>
    <path transform="translate(0, -7)" d="m10.0932 22-1.48804-2.048h-.752V22h-1.088v-5.6h2.24c1.27204 0 2.05604.68 2.05604 1.808 0 .824-.504 1.424-1.29604 1.648L11.4052 22h-1.312Zm-2.24004-4.632v1.672h1.096c.608 0 .968-.28.968-.832 0-.568-.368-.84-.968-.84h-1.096Zm7.54314 0h-2.832v1.296h2.488v.952h-2.488v1.416h2.832V22h-3.928v-5.6h3.928v.968Zm2.7105 4.728c-1.64 0-2.808-1.176-2.808-2.896s1.168-2.896 2.808-2.896c1.208 0 2.144.64 2.504 1.784l-1 .368c-.248-.712-.784-1.152-1.504-1.152-.968 0-1.664.712-1.664 1.896s.696 1.896 1.664 1.896c.72 0 1.256-.44 1.504-1.152l1 .368c-.36 1.144-1.296 1.784-2.504 1.784Z"></path>
  `
}
