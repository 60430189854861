import Model from './Model'
import User from './User'
import KintellCard from './KintellCard'
import Booking from './Booking'

export default class Review extends Model {
  resource () {
    return 'reviews'
  }

  /**
   * Transform an object of data before it is used to fill this model.
   * Note: Make sure you're referring to data and not this as it wil break things.
   * @param data
   * @returns {{Review|*}
   */
  transform (data) {
    if (!data) {
      return data
    }
    if (data.createdByUser) {
      data.createdByUser = new User(data.createdByUser)
    }
    if (data.createdForUser) {
      data.createdForUser = new User(data.createdForUser)
    }
    if (data.kintellCard) {
      data.kintellCard = new KintellCard(data.kintellCard)
    }
    if (data.booking) {
      data.booking = new Booking(data.booking)
    }

    return data
  }
}
