export default {
  watch: {
    isShowing () {
      this.$emit('popup', this.isShowing)
    },
    showNow: {
      handler () {
        this.popUpLogic()
      },
      immediate: true
    },
    value: {
      handler () {
        this.popUpLogic()
      },
      immediate: true
    }
  },

  beforeDestroy () {
    clearTimeout(this.timeoutId)
    this.isLoading = false
  },
}
