//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { UsesAuthenticatedUser, UsesKintellGroup, UsesKintellGroupPermission } from '@/mixins'
import { get } from 'vuex-pathify'

export default {
  mixins: [
    UsesAuthenticatedUser,
    UsesKintellGroup,
    UsesKintellGroupPermission
  ],

  computed: {
    ...get({
      showDrawer: 'layout/showDrawer',
      showDarkHeader: 'layout/showDarkHeader',
    }),

    logoColor () {
      let text
      let outline
      let bg

      if (this.showDrawer) {
        text = '#fff'
        outline = '#fff'
        bg = 'transparent'
      }

      if (this.showDarkHeader) {
        text = '#fff'
      }

      return {
        text,
        outline,
        bg,
      }
    },

    activeLogo () {
      const kintellGroup = this.activeKintellGroup
      if (kintellGroup && kintellGroup?.logoUrl) {
        let link = `/g/${kintellGroup.slug}`

        // if the group is closed we don't want them to click the logo
        if (!this.canPerform(this.permissions.CLICK_LOGO, kintellGroup)) {
          link = '#'
        }

        return {
          url: kintellGroup.logoUrl,
          link,
        }
      } else {
        return {
          link: '/'
        }
      }
    },
  },
}
