import { debounce } from 'lodash-es'

// create wrapper function around window.dataLayer.push
// ideally it would inject global variables on each push
const defaults = {
  version: process.env.VERSION,
  api: process.env.API_URL,
  env: process.env.NODE_ENV
}

/**
 * Push a google tag manager data layer event.
 * @param event The name of the custom data layer event
 * @param data The data attached to the event
 */
export const push = (event: string, data: any = {}) => {
  try {
    window.$consola.debug('GTM Event', event, data)
    // only if the data layer object is available
    if (typeof window.dataLayer === 'undefined') {
      return
    }
    // get the default data variables
    // example userAuthenticated: true, userId: userModel.email, userType:  userModel.type
    window.dataLayer.push({ event, ...data, ...defaults })
  } catch (error) {
    console.error(error.message)
  }
}

/**
 * Avoid spamming google tag manager and Matomo by debouncing certain pushes. Delay is 3 seconds
 */
export const debouncedPush = debounce((event, data = {}) => {
  push(event, data)
}, 3000)

// Triggers every time any page loads and it sends version number and api url
export const INIT = 'kintell.init'
// Triggers every time if user has authenticated it sends user id and usertype or else just default info
export const PAGE_VIEW = 'kintell.page'
// Triggers every time a user changes from unauthenticated to authenticated
export const AUTHENTICATED = 'kintell.authenticated'
// Trigger when a guest identifies themselves but does not sign in
export const IDENTIFIED = 'kintell.identified'

// form related events
export const FORM = {
  // general frontend validation error
  VALIDATION_ERROR: 'kintell.form.validation-error',
  // for errors returned from the API
  API_ERROR: 'kintell.form.api-error',
  // submit was successful
  SUBMITTED: 'kintell.form.submitted',
}

// Sharing related events
export const SHARE = {
  NATIVE: 'kintell.share.native',
  COPY: 'kintell.share.copy',
}

// When first time enters mobile number at sign up page or modal, and triggers when it successfully verified
export const PHONE_VERIFICATION = {
  START: 'kintell.phone-verification.start',
  BAD_PHONE: 'kintell.phone-verification.bad-phone',
  SENT: 'kintell.phone-verification.sent',
  BAD_CODE: 'kintell.phone-verification.bad-code',
  CONFIRMED: 'kintell.phone-verification.confirmed',
  RESEND: 'kintell.phone-verification.resend'
}


// Triggers when a user enters information on the sign up form
export const SIGN_UP_FORM = {
  EMAIL: 'kintell.sign-up.email',
  PASSWORD: 'kintell.sign-up.password',
  LOCATION: 'kintell.sign-up.location',
  INVALID_CODE: 'kintell.sign-up.invalid-code',
  NO_CODE: 'kintell.sign-up.no-code',
  // Triggers when new user signed up
  COMPLETE: 'kintell.signed-up'
}

// Sign up / personal details form photo editing
export const PHOTO_UPLOAD = {
  START: 'kintell.photo-upload.start',
  UPLOADED: 'kintell.photo-upload.uploaded',
  BAD_SIZE: 'kintell.photo-upload.bad-size',
  BAD_RESOLUTION: 'kintell.photo-upload.bad-resolution',
  BAD_TYPE: 'kintell.photo-upload.bad-type',
  ROTATE: 'kintell.photo-upload.rotate',
  ZOOM: 'kintell.photo-upload.zoom',
  MOVE: 'kintell.photo-upload.move',
  RESTART: 'kintell.photo-upload.restart',
  COMPLETE: 'kintell.photo-upload.complete',
}

// My rewards
export const INVITE = {
  // resent an email invite
  RESEND: 'kintell.invite.resend',
  // copied invite link
  COPY: 'kintell.invite.copy',
}

// Kintell Card related events
export const KINTELL_CARD = {
  PAGE: 'kintell.kintell-card.page',
  CREATE: 'kintell.kintell-card.create',
  DELETE: 'kintell.kintell-card.delete',
  UNPUBLISH: 'kintell.kintell-card.unpublish',
  PUBLISH: 'kintell.kintell-card.publish',
  PUBLISH_CHANGES: 'kintell.kintell-card.publish-changes',
  SAVE: 'kintell.kintell-card.save',
}

export const KINTELL_CARD_APPROVAL = {
  SEND_FOR_REVIEW: 'kintell.kintell-card-approval.send-for-review',
  // @todo Implement
  REJECTED: 'kintell.kintell-card-approval.rejected',
  // @todo Implement
  APPROVED: 'kintell.kintell-card-approval.approved',
}

export const REQUEST_INVITE = {
  OPENED: 'kintell.request-invite.opened',
  SENT: 'kintell.request-invite.sent',
}

export const MEETING = {
  JOIN: 'kintell.meeting.join',
  DEVICE_CHANGE: 'kintell.meeting.device-change',
  DIMENSIONS_CHANGED: 'kintell.meeting.dimensions-changed',
  TOGGLE_SOUND: 'kintell.meeting.toggle-sound',
  TOGGLE_VIDEO: 'kintell.meeting.toggle-video',
  TOGGLE_SCREENSHARE: 'kintell.meeting.toggle-screenshare',
  TOGGLE_FULLSCREEN: 'kintell.meeting.toggle-fullscreen',
  ENDED: 'kintell.meeting.ended',
  ERROR: 'kintell.meeting.error'
}

export const BOOKING = {
  PAGE: 'kintell.booking.page',
  BOOK_BUTTON: 'kintell.booking.book-button',
  CREATED_PROVISIONAL: 'kintell.booking.created-provisional-booking',
  COMPLETE_STEP_SELECT_TIMES: 'kintell.booking.complete-step-select-times',
  COMPLETE_STEP_AUTH: 'kintell.booking.complete-step-auth',
}

export const SEARCH = {
  QUERY: 'kintell.search.query',
}

export const PASSWORD = {
  FORGOT: 'kintell.password.forgot',
  RESET: 'kintell.password.reset',
}
