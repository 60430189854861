import { User } from '@/models'
import { Auth } from '@nuxtjs/auth'

interface AuthFields {
  email?: string,
  firstName?: string,
  lastName?: string,
  isCampaign?: boolean,
}
async function authenticateAsPlaceholder (fields: AuthFields, auth: Auth) {
  const response = await (new User())
    .action('placeholder', fields)
  await auth.setUserToken(response.jwt)
  // Reload the page to allow any async data to load
  location.reload()
}

export {
  authenticateAsPlaceholder
}
